import React from 'react';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

export default function SirenSiretPickup(props) {
    const columnConfig = [
        {
            text: 'text.data',
            field: 'siren_siret',
            noSort: 'true',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.is_siren',
            field: 'is_siren',
            noSort: 'true',
            render: (text, record) => {
                return record.is_siren ? <wrap><item><icon>done</icon></item></wrap>
                              : <wrap><item effect="material"><icon>close</icon></item></wrap>
            },
            width: 130,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.is_siret',
            field: 'is_siret',
            noSort: 'true',
            render: (text, record) => {
                return record.is_siren ? <wrap><item effect="material"><icon>close</icon></item></wrap>
                                : <wrap><item><icon>done</icon></item></wrap>
            },
            width: 130,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.existe_bdd',
            field: 'existe',
            noSort: 'true',
            render: (text, record) => {
                return record.existe ? <wrap><item><icon>done</icon></item></wrap>
                              : <wrap><item effect="material"><icon>close</icon></item></wrap>
            },
            width: 130,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.imported',
            field: 'updated',
            noSort: 'true',
            render: (text, record) => {
                return record.updated ? <wrap><item><icon>done</icon></item></wrap>
                              : <wrap><item effect="material"><icon>close</icon></item></wrap>
            },
            width: 130,
            type: COLUMN_TYPE.TEXT,
        }
    ];

    return (<>
        <ListView
            isSimple
            skipEdit
            {...props}
            skipNewButton
            columnConfig={columnConfig}
            dataSource={props.selected}
        />
    </>);
}