import React from 'react';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';

import { T } from '~/components/Translations';
import { Project } from '../../../project/Project';

/**
 *
 * @exports Sales Order
 */
export default function SalesOrder(props) {

    const columnConfig = [
        {
            text: 'text.identifiant',
            field: 'user_login',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.code_client',
            field: 'customer_code',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.reference',
            field: 'reference',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.sales_network',
            field: 'reseau_nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.raison_sociale',
            field: 'raison_sociale',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.created_at',
            field: 'date_commande',
            format: COLUMN_FORMAT.DATE, 
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.etat',
            field: 'etat',
            width: 80,
            type: COLUMN_TYPE.TEXT,
            render: v =>
                (v === "attente" ? <wrap><text>traitement</text></wrap> : <wrap><text>{ v }</text></wrap>),
        },
        {
            text: 'text.address',
            field: 'livraison_adresse',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.code_postal',
            field: 'livraison_code_postal',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.pays',
            field: 'livraison_pays',
            width: 90,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.type',
            field: 'is_en_compte',
            width: 100,
            type: COLUMN_TYPE.TEXT,
            render: v =>
                (v ? <wrap><text><T>text.en_compte</T></text></wrap> : <wrap><text><T>text.payment_cb</T></text></wrap>),
        },
        {
            text: 'text.updated_at',
            field: 'updated_at',
            format: COLUMN_FORMAT.DATETIME, 
            width: 130,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    return (
        <ListView
            {...props}
            skipNewButton
            columnConfig={columnConfig}
            defaultSorting={{ Member: "date_commande", SortDirection: 1 }}
            apiUrl={ApiUrl.SalesOrderList}
            filters={[
                { name: 'user_login', type: 'text', text: 'text.identifiant' },
                { name: 'customer_code', type: 'text', text: 'text.code_client' },
                { name: 'etat', type: 'select', text: 'text.etat', valuelist: 'etat_status' },
                { name: 'reference', type: 'text', text: 'text.reference' },
                //{ name: 'reseau_nom', type: 'text', text: 'text.sales_network' },
                { name: 'reseau_uuid', type: 'select', text: 'text.sales_network', valuelist: 'reseaux' },
                { name: 'siret', type: 'text', text: 'text.user' },
                { name: 'raison_sociale', type: 'text', text: 'text.raison_sociale' },
                { name: 'livraison_adresse', type: 'text', text: 'text.address', includeEmpty: true },
                { name: 'livraison_code_postal', type: 'text', text: 'text.code_postal', includeEmpty: true },
                { name: 'livraison_pays', type: 'text', text: 'text.pays', includeEmpty: true },
                { name: 'date_commande', type: 'date', text: 'text.created_at' }
            ]}
            listUpdateEvent={EVENT_NAME.SALES_ORDER_LIST_UPDATE}
            detailUrl={Project.getPageUrl(AppPages.JobsSalesOrderDetails)}
            detailProps={{
                fullScreen: false,
                editTitle: 'text.sales_order',
              //  popupType: PopupTypes.SalesOrderDetails,
                windowKey: 'frmSalesOrderDetails',
            }}
            listName={AppPages.JobsSalesOrder}
            showRefreshButton={false}
        />
    );
}