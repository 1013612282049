import React from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { T } from '~/components/Translations';

import { Input, Upload } from 'antd';

import { TranslationImport, TranslationExport} from './actions/translationAction';
import ImportTranslation from './importDetail';
import { openPopup } from '~/store/actions';
import { PopupTypes } from '~/components/Popup';
import { getLanguages } from '~/Shared/Translation';
import UITranslationDetails from './detail';


const listUpdateEvent = EVENT_NAME.SETUP_TRANSLATION_LIST_UPDATE;

/**
 * Recherche No Serie list of setup
 * @exports RechercheNoSerie
 */
export default function Translation(props) {
    const dispatch = useDispatch();
    const cultures = getLanguages();

    const columnConfig = [
        {
            text: 'text.code',
            field: 'code',
            readOnly: true,
            fixed: 'left',
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        ...(cultures.map(l => ({
            text: l.text,
            key: l.value,
            dataIndex: 'traduction',
            width: 300,
            type: COLUMN_TYPE.TEXT,
            render: (item, index) => {
                let _item = JSON.parse(item.traduction);
                return <wrap>{_item && _item[l.value]}</wrap>
            },
            editor: {
                customRenderer: (props) => {
                    const { editingRecord, setEditingRecord } = props;
                    const { traduction } = editingRecord;
                    const _traduction = JSON.parse(traduction);
                    return (
                        <Input
                            type='text'
                            value={_traduction[l.value]}
                            onChange={(e) => {
                                _traduction[l.value] = e.target.value;
                                setEditingRecord({
                                    ...editingRecord, traduction: JSON.stringify(_traduction)
                                });
                            }}
                            className={'ant-input'}
                        />
                    )
                }
            },
            
        }))),
        {
            fixed: 'right',
            type: COLUMN_TYPE.ACTION,
            onBeforeAction: (record, command) => {
                switch (command) { // NOSONAR
                    case 'delete':
                        if (record.from_json) {
                            message.warning(<T>System key</T>);
                            return false;
                        }
                        break;
                    default:
                        break;
                }
                return true;
            }
        }
    ];
    
    const importData = (file, onSuccess) => {
        const windowKey = 'importWnd';
        TranslationImport(file, listUpdateEvent, (response) => {
            if (response && response.Data) {
                dispatch(openPopup({
                    component: ImportTranslation,
                    windowKey,
                    title: "Import",
                    type: PopupTypes.Popup,
                    //className: popupClassName,
                    fullScreen: false,
                    bodyProps: { windowKey, fileData: response.Data }
                }));
            }
        })
    }

    const onNewTranslation = () => {
        const windowKey = 'transWnd';
        dispatch(openPopup({
            component: UITranslationDetails,
            windowKey,
            title: <T>text.translations</T>,
            type: PopupTypes.Popup,
            fullScreen: false,
            bodyProps: { windowKey }
        }));
    }

    return (
        <>
            <toolbar transparent='transparent'>
                <action right="">
                    <div
                        effect="material"
                        className="button primary"
                        onClick={() => TranslationExport()}
                    >
                        <icon>download</icon><text><T>text.export</T></text>
                    </div>

                    <Upload 
                        id="uploader" 
                        customRequest={importData} 
                        showUploadList={false}
                    >
                        <div className='button primary' title="Import"><icon>upload</icon><text>Import</text></div>
                    </Upload>
                </action>
            </toolbar>
            <ListView
                {...props}
                columnConfig={columnConfig}
                editable
                className="scroll_fix"
                apiUrl={ApiUrl.SetupTranslationList}
                filters={[
                    { name: 'code', type: 'text', text: 'text.code' }, 
                    { name: 'traduction', type: 'text', text: 'text.translation' }
                ]}
                inlineUpdateUrl={ApiUrl.SetupTranslationUpdate}
                //skipNewButton
                nameField='code'
                deleteUrl={ApiUrl.SetupTranslationDelete}
                onNewClick={onNewTranslation} //NOSONAR
                listUpdateEvent={listUpdateEvent}
                listName={AppPages.SetupTranslation}
                // scroll={{ x: (1000 + (letters.length * 50)), y: 300 }}
                scroll={{ x: 300, y: 300 }}
                showRefreshButton={false} 
            />
        </>
    );
}
