import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Project } from 'project/Project';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields';

import { T } from 'components/Translations';
import DropDownValueList from '../../../Shared/DropDown';

import { getLanguages } from '~/Shared/Translation';
import { ValidationInput, ValidationDropDownValueList } from '../../../Shared/Form/validation';

const defaultValidationFields = [
    "login", { name: "email", rules: [{ type: "required" }, { type: "email" }] }, "Role"
];

/**
 * ...
 * @exports AdministratorsDetails
 */
export default function AdministratorsDetails(props) {
    const [validationFields, setValidationFields] = useState(defaultValidationFields);

    const cultures = getLanguages();
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.ADMINISTRATORS_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.SystemUserUpdate,
        dataLoadUrl: ApiUrl.SystemUserLoad,
        deleteUrl: ApiUrl.SystemUserDelete,
        nameField: 'login',
        backPage: AppPages.Administrators,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.AdministratorsDetails),
        changingValidation: true,
        validationFields
    });
    const isWebStationAdmin = dataItem && (dataItem.Role === "callcenter" || dataItem.Role === "dispatcher");
    useEffect(() => {
        if (isWebStationAdmin) {
            setValidationFields([...defaultValidationFields, "reseau_uuid"]);
        } else {
            setValidationFields([...defaultValidationFields]);
        }
    }, [isWebStationAdmin, isNew]);// eslint-disable-line react-hooks/exhaustive-deps

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    const changeRole = (e) => {
        const dataItemCopy = { ...dataItem };

        dataItemCopy.Role = e;
        dataItemCopy.reseau_uuid = null;
        setDataItem(dataItemCopy);
    }

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.Administrators)}
            title={isNew ? <T>text.new_administrators</T> : dataItem.login}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.identifiant">
                                <ValidationInput validation={validation} name="login" type='text' autoComplete="new-password" value={dataItem.login} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.password">
                                <input name='Password' type='password' value={dataItem.Password || ''} autoComplete="new-password" onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.email">
                                <ValidationInput validation={validation} name='email' type='text' value={dataItem.email} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.culture">
                                <DropDownValueList name='culture' type='text' value={dataItem.Culture} items={cultures} onChange={e => onChangeData({ target: { name: 'Culture', value: e } })} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.roles">
                                <ValidationDropDownValueList disableCach validation={validation} name='Role' type='text' valuelist='admin_roles' value={dataItem.Role}
                                    onChange={e => changeRole(e)} // NOSONAR
                                // onChange={e => onChangeData({ target: { name: 'Role', value: e } })}
                                />
                            </FormField>
                            {
                                isWebStationAdmin &&
                                <FormField label="text.reseau">
                                        <ValidationDropDownValueList validation={validation} name='reseau_uuid' value={dataItem.reseau_uuid} valuelist='reseaux' onChange={e => onChangeData({ target: { name: 'reseau_uuid', value: e } })} // NOSONAR
                                        />
                                </FormField>
                            }
                            {
                                dataItem.Role === 'dispatcher' &&
                                <FormField label="text.erp_employee_code">
                                    <ValidationInput validation={validation} name='erp_employee_code' type='text' maxLength="5" value={dataItem.erp_employee_code} onChange={onChangeData} />
                                </FormField>
                            }
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID && !dataItem.IsCurrentUser &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon><T>text.delete</T>
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
