import React, { useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';


import { ApiUrl } from 'project/Defines.js';
import { Ajax } from '../../../components/Ajax';
import { PopupTypes } from '../../../components/Popup';
import { openPopup, closePopup } from '../../../store/actions';
import { T /*, Input, DataTip*/ } from '../../../components/Translations';
import { showLoader, hideLoader } from 'Shared/Loader';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';


const loaderName = 'SUBSTITUTE_LOADER';
/**
 * the Substitute list of SparePart in substitute tab
 * @exports SparePart-Substitute
 */
function Substitute(props) {
    const windowKey = "wndSparePartPicker";
    const { dataItem, openPopup, closePopup } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [postData, setPostData] = useState(null);

    useEffect(() => {
        if (postData && isLoading) {
            Ajax.post({
                url: ApiUrl.ArticleSubstitute,
                data: postData,
                success: function (response) {
                    if (response) {
                        if (response.HasError) {
                            message.error(response.Message)
                        } else {
                            message.success(response.Message)
                        }
                    }
                    setIsLoading(false);
                    hideLoader(loaderName);

                    dispatchCustomEvent(EVENT_NAME.PRODUCT_LIST_UPDATE);
                },
                error: function (response) {
                    hideLoader(loaderName);
                }
            });
        }
    }, [postData, isLoading]);

    const openSparePart = useCallback(() => {
        openPopup({
            windowKey,
            fullScreen: false,
            className: "middle",
            title: <T>text.select_an_article</T>,
            type: PopupTypes.SubstituteSparePartPicker,
            bodyProps: {
                ID: dataItem.ID,
                updateData: (record) => {
                    setPostData({
                        article_uuid: dataItem.ID,
                        substitute_uuid: record.ID
                    });
                    
                    closePopup(windowKey)
                    setIsLoading(true);
                    showLoader(loaderName);
                }
            }
        });
    }, [dataItem, closePopup, openPopup])

    return (<>
        <div onClick={openSparePart} className='button'><icon>process</icon><T>text.substitute</T></div>
    </>);
}

export default connect(null, (dispatch) => ({
    openPopup: (data) => dispatch(openPopup(data)),
    closePopup: (windowKey) => dispatch(closePopup(windowKey))
}))(Substitute);

