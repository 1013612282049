import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { message } from 'antd';

import { Ajax } from 'components/Ajax';
import { ApiUrl, UserRoles } from 'project/Defines.js';
import { TabStrip } from 'Shared/TabStrip';

import Products from './Products';
import Videos from './Videos';
import Normes from './Normes';
import Infos from './Infos';
import Evolutions from './Evolutions';
import Support from './Support';
import CustomerWarranty from './CustomerWarranty';
import ProductWarranty from './ProductWarranty';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import TreeView from 'Shared/TreeView';
import Translation from 'Shared/Translation';
import { T } from 'components/Translations';

import { openPopup } from 'store/actions';
import { PopupTypes } from 'components/Popup.js';
import { hideLoader, showLoader } from '~/Shared/Loader';
import FamillySkills from './FamillySkill';

const loaderBackup = 'BACKUP_LOADER';

/**
 * the universe Family tabstrip and main family tabb
 * @exports Universe-Family
 */
function Family(props) {
    const param_familles = new URLSearchParams(props.location.search).get("familles");
    const param_tab = new URLSearchParams(props.location.search).get("sub");
    const dispatch = useDispatch();
    const { history } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const [family, setFamily] = useState(null);
    const [allowRestore, setAllowRestore] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const { ID, dataItem, role } = props;
    const detailRef = useRef(null);
    const onLoad = useCallback((data, callback) => { // NOSONAR
        if (!family) {
            const key = selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : param_familles;
            if (key) {
                let filter = data.filter((f) => f.key === key);
                if (filter && filter.length > 0)
                    setFamily(filter[0]);
                else {
                    //expand
                    Ajax.get({
                        url: ApiUrl.FamiliesLoad,
                        data: { id: key },
                        success: function (response) {

                            let expandedKeys = response.path_uuid.split('|') || [];
                            callback && callback(expandedKeys);
                        }
                    })
                }
            }
        } else {
            //expand
            Ajax.get({
                url: ApiUrl.FamiliesLoad,
                data: { id: family.uuid },
                success: function (response) {

                    let expandedKeys = response.path_uuid.split('|') || [];
                    callback && callback(expandedKeys);
                }
            })
        }
    }, [family, selectedKeys, param_familles]);
    const isReadOnlyMode = role === UserRoles.DISPATCHER;
    const isSuperAdmin = props.role === UserRoles.SUPERADMIN;

    useEffect(() => {
        detailRef.current = true;

        return (() => { detailRef.current = false })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (param_familles) {
            let keys = [];
            keys.push(param_familles)
            setSelectedKeys(keys);
        }
        if (param_tab)
            setTabIndex(parseInt(param_tab));
    }, [param_familles, param_tab]);

    const tabFilter = useCallback((tab) => { return !isReadOnlyMode || tab.props.dispatcher }, [isReadOnlyMode])

    const changeUrl = useCallback((tab, key) => {
        if (key) {
            history.push('?tab=' + (isSuperAdmin ? '1' : '0') + '&sub=' + tab + '&familles=' + key);
        }
    }, [history, isSuperAdmin]);

    const onDoublClick = useCallback((event, node) => {
        if (node.uuid) {
            let keys = [];
            keys.push(node.uuid)
            setSelectedKeys(keys);
            setFamily(Object.assign({}, node));
            changeUrl(tabIndex, node.uuid);
        } else {
            setFamily(null);

        }
    }, [changeUrl, tabIndex]);

    const onSelect = useCallback((node) => {
        if (node && node.key) {
            setFamily(Object.assign({}, node));
            changeUrl(tabIndex, node.key);
        } else {
            setFamily(null);

        }
    }, [changeUrl, tabIndex]);

    const onTranslationUpdate = useCallback((data) => {
        dispatchCustomEvent(EVENT_NAME.FAMILY_LIST_UPDATE, { action: 'refreshNode', data });
    }, []);

    const onUpdate = useCallback((data) => {
        if (data) {
            dispatchCustomEvent(EVENT_NAME.FAMILY_TRANSLATIONS_REFRESH);
        }
    }, []);

    const onBackup = useCallback(() => {
        if (ID) {
            showLoader(loaderBackup);
            if (dataItem.allow_backup) {
                Ajax.post({
                    url: ApiUrl.FamiliesBackup,
                    data: { uuid: ID },
                    success: function (response) {
                        hideLoader(loaderBackup);
                        if (response && detailRef.current) {
                            if (!response.HasError) {

                                message.success(<T args={[dataItem.nom]}>message.the_families_backup_successfully_copied</T>);
                                setAllowRestore(true);
                            }
                        }
                    },
                    error: function () {
                        hideLoader(loaderBackup);
                    }
                })
            } else {
                hideLoader(loaderBackup);
                message.warning(<T>message.no_data_for_backup</T>);
            }
        }
    }, [ID, dataItem]);

    const onRestore = useCallback(() => {
        if (dataItem.allow_restore || allowRestore) {
            setFamily(null);
            dispatch(openPopup({
                windowKey: "wndFamilyBackupPicker",
                fullScreen: false,
                title: <><T>text.restore</T> - {dataItem.nom}</>,
                type: PopupTypes.FamilyBackupPicker,
                bodyProps: {
                    ...{ data: { univers_uuid: ID, nom: dataItem.nom } },
                    windowKey: "wndFamilyBackupPicker"
                }
            }));
        } else {
            message.warning(<T>message.no_data_for_restore</T>);
        }
    }, [dispatch, ID, dataItem, allowRestore]);

    return (
        <view vertical="">
            {/* <TreeView
                apiUrl={ApiUrl.FamiliesList}
                dataItemUrl={ApiUrl.FamiliesLoad}
                updateUrl={ApiUrl.FamiliesUpdate}
                parentChangeUrl={ApiUrl.FamiliesParentChange}
                deleteUrl={ApiUrl.FamiliesDelete}
                nameField="nom"
                onLoad={onLoad}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                onDoubleClick={onDoublClick}
                onSelect={onSelect}
                staticFilter={{ univers_uuid: ID }}
                listUpdateEvent={EVENT_NAME.FAMILY_LIST_UPDATE}
            /> */}

            <view small="small" preloader="preloader">
                {!isReadOnlyMode && <toolbar>
                    <wrap>
                        <action>
                            {ID &&
                                <>
                                    {
                                        //<div className="button" onClick={e => { onNewClick() }}><icon>plus</icon><T>text.add_new_family</T></div>
                                    }
                                    {
                                        //dataItem.allow_backup > 0 &&
                                        <>
                                            <div className="button" onClick={e => { onBackup() }}><icon>box</icon><T>text.backup</T></div>
                                        </>
                                    }
                                    {
                                        //(dataItem.allow_restore > 0 || allowRestore) &&
                                        <>
                                            <div className="button" onClick={e => { onRestore() }}><icon>revert</icon><T>text.restore</T></div>
                                        </>
                                    }
                                </>
                            }
                        </action>
                    </wrap>
                </toolbar>}
                <TreeView
                    apiUrl={ApiUrl.FamiliesList}
                    dataItemUrl={ApiUrl.FamiliesLoad}
                    updateUrl={ApiUrl.FamiliesUpdate}
                    parentChangeUrl={ApiUrl.FamiliesParentChange}
                    deleteUrl={ApiUrl.FamiliesDelete}
                    nameField="nom_family"
                    onLoad={onLoad}
                    onUpdate={onUpdate}
                    onBeforeUpdate={(data) => { // NOSONAR
                        return {
                            ID: data.ID || "",
                            uuid: data.uuid || "",
                            nom: data.nom,
                            nom_family: data.nom_family,
                            parent_uuid: data.parent_uuid || "",
                            univers_uuid: data.univers_uuid,
                            no_index: data.no_index
                        };
                    }}
                    selectedKeys={selectedKeys}
                    onDoubleClick={onDoublClick}
                    onSelect={onSelect}
                    staticFilter={{ univers_uuid: ID }}
                    listUpdateEvent={EVENT_NAME.FAMILY_LIST_UPDATE}
                    listCustomEvent={EVENT_NAME.FAMILY_BACKUP_CHECK}
                    newButtonTip={<T>text.add_new_family</T>}
                    hideTooltip={isReadOnlyMode}
                    editable={!isReadOnlyMode}
                />
            </view>

            <view>
                <view>
                    {
                        family &&
                        <>
                            <TabStrip onChange={(selected) => { setTabIndex(selected); changeUrl(selected, family.uuid); }} selectedIndex={tabIndex} tabFilter={tabFilter} // NOSONAR
                            >

                                <div title='text.translation'>
                                    <view scroll="" style={{ "paddingBottom": "0px" }}>
                                        <Translation
                                            ID={family.uuid}
                                            fields={['libelle']}
                                            _class='App\Models\Context\Famille'
                                            updateEvent={EVENT_NAME.FAMILY_TRANSLATIONS_UPDATE}
                                            updateCallback={onTranslationUpdate}
                                            refreshEvent={EVENT_NAME.FAMILY_TRANSLATIONS_REFRESH}
                                        // hideLoading={true}
                                        />
                                    </view>
                                    <toolbar>
                                        <wrap>
                                            <action right=''>
                                                <div className="button" onClick={(e) => dispatchCustomEvent(EVENT_NAME.FAMILY_TRANSLATIONS_UPDATE, { ID: family.uuid })} ><icon>save</icon><T>text.save</T></div>
                                            </action>
                                        </wrap>
                                    </toolbar>
                                </div>

                                <div title="text.products" primary>
                                    <Products {...family} />
                                </div>

                                <div title="text.infos">
                                    <Infos {...family} />
                                </div>

                                <div title="text.evolutions">
                                    <Evolutions  {...family} />
                                </div>

                                {
                                    //<div title="text.defects">
                                    //    <Defects  {...family} />
                                    //</div>
                                }

                                <div title="text.standards">
                                    <Normes  {...family} />
                                </div>

                                {
                                    //<div title="text.documents">
                                    //    <Documents {...family} />
                                    //</div>
                                }

                                <div title="text.videos">
                                    <Videos {...family} />
                                </div>

                                <div title="text.support">
                                    <Support {...family} />
                                </div>

                                <div title="text.customer_warranty">
                                    <CustomerWarranty {...family} />
                                </div>

                                <div title="text.product_warranty">
                                    <ProductWarranty {...family} />
                                </div>

                                <div title="text.skills" dispatcher>
                                    <FamillySkills {...family} />
                                </div>
                            </TabStrip>
                        </>
                    }
                </view>
            </view>
        </view>
    );
}


export default connect(state => ({ role: state.role }))(Family)