import React, { useRef } from "react";
import { Button, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { T } from '../../components/Translations';

export function GetColumnSearchProps({dataIndex, placeholder, onFilterData}) {
    const searchInput = useRef(null);
    const searchButton = useRef(null);

    const { t } = useTranslation();

    const handleSearch = confirm => {
        confirm();
    };
    
    const handleReset = clearFilters => {
        clearFilters();
      };

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={t(placeholder)}
                    value={selectedKeys[0]}
                    onChange={e => { // NOSONAR
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }}
                    onPressEnter={() => handleSearch(confirm)} // NOSONAR
                    style={{ width: 188, marginBottom: 8, display: 'block' }} />
                <Space>
                <Button
                    type="primary"
                    ref={searchButton}
                    onClick={() => handleSearch(confirm)} // NOSONAR
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}>

                    <T>text.search</T>
                </Button>
                <Button 
                    onClick={() => handleReset(clearFilters)} // NOSONAR
                    size="small" style={{ width: 90 }}>
                    <T>text.reset</T>
                </Button>
                </Space>
            </div>
        ),

        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#fa5b0b' : undefined }} />,
        onFilter: (value, record) => {
            return onFilterData(value, record, dataIndex);
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible && searchInput) {
                setTimeout(() => {
                    searchInput.current.select()
                }, 100);
            }
        },
    };
}
