import React, { useEffect } from 'react';

import { Project } from 'project/Project';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';


const listUpdateEvent = EVENT_NAME.FEE_LIST_UPDATE;

export default function ImportDetail(props) {
    const { fileData } = props;
    const columns = [
        {
            text: 'text.business_unit',
            field: 'business_unit',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.problem_type',
            field: 'problem_type',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.call_type',
            field: 'call_type',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.flat_rate',
            field: 'flat_rate',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.price',
            field: 'price',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.description',
            field: 'description',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.distance',
            field: 'distance',
            type: COLUMN_TYPE.TEXT
        },
        Project.getImportActionColumn(COLUMN_TYPE.TEXT, null, null, null, null, "right"),
        Project.getImportMessageColumn(COLUMN_TYPE.TEXT, null, null, 300, "right"),
    ];

    useEffect(() => {
        dispatchCustomEvent(listUpdateEvent);
    }, []);

    if (columns) {
        return (
            <ListView
                dataSource={fileData || []}
                columnConfig={columns}
                rowClassName={Project.getRecordActionClass}
                nameField='code'
                skipNewButton
                className='scroll_fix'
                skipEdit
                scroll={{ x: 300, y: 300 }}
            />
        );
    }
    return <></>;
}