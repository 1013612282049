import { showLoader, hideLoader } from 'Shared/Loader';
import { Ajax } from 'components/Ajax';
import { ApiUrl } from 'project/Defines';
import { dispatchCustomEvent } from 'project/utilities';

import { message } from 'antd';

const loaderName = 'LANGUAGE_UPDATE_LOADER';

export function UpdateLanguageData(data, listUpdateEvent, successCallback) {
    showLoader(loaderName);

    Ajax.post({
        url: ApiUrl.LanguageUpdate,
        data: data,
        success: (response) => {
            if (!response.HasError) {
                successCallback && successCallback(response);
                dispatchCustomEvent(listUpdateEvent);
                hideLoader(loaderName);
            } else {
                message.error(response.message);
                hideLoader(loaderName);
            }
        }
    });
}

