import { T } from 'components/Translations';
import { ApiUrl } from 'project/Defines.js';
import React from 'react';
import CustomUpload from 'Shared/CustomUpload';
import { FormField } from 'Shared/Form/formFields.js';
import useDetail from 'Shared/useDetail';



/**
 * the BatchUpload of societe skills
 * @exports BatchUpload
 */
export default function BatchUpload(props) {
    const { windowKey, onClose } = props;
    const { dataItem, setDataItem, updateData } = useDetail({
        data: { file: [] },
        updateUrl: ApiUrl.SocieteSkillBatchUpload,
        windowKey
    });

    const updateSuccessCallback = () => {
        onClose();
    };

    return (<>
        {dataItem &&
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.file">
                                <CustomUpload
                                    imageField='file'
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    type={'temp'}
                                    acceptTypes={['.csv']}
                                    listType='text'
                                    anyFile
                                    className='text'
                                    formIcon
                                />
                            </FormField>
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div disabled={!dataItem.file || !dataItem.file.length} onClick={(e) => updateData(updateSuccessCallback, null, { overrideData: { fileName: dataItem.file[0].image_guid } })} className='button'><icon>save</icon><T>text.save</T></div>

                        </action>
                    </wrap>
                </toolbar>
            </form>
        }</>);
}