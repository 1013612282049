import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import { Project } from 'project/Project';

import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields';

import { T } from 'components/Translations';
import DropDownValueList from '../../../Shared/DropDown';
import { getLanguages, getLanguageText } from '~/Shared/Translation';
import SunEditor from '../../../Shared/SunEditor';
import { ValidationDropDownValueList } from '../../../Shared/Form/validation';

/**
 * ...
 * @exports BankCardDetails
 */
export default function BankCardDetails(props) {
    const cultures = getLanguages();
    const { id } = useParams();
    const [sunEditorValue, setSunEditorValue] = useState('');
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.BANK_CARD_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.CGVUpdate,
        dataLoadUrl: ApiUrl.CGVLoad,
        deleteUrl: ApiUrl.CGVDelete,
        nameField: 'reseau_name',
        backPage: AppPages.BankCard,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.BankCardDetails),
        validationFields: ['compte_type_uuid', 'reseau_uuid']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    const onChangeContent = useCallback((value) => {
        setSunEditorValue(value);
    }, []);

    useEffect(() => {
        onChangeData({ target: { value: sunEditorValue, name: 'contenu' } });
    }, [sunEditorValue]);// eslint-disable-line react-hooks/exhaustive-deps

    const onFileUpload = useCallback((event) => {
        if (event.target && event.target.files.length !== 0) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                // The file's text will be printed here
                onChangeContent(event.target.result);
            };

            reader.readAsText(file);
        }
    }, [onChangeContent]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.BankCard)}
            title={isNew ? <T>text.new_cb_reseau</T> : getLanguageText(dataItem.culture)}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                    <div className='sections '>
                            <FormField label="text.reseau">
                                <ValidationDropDownValueList validation={validation} name='reseau_uuid' valuelist='reseaux' disabled={!isNew} value={dataItem.reseau_uuid} onChange={data => onChangeData({ target: { name: 'reseau_uuid', value: data } })} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.culture">
                                {/* <DropDownValueList name='culture' value={dataItem.culture}  items={cultures.map(c => ({ text: c, value: c }))} /> */}
                                <DropDownValueList name='culture' value={dataItem.culture} items={cultures} onChange={data => onChangeData({ target: { name: 'culture', value: data } })} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.type_de_compte">
                                <ValidationDropDownValueList validation={validation} name='compte_type_uuid' value={dataItem.compte_type_uuid} valuelist='compte_types' onChange={data => onChangeData({ target: { name: 'compte_type_uuid', value: data } })} // NOSONAR
                                />
                            </FormField>
                            <div className="form_fields">
                                <label checkbox=""><input name="is_cb" type="checkbox" checked={dataItem.is_cb} onChange={onChangeData} /><box><check></check></box><text><T>text.is_cb</T></text></label>
                            </div>
                        </div>
                        <div className='sections wide'>
                            <FormField label="text.file">
                                {/* <input type="file" onChange={onFileUpload} accept='.html,.htm'/> */}
                                <SunEditor value={sunEditorValue || dataItem.contenu} onChange={onChangeContent} />
                                <toolbar transparent='transparent'>
                                    <action>
                                        <div className="button primary">
                                            <icon>upload</icon>
                                            <label htmlFor="sun_editer"><text><T>text.fichier_importer</T></text></label>
                                            <input
                                                type="file"
                                                id="sun_editer"
                                                onChange={onFileUpload}
                                                style={{display: "none"}}
                                                accept='.html,.htm'
                                            />
                                        </div>
                                    </action>
                                </toolbar>
                            </FormField>
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon><T>text.delete</T>
                                    </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
