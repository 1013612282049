import { Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { PopupTypes } from '~/components/Popup.js';
import { openPopup } from '~/store/actions/index.js';
import { Ajax } from '../../components/Ajax.js';
import { DataTip, T } from '../../components/Translations';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '../../project/Project';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import CustomUpload from '../../Shared/CustomUpload/index.js';
import DetailView from '../../Shared/DetailView';
import DropDownValueList, { MultiSelectValueList } from '../../Shared/DropDown';
import { FormField } from '../../Shared/Form/formFields.js';
import { ValidationInput } from '../../Shared/Form/validation.js';
import { Input, Textarea } from '../../Shared/Input/index.js';
import { TabStrip } from '../../Shared/TabStrip';
import Translation from '../../Shared/Translation';
import useDetail from '../../Shared/useDetail';
import ProductBOM from '../Product/ProductBOM';
import Accessories from './Accessories/index.js';
import Family from './Family/index.js';
import ParameterProduct from './Parameters/index.js';
import ProductSalesNetwork from './SalesNetwork/index.js';
import SavInfo from './SavInfo/index.js';




/**
 * the ProductDetails main tabcontaining tabstrip layout
 * @exports ProductDetails
 */
export default function ProductDetails(props) {
    const backUrl = new URLSearchParams(props.location.search).get("backUrl");
    const tab = new URLSearchParams(props.location.search).get("tab");
    const [countryList, setCountryList] = useState([]);
    const [countryGroups, setCountryGroups] = useState(null);
    const [brands, setBrands] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const [initialCountryCount] = useState(7);

    const { windowKey, data } = props;

    const dispatch = useDispatch();
    const { id } = useParams();

    const listUpdateEvent = EVENT_NAME.PRODUCT_LIST_UPDATE;
    const useDetailResult = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        dataLoadUrl: ApiUrl.ProductLoad,
        updateUrl: ApiUrl.ProductsUpdate,
        deleteUrl: ApiUrl.ProductsDelete,
        backPage: AppPages.Products,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.ProductDetails),
        validationFields: ["reference"],
    });
    useEffect(() => {
        Ajax.post({
            url: ApiUrl.ValueList,
            data: {
                valueList: 'pays_uuids',
            },
            success: function (response) {
                if (response && detailRef.current) {
                    setCountryList(response);
                }
            }
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (tab)
            setTabIndex(parseInt(tab));
    }, [tab]);

    const { dataItem, setDataItem, updateData, onChangeData, closePopup, isNew, detailRef, onDetailDelete, validation } = useDetailResult;

    useEffect(() => {
        if (isNew) {
            setDataItem({ ...dataItem, code: dataItem.reference });
        }
    }, [dataItem && dataItem.reference]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        detailRef.current = true;

        return () => { detailRef.current = false; }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCountryGroups(dataItem && dataItem.country_groups);
    }, [dataItem && dataItem.country_groups]);// eslint-disable-line react-hooks/exhaustive-deps

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.PRODUCT_TRANSLATIONS_UPDATE, { ID: response.ID });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    const updateCountryCallback = useCallback((paysGroup) => {
        setCountryGroups(paysGroup.countryGroups);
        setDataItem({ ...dataItem, groupe_pays_uuid: paysGroup.selectedPaysGroup });
    }, [dataItem, setCountryGroups, setDataItem])

    const _openPopup = useCallback(() => {
        dispatch(openPopup({
            windowKey: "changeFamilyCountry",
            fullScreen: false,
            title: <T>text.edit_country_group</T>,
            type: PopupTypes.FamilyProductDetails,
            bodyProps: {
                ...{
                    data: {
                        uuid: dataItem.ID,
                        reference: dataItem.reference,
                        traduction: dataItem.nom,
                        country_group_uuids: dataItem.groupe_pays_uuid
                    },
                    updateSuccessCallback: updateCountryCallback
                }
            }
        }));
    }, [dataItem, updateCountryCallback, dispatch])


    const familyInfoLink = dataItem && dataItem.famille_info && dataItem.famille_info.length === 1 ? Project.getPageUrl(
        AppPages.UniverseDetails,
        dataItem.famille_info[0].univers_uuid,
        {
            tab: 1,
            sub: 1,
            familles: dataItem.famille_info[0].uuid
        }
    ) : false;

    const getCountryName = (value) => {
        const item = countryList.find((country) => {
            return country.value === value;
        });

        return item ? item.text : "";
    }

    const countries = (items) => {
        items = items.slice(initialCountryCount);

        return <div className="list_menu ant-dropdown-menu">
            <ul>
                {items.map((item, i) => {
                    return (
                        <li key={i} // NOSONAR
                        ><icon></icon><text>{getCountryName(item)}</text></li>
                    )
                })}
            </ul>
        </div>
    }

    useEffect(() => {
        Ajax.post({
            url: ApiUrl.ValueList,
            data: { valueList: "brands" },
            success: function (response) {
                if (response) {
                    setBrands(response);
                }
            }
        })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const onBrandChange = useCallback((e) => {
        
        onChangeData({ target: { name: 'marque_uuid', value: e } })

        if (!dataItem.univers_uuids && brands) {
            const brand = brands.find(b => b.value === e);
            if (brand && brand.defaut_univers_uuid) {
                let _dataItem = { ...dataItem };
                _dataItem.marque_uuid = e;
                _dataItem.univers_uuids = [brand.defaut_univers_uuid];
                setDataItem(_dataItem);
            }
        }

    }, [onChangeData, brands, dataItem, setDataItem])

    return (<>
        {dataItem && <DetailView
            backUrl={backUrl || Project.getPageUrl(AppPages.Products)}
            title={isNew ? <T>text.new_product</T> : dataItem.reference + (dataItem.nom && dataItem.reference && ' / ') + dataItem.nom}
            showPopup={false}
            key={dataItem.ID}
        >
            <form>
                <div className='form_container'>
                    <TabStrip
                        onChange={(selected) => setTabIndex(selected)} // NOSONAR
                        selectedIndex={tabIndex}
                        skipSecondaryTabs={isNew}
                    >
                        <div title='text.main' primary>
                            <div className='form_container'>
                                <div className='section_group'>
                                    <div className='sections '>
                                        <FormField label="text.reference">
                                            <ValidationInput validation={validation} name='reference' type='text' value={dataItem.reference} onChange={onChangeData} />
                                        </FormField>
                                        <FormField label="text.code">
                                            {dataItem.from_erp ? <DataTip title='text.product_comes_from_erp'><input readOnly type='text' value={dataItem.code} /></DataTip>
                                                : <Input type='text' name='code' value={dataItem.code} onChange={onChangeData} />}
                                        </FormField>
                                        <FormField label="text.refcom2">
                                            <Input name='refcom2' type='text' value={dataItem.refcom2} readOnly />
                                        </FormField>
                                        <FormField label="text.brand">
                                            <DropDownValueList
                                                searchOptions={{
                                                    noAccent: true,
                                                    contains: true
                                                }}
                                                showSearch
                                                disableCach
                                                valuelist='brands'
                                                // style={{ width: '100%' }}
                                                value={dataItem.marque_uuid}
                                                onChange={onBrandChange} 
                                            />
                                        </FormField>
                                        <FormField label="text.universe">
                                            <MultiSelectValueList
                                                name="univers_uuids"
                                                valuelist="Univers"
                                                {... (dataItem.univers_uuids ? { value: dataItem.univers_uuids } : { value: [] })}
                                                onChange={(e) => { onChangeData({ target: { name: "univers_uuids", value: e } }); }} // NOSONAR
                                                searchOptions={{
                                                    noAccent: true,
                                                    contains: true
                                                }}
                                            />
                                        </FormField>
                                    </div>
                                    <div className='sections '>
                                        <FormField label="text.commentaire">
                                            <Textarea name='commentaire' type='text' value={dataItem.commentaire} large_note='large_note' onChange={onChangeData} />
                                        </FormField>
                                    </div>
                                    <div className='sections '>
                                        <FormField label="text.image">
                                            <CustomUpload
                                                imageUrl='image_url'
                                                imageField='images'
                                                dataItem={dataItem}
                                                setDataItem={setDataItem}
                                                type={'product'}
                                            />
                                        </FormField>
                                    </div>
                                </div>
                                <div className='section_group'>
                                    <div className='sections l_wide'>
                                        <div className="form_fields">
                                            <div className="data_label"><T>text.country_groups</T></div>
                                            <div className="data_help">Configure settings when and how You'll get notified for content changes</div>
                                        </div>
                                        {countryGroups != null ?
                                            countryGroups.map((item, index) => {
                                                let objItem = item;

                                                if (typeof objItem === "string") {
                                                    objItem = JSON.parse(item);
                                                }

                                                let objItemID = objItem.groupe_pays_uuid;

                                                return (
                                                    <div className="form_fields"
                                                        key={index} // NOSONAR
                                                    >
                                                        <div className="input_cont badge_wrap">
                                                            <text><b>{objItem.slug}</b></text>
                                                            <group line="">
                                                                {
                                                                    objItemID.length && objItemID.slice(0, initialCountryCount).map((value, i) => {
                                                                        return (
                                                                            familyInfoLink
                                                                                ?
                                                                                <Link to={familyInfoLink} key={i} // NOSONAR
                                                                                >
                                                                                    <badge filled="" accent="" button="">
                                                                                        <text>{getCountryName(value)}</text>
                                                                                    </badge>
                                                                                </Link>
                                                                                :
                                                                                <badge filled="" accent="" button="" onClick={() => setTabIndex(7)} key={i} // NOSONAR
                                                                                >
                                                                                    <text>{getCountryName(value)}</text>
                                                                                </badge>
                                                                        )
                                                                    })
                                                                }
                                                            </group>
                                                            {
                                                                (objItemID.length > initialCountryCount) && <ellipsis>
                                                                    <Dropdown
                                                                        className=""
                                                                        trigger={['click']}
                                                                        overlay={countries(objItemID)}
                                                                        overlayStyle={{ maxHeight: 250, overflow: 'auto' }}
                                                                        placement="topRight"
                                                                    >
                                                                        <div effect="material" className="">
                                                                            <text><counter>{objItemID.length - initialCountryCount}</counter>More...</text>
                                                                        </div>
                                                                    </Dropdown>
                                                                </ellipsis>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }) : <><text></text></>
                                        }

                                        <toolbar transparent='transparent'>
                                            <action>
                                                <div
                                                    effect="material"
                                                    className="button primary"
                                                    onClick={_openPopup}
                                                    disabled={isNew}
                                                >
                                                    <text><T>text.edit_country_group</T></text>
                                                </div>
                                                {familyInfoLink ?
                                                    <Link to={familyInfoLink}>
                                                        < div effect="material" className="button primary">
                                                            <text><DataTip title={dataItem.famille_info[0].traduction}><text><T>text.family</T></text></DataTip></text>
                                                        </div>
                                                    </Link> :
                                                    (
                                                        dataItem.famille_info.length > 1 ? // NOSONAR
                                                            < div effect="material" className="button primary" onClick={() => setTabIndex(7)}>
                                                                <text><T>text.families</T></text>
                                                            </div> : <></>
                                                    )}
                                            </action>
                                        </toolbar>
                                    </div>
                                </div>
                                <div className='section_group'>
                                    <div className='sections'>
                                        <FormField>
                                            <label checkbox=''><input readOnly={true} type='checkbox' checked={dataItem.from_erp} /><box><check></check></box><text><T>text.from_erp</T></text></label>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div title='text.translation'>
                            <Translation
                                ID={dataItem.ID}
                                fields={['libelle']}
                                _class='App\Models\Catalogue\Produit'
                                updateEvent={EVENT_NAME.PRODUCT_TRANSLATIONS_UPDATE} />
                        </div>
                        <div title='text.bill_of_material'>
                            <ProductBOM {...useDetailResult} />
                        </div>
                        <div title='text.parameters'>
                            <ParameterProduct {...useDetailResult} />
                        </div>
                        <div title='text.networks'>
                            <ProductSalesNetwork {...useDetailResult} />
                        </div>
                        <div title='text.sav'>
                            <SavInfo {...useDetailResult} />
                        </div>
                        <div title='text.accessories'>
                            <Accessories {...useDetailResult} />
                        </div>
                        <div title='text.family'>
                            <Family {...useDetailResult} parentId={dataItem && dataItem.ID} />
                        </div>
                    </TabStrip>
                </div>
            </form>
            {tabIndex < 2 &&
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <>
                                    {!dataItem.from_erp && < div className='button' onClick={(e) => { onDetailDelete(dataItem) }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                                </>
                            }
                        </action>
                    </wrap>
                </toolbar>}
        </DetailView>}
    </>);
}

