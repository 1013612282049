import React, { useCallback } from 'react';

import { useDispatch, connect } from 'react-redux';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { Project } from '~/project/Project';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from '../../../project/utilities';

// import { T } from '~/components/Translations';
import { /*FeeExport,*/ FeeImport } from './actions/feeAction';
import ImportFee from './importDetail';
import { openPopup } from '~/store/actions';
import { PopupTypes } from '~/components/Popup';
import { Upload } from 'antd';

const listUpdateEvent = EVENT_NAME.FEE_LIST_UPDATE;

/**
 * ...
 * @exports Fees
 */
function Fees(props) {
    const { listState } = props;
    const dispatch = useDispatch();

    const getDetailUrl = useCallback((record, rowNumber, event, isNewRecord) => {
        if (!isNewRecord) {
            return Project.getPageUrl(AppPages.FeeDetails, record.ID);
        } else {
            return Project.getPageUrl(AppPages.FeeDetails, "create", { reseau_uuid: listState.appliedFilter.reseau_uuid });
        }
    }, [listState]);

    const columnConfig = [
        {
            text: 'text.business_unit',
            field: 'business_unit',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.problem_type',
            field: 'problem_type',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.call_type',
            field: 'call_type',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.flat_rate',
            field: 'flat_rate',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.price',
            field: 'price',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.distance',
            field: 'distance',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                if (record.distance === 0) {
                    return null;
                } else {
                    return (
                        <wrap title={text}>
                            <text>{text}</text>
                        </wrap>
                    );
                }
            },
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    const importData = (file, onSuccess) => {
        const windowKey = 'importWnd';
        FeeImport(file, listUpdateEvent, listState?.appliedFilter?.reseau_uuid, (response) => {
            if (response && response.Data) {
                dispatch(openPopup({
                    component: ImportFee,
                    windowKey,
                    title: "Import",
                    type: PopupTypes.Popup,
                    //className: popupClassName,
                    fullScreen: false,
                    bodyProps: { windowKey, fileData: response.Data }
                }));
            }
        })
    }

    return (
       <>
            { listState?.appliedFilter?.reseau_uuid && <toolbar transparent='transparent'>
                    <action right="">
                        {/* <div
                            effect="material"
                            className="button primary"
                            onClick={() => FeeExport(listState?.appliedFilter?.reseau_uuid)}
                        >
                            <text><T>text.export</T></text>
                        </div> */}

                        <Upload 
                            id="uploader" 
                            customRequest={importData} 
                            showUploadList={false}
                        >
                            <div className='button primary' title="Import"><icon>upload</icon><text>Import</text></div>
                        </Upload>
                    </action>
            </toolbar> }
            <ListView
                {...props}
                columnConfig={columnConfig}
                apiUrl={ApiUrl.FeeList}
                deleteUrl={ApiUrl.FeeDelete}
                getDetailUrl={getDetailUrl}
                filters={[
                    { name: 'reseau_uuid', type: 'select', text: 'text.reseau', valuelist: 'reseaux' },
                    { name: 'business_unit', type: 'text', text: 'text.business_unit' },
                    { name: 'problem_type_id', type: 'select', text: 'text.problem_type', valuelist: 'problem_types', parentFilter: 'reseau_uuid', clearOnParentChange: true, disableCach: true },
                    { name: 'call_type_id', type: 'select', text: 'text.call_type', valuelist: 'call_types', parentFilter: 'reseau_uuid', clearOnParentChange: true, disableCach: true },
                    { name: 'flat_rate', type: 'text', text: 'text.flat_rate' },
                    { name: 'price', type: 'text', text: 'text.price' },
                    { name: 'distance', type: 'text', text: 'text.distance' }
                ]}
                listUpdateEvent={EVENT_NAME.FEE_LIST_UPDATE}
                listName={AppPages.Fees}
                showRefreshButton={false}
                nameField='business_unit'
                skipNewButton={!(listState && listState.appliedFilter && listState.appliedFilter.reseau_uuid)}
            />
       </>

    );
}



export default connect(state => ({ listState: state.listStates[AppPages.Fees] }))(Fees);