import React, { useEffect } from 'react';

import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';

import { ApiUrl } from '../../../project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from '../../../project/utilities';

/**
 * the spareparts not attached to any zone in the schema tab in BOM Detail
 * @exports BOM-Schema-ReferredSpareparts
 */
export default function ReferredSpareparts(props) {
    const { ID, schema_uuid, onSelect, listUpdateEvent } = props;
    useEffect(() => {
        dispatchCustomEvent(EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE2, { action: "refresh" });
    }, [schema_uuid, ID]);

    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            noSort: 'false',
            width: 80,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (
                <wrap><a onClick={() => onSelect(record)} style={{ color: "var(--main-color)" }}><text>{text}</text></a></wrap>// eslint-disable-line jsx-a11y/anchor-is-valid
                //<action hover="">
                //    <div
                //        className="button extra"
                //        onClick={() => { alert(record.article_uuid) }}>
                //        <icon>plus</icon>
                //    </div>
                //</action>
            ),
        },
        {
            text: 'text.designation',
            field: 'designation',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.letter',
            field: 'lettre',
            type: COLUMN_TYPE.TEXT,
            width: 60,
            noSort: true
        }
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ArticleBillOfMaterialList}
            // deleteUrl={ApiUrl.ArticleBillOfMaterialDelete}
            isSimple
            skipNewButton
            noPaging
            listUpdateEvent={listUpdateEvent}
            staticFilter={{ nomenclature_uuid: ID, exclude_schema_uuid: schema_uuid }}
        />
    );
}