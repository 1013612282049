import React, { useState, useCallback } from 'react';
import { message } from 'antd';

import { ApiUrl, StoreKeys } from 'project/Defines.js';
import ListView from 'Shared/ListView';
import { FormField } from 'Shared/Form/formFields';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import DropDownValueList from 'Shared//DropDown';
import { getLanguages } from '~/Shared/Translation';
import { T } from 'components/Translations';
import store from 'store';
import { Ajax } from 'components/Ajax.js';
import { EVENT_NAME } from 'project/utilities';

/**
 * the Print tab in BOM Detail
 * @exports BOM-Print
 * @param {any} props
 */
export default function Print(props) {
    const cultures = getLanguages();
    const [selectedRows, setSelectedRows] = useState([]);
    const [language, setLanguage] = useState(store.get(StoreKeys.Culture));
    const [isLoading, setIsLoading] = useState(false);

    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            width: 120,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.letter',
            noSort: true,
            field: 'lettre',
            width: 80,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        },
    ];

    const downloadPDF = useCallback(() => {
        setIsLoading(true)
        Ajax.download({
            url: '/api/Print/nomenclature', //`${process.env.REACT_APP_API_URL}/api/Print/Index`,
            target: '_blank',
            fileName: `${props.updated_at.split('T')[0]}_Nomenclature_${props.reference}.pdf`,
            autoDownload: true,
            data: {
                id: props.ID,
                products: selectedRows,
                culture: language
            },
            success: function (response) {
                setIsLoading(false)
            },
            error: function (response) {
                message.error(response.message);
                setIsLoading(false)
            }
        });

    }, [selectedRows, language, props.ID, props.reference, props.updated_at]);

    const onLoadList = useCallback((data, sorts) => {
        let selected = [];

        if (data) {
            data.forEach((p) => {
                if (selectedRows.indexOf(p.uuid) !== -1) {
                    selected.push(p.uuid);
                }
            })
        }
        setSelectedRows(selected)
    }, [selectedRows]);

    return (<>
        <toolbar highlight="highlight" scroll="scroll">
            <wrap>
                <action>
                    <wrap>
                        <div effect="material" className="button primary" {...((selectedRows && selectedRows.length > 0 && language && !isLoading) ? {} : { disabled: true })} onClick={downloadPDF}><text><T>text.print</T></text></div>
                        <separator vertical="vertical"></separator>
                        {
                            //<FormField formFieldClass="line" label='text.title' >
                            //    <input name='title' type="text" />
                            //</FormField>
                        }
                        {
                            //<FormField formFieldClass="line large" label='text.logo' >
                            //    <DropDownValueList name='BrandLogos' valuelist='BrandLogo' />
                            //</FormField>
                        }
                        <FormField formFieldClass="line" label='text.culture'>
                            <DropDownValueList name='culture' type='text' value={language} items={cultures} onChange={(e) => setLanguage(e)} // NOSONAR
                            />
                        </FormField>
                    </wrap>

                </action>
            </wrap>
        </toolbar>
        <view light="light">
            <ListView
                isSimple
                skipEdit
                {...props}
                skipNewButton
                noPaging
                loading={isLoading}
                tableLayout="Fixed"
                staticFilter={{ nomenclature_uuid: props.ID }}
                columnConfig={columnConfig}
                apiUrl={ApiUrl.ProductsList}
                listUpdateEvent={EVENT_NAME.PRODUCT_BILL_OF_MATERIAL_LIST_UPDATE}
                rowSelection={{
                    selectedRowKeys: selectedRows,
                    onChange: (selectedRowKeys) => {
                        setSelectedRows(selectedRowKeys);
                    }
                }}
                onLoaded={onLoadList}
            />
        </view>
    </>);
}
