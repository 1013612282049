import { PopupTypes } from 'components/Popup.js';
import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import React, { useEffect, useRef, useState } from 'react';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';


/**
 * the FamilyInfos list of Family's Infos tab
 * @exports Universe-Family-Infos-FamilyInfos
 */
export default function FamilyInfos(props) {
    const { uuid } = props;
    const [detailProps, setDetailProps] = useState();
    const viewRef = useRef(null);

    useEffect(() => {
        viewRef.current = true;
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.add_information',
            popupType: PopupTypes.FamilyInfosDetails,
            windowKey: 'frmFamilyInfos',
            newData: { famille_uuid: uuid },
        });

        return () => { viewRef.current = false; }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const columnConfig = [
        {
            text: 'text.titre',
            field: 'titre',
            noSort: true,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.info',
            field: 'info',
            noSort: true,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.important',
            field: 'important',
            noSort: true,
            width: 100,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                return record.important ? <wrap><item><icon>done</icon></item></wrap>
                    : <wrap></wrap>
            },
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    return (
        <>

            <ListView
                {...props}
                isSimple
                noPaging
                skipEdit
                columnConfig={columnConfig}
                apiUrl={ApiUrl.FamilyInfosList}
                deleteUrl={ApiUrl.FamilyInfosDelete}
                nameField="titre"
                staticFilter={{ famille_uuid: uuid }}
                newButtonTip='text.create_new_info'
                listUpdateEvent={EVENT_NAME.FAMILY_INFOS_LIST_UPDATE}
                detailProps={detailProps}
                rowClassName={record => { // NOSONAR
                    return record.editable ? "" : "disabled"; // NOSONAR
                }}
            />
        </>
    );
};