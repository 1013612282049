import React, { useCallback } from 'react';
import { DatePicker as ANTDDatePicker, TimePicker as ANTDTimePicker } from 'antd';
import { Project } from '../../project/Project';
import { Defines } from '../../project/Defines';

/**
 * custom DataPicker outputing date with format DD/MMYYYY
 * @param {object} props  dataItem, setDataItem, dataField
 */
export function DatePicker(props) {
    const { dataItem, setDataItem, dataField, min, max, disabled, showTime } = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChange = useCallback((value) => {
        let newData = { ...dataItem };
        newData[dataField] = Project.dateTimeLocalToUtcString(value, showTime);
        setDataItem(newData);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const disableDate = useCallback((d) => {
        return (min && d.isBefore(min)) || (max && d.isAfter(max));
    })

    return <ANTDDatePicker
                name={dataField}
                defaultValue={Project.dateTimeUtcToLocal(dataItem[dataField])}
                format={showTime ? Defines.Format.LocalDateTime : Defines.Format.LocalDate}
                onChange={(e, value) => onChange(value)} // NOSONAR
                disabledDate={disableDate}
                disabled={disabled} 
                showTime={showTime}
            />
}

/**
 * custom TimePicker outputing UTC
 * @param {object} props  dataItem, setDataItem, dataField
 */
export function TimePicker(props) {
    const { dataItem, dataField, disabled } = props;

    return <ANTDTimePicker
                name={dataField}
                defaultValue={Project.dateTimeUtcToLocal(dataItem[dataField])}
                format={Defines.Format.FullTime}
                disabled={disabled} />
}