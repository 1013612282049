import React from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { Project } from '../../../project/Project';

/**
 * Client Types list of setup
 * @exports SetupClientTypes
 */
export default function SetupClientTypes(props) {
    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.SetupClientTypeDetails
        },
        {
            text: 'text.slug',
            field: 'slug',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ClientTypeList}
            deleteUrl={ApiUrl.ClientTypeDelete}
            detailUrl={Project.getPageUrl(AppPages.SetupClientTypeDetails)}
            filters={[{ name: 'traduction', type: 'text', text: 'text.name' }]}
            newButtonTip='text.create_new_client_type'
            listUpdateEvent={EVENT_NAME.SETUP_CLIENT_TYPE_LIST_UPDATE}
            detailProps={{ newTitle: 'text.create_new_client_type', editTitle: 'text.edit_client_type'}}
            listName={AppPages.SetupClientTypes}
            showRefreshButton={false} />
    );
}
