import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from '../../../Shared/Form/formFields';
import { ValidationInput } from '../../../Shared/Form/validation';
import { T } from '~/components/Translations';


const validationFields = ['code'];

/**
 * ...
 * @exports UITranslationDetails
 */
export default function UITranslationDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.SETUP_TRANSLATION_LIST_UPDATE;

    const { /*isNew,*/ dataItem, setDataItem, updateData, hasChanges, closePopup, /*onDetailDelete,*/ detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : 0 },
        windowKey,
        updateUrl: ApiUrl.SetupTranslationUpdate,
        dataLoadUrl: ApiUrl.SetupTranslationLoad,
        deleteUrl: ApiUrl.SetupTranslationsDelete,
        nameField: 'code',
        backPage: AppPages.SetupTranslation,
        listUpdateEvent,
        changingValidation: true,
        validationFields: validationFields
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            hasChanges={hasChanges} showPopup={true }
        >
            <view >
                {/* <form> */}
                <div className="form_container">

                    <div className="section_group">
                        <div className="sections">
                            <FormField label="text.code">
                                <ValidationInput validation={validation} autoComplete='off' name='code' type='text' value={dataItem.code} onChange={onChangeData} maxLength="250" />
                            </FormField>

                        </div>

                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div
                                onClick={(e) => updateData(updateSuccessCallback)}
                                className={hasChanges ? ' button primary' : 'button'}
                            ><icon>save</icon><T>text.save</T></div>
                        </action>
                    </wrap>
                </toolbar>
                {/* </form> */}
            </view>
        </DetailView>}
    </>);
}
