import React, { useCallback } from 'react';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T, DataTip } from 'components/Translations';
import { DatePicker } from 'Shared/DatePicker';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationInput } from '../../../../Shared/Form/validation';

/**
 * the Defect details of Family's Defects tab
 * @exports Universe-Family-DefectDetails
 */
export default function DefectDetails(props) {
    const { windowKey, famille_uuid, ID, data } = props;

    const onLoadData = useCallback((response) => {
        if (response) {
            response.famille_uuid = famille_uuid || (data && data.famille_uuid);
        }
    }, [famille_uuid, data]);

    const { dataItem, setDataItem, updateData, onChangeData, closePopup, detailRef, validation } = useDetail({
        data: { ID: ID },
        updateUrl: ApiUrl.FamilyDefectUpdate,
        dataLoadUrl: ApiUrl.FamilyDefectLoad,
        windowKey,
        onLoadData: onLoadData,
        validationFields: ['libelle']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(EVENT_NAME.FAMILY_DEFECT_LIST_UPDATE, { action: 'update' });
         closePopup();
    }, [setDataItem, closePopup, detailRef]);

    return (<>
        {dataItem && <div id='windowContent' className='window_cont' ref={detailRef}>
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.wording">
                                <ValidationInput validation={validation} name='libelle' type='text' value={dataItem.libelle} onChange={onChangeData} />
                            </FormField>
                        </div>
                        <div className='sections '>
                            <div className="form_fields">
                                <div className="data_label"><T>text.date_begin</T></div>
                                <DatePicker
                                    dataField="debut"
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    max={dataItem.fin}
                                />
                            </div>
                            <div className="form_fields">
                                <div className="data_label"><T>text.date_end</T></div>
                                <DatePicker
                                    dataField="fin"
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    min={dataItem.debut}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField>
                                <div effect="material" className='button accent'><icon>file</icon><T>text.file</T></div>
                            </FormField>
                            {
                                dataItem.docga_ressource_uuid &&
                                <FormField>
                                    <div className='input_cont'>
                                        <input type='text' readOnly value={dataItem.slug} />
                                        <DataTip title={'text.remove'}><div className='button micro' onClick={() => updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, docga_ressource_uuid: null } })} ><icon >remove</icon></div></DataTip>
                                        <i></i>
                                    </div>
                                </FormField>
                            }
                        </div>
                    </div>
                </div>

                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </div>}
    </>);
}
