import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Ajax } from '../../components/Ajax';
import { ApiUrl } from '../../project/Defines.js';
import { Select } from 'antd';

import { useCustomEvent } from '../../project/utilities';
import { useSWCache } from '../../Shared/SWCache'
import { connect } from 'react-redux';

const { Option } = Select;

/**
 * specific case of DropDownValueList with 'multiple' mode
 * @param {any} props valuelist, items, parentValue, additionalData, reloadEventName, ...restprops
 */
export function MultiSelectValueList(props) {
    return <DropDownValueList {...props} mode='multiple' />
}

/**
 * custom DropDownValueList working with backend value lists and custom given items
 * @param {object} props   valuelist, mode, items, parentValue, additionalData, reloadEventName, ...restprops
 */
 export function DropDownValueList(props) {
    const [loaded, setLoaded] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const { valuelist, mode, items, parentValue, additionalData, reloadEventName, disableCach, culture, autoSelectFirst, dispatch, addEmptyValue, searchOptions, textAsValue, ...restprops } = props;
    const ref = useRef(false);

    const { getCacheData, setCacheData } = useSWCache({ key: `${culture}_${valuelist}`, isClearOnLogout: true });

    const loadData = useCallback(() => { // NOSONAR
        if (valuelist) {
            if (typeof valuelist === 'string') {
                Ajax.post({
                    url: ApiUrl.ValueList,
                    data: {
                        valueList: valuelist,
                        parentValue: parentValue || "",
                        additionalData: additionalData || ""
                    },
                    success: function (response) {
                        if (response && ref.current) {
                            const data = response;
                            for (var i = 0; i < data.length; i++) { // NOSONAR
                                //data[i].date = new Date(data[i].date)
                                data[i].key = data[i].uuid;
                            };
                            setDataSource(data);
                            setLoaded(true);
                            if (autoSelectFirst && (!props.value || data.length === 1)) {
                                props.onChange && props.onChange(data[0].value);
                            }
                            !disableCach && setCacheData(data);
                        }
                    }
                });
            } else {
                setDataSource(valuelist);
                setLoaded(true);
                if (autoSelectFirst && !props.value) {
                    props.onChange && props.onChange(valuelist[0].value);
                }
            }
        }
    }, [valuelist, parentValue, additionalData, autoSelectFirst, disableCach, props, setCacheData]);

    useCustomEvent(reloadEventName || 'reload_valuelist_' + valuelist, loadData);

    useEffect(() => { // NOSONAR
        ref.current = true;
        if (items) {
            setDataSource([...items]);
            setLoaded(true);
            if (autoSelectFirst) {
                props.onChange && props.onChange(items[0].value);
            }
        }
        else {
            if (disableCach) {
                loadData();
            } else {
                getCacheData().then((data) => {
                    if (ref.current) {
                        if (data) {
                            setDataSource(data);
                            setLoaded(true);
                            if (autoSelectFirst) {
                                props.onChange && props.onChange(data[0].value);
                            }
                        } else {
                            loadData();
                        }
                    }
                });
            }
        }
        return () => { ref.current = false }
    }, [additionalData, valuelist, parentValue]);// eslint-disable-line react-hooks/exhaustive-deps

    const filterOption = (input, option) => {
        let text = searchOptions.caseSensitive ? option.text : option.text && option.text.toLowerCase();
        let searchInput = searchOptions.caseSensitive ? input : input && input.toLowerCase();
        if (searchOptions.noAccent) {
            text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            searchInput = searchInput.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        return !searchInput || (text && (searchOptions.contains ? text.includes(searchInput) : text.startsWith(searchInput)));
    };

    return <>
        {loaded && <Select
            size="default"
            mode={mode}
            virtual={false}
            {...restprops}
            onChange={(e) => props.onChange(e, dataSource)} // NOSONAR
            {...(mode !== "tags" ? { filterOption: filterOption } : {})}
        >
            {addEmptyValue && <Option value={null}>

            </Option>}
            {
                dataSource.map((item, i) =>
                    <Option key={i} value={textAsValue ? item.text : item.value} text={item.text} // NOSONAR
                    >
                        {item.text}
                    </Option>
                )
            }
        </Select>}
    </>
}

export default connect(state => ({ culture: state.culture }))(DropDownValueList);