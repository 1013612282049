import React, { useEffect, useState, useCallback } from 'react';
import { T } from '../../../components/Translations';
import { ApiUrl } from '../../../project/Defines.js';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { FormField } from '../../../Shared/Form/formFields';
import { Ajax } from '../../../components/Ajax';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * the ProductPicker in UseCase tab with list to of Products to attach the BOM to in BOM Detail
 * @exports BOM-ProductPicker
 */

export default function ProductPicker(props) {
    const { letters, nomenclature_uuid } = props;
    const [customLetters, setCustomLetters] = useState([]);
    const [customLetterValue, setCustomLetterValue] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [commonData, setCommonData] = useState({ lettre: '' });
    const [updating, setUpdating] = useState(false);
    const [nomenclature_empty, setNomenclatureEmpty] = useState(true);

    const { t } = useTranslation();
    useEffect(() => {
        setCustomLetters(letters || []);
    }, [letters]);

    useEffect(() => {
        dispatchCustomEvent(EVENT_NAME.PRODUCT_PICKER_LIST_UPDATE);
    }, [nomenclature_empty]);

    const addCustomLetter = useCallback(() => {
        const newVal = [...customLetters, customLetterValue];
        customLetterValue && setCustomLetters(newVal.filter((l, i) => newVal.indexOf(l) === i));
        setCommonData({ ...commonData, lettre: customLetterValue });
        setCustomLetterValue('');
    }, [customLetters, customLetterValue, commonData]);

    const onCustomLetterChange = useCallback((e) => {
        setCustomLetterValue(e.target.value);
    }, []);

    const onDataChange = (event, ...d) => {
        let newData = { ...commonData };
        if (event.target && event.target.name) {
            switch (event.target.type) { // NOSONAR
                case 'checkbox':
                    newData[event.target.name] = (event.target.checked && event.target.value) || '';
                    break;
                default:
                    newData[event.target.name] = event.target.value;
                    break;
            }
        }
        setCommonData(newData);
    }

    const updateData = useCallback((e) => {
        setUpdating(true);
        Ajax.post({
            url: ApiUrl.BillOfMaterialProductAdd,
            data: {
                nomenclature_uuid,
                selected_ids: selectedRows,
                ...commonData
            },
            success: function (response) {
                setUpdating(false);
                if (!response.HasError) {
                    message[response.AddedCount ? 'success' : 'info'](<T args={[response.AddedCount || t('text.no')]}>message.products_were_added</T>);

                    response.skipped.every(sk => message.info(`Product reference ${sk.Reference} had already been added ${'with ' + (sk.Lettre || 'empty') + ' letter'} before`));

                    dispatchCustomEvent(EVENT_NAME.PRODUCT_PICKER_LIST_UPDATE);
                    dispatchCustomEvent(EVENT_NAME.PRODUCT_BILL_OF_MATERIAL_LIST_UPDATE);
                    dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_DETAIL_RELOAD);

                    setSelectedRows([]);
                }
            },
            error: function (response) {
                setUpdating(false);
                message.error(response.message || <T args={[t('text.no')]}>message.products_were_added</T>);
            }
        })
}, [nomenclature_uuid, selectedRows, commonData, t]);

const columnConfig = [
    {
        text: 'text.reference',
        field: 'reference',
        width: 120,
        type: COLUMN_TYPE.TEXT,
    },
    //{
    //    text: 'text.letter',
    //    field: 'lettre',
    //    width: 80,
    //    type: COLUMN_TYPE.TEXT
    //},
    {
        text: 'text.name',
        field: 'nom',
        type: COLUMN_TYPE.TEXT
    },
];

return (<>
    <toolbar highlight="highlight" scroll="scroll" multiline="multiline">
        <wrap wide="wide">
            <action>
                <wrap>
                    <FormField formFieldClass='line'>
                        <div className="data_label">Letter</div>
                        <div role-type='checklist' className='option_bar'>
                            {
                                customLetters.map((l, i) =>
                                    <label key={l + i} // NOSONAR
                                    >
                                        <input type='checkbox' name='lettre' value={l} onChange={onDataChange} checked={commonData.lettre === l} />
                                        <wrap effect='material'><text>{l}</text> </wrap>
                                    </label>
                                )
                            }
                        </div>
                    </FormField>

                    <FormField formFieldClass='line small'>
                        <div className="data_label">Add Letter</div>
                        <div className="input_cont">
                            <input type='text' name='lettre' placeholder='' value={customLetterValue} maxLength={1} onChange={onCustomLetterChange} />
                            <button className="button micro" onClick={addCustomLetter} type="button"><icon >plus</icon></button>
                        </div>
                    </FormField>
                </wrap>
            </action>
        </wrap>
    </toolbar>
    <ListView
        {...props}
        isSimple
        skipEdit
        skipNewButton
        columnConfig={columnConfig}
        apiUrl={ApiUrl.ProductsList}
        search={{
            field: 'search',
            children: <><label checkbox=""><input type="checkbox" checked={nomenclature_empty} onChange={(e) => {
                setNomenclatureEmpty(e.target.checked);
            }} /><box><check></check></box><text><T>text.emptyBOM</T></text></label></>
        }}
        newButtonTip='text.add'
        staticFilter={{ selectedRows, nomenclature_exclude_uuid: nomenclature_uuid, nomenclature_empty}}

        listUpdateEvent={EVENT_NAME.PRODUCT_PICKER_LIST_UPDATE}
        rowClassName={record => { // NOSONAR
            return selectedRows.some(r => record.ID === r) ? 'select' : null
        }}
        rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: (selectedRowKeys) => {
                setSelectedRows(selectedRowKeys);
            }
        }}
    />
    <toolbar>
        <wrap>
            <action right=''>
                <div onClick={updateData} className='button' {...updating ? { disabled: 'disabled' } : {}}><icon>save</icon><T>text.add</T></div>
            </action>
        </wrap>
    </toolbar>
</>);
}
