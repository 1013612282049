import React, { useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { ApiUrl, AppPages, SatcType, StatusCommande, ValideCommande } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import { Project } from 'project/Project';

import { T } from 'components/Translations';

import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields';
import { Ajax } from '~/components/Ajax';
import DetailView from '~/Shared/DetailView';
import { DatePicker } from 'Shared/DatePicker';

/**
 * ...
 * @exports WarrantyRequestDetails
 */
export default function WarrantyRequestDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;

    const { t } = useTranslation();
    const listUpdateEvent = EVENT_NAME.WARRANTY_REQUEST_LIST_UPDATE;

    const { dataItem, setDataItem, onChangeData } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.WarrantyRequestUpdate,
        dataLoadUrl: ApiUrl.WarrantyRequestLoad,
        deleteUrl: ApiUrl.WarrantyRequestDelete,
        backPage: AppPages.JobsWarrantyRequest,
        // nameField: 'reference',
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.JobsWarrantyRequestDetails)
    });

    const deniedData = useCallback(() => {
        Ajax.post({
            url: ApiUrl.WarrantyRequestDenied,
            data: {
                uuid: dataItem.ID,
                satc_commentaire: dataItem.satc_commentaire,
                satc_personne: dataItem.satc_personne
            },
            success: function (response) {
                if (!response.HasError) {
                    message.success(<T>message.data_has_been_successfully_updated</T>);
                    dispatchCustomEvent(listUpdateEvent, { action: "update" });
                    setDataItem({ ...dataItem, reloadData: true });
                } else {
                    message.error(response.message);
                }
            }
        })
    }, [dataItem, listUpdateEvent, setDataItem]);

    const acceptData = useCallback(() => {
        Ajax.post({
            url: ApiUrl.WarrantyRequestAccepted,
            data: {
                uuid: dataItem.ID
            },
            success: function (response) {
                if (!response.HasError) {
                    message.success(<T>message.data_has_been_successfully_updated</T>);
                    dispatchCustomEvent(listUpdateEvent, { action: "update" });
                    setDataItem({ ...dataItem, reloadData: true });
                } else {
                    message.error(response.message);
                }
            }
        })
    }, [dataItem, listUpdateEvent, setDataItem]);

    const updatePersonSatc = useCallback(() => {
        Ajax.post({
            url: ApiUrl.WarrantyRequestPersonSatcUpdate,
            data: {
                uuid: dataItem.ID,
                satc_personne: dataItem.satc_personne
            },
            success: function (response) {
                if (!response.HasError) {
                    message.success(<T>message.data_has_been_successfully_updated</T>);
                    dispatchCustomEvent(listUpdateEvent, { action: "update" });
                } else {
                    message.error(response.message);
                }
            }
        })
    }, [dataItem, listUpdateEvent]);

    const getEtatLibelle = useCallback(() => {
        let status = '';

        if (dataItem.satc_attente === SatcType.SATC_ATTENTE) {
            status = t('text.waiting_for_satc_validation');
        } else if (dataItem.satc_attente === SatcType.SATC_REFUSE && dataItem.commande_uuid && dataItem.sales_order_etat === StatusCommande.ATTENTE_SATC) {
            status = t('text.request_invalidated_by_SATC_waiting_for_order_confirmation');
        } else if (dataItem.satc_attente === SatcType.SATC_REFUSE) {
            status = t('text.request_invalidated_by_satc');
        } else if (dataItem.valide === ValideCommande.VALIDE_VALIDE) {
            if (dataItem.satc_attente === SatcType.SATC_VALIDE) {
                status = t('text.request_validated_by_satc');
            } else {
                status = t('text.declaration_process_pending');
            }
        } else if ((dataItem.valide === ValideCommande.VALIDE_EXPORTE) || (dataItem.valide === ValideCommande.VALIDE_TRANSMISE)) {
            status = t('text.transmitted');
        } else if (dataItem.valide === ValideCommande.VALIDE_ACCEPTEE) {
            status = t('text.warranty_accepted');
        } else if (dataItem.valide === ValideCommande.VALIDE_REFUSEE) {
            status = t('text.warranty_denied');
        } else if (dataItem.valide === ValideCommande.VALIDE_PARTIEL) {
            status = t('text.partial_warranty');
        } else if (dataItem.valide === ValideCommande.VALIDE_NON_RECEVABLE) {
            status = t('text.request_non_admissible_online');
        } else {
            status = t('text.declaration_process_pending');
        }

        return status;
    }, [dataItem, t]);

    const getAttachmentIcon = useCallback(() => {
        if (dataItem.image_url) {
            if (dataItem.image_url.indexOf('.pdf') !== -1)
                return "file";
            if (dataItem.image_url.indexOf('.doc') !== -1)
                return "file";
            
            return "photo";
        }
        return "none"
    }, [dataItem])

    return (
        <>
            {dataItem &&
                <DetailView
                    backUrl={Project.getPageUrl(AppPages.JobsWarrantyRequest)}
                    title={dataItem.reference}
                >
                    <form>
                        <div className='form_container'>
                            <div className='section_group'>
                                <div className='sections'>
                                    <FormField label="text.warranty_request_nb">
                                        <input name='reference' type='text' value={dataItem.reference} readOnly />
                                    </FormField>
                                    <FormField label="text.warranty_and_user">
                                        <textarea
                                            name='societe_fax'
                                            type='text'
                                            value={`${dataItem.livraison_nom}\n${dataItem.societe_telephone}\n${dataItem.societe_fax}`}
                                            readOnly
                                        />
                                    </FormField>
                                    <FormField label="text.warranty_type">
                                        <input name='warranty_type' type='text' value={dataItem.warranty_type} readOnly />
                                    </FormField>
                                    <FormField label="text.produit">
                                        <input name='product_name' type='text' value={`${(dataItem.product_code !== "") ? ("[" + dataItem.product_code + "]" + dataItem.product_name) : ''}`} readOnly />
                                    </FormField>
                                    <FormField label="text.spare_part">
                                        <input name='spare_part_name' type='text' value={`${(dataItem.spare_part_reference !== "") ? ("[" + dataItem.spare_part_reference + "]" + dataItem.spare_part_name) : ''}`} readOnly />
                                    </FormField>
                                </div>
                                <div className='sections'>
                                    <FormField label="text.serial_nb">
                                        <input name='no_serie' type='text' value={dataItem.no_serie} readOnly />
                                    </FormField>
                                    <FormField label="text.warranty_request_date">
                                        <DatePicker dataField="date_demande" dataItem={dataItem} disabled />
                                    </FormField>
                                    <FormField label="text.product_commisioned_on">
                                        <DatePicker dataField="date_mise_en_service" dataItem={dataItem} disabled />
                                    </FormField>
                                    <FormField label="text.referencea">
                                        <input name='dossier' type='text' value={dataItem.dossier} readOnly />
                                    </FormField>
                                    <FormField label="text.defect">
                                        <input name='defect_information' type='text' value={dataItem.defect_information} readOnly />
                                    </FormField>
                                </div>
                                <div className='sections'>
                                    <FormField label="text.shipt_to_address">
                                        <textarea
                                            name='livraison_nom'
                                            type='text'
                                            value={`${dataItem.livraison_nom}\n${dataItem.livraison_adresse1}\n${dataItem.livraison_code_postal}\n${dataItem.livraison_ville}`}
                                            readOnly
                                        />
                                    </FormField>
                                    <FormField label="text.email">
                                        <input name='email' type='text' value={dataItem.email} readOnly />
                                    </FormField>
                                    <FormField label="text.customer_return_form">
                                        <input name='no_bon_retour_client' type='text' value={dataItem.no_bon_retour_client} readOnly />
                                    </FormField>
                                    <FormField label="text.commande">
                                        <label className="input_cont"><text>
                                            {!dataItem.commande_uuid ?
                                                <input type='text' readOnly value={dataItem.reference_commande} /> :
                                                <Link to={Project.getPageUrl(AppPages.JobsSalesOrderDetails, dataItem.commande_uuid, { backUrl: Project.getPageUrl(AppPages.JobsWarrantyRequestDetails, dataItem.ID) })}
                                                    style={{ color: "var(--main-color)", width: '100%', marginLeft: '5px' }}>
                                                    <text>{`${dataItem.sales_order_reference} (${dataItem.sequencial_id})`}</text>
                                                </Link>}
                                        </text><i></i></label>
                                    </FormField>
                                    <FormField label="text.commentaire">
                                        <input name='commentaire' type='text' value={dataItem.commentaire} readOnly />
                                    </FormField>
                                </div>
                            </div>
                            <div className='section_group'>
                                <div className='sections wide'>
                                    <FormField label="text.status">
                                        <input name='valide' type='text' value={getEtatLibelle()} readOnly />
                                    </FormField>
                                    {dataItem.satc_attente === SatcType.SATC_REFUSE &&
                                        <FormField label="text.refusal_comment">
                                            <input name='satc_commentaire' type='text' value={dataItem.satc_commentaire} readOnly />
                                        </FormField>
                                    }
                                    {(dataItem.satc_attente === SatcType.SATC_ATTENTE) &&
                                        <FormField label="text.refuser">
                                            <textarea
                                                name='satc_commentaire'
                                                type='text'
                                                value={dataItem.satc_commentaire}
                                                onChange={onChangeData} />
                                        </FormField>
                                    }
                                    {(dataItem.satc_attente === SatcType.SATC_ATTENTE || dataItem.satc_attente === SatcType.SATC_VALIDE || dataItem.satc_attente === SatcType.SATC_REFUSE) &&
                                        <FormField label="text.valider_request_by_processed">
                                            <div className="input_cont">
                                                <input name='satc_personne' type='text' value={dataItem.satc_personne} onChange={onChangeData} />
                                                <separator vertical=""></separator>
                                                <div effect="material" className="button" onClick={updatePersonSatc}>
                                                    <text><T>text.valider</T></text>
                                                </div>
                                                <i></i>
                                            </div>
                                        </FormField>
                                    }

                                    
                                </div>
                                {dataItem.image_url && <div className='sections'>
                                    <FormField>{
                                        // eslint-disable-next-line
                                        <a effect="material" href={dataItem.image_url} target="_blank" className="button"><icon>{getAttachmentIcon()}</icon><text><T>text.attachment</T></text></a>
                                    }
                                    </FormField>
                                </div>}
                            </div>
                        </div>
                        {dataItem.satc_attente === SatcType.SATC_ATTENTE &&
                            <toolbar>
                                <wrap>
                                    <action right=''>
                                        <div className='button' onClick={acceptData}>
                                            <icon>done</icon><T>text.accept</T>
                                        </div>

                                        <div className='button' onClick={deniedData}>
                                            <icon>close</icon><T>text.dany</T>
                                        </div>
                                    </action>
                                </wrap>
                            </toolbar>
                        }
                    </form>
                </DetailView>
            }
        </>
    )
}