import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';
import { Project } from 'project/Project';
import Translation from 'Shared/Translation';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from '../../../Shared/Form/formFields';
import { ValidationInput } from '../../../Shared/Form/validation';

/**
 * Warranty Type detail of setup
 * @exports Setup-WarrantyTypeDetails
 */
export default function WarrantyTypeDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.SETUP_WARRANTY_TYPE_LIST_UPDATE;
    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.WarrantyTypeUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.WarrantyTypeLoad,
        nameField: 'slug',
        deleteUrl: ApiUrl.WarrantyTypeDelete,
        backPage: AppPages.SetupWarrantyTypes,
        detailUrl: Project.getPageUrl(AppPages.SetupWarrantyTypeDetails),
        validationFields: ["slug"],
        listUpdateEvent
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.SETUP_WARRANTY_TYPE_TRANSLATION_UPDATE, { ID: response.ID });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.SetupWarrantyTypes)}
            title={isNew ? <T>text.create_new_warranty_type</T> : dataItem.nom}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.slug">
                                <ValidationInput validation={validation} name='slug' type='text' value={dataItem.slug} onChange={onChangeData} />
                            </FormField>
                        </div>
                    </div>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.translations">
                            </FormField>
                        </div>
                    </div>
                    <Translation
                        ID={dataItem.ID}
                        fields={['libelle']}
                        _class='App\Models\Garantie\GarantieType'
                        updateEvent={EVENT_NAME.SETUP_WARRANTY_TYPE_TRANSLATION_UPDATE}
                    />
                </div>

                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                <icon>delete</icon><T>text.delete</T>
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}

