import React, { useState } from 'react';
import { T } from '../../components/Translations';

/**
 *an area of data collapsing into a panel
 * @param {object} props  children, name, left, rounded
 */
export function Switchable({
    children,
    name,
    left,
    rounded,
    hide
}) {
    const [show, setShow] = useState(!hide);

    return <>
        <switchable overlay="" rounded={rounded && ""} mini="" className={show ? "" : "hide"}>
            {!left && children}
            <switch onClick={() => setShow(!show)}><wrap><text><T>{name || 'text.filters'}</T></text></wrap></switch>
            {left && children}
        </switchable>
    </>
}