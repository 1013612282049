import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import store from 'store';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { Ajax } from '../components/Ajax';
import { StoreKeys } from '../project/Defines';
import { Project } from '../project/Project';
import Popups from '../components/Popup';

import Menu from './Navigation/Menu';
import Loader from '../Shared/Loader';

/**
 * The layout component specifies the site layout, top menu based on login state and active states of top menu items
 * @param {object} props  { isLoggedIn, popups, culture, role, etc.}
 */
function Layout(props) {
    const { i18n } = useTranslation();

    useEffect(() => {
        message.config({
            top: 100,
            duration: 4,
        });

        const check = function () {
            if (props.isLoggedIn) {
                const exptime = store.get(StoreKeys.ExpireTime);

                if (!exptime || exptime < new Date()) {
                    Ajax.post({
                        url: "/account/check",
                        data: {},
                        success: function (response) {
                            if (response) {
                                store.set(StoreKeys.ExpireTime, (new Date()).setMinutes((new Date()).getMinutes() + 20));
                            }
                        }
                    })
                }
            }
        }
        const timer = setInterval(check, 6e4);
        check();
        return () => {
            clearInterval(timer)
        }
    }, [props.isLoggedIn]);


    let location = window.location.pathname.toLowerCase();
    return (
        <>
            {
                (
                    <>
                        <view key={i18n.language}>
                            <Loader />
                            {props.isLoggedIn && !Project.skipHeader(location) && <Menu role={props.role} culture={props.culture} />}
                            <Popups />
                            <view>
                                <Popups fullScreen />
                                {props.children}
                            </view>
                        </view>
                    </>)
                || props.children
            }
        </>
    );
}
//}

export default connect(state => ({
    isLoggedIn: state.isLoggedIn,
    popups: state.popups,
    culture: state.culture,
    role: state.role
}))(withRouter(Layout));