import React from 'react';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { EVENT_NAME } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { PopupTypes } from '../../components/Popup.js';
import { Project } from '../../project/Project.js';

/**
 * the Product page main List
 * @exports Product
 */
export default function Product(props) {

    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            width: 120,
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.ProductDetails
        },
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.brand',
            field: 'marque',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.code',
            field: 'code',
            width: 80,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ProductsList}
            deleteUrl={ApiUrl.ProductsDelete}
            detailUrl={Project.getPageUrl(AppPages.ProductDetails)}
            filters={[{ name: 'reference', type: 'text', text: 'text.reference', includeEmpty: true },
            { name: 'traduction', type: 'text', text: 'text.name' },
            { name: 'marque_uuid', type: 'select', text: 'text.brand', valuelist: 'Brands', contains: true },
            { name: 'code', type: 'text', text: 'text.code', includeEmpty: true }]}
            newButtonTip='text.create_new_product'
            listUpdateEvent={EVENT_NAME.PRODUCT_LIST_UPDATE}
            detailProps={{ newTitle: 'text.new_product', editTitle: 'text.edit_product', popupType: PopupTypes.ProductDetails, windowKey: 'wndProductsDetail' }}
            listName={AppPages.Products}
            onLoaded={(data) => { // NOSONAR
                if (data && data.length) {
                    data.forEach((item, index) => item.skipdelete = item.from_erp);
                }
                return data;
            }} />
    );
}