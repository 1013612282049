import { T } from 'components/Translations';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { Project } from 'project/Project';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields.js';
import { TabStrip } from 'Shared/TabStrip';
import Translation from 'Shared/Translation';
import useDetail from 'Shared/useDetail';
import { ValidationInput, ValidationMultiSelectValueList } from '~/Shared/Form/validation';



/**
 * the SkillsDetails and main data of Skills
 * @exports SkillsDetails
 */
export default function SkillsDetails(props) {
    const { id } = useParams();
    const { windowKey, data, onDelete } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const listUpdateEvent = EVENT_NAME.SKILL_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, onDetailDelete, detailRef, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.SkillUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.SkillLoad,
        nameField: 'nom',
        deleteUrl: ApiUrl.SkillDelete,
        backPage: AppPages.Skills,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.SkillsDetails),
        validationFields: ['nom', 'sales_network_uuids']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.SKILL_TRANSLATIONS_UPDATE, { ID: response.ID });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.Skills)}
            title={isNew ? <T>text.new_skill</T> : dataItem.nom}
        >
            <form>
                <div className='form_container'>
                    <TabStrip onChange={(selected) => setTabIndex(selected)} skipSecondaryTabs={isNew} // NOSONAR
                    >
                        <div title='text.main' primary>
                            <div className='section_group'>
                                <div className='sections '>
                                    <FormField label="text.name">
                                        <ValidationInput validation={validation} name='nom' maxLength="240" type='text' value={dataItem.nom} onChange={onChangeData} />
                                    </FormField>

                                    <FormField label="text.sales_network">
                                        <ValidationMultiSelectValueList
                                            name="sales_network_uuids"
                                            validation={validation}
                                            valuelist="ProductSalesNetworks"
                                            {... (dataItem.sales_network_uuids ? { value: dataItem.sales_network_uuids } : { value: [] })}
                                            onChange={(e) => { onChangeData({ target: { name: "sales_network_uuids", value: e } }); }} // NOSONAR
                                            searchOptions={{
                                                noAccent: true,
                                                contains: true
                                            }}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </div>
                        <div title='text.translation'>
                            <Translation
                                ID={dataItem.ID}
                                fields={['nom']}
                                _class='App\Models\Skills'
                                updateEvent={EVENT_NAME.SKILL_TRANSLATIONS_UPDATE}
                            />
                        </div>
                    </TabStrip>
                </div>
                {tabIndex < 2 &&
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                                {dataItem.ID &&
                                    <div className='button' onClick={(e) => {
                                        (onDelete && onDelete(dataItem, closePopup)) ||
                                            onDetailDelete(dataItem)
                                    }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>}
            </form>
        </DetailView>}
    </>);
}
