import React from 'react';


import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { ApiUrl, AppPages } from 'project/Defines.js';

import { Project } from '../../../project/Project';
import { EVENT_NAME } from '../../../project/utilities';
import { getLanguages } from '~/Shared/Translation';

/**
 * ...
 * @exports BankCard
 */
export default function BankCard(props) {
    const cultures = getLanguages();
    const columnConfig = [
        {
            text: 'text.reseau',
            field: 'reseau_name',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.culture',
            field: 'culture',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.is_cb',
            field: 'is_cb',
            width: 150,
            render: (text, record) => {
                return record.is_cb && <wrap><item><icon>done</icon></item></wrap>
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.type_de_compte',
            field: 'compte_types_nom',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.CGVList}
            deleteUrl={ApiUrl.CGVDelete}
            detailUrl={Project.getPageUrl(AppPages.BankCardDetails)}
            filters={[
                { name: 'reseau_uuid', type: 'select', text: 'text.reseau', valuelist: 'reseaux', autoComplete: "new-password", disableCach: true },
                { name: 'culture', type: 'select', text: 'text.culture', valuelist: cultures, autoComplete: "new-password", disableCach: true },
                { name: 'compte_types_nom', type: 'select', text: 'text.type_de_compte', valuelist: 'compte_types', autoComplete: "new-password", disableCach: true },
                { name: 'is_cb_not_null', type: 'checkbox', text: 'text.is_cb' },
                { name: 'contenu', type: 'text', text: 'text.file' }
            ]}
            nameField='reseau_name'
            newButtonTip='text.create_new_cb_reseau'
            listUpdateEvent={EVENT_NAME.BANK_CARD_LIST_UPDATE}
            detailProps={{ newTitle: 'text.new_cb_reseau', editTitle: 'text.edit_cb_reseau'}}
            listName={AppPages.BankCard}
            showRefreshButton={false}
        />
    );
}