import React, { useCallback, useState } from 'react';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T, DataTip } from 'components/Translations';
import { DatePicker } from 'Shared/DatePicker';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationInput } from 'Shared/Form/validation.js';

/**
 * the Evolution details of Family's Evolution tab
 * @exports Universe-Family-EvolutionDetails
 */
export default function EvolutionDetails(props) {
    const { windowKey, famille_uuid, ID, data } = props;
    const [updating, setUpdating] = useState(false);

    const onLoadData = useCallback((response) => {
        if (response) {
            response.famille_uuid = famille_uuid || data.famille_uuid;
        }
    }, [famille_uuid, data ]);

    const { dataItem, setDataItem, updateData, onChangeData, closePopup, detailRef, validation } = useDetail({
        data: { ID: ID },
        updateUrl: ApiUrl.FamilyEvolutionUpdate,
        dataLoadUrl: ApiUrl.FamilyEvolutionLoad,
        windowKey,
        onLoadData: onLoadData,
        validationFields: ["modele", "evolution"]
    });


    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
            setUpdating(false);
        }

        dispatchCustomEvent(EVENT_NAME.FAMILY_EVOLUTION_LIST_UPDATE, { action: 'update' });
        closePopup();
    }, [setDataItem, setUpdating, closePopup, detailRef]);

    const startUpdate = useCallback(() => {
        setUpdating(true);
        updateData(updateSuccessCallback, () => {
            setUpdating(false)
        }); 
    }, [setUpdating, updateData, updateSuccessCallback])

    return (<>
        {dataItem && <div id='windowContent' className='window_cont' ref={detailRef}>
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.model">
                                <ValidationInput name='modele' type='text' value={dataItem.modele} onChange={onChangeData} validation={validation} />
                            </FormField>
                            <FormField label="text.evolution">
                                <ValidationInput name='evolution' inputType='textarea' value={dataItem.evolution} onChange={onChangeData} validation={validation} />
                            </FormField>
                        </div>
                        <div className='sections '>
                            <div className="form_fields">
                                <div className="data_label"><T>text.date_begin</T></div>
                                <DatePicker
                                    dataField="debut"
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    max={dataItem.fin}
                                />
                            </div>
                            <div className="form_fields">
                                <div className="data_label"><T>text.date_end</T></div>
                                <DatePicker
                                    dataField="fin"
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    min={dataItem.debut}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='section_group'>
                        <div className='sections '>
                            {
                                //<FormField>
                                //    <div onClick={(e) => { }} effect="material" className='button accent'><icon>file</icon><T>text.file</T></div>
                                //</FormField>
                            }
                            {
                                dataItem.docga_ressource_uuid &&
                            <FormField>
                                <div className='input_cont'>
                                    <input type='text' readOnly value={dataItem.slug} />
                                    <DataTip title={'text.remove'}><div className='button micro' onClick={() => updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, docga_ressource_uuid: null } })} ><icon >remove</icon></div></DataTip>
                                    <i></i>
                                </div>
                            </FormField>
                            }
                        </div>
                    </div>
                </div>

                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={startUpdate} className='button' {...updating ? { disabled: 'disabled' } : {}}><icon>save</icon><T>text.save</T></div>
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </div>}
    </>);
}
