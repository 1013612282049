import React from 'react';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from '../../../project/utilities';
import { Project } from '~/project/Project';

/**
 * ...
 * @exports Skills
 */
export default function Skills (props) {

    const columnConfig = [ 
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.sales_network', 
            field: 'reseau_noms',
            render: (text, record) => {
                console.log(text);
                if (text) {
                    return <group line="">
                        {
                            text.map((reseau, index) => {
                                return (
                                    <badge filled="" accent="" button="" key={index} // NOSONAR
                                    >
                                        <text>{reseau}</text>
                                    </badge>
                                )
                            })
                        }
                    </group>
                }
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.SkillList}
            deleteUrl={ApiUrl.SkillDelete}
            detailUrl={Project.getPageUrl(AppPages.SkillsDetails)}
            filters={[{ name: 'nom', type: 'text', text: 'text.name' }]}
            newButtonTip='text.create_new_skill'
            listUpdateEvent={EVENT_NAME.SKILL_LIST_UPDATE}
            detailProps={{ newTitle: 'text.create_new_skill', editTitle: 'text.edit_skill' }}
            listName={AppPages.Skills}
            showRefreshButton={false} 
        />

    );
}