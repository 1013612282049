import React from 'react';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';

/**
 *
 * @exports Devis
 */
export default function Devis(props) {

    const columnConfig = [
        {
            text: 'text.identifiant',
            field: 'user_login',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.devis_no',
            field: 'devis_no',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.reference',
            field: 'reference',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.sales_network',
            field: 'reseau_nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.created_at',
            field: 'date_devis',
            format: COLUMN_FORMAT.DATE, 
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.raison_sociale',
            field: 'raison_sociale',
            type: COLUMN_TYPE.TEXT
        },
        
        {
            text: 'text.siret',
            field: 'siret',
            width: 80,
            type: COLUMN_TYPE.TEXT
        },
        // {
        //     text: 'text.login',
        //     field: 'user_login',
        //     width: 80,
        //     type: COLUMN_TYPE.TEXT
        // },
        {
            text: 'text.address',
            field: 'livraison_adresse',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.code_postal',
            field: 'livraison_code_postal',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        
        {
            type: COLUMN_TYPE.CUSTOM,
            width: 50,
            render: (text, record) => {
                // eslint-disable-next-line
                return <wrap><a href={record.image_url} target="_blank"><icon edit="">eye</icon></a></wrap>
            }
        },
    ];

    return (
        <ListView
            {...props}
            skipNewButton
            columnConfig={columnConfig}
            defaultSorting={{ Member: "date_devis", SortDirection: 1 }}
            apiUrl={ApiUrl.DevisList}
            filters={[
                { name: 'user_login', type: 'text', text: 'text.identifiant' },
                { name: 'devis_no', type: 'text', text: 'text.devis_no' },
                { name: 'reference', type: 'text', text: 'text.reference' },
                { name: 'reseau_uuid', type: 'select', text: 'text.sales_network', valuelist: 'reseaux' },
                { name: 'siret', type: 'text', text: 'text.user' },
                { name: 'raison_sociale', type: 'text', text: 'text.raison_sociale' },
                { name: 'livraison_adresse', type: 'text', text: 'text.address', includeEmpty: true },
                { name: 'livraison_code_postal', type: 'text', text: 'text.code_postal', includeEmpty: true },
                { name: 'livraison_pays', type: 'text', text: 'text.pays', includeEmpty: true },
                { name: 'date_devis', type: 'date', text: 'text.created_at' }
            ]}
            listUpdateEvent={EVENT_NAME.DEVIS_LIST_UPDATE}
            listName={AppPages.JobsDevis}
            showRefreshButton={false}
            onDoubleClick={() => { }} // NOSONAR
        />
    );
}