import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from '../../project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from '../../project/utilities';

import { TabStrip } from '../../Shared/TabStrip';
import { T } from '../../components/Translations';
import { Project } from '../../project/Project';
import Translation from '../../Shared/Translation';
import CustomUpload from '../../Shared/CustomUpload';
import useDetail from '../../Shared/useDetail';
import DetailView from '../../Shared/DetailView';
import { FormField } from '../../Shared/Form/formFields.js';
import UseCase from './UseCase/index';
import SparePartType from './SparePartType/index';
import Replacement from './Replacement/index';
import Substitute from './Substitute/index';
import { Input } from 'Shared/Input';

/**
 * the SparePartDetails main tab containing tabstrip layout
 * @exports SparePartDetails
 */
export default function SparePartDetails(props) {
    const backUrl = new URLSearchParams(props.location.search).get("backUrl");
    const tab = new URLSearchParams(props.location.search).get("tab");
    const [tabIndex, setTabIndex] = useState(0);
    const { id } = useParams();
    const { windowKey, data, onDelete } = props;

    useEffect(() => {
        if (tab)
            setTabIndex(parseInt(tab));
    }, [tabIndex, tab]);

    const listUpdateEvent = EVENT_NAME.SPARE_PART_LIST_UPDATE;
    const useDetailResult = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.ArticleUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.ArticleLoad,
        nameField: 'reference',
        deleteUrl: ApiUrl.ArticleDelete,
        backPage: AppPages.SpareParts,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.SparePartDetails)
    });
    const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, onDetailDelete, detailRef } = useDetailResult;

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.SPARE_PART_TRANSLATIONS_UPDATE, { ID: response.ID });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            backUrl={backUrl || Project.getPageUrl(AppPages.SpareParts)}
            title={isNew ? <T>text.new_spare_part</T> : dataItem.reference + (dataItem.designation && dataItem.reference && ' / ') + dataItem.designation}
        >
            <form>
                <div className='form_container'>
                    <TabStrip onChange={(selected) => setTabIndex(selected)} skipSecondaryTabs={isNew} selectedIndex={tabIndex} // NOSONAR
                    >
                        <div title='text.main' primary>
                            <div className='section_group'>
                                <div className='sections '>
                                    <div className="form_fields"><div className="section_title"><text><T>text.options</T></text></div><div className="form_separator"></div></div>
                                    <FormField label="text.reference">
                                        <Input name='reference' type='text' value={dataItem.reference} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.code">
                                        <Input name='code' type='text' value={dataItem.code} readOnly />
                                    </FormField>
                                    <FormField label="text.refcom2">
                                        <Input name='refcom2' type='text' value={dataItem.refcom2} readOnly />
                                    </FormField>
                                    <FormField label="text.commentaire">
                                        <textarea name='commentaire' type='text' value={dataItem.commentaire} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.history">
                                        <textarea name='historique' type='text' value={dataItem.historique} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.supplier_code">
                                        <Input name='fournisseur' type='text' value={dataItem.fournisseur} onChange={onChangeData} />
                                    </FormField>

                                </div>

                                <div className="sections ">
                                    <div className="form_fields"><div className="section_title"><text><T>text.replacement</T></text></div><div className="form_separator"></div></div>
                                    <Replacement {...useDetailResult} />

                                    <div className="form_fields"><div className="section_title"><text><T>text.spare_part_type</T></text></div><div className="form_separator"></div></div>
                                    <SparePartType {...useDetailResult} />

                                    <div className="form_fields"><div className="section_title"><text><T>text.image</T></text></div><div className="form_separator"></div></div>
                                    <FormField>
                                        <CustomUpload
                                            multiple
                                            showList
                                            imageUrl='image_url'
                                            imageField='images'
                                            dataItem={dataItem}
                                            setDataItem={setDataItem}
                                            type={'article'}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </div>
                        <div title='text.translation'>
                            <Translation
                                ID={dataItem.ID}
                                fields={['libelle']}
                                _class='App\Models\Catalogue\Article'
                                updateEvent={EVENT_NAME.SPARE_PART_TRANSLATIONS_UPDATE} />
                        </div>
                        {
                            //<div title='text.price_tool'>
                            //    <PriceTool {...dataItem} />
                            //</div>
                        }
                        <div title='text.use_case'>
                            <UseCase {...dataItem} />
                        </div>
                    </TabStrip>
                </div>
                {tabIndex < 2 &&
                    <toolbar>
                        <wrap>
                            <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID && <Substitute {...useDetailResult} /> }
                            {dataItem.ID && !dataItem.code &&
                                    <div className='button' onClick={(e) => {
                                        (onDelete && onDelete(dataItem, closePopup)) ||
                                            onDetailDelete(dataItem)
                                    }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                          </action>
                        </wrap>
                    </toolbar>}
            </form>
        </DetailView>}
    </>);
}
