import React from 'react';
import { ComplexT, T } from '~/components/Translations.js';
import { Project } from '../../../project/Project';
import { getTVAAmount, getTotalHTAmount, getTotal, getEcoPartAmount, getEcoContrAmount } from '../calculations';

import SauterPDF from '../../../assets/docs/SAUTER.pdf';
import ThermorPDF from '../../../assets/docs/THERMOR.pdf';
import AtlanticPDF from '../../../assets/docs/ATLANTIC.pdf';
import { FormField } from '../../../Shared/Form/formFields';

const { formatToPrice } = Project;
export default function PanierExternePaymentForm({ dataItem, isChecked, setIsChecked, submitData, color, universe, validation }) {
    const error_lignes = dataItem && (!dataItem.lignes || dataItem.lignes.some(l => !l.prix_ht));
    return <>
        {dataItem &&
            <view space="space" intro="intro" className={universe}>
                <div className="total_box">
                    <FormField label="text.total_ht">
                        {formatToPrice(getTotalHTAmount(dataItem))}
                    </FormField>
                    <FormField label="text.frais_de_port">
                        {formatToPrice(dataItem.fraisdeport)}
                    </FormField>
                    <FormField label="text.tva_pay">
                        {formatToPrice(getTVAAmount(dataItem))}
                    </FormField>
                    <h1 className="form_fields">
                        <div className="data_label"><T>text.total</T></div>
                        <div className="data_value">{formatToPrice(getTotal(dataItem))}</div>
                    </h1>
                    <FormField label="text.dont_eco_part_ht">
                        {formatToPrice(getEcoPartAmount(dataItem))}
                    </FormField>
                    <FormField label="text.dont_eco_contr_ht">
                        {formatToPrice(getEcoContrAmount(dataItem))}
                    </FormField>
                    <label classic="classic">
                        <input type="checkbox" checked={isChecked.payment} onChange={() => setIsChecked(prev => ({ ...prev, payment: !prev.payment }))} />
                        <box><check></check></box>
                        <text><ComplexT args={[<a href={universe === 'therpac' ? ThermorPDF : (universe === 'atlantic' ? AtlanticPDF : SauterPDF)} target="_blank" rel="noopener noreferrer"><b><T>text.terms_of_sales</T></b></a>]} // NOSONAR
                        >text.i_accept_the</ComplexT></text>
                    </label>
                    <toolbar right="right" transparent="transparent">
                        <div
                            effect="material"
                            className="button"
                            style={{ backgroundColor: color, color: 'white' }}
                            onClick={submitData}
                            disabled={!isChecked.payment || !validation.isValid || error_lignes}
                        >
                            <text><T>text.proceder_payment</T></text>
                    </div>
                </toolbar>
                {error_lignes &&
                    <div style={{ color: "red" }}><T>text.external_card_error_line</T></div>}
                </div>
            </view>
        }
    </>;
}
