import React, { useCallback, useState, useEffect } from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent, } from 'project/utilities';
import { TabStrip } from 'Shared/TabStrip';
import { T } from 'components/Translations';
import { Project } from 'project/Project';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { ValidationInput } from 'Shared/Form/validation.js';
import { useParams } from 'react-router-dom';
import { SalesNetworkNatureCode } from './SalesNetworkNatureCode';

/**
 * the SalesNetworkDetails main tab containing tabstrip layout
 * @exports SalesNetworkDetails
 */
export default function SalesNetworkDetails(props) {
  const { windowKey, data } = props;
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState(0);

  const {
    dataItem,
    setDataItem,
    updateData,
    onChangeData,
    onDetailDelete,
    closePopup,
    isNew,
    detailRef,
    validation
  } = useDetail({
    data: data || { ID: id !== "create" ? id : null },
    dataLoadUrl: ApiUrl.SalesNetworkLoad,
    deleteUrl: ApiUrl.SalesNetworkDelete,
    updateUrl: ApiUrl.SalesNetworkUpdate,
    backPage: AppPages.SalesNetwork,
    windowKey,
    validationFields: [
      "nom",
      "code",
      { name: "sap_dist_chan", rules: [{ type: "custom", validator: (data) => { return !data.sap_prix || data.sap_dist_chan; } }] },
      { name: "sap_sales_org", rules: [{ type: "custom", validator: (data) => { return !data.sap_prix || data.sap_sales_org; } }] },
      { name: "email", rules: [{ type: "email" }] }
    ]/* dataLoadUrl: ApiUrl.SalesNetworkLoad*/,
    detailUrl: Project.getPageUrl(AppPages.SalesNetworkDetails)
  });
  const updateSuccessCallback = useCallback((response) => {
    if (detailRef.current) {
      setDataItem(response);
    }
    dispatchCustomEvent(EVENT_NAME.SALES_NETWORK_LIST_UPDATE, { action: 'update' });
    closePopup();
  }, [setDataItem, closePopup, detailRef]);

  useEffect(() => {
    if (dataItem) {
      const name = dataItem && dataItem.images && dataItem.images[0] && dataItem.images[0].name;
      setDataItem({ ...dataItem, devis_template: dataItem && dataItem.images && dataItem.images[0] ? name || dataItem.devis_template : "" });
    }
  }, [dataItem && dataItem.images]);//eslint-disable-line  react-hooks/exhaustive-deps

  return (<>
    {dataItem &&
      <DetailView
        backUrl={Project.getPageUrl(AppPages.SalesNetwork)}
        title={isNew ? <T>text.new_sales_network</T> : dataItem.nom}
      >
        <form>
          <div className='form_container'>
            <TabStrip onChange={(selected) => setTabIndex(selected)} skipSecondaryTabs={isNew} // NOSONAR
            >
              <div title='text.main' primary>
                <div className='section_group'>

                  <div className='sections '>
                    <div className='form_fields'>
                      <div className='data_label'><T>text.name</T></div>
                      <ValidationInput validation={validation} autoComplete='off' name='nom' type='text' value={dataItem.nom} onChange={onChangeData} />
                    </div>
                    <div className='form_fields'>
                      <div className='data_label'><T>text.code</T></div>
                      <ValidationInput validation={validation} autoComplete='off' name='code' type='text' value={dataItem.code} onChange={onChangeData} />
                    </div>
                    <div className='form_fields'>
                      <label checkbox=''><input name='sap_prix' type='checkbox' defaultChecked={dataItem.sap_prix} onChange={onChangeData} /><box><check></check></box><text><T>text.prix_sap</T></text></label>
                    </div>
                    <div className='form_fields'>
                      <label checkbox=''><input name='is_long_zip' type='checkbox' defaultChecked={dataItem.is_long_zip} onChange={onChangeData} /><box><check></check></box><text><T>text.is_long_zip</T></text></label>
                    </div>
                    <div className='form_fields'>
                      <div className='data_label'><T>text.canal_sap</T></div>
                      <ValidationInput validation={validation} autoComplete='off' name='sap_dist_chan' type='text' value={dataItem.sap_dist_chan} onChange={onChangeData} />
                    </div>
                    <div className='form_fields'>
                      <div className='data_label'><T>text.sales_org</T></div>
                      <ValidationInput validation={validation} autoComplete='off' name='sap_sales_org' type='text' value={dataItem.sap_sales_org} onChange={onChangeData} />
                    </div>
                    <div className="form_fields">
                      <label checkbox=""><input name="station_web" type="checkbox" defaultChecked={dataItem.station_web} onChange={onChangeData} /><box><check></check></box><text><T>text.station_web</T></text></label>
                      <label checkbox=""><input name="enable_service_contract" type="checkbox" defaultChecked={dataItem.enable_service_contract} onChange={onChangeData} /><box><check></check></box><text><T>text.enable_service_contract</T></text></label>
                    </div>
                    <div className='form_fields'>
                      <div className='data_label'><T>text.email</T></div>
                      <ValidationInput validation={validation} autoComplete='off' name='email' type='text' value={dataItem.email} onChange={onChangeData} />
                    </div>
                    <div className='form_fields'>
                      <div className='data_label'><T>text.sap_code_client</T></div>
                      <ValidationInput validation={validation} autoComplete='off' name='sap_code_client' type='text' value={dataItem.sap_code_client} onChange={onChangeData} />
                    </div>
                    <div className='form_fields'>
                      <div className='data_label'><T>text.sap_code_client_part</T></div>
                      <ValidationInput validation={validation} autoComplete='off' name='sap_code_client_part' type='text' value={dataItem.sap_code_client_part} onChange={onChangeData} />
                    </div>

                    <div className="form_fields">
                      <label checkbox=""><input name="devis_active" type="checkbox" defaultChecked={dataItem.devis_active} onChange={onChangeData} /><box><check></check></box><text><T>text.devis_active</T></text></label>
                    </div>

                  </div>
                </div>
              </div>
              <div title='text.discounts_cb'>
                <SalesNetworkNatureCode {...dataItem} parentID={dataItem && dataItem.ID} />
              </div>
            </TabStrip>
          </div>
          {tabIndex < 1 &&
            <toolbar>
              <wrap>
                <action right=''>
                  <div className='button' onClick={() => updateData(updateSuccessCallback)}><icon>save</icon><T>text.save</T></div>
                  {dataItem.ID &&
                    <div className='button' onClick={e => { onDetailDelete(dataItem, closePopup); }}>
                      <icon>delete</icon><T>text.delete</T>
                    </div>
                  }
                </action>
              </wrap>
            </toolbar>
          }
        </form>
      </DetailView>}
  </>);
}
