import React, { useEffect } from 'react';

import { Project } from 'project/Project';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';


const listUpdateEvent = EVENT_NAME.SETUP_TRANSLATION_LIST_UPDATE;

export default function ImportDetail(props) {
    const { fileData } = props;
    const columns = [
        {
            text: 'text.code',
            field: 'code',
            type: COLUMN_TYPE.TEXT,
            width: 300,
            fixed: 'left',
        },
        {
            text: 'languages.fr',
            field: 'traduction',
            render: (item, index) => {
                return <wrap>{index.fr}</wrap>
            },
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'languages.en',
            field: 'traduction',
            render: (item, index) => {
                return <wrap>{index.en}</wrap>
            },
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'languages.es',
            field: 'traduction',
            render: (item, index) => {
                return <wrap>{index.es}</wrap>
            },
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'languages.it',
            field: 'traduction',
            render: (item, index) => {
                return <wrap>{index.it}</wrap>
            },
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'languages.de',
            field: 'traduction',
            render: (item, index) => {
                return <wrap>{index.de}</wrap>
            },
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'languages.ru',
            field: 'traduction',
            render: (item, index) => {
                return <wrap>{index.ru}</wrap>
            },
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'languages.pl',
            field: 'traduction',
            render: (item, index) => {
                return <wrap>{index.pl}</wrap>
            },
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'languages.nl',
            field: 'traduction',
            render: (item, index) => {
                return <wrap>{index.nl}</wrap>
            },
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'languages.pt',
            field: 'traduction',
            render: (item, index) => {
                return <wrap>{index.pt}</wrap>
            },
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        Project.getImportActionColumn(COLUMN_TYPE.TEXT, null, null, null, null, "right"),
        Project.getImportMessageColumn(COLUMN_TYPE.TEXT, null, null, 300, "right"),
    ];

    useEffect(() => {
        dispatchCustomEvent(listUpdateEvent);
    }, []);

    if (columns) {
        return (
            <ListView
                dataSource={fileData || []}
                columnConfig={columns}
                rowClassName={Project.getRecordActionClass}
                nameField='code'
                skipNewButton
                className='scroll_fix'
                skipEdit
                scroll={{ x: 300, y: 300 }}
            />
        );
    }
    return <></>;
}