import { ApiUrl, AppPages } from 'project/Defines.js';
import React, { useEffect, useState } from 'react';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { PopupClasses, PopupTypes } from '~/components/Popup';
import { Ajax } from '../../../components/Ajax';
import { EVENT_NAME } from '../../../project/utilities';

/**
 * ...
 * @exports SocieteSkills
 */
export default function SocieteSkills (props) {
    const { ID } = props;
    const [detailProps, setDetailProps] = useState({});
    const [pays, setPays] = useState(null);

    useEffect(() => {
        Ajax.post({
            url: ApiUrl.ValueList,
            data: { valueList: "pays"},
            success: function (response) {
                if (response) {
                    setPays(response);
                }
            }
        })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.pays',
            field: 'pays',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.postal_code',
            field: 'postal_codes',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                var postals = record.postal_codes.join(', '); // NOSONAR
                return <wrap title={postals}>
                    <text>{postals}</text>
                </wrap>;
            }
        },
        //{
        //    text: 'text.postal_code',
        //    field: 'data',
        //    type: COLUMN_TYPE.TEXT,
        //    render: (text, record) => {
        //        var d = JSON.parse(record.data);

        //        var t = d.map(item => {
        //            var postals = item.postal_codes.join(', '); //map(postal_code => postal_code);
        //            var pay = pays.find(p => p.value === item.pays);
        //           // debugger
        //            var text = `${(pay && pay.text) ?? item.pays}: ${postals} `;

        //            return <wrap title={text}>
        //                <text>{text}</text>
        //            </wrap>;
        //        });
        //        //console.log(d[0].pays);

        //        //var t = JSON.stringify(d);
        //        console.log(t);
        //        return t;
        //    }
        //},
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    //const onNewSocieteSkills = useCallback(() => {
    //    dispatch(
    //        openPopup({
    //            fullScreen: false,
    //            title: <T>text.add_new_skills</T>,
    //            type: PopupTypes.SocieteSkillsPickup,
    //            bodyProps: {
    //                ...{ data: { ID: ID, selected: '' } },
    //                windowKey: "changeSocieteSkill"
    //            }
    //        })
    //    );

    //}, [ID, dispatch]);


    useEffect(() => {
        var pays_uuid = ""; // NOSONAR
        if (pays) {
            var pay = pays.find(p => p.value === props.livraison_pays); // NOSONAR
            if (pay)
                pays_uuid = pay.pays_uuid;
        }

        setDetailProps({
            fullScreen: false,
            newTitle: 'text.create_new_skill',
            editTitle: 'text.edit_skill',
            popupType: PopupTypes.SocieteSkillDetails,
            windowKey: 'changeSocieteSkill',
            popupClassName: PopupClasses.Medium,
            newData: { data: { societe_uuid: ID, pays_uuid: pays_uuid} }
        });
    }, [ID, pays, props]);

    return (
        pays &&
        <ListView
            //{...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.SocieteSkillList}
            deleteUrl={ApiUrl.SocieteSkillDelete}
            //skipEdit
            // detailUrl={Project.getPageUrl(AppPages.SkillsDetails)}
            //deleteKeys={["societe_uuid", "skill_uuid"]}
            staticFilter={{ societe_uuid: ID }}
            newButtonTip='text.create_new_skill'
            listUpdateEvent={EVENT_NAME.SOCIETE_SKILL_LIST_UPDATE}
            //onNewClick={onNewSocieteSkills}
            listName={AppPages.SocieteSkills}
            showRefreshButton={false}
            nameField='nom'
            detailProps={detailProps}
        />

    );
}