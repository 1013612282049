import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';
import { Project } from 'project/Project';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from '../../../Shared/Form/formFields';
import { ValidationInput, ValidationDropDownValueList } from '../../../Shared/Form/validation';
import { MultiSelectValueList } from 'Shared/DropDown';
import VideoContainer from '../../../Shared/Video';
import { getLanguages } from '~/Shared/Translation';

/**
 * Video detail of setup
 * @exports Setup-VideoDetails
 */
export default function VideoDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.SETUP_VIDEOS_VIMEO_LIST_UPDATE;
    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.VideosVimeoUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.VideosVimeoLoad,
        nameField: 'title',
        deleteUrl: ApiUrl.VideosVimeoDelete,
        backPage: AppPages.SetupVideo,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.SetupVideoDetails),
        validationFields: ['title', 'video_id', 'tag_uuid'],
        onLoadData: (response, _setDataItem) => {
            if (response.pays) {
                response.pays = JSON.parse(response.pays);

                if(response.pays[0] == "XX") {
                    response.pays = [];

                    setAllPays(true)
                }
            }

            _setDataItem(response);
            return true;
        },
    });
    const languages = getLanguages();
    const [allPays, setAllPays] = useState(false);

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            if (response.pays) {
                response.pays = JSON.parse(response.pays);
                response.pays = response.pays[0] == "XX" ? [] : response.pays;
            }

            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    const updateData_ = useCallback(() => {
        const overrideData = {
            ...dataItem,
            pays: allPays ? JSON.stringify(["XX"]) : JSON.stringify(dataItem.pays)
        }

        return updateData(updateSuccessCallback, null, {overrideData: overrideData});
    }, [setDataItem, updateSuccessCallback, updateData]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.SetupVideo)}
            title={isNew ? <T>text.create_new_video</T> : dataItem.title}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.title">
                                <ValidationInput validation={validation} name='title' type='text' autoComplete="off" value={dataItem.title} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.video_type">
                                <ValidationDropDownValueList
                                    validation={validation}
                                    name='video_types'
                                    type='text'
                                    value={dataItem.video_type}
                                    valuelist='video_types'
                                    onChange={e => { // NOSONAR
                                        let value = e;
                                        if (!value) {
                                            value = null;
                                        }
                                        onChangeData({ target: { name: 'video_type', value } })
                                    }}
                                />
                            </FormField>
                            <FormField label="text.video_id">
                                <ValidationInput validation={validation} name='video_id' type='text' autoComplete="off" value={dataItem.video_id} onChange={onChangeData} />
                            </FormField>
                            <VideoContainer
                                videoId={dataItem.video_id}
                                videoType={dataItem.video_type}
                            />
                        </div>
                        <div className='sections '>
                            <FormField label="text.tag_de_droit">
                                <ValidationDropDownValueList
                                    validation={validation}
                                    name='tag_uuid'
                                    type='text'
                                    value={dataItem.tag_uuid}
                                    valuelist='tag_documents'
                                    onChange={e => { onChangeData({ target: { name: 'tag_uuid', value: e } })
                                    }}
                                />
                            </FormField>
                            <FormField label="text.tag_de_type">
                                <MultiSelectValueList
                                    name="tag_types_uuids"
                                    type='text'
                                    {... (dataItem.tag_types_uuids ? { value: dataItem.tag_types_uuids } : { value: [] })}
                                    valuelist="liste_de_type_de_tags"
                                    onChange={(e) => { onChangeData({ target: { name: "tag_types_uuids", value: e } }); }} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                />
                            </FormField>
                            <FormField> 
                                <label checkbox="">
                                    <input
                                        type="checkbox"
                                        checked={allPays}
                                        onChange={() => setAllPays(!allPays)}
                                    />
                                    <box><check></check></box>
                                    <text><T>text.select_all_pays</T></text>
                                </label>
                            </FormField>
                            {!allPays &&
                                <FormField label="text.pays">
                                <MultiSelectValueList
                                    name="pays"
                                    type='text'
                                    {... (dataItem.pays ? { value: dataItem.pays } : { value: [] })}
                                    valuelist="pays"
                                    onChange={(e) => {onChangeData({ target: { name: "pays", value: e } }); }} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                />
                            </FormField>}
                            <FormField label="text.lang">
                                <ValidationDropDownValueList
                                    validation={validation}
                                    name='lang'
                                    type='text'
                                    value={dataItem.lang}
                                    items={languages}
                                    onChange={e => { onChangeData({ target: { name: 'lang', value: e } })
                                    }}
                                />
                            </FormField>
                        </div>
                    </div>
                </div>

                <toolbar>
                    <wrap>
                        <action right=''>
                        <div onClick={updateData_} className='button'><icon>save</icon><T>text.save</T></div>
                        {dataItem.ID &&
                            <div className='button' onClick={(e) => {
                                onDetailDelete(dataItem)
                            }}>
                                <icon>delete</icon><T>text.delete</T>
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}

