import React from 'react';
import { ApiUrl /*, Defines*/ } from 'project/Defines.js';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';
import { EVENT_NAME } from 'project/utilities';
import { Project } from '../../../project/Project'

/**
 * the results List for each Process for run
 * @exports ProcessLogPicker
 */
export default function ProcessLogPicker(props) {
    const { date_start, status, comments } = props.data;

    const columnConfig = [
        {
            text: 'text.start_date',
            field: 'created_at',
            width: 180,
            format: COLUMN_FORMAT.DATETIME,
            noSort: true,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.status',
            field: 'status',
            width: 100,
            noSort: true,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.line',
            field: 'line',
            noSort: true,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.comments',
            field: 'comments',
            noSort: true,
            type: COLUMN_TYPE.TEXT
        },
    ];

    return (<>
        <div className="alert_contianer">
            <alert warning="warning">
                <icon>info</icon>
                <text marquee="marquee"><span>{"Process Time: " + Project.dateTimeUtcToLocalString(date_start) + "; Status - " + status + "; Comments - " + comments}</span></text>
                {
                    //<action>
                    //    <div className="button mini close"><icon>close</icon></div>
                    //</action>
                }
            </alert>
        </div>

        <ListView
            {...props}
            isSimple
            skipEdit
            skipNewButton
            noSort
            staticFilter={{ process_log_id: props.ID }}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ProcessLogDetailList}
            search={{ field: 'search' }}
            listUpdateEvent={EVENT_NAME.PROCESS_LOG_DETAIL_LIST_UPDATE}
        />
    </>);
}
