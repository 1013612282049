import React, { useCallback} from 'react';
import { connect } from 'react-redux';

import { PopupTypes } from '../../../components/Popup';
import { openPopup, closePopup } from '../../../store/actions';
import { T, Input, DataTip } from '../../../components/Translations';
import { FormField } from '../../../Shared/Form/formFields';

/**
 * the tab of SparePart Type list
 * @exports SparePart-Type
 */
function SparePartType(props) {
    const windowKey = "wndArticleType";
    const { dataItem, setDataItem, openPopup, closePopup } = props;

    const openSparePartType = useCallback(() => {
        openPopup({
            windowKey,
            fullScreen: false,
            className: "middle",
            title: <T>text.select_an_article_type</T>,
            type: PopupTypes.SparePartTypePicker,
            bodyProps: {
                updateData: (record) => {
                    //updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, article_type_uuid: record.uuid } })
                    setDataItem({ ...dataItem, article_type_uuid: record.uuid, article_type: record.traduction });
                    closePopup(windowKey)
                }
            }
        });
    }, [dataItem, closePopup, openPopup, setDataItem])

    const removeSparePartType = useCallback(() => {
        setDataItem({ ...dataItem, article_type_uuid: null, article_type: "" });
    }, [dataItem, setDataItem]);

    return (<>
        <FormField>
            <label className="input_cont">
                <Input name='article_type' type='text' value={dataItem.article_type} readOnly />
                <DataTip title={'text.select'}><div onClick={openSparePartType} className='button micro'><icon>edit</icon></div></DataTip>
                {
                    dataItem.article_type &&
                    <DataTip title={'text.remove'}><div onClick={removeSparePartType} className='button micro'><icon>remove</icon></div></DataTip>
                }

                <i></i>
            </label>
        </FormField>
    </>);
}

export default connect(null, (dispatch) => ({
    openPopup: (data) => dispatch(openPopup(data)),
    closePopup: (windowKey) => dispatch(closePopup(windowKey))
}))(SparePartType);

