import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { ApiUrl, AppPages, UserRoles } from 'project/Defines.js';
import { Project } from 'project/Project';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { PopupClasses, PopupTypes } from 'components/Popup.js';
import { Link } from 'react-router-dom';
import { T } from '~/components/Translations';
import { openPopup } from '../../store/actions';

/**
 * the societe page main List
 * @exports User
 */
function User(props) {
    const { role, openPopup } = props;

    const openBatchSkillUploadPopup = () => {
        openPopup({
            window,
            title: "text.skill_batch_upload",
            type: PopupTypes.SocieteSkillBatchUpload,
            fullScreen: false,
            popupClassName: PopupClasses.Small,
        })
    }
    const columnConfig = [
        {
            text: 'text.siret',
            field: 'siret',
            width: 150,
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.UserDetails
        },
        {
            text: 'text.name',
            field: 'raison_sociale',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.code_postal',
            width: 120,
            field: 'livraison_code_postal',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.city',
            field: 'livraison_ville',
            width: 150,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.country',
            field: 'livraison_pays',
            width: 150,
            type: COLUMN_TYPE.TEXT,
        },
        {
            type: COLUMN_TYPE.ACTION,
            render: ((text, record, commandList) => (
                <>
                    <elastic>
                        <action hover="">
                            {commandList.filter(cmd => cmd.command === 'edit').map((cmd) => {
                                return <div
                                    key={cmd.command}
                                    command={cmd.command} className="button extra" onClick={() => { cmd.onClick(record); }}>
                                    {cmd.noIcon ? cmd.command : <icon edit="">{cmd.command}</icon>}
                                </div>
                            })}
                        </action>
                    </elastic>
                </>
            ))
        },
    ];
    const expandedColumnConfig = [
        {
            text: 'text.identifiant',
            field: 'login',
            type: COLUMN_TYPE.TEXT,
            width: 200,
        },
        {
            text: 'text.name',
            field: 'nom',
            width: 250,
            type: COLUMN_TYPE.TEXT,
            onRenderLink: record => Project.getPageUrl(AppPages.SocieteUserDetails, [record.societe_uuid, record.ID])
        },
        {
            text: 'text.last_name',
            field: 'prenom',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.email',
            width: 90,
            field: 'email',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.code_postal',
            width: 90,
            field: 'code_postal',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.city',
            field: 'ville',
            width: 150,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.telephone',
            field: 'telephone',
            width: 150,
            type: COLUMN_TYPE.TEXT,
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];
    const onExpandedRowRender = useCallback((record) => {
        return <ListView
            isSimple
            isChieldView
            noPaging
            skipEdit
            skipNewButton={true}
            style={{ paddingLeft: 10 }}
            staticFilter={{ societe_uuid: record.ID }}
            apiUrl={ApiUrl.SocieteUserList}
            columnConfig={expandedColumnConfig}
            pagination={false}
            listUpdateEvent={EVENT_NAME.SOCIETE_USER_LIST_UPDATE}
        />;
    }, [expandedColumnConfig]);
    const filters = [{ name: 'identifiant', type: 'text', text: 'text.identifiant' },
    { name: 'siret', type: 'text', text: 'text.siret' },
    { name: 'nom', type: 'text', text: 'text.name', includeEmpty: true },
    { name: 'email', type: 'text', text: 'text.email', includeEmpty: true },
    { name: 'code_postal', type: 'text', text: 'text.code_postal', includeEmpty: true },
    { name: 'ville', type: 'text', text: 'text.city', includeEmpty: true },
    { name: 'pays', type: 'select', text: 'text.country', valuelist: 'pays', autoComplete: "new-password" },
    { name: 'telephone', type: 'text', text: 'text.telephone', includeEmpty: true },
    { name: 'nature_code', type: 'text', text: 'text.nature_code', includeEmpty: true },
    { name: 'type_code', type: 'text', text: 'text.type_code', includeEmpty: true },
    { name: 'reseau_uuid', type: 'select', text: 'text.network', valuelist: 'reseaux' },
    { name: 'code_client', type: 'text', text: 'text.code_client' },
    { name: 'code_contact', type: 'text', text: 'text.code_contact' },
    { name: 'code_station', type: 'text', text: 'text.code_station', includeEmpty: true },
    { name: 'code_station_not_null', type: 'checkbox', text: 'text.code_station_not_empty' },
    { name: 'part_confiance_code_azur', type: 'text', text: 'text.code_azur' },
    { name: 'code_azur_not_null', type: 'checkbox', text: 'text.code_azur_not_empty' }];
    return (
        <>
            <toolbar light='' shadow=''>
                <Link to={Project.getPageUrl(AppPages.UsersSiretChanges)}><div className='button accent' ><text><T>text.siret_change</T></text></div></Link>
                {role !== UserRoles.SATC && <div className='button accent' onClick={openBatchSkillUploadPopup}><text><T>text.skill_batch_upload</T></text></div>}
            </toolbar>
            <ListView
                {...props}
                columnConfig={columnConfig}
                apiUrl={ApiUrl.UserList}
                deleteUrl={ApiUrl.UserDelete}
                detailUrl={Project.getPageUrl(AppPages.UserDetails)}
                expandable={{
                    expandedRowRender: record => onExpandedRowRender(record),
                    rowExpandable: () => {
                        return true;
                    },
                }}
                filters={
                    role === UserRoles.DISPATCHER ? [...filters, { name: 'station_web', type: 'checkbox', text: 'Station Web' }] : filters
                }
                nameField="raison_sociale"
                newButtonTip='text.create_new_user'
                listUpdateEvent={EVENT_NAME.USER_LIST_UPDATE}
                detailProps={{
                    newTitle: 'text.new_user', editTitle: 'text.edit_user', popupType: PopupTypes.UserDetails, windowKey: 'wndUserDetail',
                }}
                listName={AppPages.User}
            />
        </>
    );
}

export default connect(state => ({ role: state.role }), dispatch => ({ openPopup: (props) => dispatch(openPopup(props)) }))(User)