import React, { useState, useCallback, useEffect } from "react";
import { T, Input } from '../../components/Translations';
import { Switchable } from '../../Shared/Switchable';
import DropDownValueList from '../../Shared/DropDown';
import { DatePicker } from '../../Shared/DatePicker';
import { useTranslation } from "react-i18next";
import { FormField } from "../Form/formFields";
import { Project } from '../../project/Project';

/**
 * the layout for all filters
 * @param {object} props  onClearAll, children, onApply
 */
function FilterContainer(props) {
    const { onClearAll, children, onApply } = props;
    return (<>
        <view className="filter_container">
            <div className="filter_content">
                <wrap>
                    {children}
                </wrap>
                <toolbar>
                    <action>
                        <a className="button link right" disabled="" onClick={() => onClearAll()}><text><T>text.clear_all</T></text></a> {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                    </action>

                    <action right="">
                        <button command="Apply" className="button action" type="button" onClick={() => onApply()}><text><T>text.filter_apply</T></text></button>
                    </action>
                </toolbar>
            </div>
        </view>
    </>);
}

/**
 * the control generating filter fields for list views and responsible for inner logic of filter values
 * @param {any} props contains: filters, appliedFilter, setAppliedFilter, pagination, setPaginatio
 * @exports Filters
 */
export default function Filters(props) {
    const { t } = useTranslation();
    const {
        filters,
        appliedFilter,
        setAppliedFilter,
        pagination,
        setPagination } = props;
    const [filter, setFilter] = useState(null);
    const [clearedFilter, setClearedFilter] = useState(null);
    const clearFilter = useCallback(() => {
        setFilter({ ...clearedFilter });
        setAppliedFilter({ ...clearedFilter });
        setPagination({ ...pagination, current: 1 });

    }, [setAppliedFilter, clearedFilter, pagination, setPagination]);


    const apply = useCallback((event) => {
        setAppliedFilter({ ...appliedFilter, ...filter });
        setPagination({ ...pagination, current: 1 });
    }, [appliedFilter, filter, pagination, setAppliedFilter, setPagination]);

    const onKeyPress = useCallback((event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            apply();
        }
    }, [apply]);

    useEffect(() => {
        setClearedFilter((filters && {
            ...filters.reduce((result, f) => {
                if (f.defaultValue) {
                    result[f.name] = f.defaultValue;
                } else {
                    switch (f.type) {
                        case 'text':
                        case 'select':
                        case 'date':
                            result[f.name] = '';
                            if (f.includeEmpty) {
                                result[`${f.name}-empty`] = false;
                            }
                            break;
                        case 'checkbox':
                            result[f.name] = false;
                            break;
                        default:
                            break;
                    }
                }
                return result;
            }, {})
        }) || {});
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        !appliedFilter && clearedFilter ? clearFilter() : setFilter(appliedFilter);
    }, [clearedFilter]);// eslint-disable-line react-hooks/exhaustive-deps

    const filterFieldChange = useCallback((event, applyChanges) => {
        if (event) {
            const newFilter = { ...filter };
            const target = event.target;
            newFilter[target.name] = target.type !== 'checkbox' ? target.value : target.checked;
            filters.forEach(f => {
                if (f.parentFilter === target.name && f.clearOnParentChange) {
                    newFilter[f.name] = f.type !== 'checkbox' ? '' : false;
                }
            });
            setFilter(newFilter);
            if (applyChanges) {
                setAppliedFilter({ ...appliedFilter, ...filter });
            }
        }
    }, [filters, filter, appliedFilter, setAppliedFilter]);

    return (< >
        {filters && filter && <>
            <Switchable>
                <FilterContainer onApply={apply} onClearAll={() => clearFilter()} // NOSONAR
                >
                    {filters.map((f, index) => {
                        switch (f.type) {
                            case 'text':
                                return <div className="filter_block" key={f.name + index} // NOSONAR
                                >
                                    <div className="data_cell">
                                        <wrap block="">
                                            <label className="input_cont">
                                                <Input type="text"
                                                    placeholder={f.text}
                                                    name={f.name}
                                                    value={filter[f.name]}
                                                    onChange={filterFieldChange}
                                                    onKeyPress={onKeyPress} />
                                                {f.includeEmpty && <>
                                                    <separator vertical=""></separator>
                                                    <label simple="" checkbox=""><name><T>text.empty</T></name><input type="checkbox" name={`${f.name}-empty`} onChange={filterFieldChange} checked={filter[`${f.name}-empty`]} /><text light=''></text></label>
                                                </>}
                                                <i></i>
                                            </label>
                                        </wrap>
                                    </div>
                                </div>;
                            case 'select':
                                return <div className="filter_block" key={f.name + index} // NOSONAR
                                >
                                    <div className="data_cell">
                                        <wrap block="">
                                            <DropDownValueList
                                                searchOptions={f}
                                                showSearch
                                                style={{ width: '100%' }}
                                                type="text"
                                                autoComplete={f.autoComplete || "off"}
                                                valuelist={f.valuelist}
                                                textAsValue={f.textAsValue}
                                                name={f.name}
                                                {...(f.disableCach ? { disableCach: true } : {})}
                                                {...(filter[f.name] ? { value: filter[f.name] } : { placeholder: <T>{f.text}</T> })}
                                                {...(!!f.parentFilter ? { parentValue: filter[f.parentFilter] } : null)}
                                                onChange={(e) => filterFieldChange({ target: { name: f.name, value: e } }, f.applyOnChange)} // NOSONAR
                                                allowClear
                                            />
                                            <i></i>
                                        </wrap>
                                    </div>
                                </div>;
                            case 'date':

                                return <div className="filter_block" key={f.name + index} // NOSONAR
                                >
                                    <FormField label={f.text}>
                                        <wrap block="">
                                            <DatePicker
                                                placeholder={t('text.from')}
                                                name={f.name + '_from'}
                                                onKeyPress={onKeyPress}
                                                dataField={f.name + '_from'}
                                                dataItem={filter}
                                                setDataItem={(d) => filterFieldChange({ target: { name: f.name + '_from', value: Project.dateTimeLocalToUtc(d[f.name + '_from']) } }, f.applyOnChange)} // NOSONAR
                                                max={(filter[f.name + '_to'] && Project.dateTimeUtcToLocal(filter[f.name + '_to'])) || ''}
                                            />
                                        </wrap> <icon>minus</icon>
                                        <wrap block="">
                                            <DatePicker placeholder={t('text.to')}
                                                name={f.name + '_to'}
                                                onKeyPress={onKeyPress}
                                                dataField={f.name + '_to'}
                                                dataItem={filter}
                                                setDataItem={(d) => filterFieldChange({ target: { name: f.name + '_to', value: Project.dateTimeLocalToUtc(d[f.name + '_to']) } }, f.applyOnChange)} // NOSONAR
                                                min={(filter[f.name + '_from'] && Project.dateTimeUtcToLocal(filter[f.name + '_from'])) || ''}
                                            />
                                        </wrap>
                                    </FormField>
                                </div>;
                            case 'checkbox':
                                return <div className="filter_block" key={f.name + index} // NOSONAR
                                >
                                    <div className="data_cell">
                                        <wrap block="">
                                            <label className="input_cont">
                                                <label simple="" checkbox=""><name><T>{f.text}</T></name><input type="checkbox" name={f.name} onChange={filterFieldChange} checked={filter[f.name]} /><text light=''></text></label>
                                                <i></i>
                                            </label>
                                        </wrap>
                                    </div>
                                </div>;
                            default:
                                return null;
                        }
                    })}

                </FilterContainer>
            </Switchable>
            <gap></gap>
        </>}
    </>);
}