import React, { useCallback } from 'react';
import { InputNumber } from 'antd';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent, } from 'project/utilities';
import DropDownValueList from 'Shared/DropDown';
import { TabStrip } from 'Shared/TabStrip';
import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationDropDownValueList, ValidationInput } from 'Shared/Form/validation.js';

/**
 * the CreditCardPaymentsDetails of CreditCardPayments tab of sales networks
 * @exports CreditCardPaymentsDetails
 */
export default function CreditCardPaymentsDetails(props) {
    const { windowKey, data, onDelete } = props;

    const onLoadData = useCallback((response) => {
        if (response) {
            response.reseau_uuid = data.reseau_uuid;
        }
    }, [data]);

    const { isNew,
        dataItem,
        setDataItem,
        updateData,
        onChangeData,
        closePopup,
        detailRef,
        validation } = useDetail({
            data,
            updateUrl: ApiUrl.CreditCardPaymentsUpdate,
            windowKey,
            onLoadData: onLoadData,
            validationFields: ['activite_uuid', 'compte_gescom', 'compte_type_uuid','pays']
        });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(EVENT_NAME.SALES_NETWORK_PAYMENT_LIST_UPDATE, { action: 'update' });
    }, [setDataItem, detailRef]);

    return (<>
        {dataItem && <div id='windowContent' className='window_cont' ref={detailRef}>
            <form>
                <div className='form_container'>
                    <TabStrip skipSecondaryTabs={isNew}>
                        <div title='text.main' primary>
                            <div className='section_group'>
                                <div className='sections '>
                                    <FormField label="text.label">
                                        <input name='label' type='text' value={dataItem.label} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.code_gescom">
                                        <ValidationInput validation={validation} name='compte_gescom' type='text' value={dataItem.compte_gescom} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.montant3ds">
                                        <InputNumber name='montant3ds' min={0} max={100000} placeholder='' value={dataItem.montant3ds} onChange={(e) => { onChangeData({ target: { name: 'montant3ds', value: e } }) }} // NOSONAR
                                        />
                                    </FormField>
                                </div>

                                <div className='sections '>
                                    <div className='form_fields'>
                                        <div className='data_label'><T>text.pays</T></div>
                                        <ValidationDropDownValueList validation={validation} name='pays' value={dataItem.pays} valuelist='pays' onChange={data => onChangeData({ target: { name: 'pays', value: data } })} // NOSONAR
                                        />
                                    </div>
                                    <div className='form_fields'>
                                        <div className='data_label'><T>text.compte_type</T></div>
                                        <ValidationDropDownValueList validation={validation} name='compte_type_uuid' value={dataItem.compte_type_uuid} valuelist='compte_types' onChange={data => onChangeData({ target: { name: 'compte_type_uuid', value: data } })} // NOSONAR
                                        />
                                    </div>
                                    <div className='form_fields'>
                                        <div className='data_label'><T>text.boutique</T></div>
                                        <DropDownValueList name='vad_banque_compte_uuid' value={dataItem.vad_banque_compte_uuid} valuelist='boutique' onChange={data => onChangeData({ target: { name: 'vad_banque_compte_uuid', value: data } })} style={{ width: 200 }} // NOSONAR
                                        />
                                    </div>
                                    <div className='form_fields'>
                                        <div className='data_label'><T>text.activites</T></div>
                                        <ValidationDropDownValueList validation={validation} name='activite_uuid' value={dataItem.activite_uuid} valuelist='activites' onChange={data => onChangeData({ target: { name: 'activite_uuid', value: data } })} // NOSONAR
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </TabStrip>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => { onDelete(dataItem, closePopup) }}>
                                    <icon>delete</icon><T>text.delete</T>
                                    </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </div>}
    </>);
}
