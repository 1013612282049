import React, { useState, useEffect } from 'react';
import { ApiUrl, AppPages, StoreKeys } from '../../../project/Defines.js';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { PopupTypes, PopupClasses } from '../../../components/Popup.js';
import store from 'store';
import { EVENT_NAME } from '../../../project/utilities';
import { Project } from '../../../project/Project.js';

const defaultLetters = ['A', 'B', 'C', 'D'];
const validationFields = [{ name: 'quantite', rules: [{ type: 'required' }, { type: 'minValue', minValue: 1 }] }];

/**
 * the Spare Parts tab with list of attached SpareParts in BOM Detail
 * @exports BOM-SpareParts
 */
export default function SpareParts(props) {
    const { article_letters, product_letters, ID } = props;
    let letters = [...defaultLetters, ...(product_letters || []), ...(article_letters || [])];
    letters = letters.filter((l, i) => letters.indexOf(l) === i).sort();

    const [detailProps, setDetailProps] = useState({
        fullScreen: false,
        newTitle: 'text.add_spare_part',
        // editTitle: 'Edit Bill Of Material',
        popupType: PopupTypes.SparePartPicker,
        popupClassName: PopupClasses.Large,
        windowKey: 'pickSpareParts',
        newData: {
            letters,
            nomenclature_uuid: ID
        }
    });

    useEffect(() => {
        setDetailProps({
            ...detailProps, newData: {
                letters,
                nomenclature_uuid: ID
            }
        })
    }, [ID, letters.join()]);// eslint-disable-line react-hooks/exhaustive-deps

    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            width: 120,
            type: COLUMN_TYPE.TEXT,
            readOnly: true,
            fixed: "left",
            onRenderLink: (record) => Project.getPageUrl(AppPages.SparePartDetails, record.article_uuid, { backUrl: Project.getPageUrl(AppPages.BillOfMaterialDetails, ID, { tab: 3 }) })
        },
        {
            text: 'text.designation',
            field: 'designation',
            width: 200,
            fixed: "left",
            readOnly: true,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.period',
            field: 'periode',
            width: 140,
            fixed: "left",
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.quantite',
            field: 'quantite',
            width: 90,
            fixed: "left",
            type: COLUMN_TYPE.TEXT
            //editor: {
            //    customRenderer: (props) => {
            //        const { editingRecord, setEditingRecord } = props;
            //        return (
            //            <>
            ///                <ValidationInput
            //                    autoComplete='off'
            //                    validation={validation}
            //                    maxLength="50"
            //                    name="quantite"
            //                    placeholder=" "
            //                    type="text"
            //                    value={editingRecord.quantite}
            //                    onChange={(e) => {
            //                        editingRecord.quantite = e.target.value;
            //                        setValidation(validate({ data: { quantite: editingRecord.quantite },  validationFields}))
            //                    }}
            //                />
            //            </>
            //        )
            //    }
            //}
        },
        ...(letters.map(l => ({
            text: l,
            noSort: true,

            field: 'lettre',
            key: l,
            width: 40,
            type: COLUMN_TYPE.TEXT,
            render: v => (v === l ? <wrap style={{padding: "0 5px"} }><icon>done</icon></wrap> : null),
            editor: {
                customRenderer: (props) => { // NOSONAR
                    const { editingRecord, title, setEditingRecord } = props;
                    const { lettre, excluding_letters } = editingRecord;
                    const checked = lettre === title;
                    return (
                        <label classic=''>
                            <input type="checkbox"
                                disabled={excluding_letters && excluding_letters.includes(title)}
                                checked={checked}
                                onClick={() => { setEditingRecord({ ...editingRecord, lettre: checked ? excluding_letters && title : title }); }}
                            /><box><check></check></box>
                        </label>
                    )
                }
            }
        }))),
        {
            text: 'text.commentaire',
            field: 'commentaire',
            fixed: "right",
            width: 180,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.complement',
            field: 'complement',
            fixed: "right",
            width: 300,
            type: COLUMN_TYPE.TEXT
        },
        {
            fixed: "right",
            type: COLUMN_TYPE.ACTION
        },
    ];

    return (
        <ListView
            // tableLayout="auto"
            editable
            search={{ field: 'search' }}
            className="scroll_fix"
            isSimple
            //  noPaging
            {...props}
            staticFilter={{ nomenclature_uuid: ID, lang: store.get(StoreKeys.Culture) }}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ArticleBillOfMaterialList}
            inlineUpdateUrl={ApiUrl.ArticleBillOfMaterialUpdate}
            deleteUrl={ApiUrl.ArticleBillOfMaterialDelete}
            //onSaved={onSaved}
            deleteMessage="message.delete_confirm_article_bom"
            //filters={[
            //    { name: 'reference', type: 'text', text: 'text.reference', includeEmpty: true },
            //    { name: 'commentaire', type: 'text', text: 'text.commentaire' },
            //    { name: 'complement', type: 'text', text: 'text.complement' }
            //]}
            dataItemUrl={ApiUrl.ArticleBillOfMaterialLoad}
            nameField='designation'
            newButtonTip='text.add_spare_part'
            listUpdateEvent={EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE + " " + EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE2 + " " + EVENT_NAME.BILL_OF_MATERIAL_ZONE_ARTICLE_LIST_UPDATE2 + " " + EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2}
            validationFields={validationFields}
            scroll={{ x: (1000 + (letters.length * 50)), y: 300 }}
            detailProps={detailProps} />
    );
}
