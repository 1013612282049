import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';
import { Project } from 'project/Project';
import Translation from 'Shared/Translation';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';

/**
 * Defect detail of setup
 * @exports Setup-DefectDetails
 */
export default function DefectDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.SETUP_DEFECT_LIST_UPDATE;
    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.DefectUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.DefectLoad,
        nameField: 'nom',
        deleteUrl: ApiUrl.DefectDelete,
        backPage: AppPages.SetupProductDefect,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.SetupProductDefectDetails)
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.SETUP_DEFECT_TRANSLATION_UPDATE, { ID: response.ID });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.SetupProductDefect)}
            title={isNew ? <T>text.create_new_defect</T> : dataItem.nom}
        >
            <form>
                <div className='form_container'>
                    <Translation
                        ID={dataItem.ID}
                        fields={['libelle']}
                        _class='App\Models\Sav\Defaut'
                        updateEvent={EVENT_NAME.SETUP_DEFECT_TRANSLATION_UPDATE}
                        updateCallback={() => { setDataItem({ ...dataItem, reloadData: true }); }} // NOSONAR
                    />
                </div>

                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                <icon>delete</icon><T>text.delete</T>
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}

