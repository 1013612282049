import React from 'react';
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import { connect } from 'react-redux';

let x = 0;

function Suneditor(props) {
    const { value, onChange, culture, ..._props } = props;
    return <SunEditor lang={culture} width="100%" height={ props.height || 200}
        key={x}
        setContents={value}
        enableToolbar={true}
        setOptions={{
            height: 200,
            buttonList: [
                ["undo", "redo"],
                ["font", "fontSize", "formatBlock"],
                ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                ["removeFormat"],
                ["fontColor", "hiliteColor"],
                ["outdent", "indent"],
                ["align", "horizontalRule", "list", "table"],
                ["link", "image", "video"],
                ["showBlocks", "codeView"]]
            //buttonList.complex // Or Array of button list, eg. [['font', 'align'], ['image']]
            // Other option
        }}
        onChange={onChange}
        {..._props}
    />;
}
export default connect(state => ({
    culture: state.culture,
}))(Suneditor);