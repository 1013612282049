import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { TabStrip } from 'Shared/TabStrip';
import { T } from 'components/Translations';
import { Project } from 'project/Project';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields.js';
import Schedule from 'Shared/Schedule/index'
import ProcessLog from './ProcessLog'
import { ValidationInput } from '../../Shared/Form/validation.js';

/**
 * the Process for scheduling main page  Details
 * @exports ProcessDetails
 */
export default function ProcessDetails(props) {
    const { id } = useParams();
    const { windowKey, data, onDelete } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const listUpdateEvent = EVENT_NAME.PROCESS_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, onDetailDelete, detailRef, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.ProcessUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.ProcessLoad,
        nameField: 'process_name',
        deleteUrl: ApiUrl.ProcessDelete,
        backPage: AppPages.Process,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.ProcessDetails),
        validationFields: ["process_code", "process_name"],
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);


    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.Process)}
            title={isNew ? <T>text.new_process</T> : dataItem.process_code}
        >
            <form>
                <div className='form_container'>
                    <TabStrip onChange={(selected) => setTabIndex(selected)} skipSecondaryTabs={isNew} // NOSONAR
                    >
                        <div title='text.main' primary>
                            <div className='section_group'>
                                <div className='sections '>
                                    <FormField label="text.process_code">
                                        <ValidationInput validation={validation} name='process_code' type='text' value={dataItem.process_code} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.process_name">
                                        <ValidationInput validation={validation} name='process_name' type='text' value={dataItem.process_name} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.process_description">
                                        <textarea name='process_description' type='text' value={dataItem.process_description} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.line_number">
                                        <input name='line_number' type='text' value={dataItem.line_number} onChange={onChangeData} />
                                    </FormField>
                                    <FormField>
                                        <label checkbox=''><input name='inactive' type='checkbox' defaultChecked={dataItem.inactive} onChange={onChangeData} /><box><check></check></box><text><T>text.inactive</T></text></label>
                                    </FormField>
                                </div>
                                <div className='sections s_medium'>
                                    <Schedule onChangeData={onChangeData} {...dataItem}/>
                                </div>
                            </div>
                        </div>
                        <div title='text.process_log'>
                            <ProcessLog {...dataItem} />
                        </div>
                    </TabStrip>
                </div>
                {tabIndex === 0 &&
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                                {dataItem.ID &&
                                    <div className='button' onClick={(e) => {
                                        (onDelete && onDelete(dataItem, closePopup)) ||
                                            onDetailDelete(dataItem)
                                    }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>}
            </form>
        </DetailView>}
    </>);
}