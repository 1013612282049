import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import { closePopup } from 'store/actions';
import { Ajax } from 'components/Ajax';
import { T } from 'components/Translations';
import { ApiUrl } from 'project/Defines.js';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import VideoContainer from '../../../../Shared/Video';

/**
 * the videos pickup list to add videos to family of Family's Videos tab
 * @exports Universe-Family-Video
 */
export default function FamilyVideoPickup(props) {
    const dispatch = useDispatch();
    const [selectedRows, setSelectedRows] = useState([]);
    const [updating, setUpdating] = useState(false);

    const columnConfig = [
        {
            text: 'text.title',
            field: 'title',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.video_id',
            field: 'video_id',
            width: 300,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                return <wrap>
                    <VideoContainer
                        style={{ marginTop: '7px' }}
                        videoId={record.video_id}
                        videoType={record.video_type}
                    />
                </wrap>
            }
        },
    ];

    const updateData = useCallback(() => {
        setUpdating(true);
        Ajax.post({
            url: ApiUrl.FamilyVideoAdd,
            data: {
                famille_uuid: props.ID,
                selected: selectedRows
            },
            success: function (response) {
                setUpdating(false);
                if (!response.HasError) {
                    message.success(<T args={[response.AddedCount] || 'No'}>message.videos_were_added</T>);
                    dispatchCustomEvent(EVENT_NAME.FAMILY_VIDEO_LIST_UPDATE, { action: "update" });
                    dispatch(closePopup("lookupFamilyVideo"));
                } else {
                    message.error(response.message)
                }
            },
            error: function (response) {
                setUpdating(false);
            }
        })
    }, [selectedRows, props.ID, dispatch]);

    return (<>
        <ListView
            search={{ field: 'search' }}
            isSimple
            skipEdit
            {...props}
            skipNewButton
            //noPaging
            tableLayout="Fixed"
            staticFilter={{ famille_uuid: props.ID }}
            rowSelection={{
                selectedRowKeys: selectedRows,
                onChange: (selectedRowKeys) => {
                    setSelectedRows(selectedRowKeys);
                }
            }}
            onLoaded={(data, sorts) => { // NOSONAR
                return data;
            }}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.VideosVimeoList}
        />
        {
            <toolbar>
                <wrap>
                    <action right=''>
                        <div onClick={updateData} className='button' {...updating ? { disabled: 'disabled' } : {}}><icon>save</icon><T>text.add</T></div>
                    </action>
                </wrap>
            </toolbar>
        }
    </>);
}