import React from 'react';
import { useParams } from 'react-router-dom';

import LogoSauter from '../../../assets/images/logo_sauter.png';
import LogoTherpac from '../../../assets/images/logo_thermor.png';
import LogoAtlantic from '../../../assets/images/logo_atlantic.svg';

export default function Logo() {
    const params = useParams();

    const getLogo = () => {
        let logo = null;

        switch (params.universe) {
            case 'sauter':
            case undefined:
                logo = LogoSauter;
                break;
            case 'therpac':
                logo = LogoTherpac;
                break;
            case 'atlantic':
            case 'acv':
            case 'afbg':
                logo = LogoAtlantic;
                break;
            default:
                break;
        }

        return logo;
    }

    return <>
            <toolbar logo="logo">
                <img src={getLogo()} alt="logo"/>
            </toolbar>
    </>
}