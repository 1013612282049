import React from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { Project } from '../../../project/Project';

/**
 * Defects list of setup
 * @exports SetupDefects
 */
export default function SetupDefects(props) {

    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.SetupProductDefectDetails
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.DefectList}
            deleteUrl={ApiUrl.DefectDelete}
            detailUrl={Project.getPageUrl(AppPages.SetupProductDefectDetails)}
            filters={[{ name: 'created_at', type: 'date', text: 'text.created_at' },
            { name: 'updated_at', type: 'date', text: 'text.updated_at' }]}
            newButtonTip='text.create_new_defect'
            listUpdateEvent={EVENT_NAME.SETUP_DEFECT_LIST_UPDATE}
            detailProps={{ newTitle: 'text.create_new_defect', editTitle: 'text.edit_defect' }}
            listName={AppPages.SetupProductDefect}
            showRefreshButton={false} />
    );
}
