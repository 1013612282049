import React from 'react';

import { T } from '~/components/Translations';

export default function Toolbar({dataItem, onReset, color}) {
    return <>
        <toolbar>
            <div className="caption">
                <text><b><T>text.votre_panier</T> {dataItem && dataItem.reference}</b></text>
            </div>
            <separator vertical="vertical"></separator>
            <div
                effect="material"
                className="button"
                style={{backgroundColor: color, color: 'white'}} 
                onClick={onReset}
            >
                <icon>load</icon>
                <text><T>text.reinitialiser</T></text>
            </div>
        </toolbar>
    </>
}