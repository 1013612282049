import { ApiUrl, AppPages } from 'project/Defines.js';
import React, { useEffect, useState } from 'react';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { PopupClasses, PopupTypes } from '~/components/Popup';
import { EVENT_NAME } from '../../../project/utilities';
import { Project } from '~/project/Project';

/**
 * ...
 * @exports SocieteSkills
 */
export default function SocieteTechnisian (props) {
    const { ID } = props;
    const [detailProps, setDetailProps] = useState({});

    const columnConfig = [
        {
            text: 'text.first_name',
            field: 'prenom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.last_name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    useEffect(() => {
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.create_new_technician',
            editTitle: 'text.edit_technician',
            popupType: PopupTypes.SocieteTechnisianDetails,
            windowKey: 'changeSocieteTechnisian',
            popupClassName: PopupClasses.Medium,
            newData: { data: { societe_uuid: ID} }
        });
    }, [ID, props]);

    return (
        <ListView
            //{...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.SocieteTechnisianList}
            deleteUrl={ApiUrl.SocieteTechnisianDelete}
            detailUrl={Project.getPageUrl(AppPages.SocieteTechnisianDetails)}
            //deleteKeys={["societe_uuid", "skill_uuid"]}
            staticFilter={{ societe_uuid: ID }}
            newButtonTip='text.create_new_technician'
            listUpdateEvent={EVENT_NAME.SOCIETE_TECHNISIAN_LIST_UPDATE}
            //onNewClick={onNewSocieteSkills}
            //listName={AppPages.SocieteSkills}
            showRefreshButton={false}
            nameField='nom'
            detailProps={detailProps}
        />

    );
}