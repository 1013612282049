import React  from 'react';
import { PopupTypes } from '~/components/Popup';
import { ApiUrl, AppPages } from '~/project/Defines';
import { EVENT_NAME } from '~/project/utilities';

import ListView from '~/Shared/ListView';
import { COLUMN_TYPE } from '~/Shared/ListView/helper';

export default function SetupCountryGrouping(props) {
    const country_uuids = props.ID;
    const listUpdateEvent = EVENT_NAME.SETUP_COUNTRY_GROUP_LIST_UPDATE;

    const columnConfig = [
        {
            text: 'text.group_name',
            field: 'slug',
            width: 240,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.pays',
            field: 'country_name',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION
        }
    ];

    return (
        <>
            <view light="light">
                <ListView
                    {...props}
                    columnConfig={columnConfig}
                    apiUrl={ApiUrl.CountryGroupList}
                    deleteUrl={ApiUrl.CountryGroupDelete}
                    nameField="slug"
                    newButtonTip='text.add_new_country_group'
                    listUpdateEvent={listUpdateEvent}
                    listName={AppPages.Setup}
                    filters={[
                        { name: 'slug', type: 'text', text: 'text.group_name' },
                        { name: 'country_names', type: 'select', text: 'text.pays', valuelist: 'pays', autoComplete: "new-password", disableCach: true, noAccent: true }
                    ]}
                    detailProps={{
                        fullScreen: false,
                        newTitle: 'text.add_new_country_group',
                        editTitle: 'text.edit_country_group',
                        popupType: PopupTypes.SetupCountryGroupingDetails,
                        windowKey: 'changeCountryGrouping',
                        newData: { data: { slug: '', country_name: '', country_names: '', country_uuids: country_uuids } }
                    }}
                />
            </view>
        </>
     );
}