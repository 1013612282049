import React from 'react';
import { message } from 'antd';
import { ApiUrl } from 'project/Defines.js';
import { Ajax } from 'components/Ajax';
import {  T } from 'components/Translations';

/**
 * execute current Process
 * @exports DoProcess
 */
export function doProcess(record, onSuccess, onEerror) {

    Ajax.post({
        url: ApiUrl.ProcessDoAction,
        data: {
            process_id: record.ID,
            process_code: record.process_code
        },
        success: function (response) {
            if (response) {
                if (!response.HasError) {
                    message.success(<T>message.process_compleated</T>);
                } else {
                    message.error(response.message)
                }
            }
            onSuccess && onSuccess(response)
        },
        error: function (response) {
            onEerror && onEerror(response)
        }
    })
};

 
