import React, { useEffect, useState, useCallback } from 'react';
import { message } from 'antd';
import { useTranslation } from "react-i18next";
import { T } from '../../../components/Translations';
import { ApiUrl } from '../../../project/Defines.js';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { FormField } from '../../../Shared/Form/formFields';
import { Ajax } from '../../../components/Ajax';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';
import { ValidationInput, validate } from '../../../Shared/Form/validation';

/**
 * the Spare Parts tab with list to pick to attach SpareParts to BOM in BOM Detail
 * @exports BOM-SparePartPicker
 */
const defaultCommonData = { quantite: 1, periode: '', commentaire: '', complement: '', lettres: [] };
const validationFields = [{ name: 'quantite', rules: [{ type: 'minValue', minValue: 1 }] }]
export default function SparePartPicker(props) {
    const { letters, nomenclature_uuid } = props;
    const [customLetters, setCustomLetters] = useState([]);
    const [customLetterValue, setCustomLetterValue] = useState('');
    const [searchInTranslation, setSearchInTranslation] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [validation, setValidation] = useState({ isValid: false });
    const [commonData, setCommonData] = useState({ ...defaultCommonData, lettres: [] });
    const [updating, setUpdating] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        dispatchCustomEvent(EVENT_NAME.SPARE_PART_PICKER_LIST_UPDATE);
    }, [searchInTranslation]);

    useEffect(() => {
        setValidation(validate({ validationFields, data: commonData }));
    }, [commonData]);

    useEffect(() => {
        setCustomLetters(letters || []);
    }, [letters]);

    const addCustomLetter = useCallback(() => {
        const newVal = [...customLetters, customLetterValue];
        customLetterValue && setCustomLetters(newVal.filter((l, i) => newVal.indexOf(l) === i));
        let lettres = commonData.lettres.slice()
        lettres.push(customLetterValue);
        setCommonData({ ...commonData, lettres });
        setCustomLetterValue('');
    }, [customLetters, customLetterValue, commonData]);

    const onCustomLetterChange = useCallback((e) => {
        setCustomLetterValue(e.target.value);
    }, []);

    const onDataChange = (event, ...d) => { // NOSONAR
        let newData = { ...commonData };
        if (event.target && event.target.name) {
            switch (event.target.type) { // NOSONAR
                case 'checkbox':
                    if (event.target.name === 'lettres') {
                        if (event.target.checked && event.target.value && !newData.lettres.includes(event.target.value)) {
                            newData.lettres.push(event.target.value);
                        } else {
                            newData.lettres = newData.lettres.filter(l => l !== event.target.value)
                        }
                    } else {
                        newData[event.target.name] = (event.target.checked && event.target.value) || '';
                    }
                    break;
                default:
                    newData[event.target.name] = event.target.value;
                    break;
            }
        }
        setCommonData(newData);
    }

    const updateData = useCallback((e) => {
        setUpdating(true);
        Ajax.post({
            url: ApiUrl.ArticleBillOfMaterialAdd,
            data: {
                nomenclature_uuid,
                selected_ids: selectedRows,
                ...commonData
            },
            success: function (response) {
                setUpdating(false);
                if (!response.HasError) {
                    message[response.AddedCount ? 'success' : 'info'](<T args={[response.AddedCount || t('text.no')]}>message.spare_parts_were_added</T>, 3);

                    (response.skipped || []).every(sk => message.info(<T args={[sk.Reference, (sk.Lettre || t('text.empty'))]}>message.spare_parts_were_already_added</T>, 3));

                    dispatchCustomEvent(EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE);
                    dispatchCustomEvent(EVENT_NAME.SPARE_PART_PICKER_LIST_UPDATE);
                    setSelectedRows([]);
                    setCommonData({ ...defaultCommonData, lettres: [] });
                    dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_DETAIL_RELOAD);

                    dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2, { action: "update" });
                    dispatchCustomEvent(EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE2, { action: "update" });

                } else {
                    message.error(response.message)
                }
            },
            error: function (response) {
                setUpdating(false);
            }
        })
    }, [nomenclature_uuid, selectedRows, commonData, t]);

    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.designation',
            field: 'designation',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.supplier',
            field: 'fournisseur',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
    ];

    return (<>
        <toolbar highlight="highlight" scroll="scroll" multiline="multiline">
            <wrap wide="wide">
                <action>
                    <wrap>
                        <FormField formFieldClass='line'>
                            <div className="data_label"><T>text.letter</T></div>
                            <div role-type='checklist' className='option_bar'>
                                {
                                    customLetters.map((l, i) =>
                                        <label key={l + i} // NOSONAR
                                        >
                                            <input type='checkbox' name='lettres' value={l} onChange={onDataChange} checked={commonData.lettres.includes(l)} />
                                            <wrap effect='material'><text>{l}</text> </wrap>
                                        </label>
                                    )
                                }
                            </div>
                        </FormField>

                        <FormField formFieldClass='line middle'>
                            <div className="input_cont">
                                <input type='text' name='lettre' placeholder='Add Letter' value={customLetterValue} maxLength={1} onChange={onCustomLetterChange} />
                                <button className="button micro" onClick={addCustomLetter} type="button"><icon >plus</icon></button>
                            </div>
                        </FormField>
                    </wrap>
                </action>
                <action>
                    <wrap>
                        <FormField formFieldClass='line small'>
                            <div className="data_label"><T>text.quantite</T></div>
                            <ValidationInput validation={validation} name='quantite' type='text' value={commonData.quantite} onChange={onDataChange} // NOSONAR
                            />
                        </FormField>
                        <FormField formFieldClass='line'>
                            <input name='periode' type='text' placeholder={t('text.period')} maxLength='1000' value={commonData.periode} onChange={onDataChange/*onChangeData*/} />
                        </FormField>
                        <FormField formFieldClass='large line'>
                            <input name='commentaire' type='text' placeholder={t('text.commentaire')} value={commonData.commentaire} onChange={onDataChange/*onChangeData*/} />
                        </FormField>
                        <FormField formFieldClass='line'>
                            <input name='complement' type='text' placeholder={t('text.complement')} value={commonData.complement} onChange={onDataChange/*onChangeData*/} />
                        </FormField>
                    </wrap>
                </action>
            </wrap>
        </toolbar>
        <ListView
            {...props}
            isSimple
            skipEdit
            skipNewButton
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ArticleList}
            search={{
                field: 'search',
                children: <><label checkbox=""><input type="checkbox" checked={searchInTranslation} onChange={(e) => {
                    setSearchInTranslation(e.target.checked);
                }} /><box><check></check></box><text><T>text.search_in_translation</T></text></label></>
            }}
            newButtonTip='text.add'
            staticFilter={{ nomenclature_uuid, searchInTranslation }}

            listUpdateEvent={EVENT_NAME.SPARE_PART_PICKER_LIST_UPDATE}
            rowClassName={record => { // NOSONAR
                return selectedRows.some(r => record.ID === r) ? 'select' : null
            }}
            rowSelection={{
                selectedRowKeys: selectedRows,
                onChange: (selectedRowKeys) => {
                    setSelectedRows(selectedRowKeys);
                }
            }}
        />
        <toolbar>
            <wrap>
                <action right=''>
                    <div disabled={!validation.isValid || !selectedRows || !selectedRows.length || updating} onClick={updateData} className='button'><icon>save</icon><T>text.add</T></div>
                </action>
            </wrap>
        </toolbar>
    </>);
}
