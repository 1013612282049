import React, { useState, useCallback, useEffect } from 'react';
import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { DataTip, T } from 'components/Translations'
import { FormField } from '../../../Shared/Form/formFields';
import { Ajax } from '../../../components/Ajax';
import { message } from 'antd';

/**
 * the User(Societe) detail ClubStatus list tab
 * @exports User-SocieteClubStatus
 */
export default function SocieteClubStatus(props) {
    const { dataItem, setDataItem, updateData, detailRef, listUpdateEvent } = props;
    const [isCodeEditing, setIsCodeEditing] = useState(false);
    const [codeCurrentValue, setCodeCurrentValue] = useState('');

    const [isCodeEmailEditing, setIsCodeEmailEditing] = useState(false);
    const [codeEmailCurrentValue, setCodeEmailCurrentValue] = useState('');

    useEffect(() => {
        setCodeCurrentValue(dataItem.acces_club_code_acces);
    }, [dataItem.acces_club_code_acces]);

    useEffect(() => {
        setCodeEmailCurrentValue(dataItem.acces_club_email);
    }, [dataItem.acces_club_email]);

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem({ ...dataItem, acces_club_code_acces: response.acces_club_code_acces, acces_club_email: response.acces_club_email });
            setIsCodeEditing(false);
            setIsCodeEmailEditing(false);
        }
        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
    }, [setDataItem, dataItem, detailRef, listUpdateEvent]);

    const onCodeChange = useCallback(e => {
        setCodeCurrentValue(e.target.value);
    }, [setCodeCurrentValue]);

    const onCodeEmailChange = useCallback(e => {
        setCodeEmailCurrentValue(e.target.value);
    }, [setCodeEmailCurrentValue]);

    const sendEmail = useCallback(() => {
        Ajax.get({
            url: ApiUrl.ClubSendEmail,
            data: { identifiant: dataItem.siret },
            success: function (response) {
                if (response && detailRef.current) {
                    message[response.hasError ? "error" : "success"](response.message);
                }
            }
        })
    }, [dataItem, detailRef]);
    const columnConfig = [
        {
            text: 'text.club',
            field: 'club_nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.status',
            field: 'statut_nom',
            width: 250,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.points',
            field: 'points',
            width: 90,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.points_n_1',
            field: 'points_n_1',
            width: 90,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.solde',
            field: 'solde',
            width: 150,
            type: COLUMN_TYPE.TEXT,
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return <>
        {dataItem && <> < toolbar highlight="highlight" scroll="scroll">
            <wrap wide="wide">
                <action>
                    <wrap>
                        <FormField formFieldClass='line'>
                            <div className="data_label"><T>text.code</T></div>

                            <div className='input_cont'>
                                {isCodeEditing ?
                                    <>
                                        <input name='acces_club_code_acces' type='text' value={codeCurrentValue || ''} onChange={onCodeChange} />
                                        <DataTip title={'text.save'}> <div className='button micro' onClick={() => { updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, acces_club_code_acces: codeCurrentValue, onlySociete: true } }) }}><icon>done</icon></div></DataTip>
                                        <DataTip title={'text.cancel'}><div className='button micro' onClick={e => setIsCodeEditing(false)}><icon >cancel</icon></div></DataTip>

                                    </> :
                                    <><input type='text' readOnly value={dataItem.acces_club_code_acces} />   <DataTip title={'text.edit'}><div className='button micro' type='button' onClick={e => { setIsCodeEditing(true); setCodeCurrentValue(dataItem.acces_club_code_acces); }}><icon >edit</icon></div></DataTip></>}
                            </div>
                        </FormField>
                        <FormField formFieldClass='line'>
                            <div className="data_label"><T>text.email_to_send_the_code</T></div>

                            <div className='input_cont' style={{ "width": "250px" }}>
                                {isCodeEmailEditing ?
                                    <>
                                        <input name='acces_club_email' type='text' value={codeEmailCurrentValue || ''} onChange={onCodeEmailChange} />
                                        <DataTip title={'text.save'}> <div className='button micro' onClick={() => { updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, acces_club_email: codeEmailCurrentValue, onlySociete: true } }) }}><icon>done</icon></div></DataTip>
                                        <DataTip title={'text.cancel'}><div className='button micro' onClick={e => setIsCodeEmailEditing(false)}><icon >cancel</icon></div></DataTip>

                                    </> :
                                    <><input type='text' readOnly value={dataItem.acces_club_email} />   <DataTip title={'text.edit'}><div className='button micro' type='button' onClick={e => { setIsCodeEmailEditing(true); setCodeCurrentValue(dataItem.acces_club_code_acces); }}><icon >edit</icon></div></DataTip></>}
                            </div>
                        </FormField>
                        <div effect="material" className="button accent" disabled={isCodeEmailEditing || isCodeEditing} onClick={sendEmail}><icon>mail</icon><text><T>text.send_code_by_email</T></text></div>
                    </wrap>
                </action>
            </wrap>
        </toolbar>
            <ListView
                isSimple
                //   isChieldView
                noPaging
                skipEdit
                skipNewButton
                staticFilter={{ societe_uuid: dataItem.ID }}
                apiUrl={ApiUrl.SocieteClubStatusList}
                columnConfig={columnConfig}
                pagination={false}
                listUpdateEvent={EVENT_NAME.SOCIETE_CLUB_STATUS_LIST_UPDATE}
            //detailProps={{ newTitle: 'text.new_club_status', editTitle: 'text.edit_club_status', popupType: PopupTypes.SocieteUserDetails, windowKey: 'wndSocieteClubStatusDetail', }}
            /></>}</>;
}