import { InputNumber, message } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Ajax } from '~/components/Ajax.js';
import useDetail from '~/Shared/useDetail/index.js';
import { ApiUrl, UniversConfig } from '../../project/Defines.js';
import { deepCopy } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { getPrixHT, getTotalHT, getDeeeHT, getEcoContr } from './calculations';
import Logo from './Logo/Logo';
import PanierExterneForm from './PanierExterneForm/index';
import Toolbar from './Toolbar/Toolbar.js';






const defaultValidationFields = ["adresse_facturation.raison_sociale",
    { name: "email", rules: [{ type: "required" }, { type: "email" }] },
    { name: "telephone", rules: [{ type: "required" }, { type: "regexp", regexp: /^[0]{1}[0-9]{9}$/, message: "text.telephone_number_validation" }] }, // NOSONAR
    "adresse_facturation.adresse",
    { name: "adresse_facturation.cp", rules: [{ type: "required" }, { type: "regexp", regexp: /^[0-9]{5}$/, message: "text.code_postal_validation" }] }, // NOSONAR
    "adresse_facturation.ville",
];
const extendedValidationFields = [...defaultValidationFields,
    { name: "telephone", rules: [{ type: "required" }, { type: "regexp", regexp: /^[0]{1}[0-9]{9}$/, message: "text.telephone_number_validation" }] }, // NOSONAR
    "adresse_livraison.raison_sociale",
    "adresse_livraison.adresse",
    { name: "adresse_livraison.cp", rules: [{ type: "required" }, { type: "regexp", regexp: /^[0-9]{5}$/, message: "text.code_postal_validation" }] }, // NOSONAR
    "adresse_livraison.ville"];
export default function PanierExterne(props) {
    const history = useHistory();
    const params = useParams();
    const ref = useRef(true);

    const [initialData, setInitialData] = useState();
    const [isChecked, setIsChecked] = useState({ address: false, payment: false });
    const [color, setColor] = useState();
    const [loading, setLoading] = useState(false);
    const [validationFields, setValidationFields] = useState(extendedValidationFields)

    const { dataItem, setDataItem, onChangeData, validation } = useDetail({
        data: {},
        dataLoadUrl: ApiUrl.ExternalCardList,
        dataLoadParams: params,
        onLoadData: data => {
            if (ref.current) {
                setInitialData(deepCopy(data));
            };
        },
        validationFields,
        changingValidation: true
    });

    const submitData = useCallback(() => {
        setLoading(true);
        const data = { ...dataItem };
        if (isChecked.address) {
            data.adresse_livraison = { ...data.adresse_facturation };
        }
        Ajax.post({
            url: ApiUrl.SubmitPayment,
            data,
            success: (response) => {
                if (response.HasError) {
                    setLoading(false);
                    message.error(response.message);
                } else {
                    document.write(response.html);
                    document.getElementById('payment').submit();
                }
            },
            error: () => {
                setLoading(false);
            }
        });
    }, [dataItem, isChecked.address]);

    useEffect(() => {
        if (!isChecked.address) {
            setValidationFields(extendedValidationFields);
        } else {

            setValidationFields(defaultValidationFields);
        }
    }, [isChecked.address])

    useEffect(() => {
        const universe = params.universe || 'sauter';

        if (universe === 'sauter' || universe === 'therpac') {
            setColor(UniversConfig[universe].color);
        } else if (universe === 'atlantic' || universe === 'afbg' || universe === 'acv') {
            setColor(UniversConfig['atlantic'].color);
        } else {
            history.push("/notFound".toLowerCase())
        }

        return () => { ref.current = false };
    }, [history, params.universe])

    const onDelete = (index) => {
        const _lignes = [...dataItem.lignes];
        if (_lignes.length > 1) {
            _lignes.splice(index, 1);
        }
        setDataItem(prev => ({
            ...prev,
            lignes: _lignes
        }));
    }

    const onReset = () => {
        setDataItem(deepCopy(initialData));
        setIsChecked({ address: false, payment: false });
    }

    const handleQuantityChange = (value, id, maxvalue) => {
        const _lignes = [...dataItem.lignes];
        _lignes.find(l => l.id === id).quantite = value;
        setDataItem(prev => ({
            ...prev,
            lignes: _lignes
        }));
    };

    const columnConfig = [
        {
            text: 'text.reference',
            field: 'art_code',
            noSort: 'false',
            width: 80,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.produit',
            field: 'libelle',
            noSort: 'false',
            width: 400,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.prix_ht',
            noSort: 'false',
            width: 30,
            type: COLUMN_TYPE.TEXT,
            render: (el) => (
                <div style={{ textAlign: 'center' }}>
                    {(el.prix_ht && getPrixHT(el).toLocaleString('fr-FR', { style: "currency", currency: "EUR" })) || <div style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}>!</div>}
                </div>
            )
        },
        {
            text: 'text.eco_part',
            noSort: 'false',
            width: 30,
            type: COLUMN_TYPE.TEXT,
            render: (el) => (
                <div style={{ textAlign: 'center' }}>
                    {(getDeeeHT(el) && getDeeeHT(el).toLocaleString('fr-FR', { style: "currency", currency: "EUR" })) || <div></div>}
                </div>
            )
        },
        {
            text: 'text.eco_contr',
            noSort: 'false',
            width: 30,
            type: COLUMN_TYPE.TEXT,
            render: (el) => (
                <div style={{ textAlign: 'center' }}>
                    {(getEcoContr(el) && getEcoContr(el).toLocaleString('fr-FR', { style: "currency", currency: "EUR" })) || <div></div>}
                </div>
            )
        },
        {
            text: 'text.quantite',
            noSort: 'false',
            width: 30,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                let maxValue = initialData.lignes.find(l => l.id === record.id).quantite // NOSONAR
                return <div className="form_fields">
                    <InputNumber min={0} max={maxValue} precision={0} value={record.quantite} onChange={value => handleQuantityChange(value, record.id)} // NOSONAR
                    />
                </div>
            }
        },
        {
            text: 'text.total_ht',
            noSort: 'false',
            width: 30,
            type: COLUMN_TYPE.TEXT,
            render: (el) => (
                <div style={{ textAlign: 'center' }}>
                    {el.prix_ht ? (getTotalHT(el).toLocaleString('fr-FR', { style: "currency", currency: "EUR" })) : <div style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}>!</div>}
                </div>
            )

        },
        {
            type: COLUMN_TYPE.TEXT,
            noSort: 'false',
            width: 30,
            render: (text, record, index) => (
                <elastic>
                    <action hover="">
                        <div command="delete" className="button extra" onClick={() => onDelete(index)}>
                            <icon edit="">delete</icon>
                        </div>
                    </action>
                </elastic>
            )
        },
    ];

    return <>
        <Logo />
        {loading ? <h1>loading</h1> : <view>
            <Toolbar
                dataItem={dataItem}
                onReset={onReset} // NOSONAR
                color={color}
            />
            {
                dataItem &&
                <ListView
                    {...props}
                    skipLogin
                    skipEdit
                    isSimple
                    skipNewButton
                    noPaging
                    columnConfig={columnConfig}
                    onCustomDelete={onDelete} // NOSONAR
                    dataSource={dataItem.lignes.map(el => { el.key = el.id; return el; })}
                    detailProps={{ auto_height: 'auto_height' }}
                    auto_height
                    small
                />
            }
            <PanierExterneForm
                dataItem={dataItem}
                onChangeData={onChangeData}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                submitData={submitData}
                color={color}
                universe={params.universe || 'sauter'}
                validation={validation}
            />
        </view>}
    </>;
}
