import React from "react";
import { T } from '../../components/Translations';

/**
 * template tu put in forms
 * @param {any} props contains: label, labelClass, formFieldClass, children
 * @exports FormField
 */
export function FormField(props) {
    const { label, labelClass, formFieldClass, children } = props;

    return (<div className={'form_fields ' + (formFieldClass || '')}>
        {(label || labelClass) && <div className={'data_label' + (labelClass || '')}><T>{label}</T></div>}
        {children}
    </div>);
}

/**
 *a template to put in forms
 * @exports FieldGroup
 */
export function FieldGroup({ children }) {
    return (
        <div className='field_group'>
            {children}
        </div>);
}