import React, { useCallback, useEffect, useState } from 'react';

import { ApiUrl } from '../../../project/Defines';
import { EVENT_NAME, dispatchCustomEvent } from '../../../project/utilities';
import { getLanguages } from '~/Shared/Translation';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import ListView from '../../../Shared/ListView';
import useDetail from '../../../Shared/useDetail';
import { FormField } from '../../../Shared/Form/formFields';
import { Ajax } from '../../../components/Ajax';
import { DataTip } from '../../../components/Translations';
import { ValidationDropDownValueList } from '../../../Shared/Form/validation';

/**
 * the product detail Parameter list tab wit inline add
 * @exports ParameterProduct
 */
export default function ParameterProduct(props) {
    const cultures = getLanguages();
    const [dataItemDefaultValue, setDataItemDefaultValue] = useState({ valeur_par_defaut: '', parametre_uuid: '' });
    const produit_uuid = props.dataItem.ID;
    const listUpdateEvent = EVENT_NAME.PARAMETER_PRODUCT_LIST_UPDATE;
    const { dataItem, setDataItem, updateData, onChangeData, onDetailDelete, detailRef, validation } = useDetail({
        data: dataItemDefaultValue,
        updateUrl: ApiUrl.ParameterProductUpdate,
        //nameField: 'nom',
        deleteUrl: ApiUrl.ParameterProductDelete,
        additionalData: { produit_uuid },
        listUpdateEvent,
        validationFields: ["parametre_uuid"]
    });

    useEffect(() => {
        const newVal = { ...dataItemDefaultValue };
        cultures.forEach(c => { newVal[c.value] = '' });
        setDataItemDefaultValue({ ...newVal });
        setDataItem({ ...newVal });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const updateTranslationData = useCallback((id) => {
        const data = cultures.filter(c => dataItem[c.value]).map(c => ({ lang: c.value, champ: 'libelle', traduction: dataItem[c.value], _class: 'App\\Models\\Catalogue\\ParametreProduit' }))
        Ajax.post({
            url: ApiUrl.TranslationsUpdate,
            data: {
                tdm: data,
                class_uuid: id
            },
            success: function () {
                if (detailRef.current) {
                    setDataItem(dataItemDefaultValue);
                    dispatchCustomEvent(listUpdateEvent, { action: 'update' });
                    dispatchCustomEvent(EVENT_NAME.RELOAD_PARAMETRES_VALUE_LIST);
                }
            }
        })
    }, [dataItem, detailRef, setDataItem, listUpdateEvent, dataItemDefaultValue, cultures]);

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            updateTranslationData(response.ID);
        }
    }, [detailRef, updateTranslationData]);

    const columnConfig = dataItem && [
        {
            text: 'text.name',
            type: COLUMN_TYPE.TEXT,
            field: 'nom',
            // noSort: true,
            //render: (text, record) => {
            //    return record.isTopEditing ? <wrap><FormField><DropDownValueList valuelist='Parametres' value={dataItem.parametre_uuid} onChange={e => onChangeData({ target: { name: 'parametre_uuid', value: e } })} /></FormField></wrap> : <wrap><text>{text}</text></wrap>;
            //},
            children: [
                {
                    title: <wrap><FormField><ValidationDropDownValueList validation={validation} name='parametre_uuid' disableCach reloadEventName={EVENT_NAME.RELOAD_PARAMETRES_VALUE_LIST} valuelist='Parametres' additionalData={produit_uuid} value={dataItem.parametre_uuid} onChange={e => onChangeData({ target: { name: 'parametre_uuid', value: e } })} // NOSONAR
                    /></FormField></wrap>,
                    key: 'nom',
                    sorter: null,
                    dataIndex: 'nom',
                    width: 200,
                    render: (text) => <wrap><text>{text}</text></wrap>
                }],
        },
        {
            text: 'text.by_default',
            type: COLUMN_TYPE.TEXT,
            noSort: true,
            children: [
                {
                    title: <wrap><FormField><input type='text' name={'valeur_par_defaut'} value={dataItem.valeur_par_defaut} onChange={onChangeData} /></FormField></wrap>,
                    key: 'valeur_par_defaut',
                    dataIndex: 'valeur_par_defaut',
                    width: 200,
                    render: (text) => <wrap><text>{text}</text></wrap>
                }]
        },
        ...(cultures.map(l => ({
            text: l.text, //'text.' + l,
            type: COLUMN_TYPE.TEXT,
            noSort: true,
            children: [
                {
                    title: <wrap><FormField><input type='text' value={dataItem[l.value]} name={l.value} onChange={onChangeData} /></FormField></wrap>,
                    key: 'translations',
                    dataIndex: 'translations',
                    render: (translations) => {
                        let value = '';
                        if (translations && translations.length) {
                            const translation = translations.find(str => {
                                return JSON.parse(str).lang === l.value;
                            });
                            if (translation) {
                                value = JSON.parse(translation).traduction;
                            }
                        }
                        return <wrap>{value}</wrap>
                    }
                }]
        }))),
        {
            type: COLUMN_TYPE.ACTION,
            children: [
                {
                    title: <wrap><DataTip title={'text.add'}><div className='button micro' onClick={() => updateData(updateSuccessCallback)}> <icon>done</icon></div></DataTip></wrap>,
                    width: 60,
                    render: (text, record) => (
                        <>
                            <elastic>
                                <action hover=""><div
                                    key='delete'
                                    command='delete' className="button extra" onClick={() => { onDetailDelete(record, () => { dispatchCustomEvent(EVENT_NAME.RELOAD_PARAMETRES_VALUE_LIST) }); }}> <icon >delete</icon>  </div>
                                </action>
                            </elastic>
                        </>)
                }],
        },
    ];

    return dataItem && <ListView
        editable
        isSimple
        skipNewButton
        noPaging
        {...props}
        staticFilter={{ produit_uuid }}
        columnConfig={columnConfig}
        apiUrl={ApiUrl.ParameterProductList}
        inlineUpdateUrl={ApiUrl.ParameterProductUpdate}
        deleteUrl={ApiUrl.ParameterProductDelete}
        dataItemUrl={ApiUrl.ParameterProductLoad}
        nameField='designation'
        newButtonTip='text.add_parameter'
        listUpdateEvent={listUpdateEvent}
    />;
}