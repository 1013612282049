import React from 'react';
import { ApiUrl, AppPages } from '../../../project/Defines.js';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { Project } from '../../../project/Project.js';
import { EVENT_NAME } from '../../../project/utilities';

/**
 * the Product list of SparePart in UseCase tab
 * @exports SparePart-UseCase
 */
export default function UseCase(props) {
    const columnConfig = [

        {
            text: 'text.domain',
            field: 'domaine',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.sales_network',
           // noSort: true,
            field: 'nom',
            //width: 280,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.reference',
            field: 'reference',
            width: 120,
            type: COLUMN_TYPE.TEXT,
            // linkAppPage: AppPages.ProductDetails
            onRenderLink: (record) => Project.getPageUrl(AppPages.ProductDetails, record.ID,
                { backUrl: Project.getPageUrl(AppPages.SparePartDetails, props.ID, { tab: 3 }) }
            )
        },
        {
            text: 'text.name',
            field: 'traduction',
            type: COLUMN_TYPE.TEXT
        }
    ];

    return (<>
        <ListView
            search={{ field: 'search' }}
            isSimple
            skipEdit
            {...props}
            skipNewButton
            noPaging
            tableLayout="Fixed"
            staticFilter={{ article_uuid: props.ID }}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ArticleProductList}
            listUpdateEvent={EVENT_NAME.PRODUCT_LIST_UPDATE}
        />
    </>);
}
