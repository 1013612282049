import React from 'react';
import { useParams } from 'react-router-dom';
import { T } from '../../../components/Translations';

export default function PanierError() {
    const { ref } = useParams();

    return <>
        <div className="alert_contianer" center="center">
            <alert error="error" message="message">
                <text><b><T>message.payment_error</T></b><T args={[ref]}>message.order_payment_error</T></text>
            </alert>
        </div>
    </>
}