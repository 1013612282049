import React, { useEffect, useState } from 'react';
import { ApiUrl, AppPages } from '../../../project/Defines.js';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { Project } from '../../../project/Project';
import { EVENT_NAME } from '../../../project/utilities';
import { PopupTypes, PopupClasses } from '../../../components/Popup.js';
const defaultLetters = ['A', 'B', 'C', 'D'];

/**
 * the UseCase tab with list to of Products using that BOM in BOM Detail
 * @exports BOM-UseCase
 */
export default function UseCase(props) {
    const { article_letters, product_letters, ID } = props;
    const [detailProps, setDetailProps] = useState({});
    let letters = [...defaultLetters, ...(product_letters || []), ...(article_letters || [])];
    letters = letters.filter((l, i) => letters.indexOf(l) === i).sort();

    useEffect(() => {
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.add_product',
            popupType: PopupTypes.ProductPicker,
            popupClassName: PopupClasses.Large,
            windowKey: 'pickProducts',
            newData: {
                letters,
                nomenclature_uuid: ID
            }
        })
    }, [ID]);// eslint-disable-line react-hooks/exhaustive-deps

    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            width: 120,
            type: COLUMN_TYPE.TEXT,
            readOnly: true,
            onRenderLink: (record) => Project.getPageUrl(AppPages.ProductDetails, record.ID, { backUrl: Project.getPageUrl(AppPages.BillOfMaterialDetails, props.ID, { tab: 2 }) })
        },
        {
            text: 'text.letter',
            field: 'lettre',
            width: 80,
            type: COLUMN_TYPE.TEXT,
            editor: {
                maxLength: 1
            }
        },
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT,
            readOnly: true
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    return (<>
        <ListView
            editable
            search={{ field: 'search' }}
            isSimple
            noPaging
            tableLayout="Fixed"
            staticFilter={{ nomenclature_uuid: ID }}
            inlineUpdateUrl={ApiUrl.ProductsUpdate}
            deleteUrl={ApiUrl.RemoveBOMProduct}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ProductsList}
            newButtonTip='text.create_new_use_case'
            listUpdateEvent={EVENT_NAME.PRODUCT_BILL_OF_MATERIAL_LIST_UPDATE}
            detailProps={detailProps}
        />
    </>);
}
