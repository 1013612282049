import React from 'react';

import ScheduleItem from './scheduleItem';

/**
 *list of adding scheduleItems
 */
export default function ScheduleList({selectedScheduleType, scheduleItems, setScheduleItems, getNewItem, scheduleItemRemoved}) {
    return (
        <>
            {
                //<div className="form_fields ">
                //    <div className="data_label">Schedule:</div>
                //</div>
            }
                {scheduleItems.map((item, index) => {
                    return (
                        <div className="field_group" key={index} // NOSONAR
                        >
                            <ScheduleItem
                                item={item}
                                showHeader={index === 0}
                                scheduleItems={scheduleItems}
                                setScheduleItems={setScheduleItems}
                                getNewItem={getNewItem}
                                selectedScheduleType={selectedScheduleType}
                                scheduleItemRemoved={() => scheduleItemRemoved(index)} // NOSONAR
                            >
                            </ScheduleItem>
                        </div>
                    )
                })}
        </>
    );
}