import React, { useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Ajax } from '../../components/Ajax';
import { ApiUrl } from '../../project/Defines.js';

import { useCustomEvent } from '../../project/utilities';
import { useSWCache } from '../SWCache'

import { TreeSelect } from 'antd';

const { TreeNode } = TreeSelect;

function DropdownTree(props) {
    const [loaded, setLoaded] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const { valuelist, mode, items, parentValue, additionalData, reloadEventName, disableCach, hide, culture, dispatch, searchTreeNodeOption, ...restprops } = props;
    const ref = useRef(false);

    const { getCacheData, setCacheData } = useSWCache({ key: `${culture}_${valuelist}`, isClearOnLogout: true });

    const loadData = useCallback(() => { // NOSONAR
        if (valuelist) {
            if (typeof valuelist === 'string') {
                Ajax.post({
                    url: ApiUrl.ValueList,
                    data: {
                        valueList: valuelist,
                        parentValue: parentValue || "",
                        additionalData: additionalData || ""
                    },
                    success: function (response) {
                        if (response && ref.current) {
                            const data = response;
                            for (var i = 0; i < data.length; i++) { // NOSONAR
                                //data[i].date = new Date(data[i].date)
                                data[i].key = data[i].uuid;
                            };
                            setDataSource(data);
                            setLoaded(true);
                            !disableCach && setCacheData(data);
                        }
                    }
                });
            } else {
                setDataSource(valuelist);
                setLoaded(true);
            }
        }
    }, [valuelist, parentValue, additionalData, disableCach, setCacheData]);

    useCustomEvent(reloadEventName || 'reload_valuelist_' + valuelist, loadData);

    useEffect(() => {
        ref.current = true;
        if (items) {
            setDataSource([...items]);
            setLoaded(true);
        }
        else {
            if (disableCach) {
                loadData();
            } else {
                getCacheData().then((data) => {
                    if (data) {
                        setDataSource(data);
                        setLoaded(true);
                    } else {
                        loadData();
                    }
                });
            }
        }
        return () => { ref.current = false }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const filterTreeNodeOption = (input, treeNode) => {
        let text = searchTreeNodeOption.caseSensitive ? treeNode.title : treeNode.title && treeNode.title.toLowerCase();
        let searchInput = searchTreeNodeOption.caseSensitive ? input : input && input.toLowerCase();
        if (searchTreeNodeOption.noAccent) {
            text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            searchInput = searchInput.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        return !searchInput || (text && (searchTreeNodeOption.contains ? text.includes(searchInput) : text.startsWith(searchInput)));
    };

    return <>
        {loaded &&
            <TreeSelect
                autoComplete="new-password"
                filterTreeNode={filterTreeNodeOption} // NOSONAR
                {...restprops}
            >
                <>
                    <TreeNode value="Groupe Pays" title="Groupe Pays">
                        {dataSource.filter(item => item.value.includes('groupe'))
                            //.sort((a, b) => a.text > b.text ? 1 : -1)
                            .map((itemGroupePay, i) => {
                                return (
                                    <TreeNode key={itemGroupePay.value} value={itemGroupePay.value} title={itemGroupePay.text} />
                                )
                            })}
                    </TreeNode>
                </>
            </TreeSelect>
        }
    </>
}

export default connect(state => ({
    culture: state.culture
}))(DropdownTree);
