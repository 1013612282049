import React from 'react';
import { useTranslation } from 'react-i18next';

import { ApiUrl } from '../../../project/Defines.js';
import { T } from '../../../components/Translations'
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';

/**
 * the TypePicker of SparePart in type tab
 * @exports SparePart-TypePicker
 */
export default function SparePartTypePicker(props) {
    const { t } = useTranslation("defines");

    const { updateData } = props;
    const columnConfig = [
        {
            text: 'text.name',
            field: 'traduction',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
            width: t('width.action_select'),
            render: (text, record) =>
                <elastic>
                    <action hover="">
                        <div effect="material" className="button accent" onClick={() => { updateData(record); }}><text><T>text.select</T></text></div>
                    </action>
                </elastic>
        },
    ];

    return (<>
        <ListView
            {...props}
            isSimple
            skipNewButton
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ArticleTypeList}
            search={{ field: 'search' }} />
    </>);
}

