import React, { useState, useCallback } from 'react';
import { uniqueId } from 'lodash';

import DropDownValueList from 'Shared/DropDown';

import ScheduleList from './ScheduleList';
import { ScheduleType } from 'project/Defines';
import { T } from '~/components/Translations';

/**
 *the main component creating shecdules for different periods
 */
export default function Schedule(props) {
    const { onChangeData } = props;
    const [selectedScheduleType, setSelectedScheduleType] = useState(props.schedule_type);
    const [scheduleItems, setScheduleItems] = useState(props.schedule_items);

    const DAILY = props.schedule_type === ScheduleType.DAILY;
    const WEEKLY = props.schedule_type === ScheduleType.WEEKLY;
    const MONTHLY = props.schedule_type === ScheduleType.MONTHLY;
    const YEARLY = props.schedule_type === ScheduleType.YEARLY;

    function getNewItem() {
        return { id: uniqueId() };
    }

    const onChangeScheduleType = useCallback((value) => {
        const newItems = [getNewItem()];
        setSelectedScheduleType(value);
        setScheduleItems(newItems);

        onChangeData({ target: { name: 'schedule_items', value: newItems } });
        onChangeData({ target: { name: 'schedule_type', value: value } });
    }, [onChangeData])

    const addSchedulItem = useCallback(() => {
        const scheduleItemsCopy = [...scheduleItems, getNewItem()];

        setScheduleItems(scheduleItemsCopy);
        onChangeData({ target: { name: 'schedule_items', value: scheduleItemsCopy } });
    }, [scheduleItems, onChangeData]);

    const removeScheduleItem = useCallback((index) => {
        const _scheduleItems = [...scheduleItems];

        _scheduleItems.splice(index, 1);
        setScheduleItems([..._scheduleItems]);
        onChangeData({ target: { name: 'schedule_items', value: [..._scheduleItems] } });
    }, [scheduleItems, onChangeData]);

    return (
        <>
                    <div className="field_group ">
                        <div className="form_fields ">
                            <div className="data_label"><T>text.schedule_type</T></div>
                                <DropDownValueList valuelist='ScheduleTypes' value={selectedScheduleType} onChange={onChangeScheduleType} />
                        </div>
                        {
                            (DAILY || WEEKLY || MONTHLY || YEARLY) && (
                                <action>
                                    <div className="button mini" onClick={addSchedulItem}>
                                        <icon>plus</icon>
                                    </div>
                                </action>
                            )
                        }
                    </div>

                    {
                        scheduleItems && scheduleItems.length > 0 &&
                        <ScheduleList
                            selectedScheduleType={selectedScheduleType}
                            scheduleItems={scheduleItems}
                            setScheduleItems={v => { // NOSONAR
                                setScheduleItems(v);
                                onChangeData({ target: { name: 'schedule_items', value: v } });
                            }}
                            getNewItem={getNewItem} // NOSONAR
                            scheduleItemRemoved={removeScheduleItem}
                        />
                    }
        </>
    );
}