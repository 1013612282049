import React from 'react';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import { ApiUrl, AppPages, ValideCommande } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';

import { Project } from '~/project/Project';

/**
 *
 * @exports Warranty Request
 */
function WarrantyRequest(props) {
    const { t } = useTranslation();
    const columnConfig = [
        {
            text: 'text.identifiant',
            field: 'identifiant',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.code_client',
            field: 'customer_code',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.user',
            field: 'siret',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.warranty_type',
            field: 'warranty_type',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.product_name',
            field: 'product_name',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.call_center_status',
            field: 'satc_attente',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.valide',
            field: 'valide',
            render: (text, record) => {
                if (record.valide === ValideCommande.VALIDE_EXPORTE)
                    text = t('text.valide_transmitted');
                else if (record.valide === ValideCommande.VALIDE_TRANSMISE)
                    text = t('text.valide_transmitting');
                return (
                    <wrap title={text}>
                        <text>{text}</text>
                    </wrap>
                );
        },
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.warranty_reference_nb',
            field: 'reference',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.updated_at',
            field: 'updated_at',
            format: COLUMN_FORMAT.DATETIME,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: props.userName === "ogma" ? COLUMN_TYPE.CUSTOM : COLUMN_TYPE.HIDDEN,
            width: 50,
            render: (text, record) => {
                // eslint-disable-next-line
                return record.image_url ? <wrap><a href={record.image_url} target="_blank"><icon edit="">eye</icon></a></wrap> : <wrap></wrap>;
            }
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    return (
        <ListView
            {...props}
            skipNewButton
            columnConfig={columnConfig}
            defaultSorting={{ Member: "updated_at", SortDirection: 1 }}
            apiUrl={ApiUrl.WarrantyRequestList}
            deleteUrl={ApiUrl.WarrantyRequestDelete}
            filters={[
                { name: 'identifiant', type: 'text', text: 'text.identifiant' },
                { name: 'customer_code', type: 'text', text: 'text.code_client' },
                { name: 'siret', type: 'text', text: 'text.user' },
                { name: 'reseau_uuid', type: 'select', text: 'text.sales_network', valuelist: 'reseaux' },
                { name: 'garantie_type_uuid', type: 'select', text: 'text.warranty_type', valuelist: 'garantie_types' },
                { name: 'reference', type: 'text', text: 'text.warranty_reference_nb', includeEmpty: true  },
                { name: 'satc_attente', type: 'text', text: 'text.call_center_status', includeEmpty: true },
                { name: 'reference_commande', type: 'text', text: 'text.reference_commande', includeEmpty: true },
            ]}
            listUpdateEvent={EVENT_NAME.WARRANTY_REQUEST_LIST_UPDATE}
            detailUrl={Project.getPageUrl(AppPages.JobsWarrantyRequestDetails)}
            detailProps={{
                editTitle: 'text.warranty_request',
                fullScreen: false,
                // popupType: PopupTypes.WarrantyRequestDetails,
                windowKey: 'frmWarrantyRequestDetails',
            }}
            nameField='siret'
            listName={AppPages.JobsWarrantyRequest}
            showRefreshButton={false}
        />
    );
}

export default connect(state => ({ userName: state.userName }))(WarrantyRequest)