import React from 'react';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';

import { Project } from '../../../project/Project';

/**
 * ...
 * @exports History
 */
export default function History(props) {

    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference_code',
            type: COLUMN_TYPE.TEXT,
            width: 130
        },
        {
            text: 'text.name',
            field: 'created_by',
            type: COLUMN_TYPE.TEXT,
            width: 100
        },
        {
            text: 'text.commentaire',
            field: 'comment',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.type_code',
            field: 'operation_type',
            type: COLUMN_TYPE.TEXT,
            width: 120
        },
        {
            text: 'text.created_at',
            field: 'created_at',
            format: COLUMN_FORMAT.DATETIME,
            width: 140,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            skipNewButton
            columnConfig={columnConfig}
            apiUrl={ApiUrl.HistoryList}
            //deleteUrl={ApiUrl.HistoryDelete}
            detailUrl={Project.getPageUrl(AppPages.HistoryDetails)}
            filters={[
                { name: 'created_at', type: 'date', text: 'text.created_at' },
                { name: 'reference_code', type: 'select', text: 'text.reference', valuelist: 'history_reference', autoComplete: "new-password" },
                //{ name: 'updated_at', type: 'date', text: 'text.updated_at' }
            ]}
            listUpdateEvent={EVENT_NAME.HISTORY_LIST_UPDATE}
            detailProps={{ newTitle: 'text.new_tva', editTitle: 'text.edit_tva'}}
            listName={AppPages.History}
            showRefreshButton={false}
        />
    );
}