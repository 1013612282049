import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';


import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import { Project } from 'project/Project';

import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields';

import { T } from 'components/Translations';
import { ValidationDropDownValueList, ValidationInput } from 'Shared/Form/validation.js';


/**
 * ...
 * @exports ReseauConfigCBSDetails
 */
export default function ReseauConfigCBSDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.CB_CONFIG_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.ReseauConfigCBSUpdate,
        dataLoadUrl: ApiUrl.ReseauConfigCBSLoad,
        deleteUrl: ApiUrl.ReseauConfigCBSDelete,
        nameField: 'reseau_nom',
        backPage: AppPages.ReseauConfigCBS,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.ReseauConfigCBSDetails),
        validationFields: ['site_id', 'secret', 'mode', 'reseau_uuid', 'type_compte', 'pays', 'activite_uuid']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);



    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.ReseauConfigCBS)}
            title={isNew ? <T>text.new_cb_config</T> : <T>text.edit_cb_config</T>}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.site_id">
                                <ValidationInput validation={validation} name='site_id' type='text' value={dataItem.site_id} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.secret">
                                <ValidationInput validation={validation} name='secret' type='text' value={dataItem.secret} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.identifiant_site">
                                <ValidationInput validation={validation} name="identifiant_site" type="text" value={dataItem.identifiant_site} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.compte_cb">
                                <ValidationInput validation={validation} name='compte_cb' type='text' value={dataItem.compte_cb} onChange={onChangeData} />
                            </FormField>
                            <FormField label='text.systeme_de_paiement'>
                                <ValidationDropDownValueList validation={validation} name='payment_system' value={dataItem.payment_system} valuelist='payment_system' onChange={data => onChangeData({ target: { name: 'payment_system', value: data } })} // NOSONAR
                                />
                            </FormField>
                            <FormField >
                                <label checkbox=''><input name='is_default' type='checkbox' defaultChecked={dataItem.is_default} onChange={onChangeData} /><box><check></check></box><text><T>text.is_default</T></text></label>
                            </FormField>
                        </div>

                        <div className='sections '>
                            <FormField label="text.reseau">
                                <ValidationDropDownValueList validation={validation} name='reseau_uuid' value={dataItem.reseau_uuid} valuelist='reseaux' onChange={data => onChangeData({ target: { name: 'reseau_uuid', value: data } })} style={{ width: 200 }} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.mode">
                                <ValidationDropDownValueList validation={validation} name='mode' value={dataItem.mode} valuelist='mode' onChange={data => onChangeData({ target: { name: 'mode', value: data } })} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.compte_type">
                                <ValidationDropDownValueList validation={validation} name='type_compte' value={dataItem.type_compte} textAsValue={true} valuelist='compte_types' onChange={data => onChangeData({ target: { name: 'type_compte', value: data } })} // NOSONAR
                                />
                            </FormField>

                            <FormField label="text.pays">
                                <ValidationDropDownValueList
                                    validation={validation}
                                    name='pays'
                                    value={dataItem.pays}
                                    valuelist='pays'
                                    onChange={data => onChangeData({ target: { name: 'pays', value: data } })} // NOSONAR
                                    showSearch
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }} />
                            </FormField>
                            <FormField label="text.activites">
                                <ValidationDropDownValueList validation={validation} name='activite_uuid' value={dataItem.activite_uuid} valuelist='activites' onChange={data => onChangeData({ target: { name: 'activite_uuid', value: data } })} // NOSONAR
                                />
                            </FormField>
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    if (dataItem.commandes_count > 0) {
                                        message.warning(<T>message.cb_config_delete_warning_message</T>);
                                        return;
                                    }
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon><T>text.delete</T>
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
