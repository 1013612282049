import { T } from 'components/Translations';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { Project } from 'project/Project';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields.js';
import useDetail from 'Shared/useDetail';
import { ValidationDropDownValueList, ValidationInput } from '~/Shared/Form/validation';



/**
 * the FeesDetails and main data of Fees
 * @exports FeesDetails
 */
export default function FeeDetails(props) {
    const { id } = useParams();
    const reseau_uuid = new URLSearchParams(props.location.search).get("reseau_uuid");
    const { windowKey, data, onDelete } = props;
    const [tabIndex] = useState(0);
    const listUpdateEvent = EVENT_NAME.FEE_LIST_UPDATE;
   const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, onDetailDelete, detailRef, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.FeeUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.FeeLoad,
        onLoadData: (response, setDataItem) => {
            if (!response.ID) {
                setDataItem({ ...response, reseau_uuid });
                return true;
            }
        },
        // nameField: 'nom',
        deleteUrl: ApiUrl.FeeDelete,
        backPage: AppPages.Fees,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.FeesDetails),
        validationFields: ['business_unit', 'reseau_uuid', { name: "price", rules: [{ type: "number" }] }, { name: "flat_rate", rules: [{ type: "number" }] }]
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }
        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.Fees)}
            title={isNew ? <T>text.new_fee</T> : <T>text.edit_fee</T>}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.sales_network">
                                <ValidationDropDownValueList
                                    name="reseau_uuid"
                                    value={dataItem.reseau_uuid}
                                    validation={validation}
                                    valuelist="reseaux"
                                    onChange={(e) => { onChangeData({ target: { name: "reseau_uuid", value: e } }); }} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                    disabled={true}
                                />
                            </FormField>
                            <FormField label="text.call_type">
                                <ValidationDropDownValueList
                                    name="call_type_id"
                                    value={dataItem.call_type_id}
                                    validation={validation}
                                    valuelist="call_types"
                                    onChange={(e) => { onChangeData({ target: { name: "call_type_id", value: e } }); }} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                    parentValue={dataItem.reseau_uuid}
                                    disableCach
                                />
                            </FormField>
                            <FormField label="text.problem_type">
                                <ValidationDropDownValueList
                                    name="problem_type_id"
                                    value={dataItem.problem_type_id}
                                    validation={validation}
                                    valuelist="problem_types"
                                    onChange={(e) => { onChangeData({ target: { name: "problem_type_id", value: e } }); }} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                    parentValue={dataItem.reseau_uuid}
                                    disableCach
                                />
                            </FormField>
                            <FormField label="text.description">
                                <ValidationInput validation={validation} name='description' inputType='textarea' value={dataItem.description} onChange={onChangeData} />
                            </FormField>

                        </div>
                        <div className='sections '>
                            <FormField label="text.business_unit">
                                <ValidationInput validation={validation}
                                    name='business_unit'
                                    type='text'
                                    value={dataItem.business_unit}
                                    onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.flat_rate">
                                <ValidationInput validation={validation}
                                    name='flat_rate'
                                    type='text'
                                    value={dataItem.flat_rate}
                                    onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.price">
                                <ValidationInput
                                    validation={validation}
                                    name='price'
                                    type='text'
                                    value={dataItem.price}
                                    onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.distance">
                                <ValidationInput
                                    validation={validation}
                                    name='distance'
                                    type='text'
                                    value={dataItem.distance}
                                    onChange={onChangeData} />
                            </FormField>
                        </div>
                    </div>
                </div>
                {tabIndex < 2 &&
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                                {dataItem.ID &&
                                    <div className='button' onClick={(e) => {
                                        (onDelete && onDelete(dataItem, closePopup)) ||
                                            onDetailDelete(dataItem)
                                    }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>}
            </form>
        </DetailView>}
    </>);
}
