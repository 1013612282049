import React from 'react';
import { Link } from 'react-router-dom';

import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '../../project/Project';
import { EVENT_NAME } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { PopupTypes } from '../../components/Popup.js';
import { T } from 'components/Translations';

/**
 * the BillOfMaterial List View
 * @exports BOM-List
 */
export default function BillOfMaterials(props) {
    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.BillOfMaterialDetails
        },
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.commentaire',
            field: 'commentaire',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.complement',
            field: 'complement',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <>
            <toolbar light='' shadow=''>
                <Link to={Project.getPageUrl(AppPages.BillOfMaterialExtract)}><div className='button accent' ><text><T>text.extraction_of_nomenclatures</T></text></div></Link>
            </toolbar>
            <ListView
                {...props}
                columnConfig={columnConfig}
                apiUrl={ApiUrl.BillOfMaterialList}
                deleteUrl={ApiUrl.BillOfMaterialDelete}
                detailUrl={Project.getPageUrl(AppPages.BillOfMaterialDetails)}
                filters={[
                    { name: 'reference', type: 'text', text: 'text.reference', includeEmpty: true, defaultValue1: 'Varfree 150' },
                    { name: 'nom', type: 'text', text: 'text.name' },
                    { name: 'commentaire', type: 'text', text: 'text.commentaire' },
                    { name: 'complement', type: 'text', text: 'text.complement' }
                ]}
                nameField="reference"
                newButtonTip='text.create_new_bill_of_material'
                listUpdateEvent={EVENT_NAME.BILL_OF_MATERIAL_LIST_UPDATE}
                detailProps={{
                    newTitle: 'text.new_bill_of_material',
                    editTitle: 'text.edit_bill_of_material',
                    popupType: PopupTypes.BillOfMaterialDetails,
                    windowKey: 'wndBillOfMaterialDetail',
                    newData: {
                        data: {
                            reference: '', commentaire: '', complement: ''
                        }
                    }
                }}
                listName={AppPages.BillOfMaterials} />
        </>
    );
}
