import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Project } from 'project/Project';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields';

import { T } from 'components/Translations';
import { ValidationInput, ValidationMultiSelectValueList } from '../../../Shared/Form/validation';

/**
 * ...
 * @exports APIAccessDetails
 */
export default function APIAccessDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.API_ACCESS_TYPE_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.ApiKeyUpdate,
        dataLoadUrl: ApiUrl.ApiKeyLoad,
        deleteUrl: ApiUrl.ApiKeyDelete,
        nameField: 'api',
        backPage: AppPages.APIAccess,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.APIAccessDetails),
        validationFields:['key','secret','api', 'api_methods']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, listUpdateEvent, detailRef]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.APIAccess)}
            title={isNew ? <T>text.new_api_access</T> : dataItem.api}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                    <div className='sections '>
                            <FormField label="text.apikey">
                                <ValidationInput validation={validation} name='key' type='text' value={dataItem.key} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.secret">
                                <ValidationInput validation={validation}  name='secret' type='text' value={dataItem.secret} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.description">
                                <ValidationInput validation={validation}  name='api' type='text' value={dataItem.api} onChange={onChangeData} />
                            </FormField>
                        </div>
                    </div>
                    <div className='section_group'>
                        <div className='sections wide'>
                            <FormField label="text.rest_api_methods_list">
                                <ValidationMultiSelectValueList
                                    validation={validation}
                                    name='api_methods'  
                                    valuelist='api_methods' 
                                    value={dataItem.api_methods || []} 
                                    onChange={e => onChangeData({ target: { name: 'api_methods', value: e } })} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}    
                                />
                            </FormField>
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div 
                                onClick={(e) => updateData(updateSuccessCallback)} 
                                className='button'><icon>save</icon><T>text.save</T>
                            </div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon><T>text.delete</T>
                                    </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
