import React, { useCallback, useState } from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { Project } from 'project/Project';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';
import { PopupTypes } from 'components/Popup.js';
import { DataTip /*, T*/ } from 'components/Translations';

import { doProcess } from './actions/processAction';

/**
 * the Process page main List for scheduling
 * @exports Process
 */
export default function Process(props) {
    const [inProcess, setInProcess] = useState(false);

    const ProcessDoAction = useCallback((record) => {
        if (inProcess) return;

        setInProcess(true);

        doProcess(record, (response) => {
            if (response && !response.HasError) {
                dispatchCustomEvent(EVENT_NAME.PROCESS_LIST_UPDATE, { action: "refresh" });
            }
            setInProcess(false)
        }, () => setInProcess(false));

    }, [inProcess]);

    const columnConfig = [
        {
            text: 'text.process_code',
            field: 'process_code',
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.ProcessDetails
        },
        {
            title: ' ',
            width: 40,
            noSort: true,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (
                <>
                    <elastic>
                        <action hover=""><DataTip title={'text.run_process'}><div key='play' command='process' className="button extra" onClick={() => ProcessDoAction(record)}> <icon >play</icon></div></DataTip></action>
                    </elastic>
                </>)
        },
        {
            text: 'text.process_name',
            field: 'process_name',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.process_description',
            field: 'process_description',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.order',
            field: 'line_number',
            width: 140,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.type',
            field: 'schedule_type',
            width: 70,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.inactive',
            field: 'inactive',
            width: 80,
            render: (text, record, index) => {
                if (record.inactive)
                    return <wrap><text><icon edit="">done</icon></text></wrap>;

                return <></>;
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.status',
            field: 'status',
            width: 80,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.start_date',
            field: 'date_start',
            width: 140,
            format: COLUMN_FORMAT.DATETIME,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.process_time',
            field: 'process_time',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (record.process_time ? <wrap><DataTip title={(parseInt((parseInt(record.process_time.replace(' sec', '')) * 100) / 60) / 100) + ' min'}>{record.process_time}</DataTip></wrap > : null),
        },
        //{
        //    text: 'text.schedule',
        //    field: 'schedule',
        //    type: COLUMN_TYPE.TEXT,
        //},
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <>
            <ListView
                {...props}
                loading={inProcess}
                columnConfig={columnConfig}
                apiUrl={ApiUrl.ProcessList}
                deleteUrl={ApiUrl.ProcessDelete}
                detailUrl={Project.getPageUrl(AppPages.ProcessDetails)}
                filters={[{ name: 'process_code', type: 'text', text: 'text.process_code' }, { name: 'process_name', type: 'text', text: 'text.process_name' }]}
                newButtonTip='text.create_new_process'
                listUpdateEvent={EVENT_NAME.PROCESS_LIST_UPDATE}
                detailProps={{ newTitle: 'text.new_process', editTitle: 'text.edit_process', popupType: PopupTypes.ProcessDetails, windowKey: 'wndProcessDetail' }}
                nameField="process_name"
                listName={AppPages.Process} />
        </>
    );
}
