import React from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { Project } from 'project/Project';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { PopupTypes } from 'components/Popup.js';

/**
 * the Brand List
 * @exports Brand
 */
export default function Brand(props) {
    const columns = [
        {
            text: 'text.name',
            field: 'nom',
            width: 1200,
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.BrandDetails
        },
        {
            text: 'text.code',
            field: 'code',
            // width: 200,
            type: COLUMN_TYPE.TEXT
        },
        {
            // resizable: false,
            // fixed: "right",
            type: COLUMN_TYPE.ACTION
        },
    ];
    
    return (
        <ListView
            {...props}
            columnConfig={columns}
            // columnConfig={_columns || []}
            // component={components}
            apiUrl={ApiUrl.BrandList}
            deleteUrl={ApiUrl.BrandsDelete}
            detailUrl={Project.getPageUrl(AppPages.BrandDetails)}
            filters={[{ name: 'nom', type: 'text', text: 'text.name' }]}
            newButtonTip='text.create_new_brand'
            listUpdateEvent={EVENT_NAME.BRAND_LIST_UPDATE}
            detailProps={{ newTitle: 'text.new_brand', editTitle: 'text.edit_brand', popupType: PopupTypes.BrandDetails, windowKey: 'wndBrandDetail' }}
            listName={AppPages.Brand}
            resizable
        />
    );
}
