import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ApiUrl } from 'project/Defines.js';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';
import { T, DataTip } from 'components/Translations';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import { PopupTypes, PopupClasses } from 'components/Popup.js';
import { openPopup } from 'store/actions';
import { doProcess } from '../actions/processAction';

/**
 * the ProcessLog List for each Process that has run
 * @exports ProcessLog
 */
export default function ProcessLog(props) {
    const { ID, process_code } = props;
    const dispatch = useDispatch();

    const [inProcess, setInProcess] = useState(false);

    const ProcessDoAction = useCallback(() => {
        if (inProcess) return;

        setInProcess(true);

        doProcess({ ID, process_code }, (response) => {
            if (response && !response.HasError) {
                dispatchCustomEvent(EVENT_NAME.PROCESS_LOG_LIST_UPDATE, { action: "refresh" });
            }
            setInProcess(false)
        }, () => setInProcess(false));

    }, [inProcess, ID, process_code]);

    const onShowProcessLogDetails = useCallback((record) => {
        dispatch(openPopup({
            fullScreen: false,
            title: <T>{"Process Log Details"}</T>,
            type: PopupTypes.ProcessLogPicker,
            popupClassName: PopupClasses.Large,
            windowKey: 'pickProcessLog',
            bodyProps: {
                ...{ ID: record.ID, data: record },
                windowKey: 'pickProcessLog',
            }
        }))
    }, [dispatch]);

    const columnConfig = [
        {
            text: 'text.module',
            field: 'module',
            width: 200,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.status',
            field: 'status',
            width: 80,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.start_date',
            field: 'date_start',
            width: 180,
            format: COLUMN_FORMAT.DATETIME,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.process_time',
            field: 'process_time',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (record.process_time ? <wrap><DataTip title={(parseInt((parseInt(record.process_time.replace(' sec','')) * 100) / 60) / 100) + ' min' }>{record.process_time}</DataTip></wrap > : null),
        },
        {
            text: 'text.comments',
            field: 'comments',
            type: COLUMN_TYPE.TEXT
        },
        {
            //title: <DataTip title="text.error"><wrap><icon>errors</icon></wrap></DataTip>,
            text: 'text.errors',
            width: 120,
            noSort: true,
            field: 'errors',
            type: COLUMN_TYPE.TEXT,
            //render: (text, record) => (record.errors > 0 ? <wrap><icon>done</icon></wrap> : null),
            render: (text, record) => (record.errors ? <wrap><DataTip title={record.errors}><center><icon>errors</icon></center></DataTip></wrap > : null),
        },
        {
            //title: <DataTip title="text.warning"><wrap><icon>warning</icon></wrap></DataTip>,
            text: 'text.warnings',
            width: 120,
            field: 'warnings',
            noSort: true,
            type: COLUMN_TYPE.TEXT,
            //render: (text, record) => (record.warnings > 0 ? <wrap><icon>done</icon></wrap> : null),
            render: (text, record) => (record.warnings ? <wrap><DataTip title={record.warnings}><center><icon>warning</icon></center></DataTip></wrap> : null),
        },
        {
            title: ' ',
            width: 40,
            noSort: true,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (
                <>
                    <elastic>
                        <action hover=""><div className="button extra" onClick={() => onShowProcessLogDetails(record)}> <icon>more</icon></div></action>
                    </elastic>
                </>)
        },

    ];

    return (<>
        <ListView
            search={{ field: 'module' }}
            isSimple
            skipEdit
            {...props}
            loading={inProcess}
            noSort
            skipNewButton
            noPaging
            tableLayout="Fixed"
            listUpdateEvent={EVENT_NAME.PROCESS_LOG_LIST_UPDATE}
            staticFilter={{ process_id: props.ID }}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ProcessLogList}
        />
        <toolbar>
            <wrap>
                <action right=''>
                    <div onClick={(e) => ProcessDoAction()} className='button'><icon>play</icon><T>text.run_process</T></div>
                </action>
            </wrap>
        </toolbar>
    </>);
}