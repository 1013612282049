import React, { useCallback } from 'react';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { useDispatch } from 'react-redux';
import { openPopup } from '~/store/actions';
import { T } from '~/components/Translations';
import { PopupTypes } from '~/components/Popup';
import { EVENT_NAME } from '~/project/utilities';

/**
 * ...
 * @exports FamillySkills
 */
export default function FamillySkills (props) {
    const dispatch = useDispatch();

    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    const onNewFamillySkills = useCallback(() => {
        dispatch(
            openPopup({
                windowKey: "changeFamillySkills",
                fullScreen: false,
                title: <T>text.add_new_skills</T>,
                type: PopupTypes.FamillySkillsPickup,
                bodyProps: {
                    ...{ data: { ID: props.ID, selected: '' } }
                    // windowKey: "changeFamillySkills"
                }
            })
        );

    }, [props.ID, dispatch]);

    return (
        <ListView
            {...props}
            skipEdit
            columnConfig={columnConfig}
            apiUrl={ApiUrl.FamillySkillList}
            deleteUrl={ApiUrl.FamillySkillDelete}
            deleteKeys={["famille_uuid", "skill_uuid"]}
            // detailUrl={Project.getPageUrl(AppPages.SkillsDetails)}
            // filters={[{ name: 'nom', type: 'text', text: 'text.name' }]}
            staticFilter={{ famille_uuid: props.ID }}
            newButtonTip='text.create_new_skill'
            listUpdateEvent={EVENT_NAME.FAMILLY_SKILL_LIST_UPDATE}
            onNewClick={onNewFamillySkills}
            detailProps={{ newTitle: 'text.create_new_skill', editTitle: 'text.edit_skill' }}
            listName={AppPages.FamillySkills}
            showRefreshButton={false}
        />
    );
}