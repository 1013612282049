import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ApiUrl, AppPages } from '../../../project/Defines.js';
import { EVENT_NAME } from '../../../project/utilities';

import { T } from '../../../components/Translations';
import { Project } from '../../../project/Project';
import CustomUpload from '../../../Shared/CustomUpload';
import useDetail from '../../../Shared/useDetail';
import DetailView from '../../../Shared/DetailView';
import ListView from '../../../Shared/ListView/index.js';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper.js';
import { Ajax } from '../../../components/Ajax.js';
import { showLoader, hideLoader } from '../../../Shared/Loader';

const loaderName = 'SPARE_PART_IMAGE_UPLOAD_LOADER';

/**
 * the SparePartDetails main tab containing tabstrip layout
 * @exports SparePartDetails
 */
export default function SparePartImagesUpload(props) {
    let history = useHistory();
    const [listDataSource, setListDataSource] = useState([]);
    const [imageData, setImageData] = useState([]);
    const listUpdateEvent = EVENT_NAME.SPARE_PART_IMAGE_LIST_UPDATE;
    const useDetailResult = useDetail({
        data: {
            images: [],
            image_url: {}
            //"images": [{ "image_guid": "7e00dfa12f434c27bea226da0d91c7b7", "name": "THERPAC_699286_1.jpg" },
            //{ "image_guid": "8e00fb4613ea42ee8e2e627a057474ed", "name": "ATLBEL_419_1.jpg" },
            //{ "image_guid": "f7cf78ac306f4e92a8034260bbfe9bff", "name": "ATLBEL_419_2.jpg" },
            //{ "image_guid": "c7bea554835f4e2ebf96d52ce73953d8", "name": "download.jpg" }],
            //"image_url": {
            //    "7e00dfa12f434c27bea226da0d91c7b7": "https://localhost:44379/images/GetFile?type=Temp&filename=7e00dfa12f434c27bea226da0d91c7b7",
            //    "8e00fb4613ea42ee8e2e627a057474ed": "https://localhost:44379/images/GetFile?type=Temp&filename=8e00fb4613ea42ee8e2e627a057474ed",
            //    "f7cf78ac306f4e92a8034260bbfe9bff": "https://localhost:44379/images/GetFile?type=Temp&filename=f7cf78ac306f4e92a8034260bbfe9bff",
            //    "c7bea554835f4e2ebf96d52ce73953d8": "https://localhost:44379/images/GetFile?type=Temp&filename=c7bea554835f4e2ebf96d52ce73953d8"
            //}
        },
        updateUrl: ApiUrl.ArticleImageUpdate,
        backPage: AppPages.SpareParts,
        listUpdateEvent
    });
    const { dataItem, setDataItem, updateData } = useDetailResult;

    const columnConfig = [
        {
            width: 150,
            type: COLUMN_TYPE.CUSTOM,
            render: (text, record) => {
                if (record.error) {
                    return <wrap><T>{record.error}</T></wrap>
                }
                if (!record.data_uuid) {
                    return <wrap><T>message.no_appropriate_record_found</T></wrap>
                }
                return <wrap><T>message.ready_to_upload</T></wrap>
            }
        },
        {
            text: 'text.name',
            field: 'name',
            type: COLUMN_TYPE.TEXT,
            noSort: true
        },
        //{
        //    text: 'text.reseau_code',
        //    field: 'reseau_code',
        //    type: COLUMN_TYPE.TEXT
        //},
        {
            text: 'text.article_code',
            field: 'article_code',
            width: 150,
            type: COLUMN_TYPE.TEXT,
            noSort: true
            //  linkAppPage: AppPages.SparePartDetails
        },
        {
            text: 'text.order',
            field: 'line_number',
            width: 60,
            type: COLUMN_TYPE.TEXT,
            noSort: true
            //  linkAppPage: AppPages.SparePartDetails
        },
        {
            text: 'text.image',
            field: 'image_url',
            width: 200,
            type: COLUMN_TYPE.IMAGE,
            noSort: true
        }
    ];


    const updateSuccessCallback = useCallback(() => {
        history.push(Project.getPageUrl(AppPages.SpareParts));
    }, [history]);

    const updateImages = useCallback(() => {
        updateData(updateSuccessCallback, null, { overrideData: { imagesString: JSON.stringify( listDataSource.filter(l => l.data_uuid).map(item => ({ article_code: item.article_code, name: item.name, image_guid: item.image_guid }))) } })
    }, [updateData, listDataSource, updateSuccessCallback]);

    useEffect(() => {
        showLoader(loaderName);
        if (dataItem && dataItem.images) {
            const _imageData = dataItem.images.map((image, i) => {
                let error = false;
                const [imageName] = image.name.split('.');
                let [/*reseau_code,*/ article_code, line_number, ...rest] = imageName.split('_');
                if (!line_number) {
                    line_number = 0;
                }
                if (/*!reseau_code ||*/ !article_code || isNaN(line_number) || rest.length) {
                    error = 'message.invalid_name_format';
                }
                return {
                    key: image.image_guid,
                    image_guid: image.image_guid,
                    image_url: image.image_url,
                    //reseau_code,
                    article_code,
                    line_number,
                    name: image.name,
                    error
                }
            });
            setImageData(_imageData.sort((a, b) => { return a.name > b.name ? 1 : -1 }));
        } else {

            setListDataSource([]);
            hideLoader(loaderName);
        }
    }, [dataItem && dataItem.images]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let validData = imageData.filter(id => !id.error);
        if (validData.length) {
            Ajax.post({
                url: ApiUrl.ArticleImages,
                data: { filterDataString: JSON.stringify(validData.map(v => ({ article_code: v.article_code }))) },
                success: function (response) {
                    if (response.Data) {
                        setListDataSource(imageData.map((image) => ({ ...image, data_uuid: response.Data.find(d => /*d.reseau_code === image.reseau_code && */d.code === image.article_code)?.ID })));
                    }
                    hideLoader(loaderName);
                }
            });
        } else {
            setListDataSource(imageData);
            hideLoader(loaderName);
        }
    }, [imageData]);


    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.SpareParts)}
            title={<T>text.upload_article_images</T>}
        >
            <div className="alert_contianer">
                <alert warning="warning">
                    <icon>info</icon>
                    <text marquee="marquee"><span><T>message.please_note_this_function_can_only_be_used_on_references</T></span></text>
                </alert>
            </div>
            <form>
                <CustomUpload
                    multiple
                    keepName
                    imageUrl='image_url'
                    imageField='images'
                    dataItem={dataItem}
                    setDataItem={setDataItem}
                    type={'article'}
                />
                <ListView
                    {...props}
                    columnConfig={columnConfig}
                    dataSource={listDataSource}
                    //detailFullUrl={Project.getPageUrl(AppPages.SparePartImagesUpload)}
                    //filters={[
                    //    { name: 'article_code', type: 'text', text: 'text.article_code', includeEmpty: true },
                    //    { name: 'reseau_name', type: 'text', text: 'text.reseau_name', includeEmpty: true },
                    //]}
                    rowClassName={record => { // NOSONAR
                        return !record.data_uuid || record.error || record.duplicate ? 'error' : '';
                    }}

                    isSimple
                    isChieldView
                    skipNewButton
                    listUpdateEvent={EVENT_NAME.SPARE_PART_IMAGE_LIST_UPDATE}
                    //    detailProps={{ newTitle: 'text.new_images', editTitle: 'text.edit_spare_part', popupType: PopupTypes.SparePartDetails, windowKey: 'wndSparePartDetail' }}
                    listName={AppPages.SparePartImagesUpload}

                />
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div disabled={!listDataSource.length || !listDataSource.some(record => record.data_uuid)}
                                onClick={updateImages} className='button'><icon>save</icon><T>text.save</T></div>
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
