import React, { useEffect, useState, useRef, useCallback } from 'react';
import { message } from 'antd';

export const InputTypes = {
    INPUT: 'INPUT',
    TEXTAREA: 'TEXTAREA'
}

const Editable = (props) => {
    const [_value, _setValue] = useState(props.value || "");
    const { onChange, value, inputType, skipAutoRestrict, copy, ..._props } = props;
    const timer = useRef();

    const _onChange = useCallback((e) => {
        _setValue(e.target.value);
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(_value);

        message.success("Text Copied to Clipboard");
    };

    useEffect(() => {
        return () => {
            timer.current = null;
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        _setValue(value || "");
    }, [value]);

    useEffect(() => {
        const eventData = { target: { value: _value, name: props.name } }
        timer.current && clearTimeout(timer.current);
        timer.current = setTimeout(() => timer.current && onChange && onChange(eventData), 300);
    }, [_value]);// eslint-disable-line react-hooks/exhaustive-deps

    let autoProps = skipAutoRestrict ? null : {
        autoCapitalize: "off",
        autoComplete: "off"
    }

    const render = () => {
        switch (inputType) { // NOSONAR
            case InputTypes.TEXTAREA:
                return <textarea value={_value} onChange={_onChange} {...autoProps} {..._props} />
            default:
                return copy ?
                    <div className="input_cont">
                        <input value={_value} onChange={_onChange} {...autoProps} {..._props} />
                        <div className="button micro" onClick={handleCopy}><icon>copy</icon></div>
                        <i></i>
                    </div> : <input value={_value} onChange={_onChange} {...autoProps} {..._props} />
        }
    }

    return render()
}

export const Input = (props) => {
    return <Editable {...props} />
}

export const Textarea = (props) => {
    return <Editable {...props} inputType={InputTypes.TEXTAREA} />
}