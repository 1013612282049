import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';
import { Project } from 'project/Project';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from '../../../Shared/Form/formFields';
import CustomUpload from '../../../Shared/CustomUpload';
import { ValidationInput } from '../../../Shared/Form/validation';

/**
 * Norm(standart) detail of setup
 * @exports Setup-NormDetails
 */
export default function NormDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.SETUP_NORMS_LIST_UPDATE;
    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.NormsUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.NormsLoad,
        nameField: 'libelle',
        deleteUrl: ApiUrl.NormsDelete,
        backPage: AppPages.SetupNormes,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.SetupNormDetails), 
        validationFields: ["libelle"]
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.SetupNormes)}
            title={isNew ? <T>text.create_new_standard</T> : dataItem.libelle}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.norms">
                                <ValidationInput validation={validation}  name='libelle' type='text' autoComplete='off' value={dataItem.libelle} onChange={onChangeData} />
                            </FormField>
                            {/*<FormField label="Logo">
                                <input name='logo' type='text' value={dataItem.logo} onChange={onChangeData} />
                            </FormField>*/}
                            <FormField label="text.logo">
                                <CustomUpload
                                    imageUrl='image_url'
                                    imageField='images'
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    type={'normes'}
                                />
                            </FormField>
                        </div>
                    </div>
                </div>

                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                <icon>delete</icon><T>text.delete</T>
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}

