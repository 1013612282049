import React, { useCallback } from 'react';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationInput } from 'Shared/Form/validation';

/**
 * the FamilyInfos details of Family's Infos tab
 * @exports Universe-Family-FamilyInfosDetails
 */
export default function FamilyInfosDetails(props) {
    const { windowKey, famille_uuid, sav_info_uuid, ID, data } = props;

    const onLoadData = useCallback((response) => {
        if (response) {
            response.famille_uuid = famille_uuid || (data && data.famille_uuid);
            response.sav_info_uuid = sav_info_uuid || (data && data.sav_info_uuid) || "";
            if (response.ID === "") {
                response.ordre = -1;
            }
        }
    }, [famille_uuid, data, sav_info_uuid]);

    const { dataItem, setDataItem, updateData, onChangeData, closePopup, detailRef, validation } = useDetail({
        data: { ID: ID },
        updateUrl: ApiUrl.FamilyInfosUpdate,
        dataLoadUrl: ApiUrl.FamilyInfosLoad,
        windowKey,
        onLoadData: onLoadData,
        validationFields: ['titre', 'info']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(EVENT_NAME.FAMILY_INFOS_LIST_UPDATE, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef]);

    return (<>
        {dataItem && <div id='windowContent' className='window_cont' ref={detailRef}>
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections wide'>
                            <FormField label="text.titre">
                                <ValidationInput validation={validation} name='titre' type='text' value={dataItem.titre} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.info">
                                <ValidationInput validation={validation} name='info' type='text' value={dataItem.info} onChange={onChangeData} />
                            </FormField>
                            <FormField>
                                <label checkbox=""><input name="important" type="checkbox" defaultChecked={dataItem.important} onChange={onChangeData} /><box><check></check></box><text><T>text.important</T></text></label>
                            </FormField>
                        </div>
                    </div>
                </div>

                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </div>}
    </>);
}