import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import { Ajax } from 'components/Ajax';
import { T } from 'components/Translations';
import { ApiUrl } from 'project/Defines.js';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import { closePopup } from '~/store/actions';

/**
 * @exports SocieteSkillsPickup
 */
export default function SocieteSkillsPickup(props) {
    const { data } = props;
    const dispatch = useDispatch();
    const [selectedRows, setSelectedRows] = useState([]);
    const [updating, setUpdating] = useState(false);

    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        }
    ];

    const _closePopup = useCallback(() => {
        dispatch(closePopup("changeSocieteSkill"));
    }, [dispatch]);

    const updateData = useCallback(() => {
        setUpdating(true);
        Ajax.post({
            url: ApiUrl.SocieteSkillAdd,
            data: {
                societe_uuid: data.ID,
                selected: selectedRows
            },
            success: function (response) {
                setUpdating(false);
                if (!response.HasError) {
                    message.success(<T args={[response.AddedCount || 'No']}>message.skills_were_added</T>);

                    dispatchCustomEvent(EVENT_NAME.SOCIETE_SKILL_LIST_UPDATE, { action: "update" });
                    _closePopup();
                } else {
                    message.error(response.message)
                }
            },
            error: function (response) {
                setUpdating(false);
                message.error(<T>{response.message}</T>)
            }
        })
    }, [selectedRows, data.ID, _closePopup]);

    return (<>
        <ListView
            search={{ field: 'nom' }}
            columnConfig={columnConfig}
            isSimple
            // skipEdit
            {...props}
            skipNewButton
            //noPaging
            tableLayout="Fixed"
            staticFilter={{ societe_uuid: props.data.ID }}
            rowSelection={{
                selectedRowKeys: selectedRows,
                onChange: (selectedRowKeys) => {
                    setSelectedRows(selectedRowKeys);
                }
            }}
            onLoaded={(data, sorts) => { // NOSONAR
                return data;
            }}
            apiUrl={ApiUrl.SkillList}
        />
        {
            <toolbar>
                <wrap>
                    <action right=''>
                        <div
                            onClick={updateData}
                            className='button'
                            {...(updating || selectedRows.length === 0) ? { disabled: 'disabled' } : {}}
                        ><icon>save</icon><T>text.add</T></div>
                    </action>
                </wrap>
            </toolbar>
        }
    </>);
}