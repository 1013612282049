import React, { useCallback, useState, useEffect } from 'react';

import { ApiUrl } from '../../../project/Defines';
import { EVENT_NAME, dispatchCustomEvent } from '../../../project/utilities';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import ListView from '../../../Shared/ListView';
import useDetail from '../../../Shared/useDetail';
import { FormField } from '../../../Shared/Form/formFields';
import { DataTip } from '../../../components/Translations';
import { ValidationDropDownValueList } from '../../../Shared/Form/validation';

const dataItemDefaultValue = { reseau_uuid: '' };
/**
 * the sales networks list product is attached to
 * @exports ProductSalesNetwork
 */
export default function ProductSalesNetwork(props) {
    const produit_uuid = props.dataItem.ID;
    const listUpdateEvent = EVENT_NAME.PRODUCT_SALES_NETWORKS_LIST_UPDATE;
    const [updating, setUpdating] = useState(false);
    const { dataItem, setDataItem, updateData, onChangeData, onDetailDelete, validation, detailRef } = useDetail({
        data: dataItemDefaultValue,
        updateUrl: ApiUrl.ProductSalesNetworkUpdate,
        deleteUrl: ApiUrl.ProductSalesNetworkDelete,
        deleteKeys: ['produit_uuid', 'reseau_uuid'],
        additionalData: { produit_uuid },
        listUpdateEvent: listUpdateEvent,
        validationFields: ['reseau_uuid']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setUpdating(true);
            setDataItem(dataItemDefaultValue);
            setUpdating(false);
        }

    }, [setDataItem, detailRef]);

    useEffect(() => {
        if (!updating) {
            dispatchCustomEvent(listUpdateEvent);
        }
    }, [updating, listUpdateEvent]);
    const columnConfig = dataItem && [

        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT,
            children: [
                {
                    title: <wrap><FormField>
                        {
                            updating ? <></> : <ValidationDropDownValueList validation={validation} name='reseau_uuid' disableCach reloadEventName={EVENT_NAME.RELOAD_SALES_NETWORKS_VALUE_LIST} valuelist='ProductSalesNetworks' additionalData={produit_uuid} value={dataItem.reseau_uuid} onChange={e => onChangeData({ target: { name: 'reseau_uuid', value: e } })} // NOSONAR
                            />
                        }
                    </FormField></wrap>,
                    key: 'nom',
                    dataIndex: 'nom',
                    render: (text) => <wrap><text>{text}</text></wrap>
                }],
        },
        {
            type: COLUMN_TYPE.ACTION,
            children: [
                {
                    title: <wrap><DataTip title={'text.add'}><div className='button micro' onClick={() => updateData(updateSuccessCallback)}> <icon>done</icon></div></DataTip></wrap>,
                    width: 60,
                    render: (text, record) => (
                        <>
                            <elastic>
                                <action hover=""><div
                                    key='delete'
                                    command='delete' className="button extra" onClick={() => { onDetailDelete(record, () => { dispatchCustomEvent(EVENT_NAME.RELOAD_SALES_NETWORKS_VALUE_LIST) }); }}> <icon >delete</icon>  </div>
                                </action>
                            </elastic>
                        </>)
                }],
        },
    ];

    return dataItem && <ListView
        editable
        isSimple
        skipNewButton
        noPaging
        {...props}
        deleteKeys={['produit_uuid', 'reseau_uuid']}
        staticFilter={{ produit_uuid }}
        columnConfig={columnConfig}
        apiUrl={ApiUrl.ProductSalesNetworkList}
        inlineUpdateUrl={ApiUrl.ProductSalesNetworkUpdate}
        deleteUrl={ApiUrl.ProductSalesNetworkDelete}
        dataItemUrl={ApiUrl.ProductSalesNetworkLoad}
        nameField='designation'
        listUpdateEvent={listUpdateEvent}
    />;
}