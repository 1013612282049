import React, { useEffect, useState } from 'react';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { PopupTypes, PopupClasses } from 'components/Popup.js';
import VideoContainer from '../../../../Shared/Video';

/**
 * the Videos list of Family's Videos tab
 * @exports Universe-Family-Videos
 */
export default function Videos(props) {
    const { uuid } = props;
    const [detailProps, setDetailProps] = useState({});
    const columnConfig = [
        {
            text: 'text.title',
            field: 'title',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.video_id',
            field: 'video_id',
            width: 300,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                return <wrap>
                    <VideoContainer
                        style={{ marginTop: '7px' }}
                        videoId={record.video_id}
                        videoType={record.video_type}
                    />
                </wrap>
            }
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    useEffect(() => {
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.select_videos',
            popupType: PopupTypes.FamilyVideoPickup,
            popupClassName: PopupClasses.Large,
            windowKey: 'lookupFamilyVideo',
            newData: { ID: uuid }
        });
    }, [uuid]);

    return (
        <ListView
            {...props}
            isSimple
            skipEdit
            noPaging
            columnConfig={columnConfig}
            apiUrl={ApiUrl.FamilyVideoList}
            deleteUrl={ApiUrl.FamilyVideoDelete}
            deleteKeys={["familles_uuid", "videos_vimeo_uuid"]}
            nameField="title"
            staticFilter={{ famille_uuid: uuid }}
            rowClassName={record => { // NOSONAR
                return record.editable ? "" : "disabled"; // NOSONAR
            }}
            newButtonTip='text.add_new_video'
            listUpdateEvent={EVENT_NAME.FAMILY_VIDEO_LIST_UPDATE}
            listName={AppPages.Videos}
            //onNewClick={() => onNewProduct()}
            detailProps={detailProps}
        />
    );
};