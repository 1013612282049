import React, { useCallback, useRef, useEffect } from 'react';
import { message } from 'antd';

import { Ajax } from 'components/Ajax';
import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { FormField } from 'Shared/Form/formFields';
import DropDownValueList from 'Shared/DropDown';
import { T } from '~/components/Translations';

/**
 * the Customer Warranty list of Family's CustomerWarranty tab
 * @exports Universe-Family-CustomerWarranty
 */
export default function CustomerWarranty(props) {
    const { uuid } = props;
    const viewRef = useRef(null);

    useEffect(() => {
        viewRef.current = true;

        return () => { viewRef.current = false; }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const updateCustomerWarranty = useCallback((record, garantie_type_uuid) => {
        Ajax.post({
            url: ApiUrl.FamilyCustomerWarrantyUpdate,
            data: {
                "famille_uuid": uuid, "client_type_uuid": record.uuid, "garantie_type_uuid": garantie_type_uuid
            },
            success: function (response) {
                if (response && viewRef.current) {
                    if (!response.HasError) {
                        message.success(<T>message.data_has_been_successfully_updated</T>);
                        dispatchCustomEvent(EVENT_NAME.FAMILY_CUSTOMER_WARRANTY_LIST_UPDATE, { action: "update" });
                        dispatchCustomEvent(EVENT_NAME.FAMILY_LIST_UPDATE, { action: 'refreshNode' });
                    } else {
                        message.error(response.message)
                    }
                }
            }
        })
    }, [uuid]);

    const columnConfig = [
        {
            text: 'text.customer_type',
            field: 'slug',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.type_of_guarantee',
            field: 'garantie_type_uuid',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) =>
                <wrap>
                    <FormField>
                        {
                            viewRef.current &&
                            <DropDownValueList 
                                name='garantie_types' 
                                value={record.garantie_type_uuid || undefined} 
                                valuelist='garantie_types' 
                                placeholder={record.parent_garantie_type || ""}
                                onChange={(e) => updateCustomerWarranty(record, e || "")} 
                                allowClear // NOSONAR
                            />
                        }
                    </FormField>
                </wrap>
        },
    ];

    return (
        <>
            <ListView
                {...props}
                isSimple
                skipEdit
                skipNewButton
                noPaging
                columnConfig={columnConfig}
                apiUrl={ApiUrl.FamilyCustomerWarrantyList}
                staticFilter={{ famille_uuid: uuid }}
                listUpdateEvent={EVENT_NAME.FAMILY_CUSTOMER_WARRANTY_LIST_UPDATE}
            />
        </>
    );
};