import React, { useCallback } from 'react';

import { ApiUrl } from '../../../project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from '../../../project/utilities';

import { T } from '../../../components/Translations';
import useDetail from '../../../Shared/useDetail';
import { FormField } from '../../../Shared/Form/formFields.js';
import { ValidationUpload } from '../../../Shared/Form/validation.js';
import Translation from '~/Shared/Translation/index.js';

/**
 * the schema editing part in BOM Detail
 * @exports BOM-SchemaDetails
 */
export default function SchemaDetails(props) {
    const { windowKey, data, onDelete, nameEdit } = props;

    const onLoadData = useCallback((response, setDataItem) => {
        if (response && data.nomenclature_uuid) {
            response.nomenclature_uuid = data.nomenclature_uuid;
        }
        setDataItem(response)
    }, [data]);

    const { dataItem, setDataItem, updateData, onChangeData, closePopup, detailRef, validation, updating } = useDetail({ data, updateUrl: ApiUrl.SchemaUpdate, dataLoadUrl: ApiUrl.SchemaLoad, windowKey, onLoadData: onLoadData, validationFields: ['images'] });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_SCHEMA_UPDATE, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_SCHEMA_NAME_UPDATE, { ID: response.ID });
    }, [setDataItem, detailRef]);

    const SaveAndNewSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem({
                nom: '',
                images: [],
                nomenclature_uuid: data.nomenclature_uuid
            });
        }

        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_SCHEMA_UPDATE, { action: 'update' });
    }, [setDataItem, detailRef, data && data.nomenclature_uuid]);// eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        {dataItem && <div id='windowContent' className='window_cont' ref={detailRef}>
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='section'>
                            <FormField label="text.name">
                                <div class="form_container1">
                                    <div class="section_group">
                                        <div class="sections">
                                        <div class="form-fields">
                                            <input style={{marginLeft:'10px', width:'300px' }} name='nom' type='text' value={dataItem.nom} onChange={onChangeData} />
                                        </div>
                                        </div>
                                    </div> 
                                </div>
                            </FormField>
                            <FormField label="text.label">
                                <Translation
                                    ID={dataItem.ID}
                                    fields={['libelle']}
                                    _class='App\Models\Catalogue\Schema'
                                    updateEvent={EVENT_NAME.BILL_OF_MATERIAL_SCHEMA_NAME_UPDATE} 
                                />
                            </FormField>
                        </div>
                        {!nameEdit && <div className='section'>
                            <FormField label="text.image">
                                <ValidationUpload
                                    validation={validation}
                                    imageUrl='image_url'
                                    imageField='images'
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    type={'schemas'}
                                    className="large"
                                />
                            </FormField>
                        </div>}
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div
                                className='button'
                                onClick={(e) => updateData(updateSuccessCallback)}
                                {...updating ? { disabled: 'disabled' } : {}}
                            >
                                <icon>save</icon><T>text.save</T>
                            </div>
                            {!nameEdit && <div
                                className='button'
                                onClick={(e) => {
                                    updateData(SaveAndNewSuccessCallback)
                                }}
                                {...updating ? { disabled: 'disabled' } : {}}
                            >
                                <icon>save</icon><T>text.save_and_new</T>
                            </div>}
                            {dataItem.ID  && !nameEdit &&
                                <div className='button' onClick={(e) => { onDelete(dataItem, closePopup) }}>
                                    <icon>delete</icon><T>text.delete</T>
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </div>}
    </>);
}
