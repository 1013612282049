import React, { useCallback } from 'react';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationDropDownValueList } from '../../../Shared/Form/validation';

/**
 * the SalesNetwork details of SalesNetwork tab of User(Societe)
 * @exports User-SalesNetwork
 */
export default function SocieteSalesNetworkDetails(props) {
    const { windowKey, data, onDelete } = props;
    const listUpdateEvent = EVENT_NAME.SOCIETE_SALES_NETWORK_LIST_UPDATE;

    const { dataItem, setDataItem, updateData, onChangeData, closePopup, onDetailDelete, detailRef, validation } = useDetail({
        data: data,
        updateUrl: ApiUrl.SocieteSalesNetworkUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.SocieteSalesNetworkLoad,
        nameField: 'nom',
        deleteUrl: ApiUrl.SocieteSalesNetworksDelete,
        listUpdateEvent,
        onLoadData: (response) => {
            response.societe_uuid = data.societe_uuid;
            setDataItem(response);
            return true;
        },
        validationFields: ['reseau_uuid']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }
        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
    }, [setDataItem, detailRef, listUpdateEvent]);

    return (<>
        {dataItem &&
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.sales_network">
                                <ValidationDropDownValueList validation={validation} name='reseau_uuid' value={dataItem.reseau_uuid} valuelist='reseaux' onChange={e => onChangeData({ target: { name: 'reseau_uuid', value: e } })} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.code_client">
                                <input name='code_client' type='text' value={dataItem.code_client} maxLength="45" onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.code_contact">
                                <input name='code_contact' type='text' value={dataItem.code_contact} maxLength="45" onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.code_fournisseur">
                                <input name='supplier_code' type='text' value={dataItem.supplier_code} maxLength="45" onChange={onChangeData} />
                            </FormField>
                            {dataItem.station_web && < FormField label="text.dispatcher">
                                <ValidationDropDownValueList validation={validation} name='dispatcher_uuid' value={dataItem.dispatcher_uuid} valuelist='dispatchers' disableCach parentValue={dataItem.reseau_uuid} onChange={e => onChangeData({ target: { name: 'dispatcher_uuid', value: e } })} // NOSONAR
                                />
                            </FormField>
                            }
                        </div>
                    </div>
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                                {dataItem.ID &&
                                    <div className='button' onClick={(e) => {
                                        (onDelete && onDelete(dataItem, closePopup)) ||
                                            onDetailDelete(dataItem);
                                    }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>
                </div>
            </form>}
    </>);
}