import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MultiSelectValueList } from 'Shared/DropDown';
import { ApiUrl, AppPages, UserRoles } from 'project/Defines.js';
import { T } from 'components/Translations';
import Family from './Family/index';
import { Sso } from './Sso/index';
import { Project } from 'project/Project';

import { Ajax } from 'components/Ajax';
import { TabStrip } from 'Shared/TabStrip';
import { EVENT_NAME, dispatchCustomEvent, useCustomEvent } from "project/utilities";
import { openPopup } from 'store/actions';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { ValidationInput } from '../../Shared/Form/validation';

const validationFields = ["nom", { name: "email_contact", rules: [{ type: "required" }, { type: "email" }] }];
/**
 * the UniverseDetails main tab containing tabstrip layout
 * @exports UniverseDetails
 */
function UniverseDetails(props) {
    const backUrl = new URLSearchParams(props.location.search).get("backUrl");
    const tab = new URLSearchParams(props.location.search).get("tab");
    const { id } = useParams();
    const { windowKey, data } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const listUpdateEvent = EVENT_NAME.UNIVERSE_LIST_UPDATE;

    const { dataItem, setDataItem, updateData, onChangeData, closePopup, isNew, detailRef, onDetailDelete, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.UniverseUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.UniverseLoad,
        nameField: 'nom',
        deleteUrl: ApiUrl.UniverseDelete,
        backPage: AppPages.Universe,
        listUpdateEvent,
        validationFields: validationFields, /* dataLoadUrl: ApiUrl.SalesNetworkLoad*/
        detailUrl: Project.getPageUrl(AppPages.UniverseDetails)
    });

    const isReadOnly = props.role === UserRoles.DISPATCHER;
    const isSuperAdmin = props.role === UserRoles.SUPERADMIN;


    useEffect(() => {
        if (!isNew && !tab && id !== "create" && isSuperAdmin)
            setTabIndex(1);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (tab)
            setTabIndex(parseInt(tab));
    }, [tab]);

    const nameChangeDataCallback = useCallback((event, data) => {
        data["slug"] = data["nom"].toLowerCase()
    }, []);

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
    }, [setDataItem, detailRef, listUpdateEvent]);

    const tabFilter = useCallback((tab) => { return isSuperAdmin || tab.props.family }, [isSuperAdmin])

    const onFamilyUpdated = useCallback(() => {
        Ajax.get({
            url: ApiUrl.UniverseLoad,
            data: { id: dataItem.ID },
            success: function (response) {
                if (response && detailRef.current) {
                    const d = { ...dataItem };
                    d.allow_backup = response.allow_backup;
                    d.allow_restore = response.allow_restore;
                    setDataItem(d);
                }
            }
        })
    }, [dataItem, detailRef, setDataItem]);

    useCustomEvent(EVENT_NAME.FAMILY_LIST_UPDATE + " " + EVENT_NAME.FAMILY_BACKUP_CHECK, onFamilyUpdated);

    return (<>
        {dataItem && <DetailView
            backUrl={backUrl || Project.getPageUrl(AppPages.Universe)}
            title={isNew ? <T>text.new_universe</T> : dataItem.nom}
            key={dataItem.ID}
        >

            <div className="form_container">
                <TabStrip onChange={(selected) => { // NOSONAR
                    setTabIndex(selected)
                }
                } skipSecondaryTabs={isNew} selectedIndex={tabIndex} tabFilter={tabFilter}>

                    <div title="text.main" {...(isNew ? { primary: true } : {})}>
                        <div className="section_group">

                            <div className="sections ">
                                <div className="form_fields">
                                    <div className="data_label"><T>text.name</T></div>
                                    <ValidationInput readOnly={isReadOnly} autoFocus validation={validation} name="nom" type="text" value={dataItem.nom} onChange={e => { onChangeData(e, nameChangeDataCallback) }} // NOSONAR
                                    />
                                </div>
                                <div className="form_fields">
                                    <div className="data_label"><T>text.email_contact</T></div>
                                    <ValidationInput readOnly={isReadOnly} validation={validation} name="email_contact" type="text" value={dataItem.email_contact} onChange={onChangeData} />
                                </div>
                                <div className="form_fields">
                                    <div className="data_label"><T>text.redirect_url</T></div>
                                    <input readOnly={isReadOnly} name="url_redirection" type="text" value={dataItem.url_redirection} onChange={onChangeData} />
                                </div>
                                <div className="form_fields">
                                    <div className="data_label"><T>text.associated_universes</T></div>
                                    <MultiSelectValueList disabled={isReadOnly} name="univers_uuids" valuelist="Univers"
                                        {... (dataItem.univers_uuids ? { value: dataItem.univers_uuids } : { value: [] })}
                                        onChange={(e) => { onChangeData({ target: { name: "univers_uuids", value: e } }); }} // NOSONAR
                                        searchOptions={{
                                            noAccent: true,
                                            contains: true
                                        }}
                                    />
                                </div>

                                <div className="form_fields">
                                    <div className="data_label"><T>text.associated_reseauxs</T></div>
                                    <MultiSelectValueList disabled={isReadOnly} name="reseau_uuids" valuelist="reseaux"
                                        {... (dataItem.reseau_uuids ? { value: dataItem.reseau_uuids } : { value: [] })}
                                        onChange={(e) => { onChangeData({ target: { name: "reseau_uuids", value: e } }); }} // NOSONAR
                                        searchOptions={{
                                            noAccent: true,
                                            contains: true
                                        }}
                                    />
                                </div>

                                <div className="form_fields">
                                    <label checkbox=""><input readOnly={isReadOnly} name="restricted" type="checkbox" defaultChecked={dataItem.restricted} onChange={onChangeData} /><box><check></check></box><text><T>text.is_restricted</T></text></label>
                                </div>
                                <div className="form_fields">
                                    <div className="data_label"><T>text.url_site_pro</T></div>
                                    <input name="url_site_pro" type="text" value={dataItem.url_site_pro} onChange={onChangeData} readOnly={isReadOnly} />
                                </div>

                                <div className="form_fields">
                                    <div className="data_label"><T>text.cookiebot</T></div>
                                    <input name="cookiebot" type="text" value={dataItem.cookiebot} onChange={onChangeData} readOnly={isReadOnly} />
                                </div>
                                <div className="form_fields">
                                    <div className="data_label"><T>text.datalayer</T></div>
                                    <input name="datalayer" type="text" value={dataItem.datalayer} onChange={onChangeData} readOnly={isReadOnly} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div title="text.families" {...(!isNew ? { primary: true } : {})} family>
                        <Family {...dataItem} dataItem={dataItem} {...props} />
                    </div>
                    <div title="text.properties" sso>
                        <Sso {...dataItem} dataItem={dataItem} {...props} />
                    </div>
                </TabStrip>
            </div>
            {
                tabIndex === 0 && !isReadOnly && isSuperAdmin &&
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div className="button" onClick={() => updateData(updateSuccessCallback)}><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <>
                                    <div className="button" onClick={e => { onDetailDelete(dataItem, closePopup) }}><icon>delete</icon><T>text.delete</T></div>
                                    {
                                        //dataItem.allow_backup > 0 &&
                                        //<>
                                        //    <div className="button" onClick={e => { onBackup() }}><icon>box</icon><T>text.backup</T></div>
                                        //</>
                                    }
                                    {
                                        //dataItem.allow_restore > 0 &&
                                        //<>
                                        //    <div className="button" onClick={e => { onRestore() }}><icon>revert</icon><T>text.restore</T></div>
                                        //</>
                                    }
                                </>
                            }
                        </action>
                    </wrap>
                </toolbar>
            }
        </DetailView>}
    </>);
}

export default connect(state => ({ role: state.role }),
    dispatch => ({ openPopup: (data) => dispatch(openPopup(data)) }))(UniverseDetails);