import React from 'react';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { Project } from '../../../project/Project';

/**
 *
 * @exports Administrators
 */
export default function Administrators(props) {

    const columnConfig = [
        {
            text: 'text.identifiant',
            field: 'login',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.email',
            field: 'email',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
            render:((text, record,commandList) => (
                <>
                    <elastic>
                        <action hover="">
                            {commandList.filter(cmd => cmd.command === 'edit' || !record.IsCurrentUser).map((cmd) => {
                                return record && !record['skip' + cmd.command] ? <div
                                    key={cmd.command}
                                    command={cmd.command} className="button extra" onClick={() => { cmd.onClick(record); }}>
                                    {cmd.noIcon ? cmd.command : <icon edit="">{cmd.command}</icon>}
                                </div> : <div key={cmd.command}></div>

                            })}
                        </action>
                    </elastic>
                </>
            ))
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.SystemUserList}
            deleteUrl={ApiUrl.SystemUserDelete}
            detailUrl={Project.getPageUrl(AppPages.AdministratorsDetails)}
            filters={[
                { name: 'Email', type: 'text', text: 'text.email' }
            ]}
            newButtonTip='text.create_new_administrators'
            listUpdateEvent={EVENT_NAME.ADMINISTRATORS_LIST_UPDATE}
            detailProps={{ newTitle: 'text.new_administrators', editTitle: 'text.edit_administrators' }}
            listName={AppPages.Administrators}
            showRefreshButton={false}
            nameField='login'
        />
    );
}