import React from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import { T } from 'components/Translations';
import { Project } from 'project/Project';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from '../../../Shared/Form/formFields';
import { ValidationInput } from '../../../Shared/Form/validation';

/**
 * Recherche No Serie detail of setup
 * @exports Setup-RechercheNoSerieDetails
 */
export default function RechercheNoSerieDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.SETUP_RECHERCHE_NO_SERIE_LIST_UPDATE;
    const { isNew, dataItem, updateData, onDetailDelete, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.RechercheNoSerieUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.RechercheNoSerieLoad,
        nameField: 'code_sf',
        deleteUrl: ApiUrl.RechercheNoSerieDelete,
        backPage: AppPages.SetupRechercheNoSerie,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.SetupRechercheNoSerieDetails),
        validationFields: ["code_sf", "code_substitution"],
    });

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.SetupRechercheNoSerie)}
            title={isNew ? <T>text.create_new</T>: dataItem.code_sf}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.code_retour_webservice_sf">
                                <ValidationInput validation={validation} autoComplete='off' name='code_sf' type='text' value={dataItem.code_sf} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.correspondance_espace_sav">
                                <ValidationInput validation={validation} autoComplete='off' name='code_substitution' type='text' value={dataItem.code_substitution} onChange={onChangeData} />
                            </FormField>
                        </div>
                    </div>
                    
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData()} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon><T>text.delete</T>
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
