import React from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { Project } from '../../../project/Project';

/**
 * Recherche No Serie list of setup
 * @exports RechercheNoSerie
 */
export default function RechercheNoSerie(props) {
    const columnConfig = [
        {
            text: 'text.code_retour_webservice_sf',
            field: 'code_sf',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.correspondance_espace_sav',
            field: 'code_substitution',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.RechercheNoSerieList}
            deleteUrl={ApiUrl.RechercheNoSerieDelete}
            detailUrl={Project.getPageUrl(AppPages.SetupRechercheNoSerieDetails)}
            filters={[{ name: 'code_sf', type: 'text', text: 'text.code_retour_webservice_sf' }, { name: 'code_substitution', type: 'text', text: 'text.correspondance_espace_sav' }]}
            newButtonTip='text.create_new'
            listUpdateEvent={EVENT_NAME.SETUP_RECHERCHE_NO_SERIE_LIST_UPDATE}
            detailProps={{ newTitle: 'text.create_new', editTitle: 'text.edit' }}
            listName={AppPages.SetupRechercheNoSerie}
            showRefreshButton={false} />
    );
}
