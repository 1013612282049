import React, { useState, useEffect } from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { Project } from '../../../project/Project';
import { UpdateLanguageData } from './actions/languageAction';

/**
 * Languages list of setup
 * @exports SetupLanguages
 */
export default function SetupLanguages(props) {
    const [languageData, setLanguageData] = useState(null);
    const columnConfig = [
        {
            text: 'text.lang',
            field: 'lang',
            width: 100,
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.SetupLanguageDetails
        },
        {
            text: 'text.flag',
            field: 'image',
            width: 100,
            imgWidth: 50,
            imgHeight: 50,
            type: COLUMN_TYPE.IMAGE
        },
        {
            text: 'text.active',
            field: 'active',
            width: 100,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (
                <elastic>
                    <action>
                        <div className="button extra" onClick={(e) => { setLanguageData({ active: !record.active, id: record.ID }) }}>
                            {record.active ? <wrap><item><icon>done</icon></item></wrap> : <wrap><item effect="material"><icon>close</icon></item></wrap>}
                        </div>
                    </action>
                </elastic>
            )
        },
        {
            text: 'text.traduction',
            field: 'traduction',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.poid',
            field: 'poid',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.index',
            field: 'index',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];

    useEffect(() => {
        if (languageData != null) {
            UpdateLanguageData(languageData, EVENT_NAME.SETUP_LANGUAGE_LIST_UPDATE, (response) => {
                setLanguageData(null);
            });
        }
    }, [languageData]);// eslint-disable-line react-hooks/exhaustive-deps



    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.LanguageList}
            deleteUrl={ApiUrl.LanguagesDelete}
            detailUrl={Project.getPageUrl(AppPages.SetupLanguageDetails)}
            filters={[{ name: 'lang', type: 'text', text: 'text.lang' }]}
            newButtonTip='text.create_new_language'
            listUpdateEvent={EVENT_NAME.SETUP_LANGUAGE_LIST_UPDATE}
            detailProps={{ newTitle: 'text.create_new_country', editTitle: 'text.edit_language' }}
            listName={AppPages.SetupLanguages}
            showRefreshButton={false} />
    );
}
