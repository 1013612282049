import React, { useCallback, useEffect, useState, useRef } from 'react';

import { ApiUrl } from '../../../project/Defines';
import useDetail from '../../../Shared/useDetail';
import { FormField } from '../../../Shared/Form/formFields';
import { DataTip } from '../../../components/Translations';

/**
 * the SavInfo  tab of products
 * @exports SavInfo
 */
export default function SavInfo(props) {
    const { dataItem, setDataItem, detailRef } = props;
    const savRef = useRef(null);
    const { updateData } = useDetail({
        data: {},
        updateUrl: ApiUrl.ProductsSavCommentaireUpdate
    });
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState('');
    useEffect(() => {
        setCurrentValue(dataItem.sav_info_commentaire);
    }, [dataItem.sav_info_commentaire]);
    const onChange = useCallback(e => { setCurrentValue(e.target.value); },[]);

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current && savRef.current) {
            setDataItem({ ...dataItem, sav_info_commentaire: response.commentaire });
            setIsEditing(false);
        }
    }, [detailRef, dataItem, setDataItem]);

    return <div className='form_container' ref={savRef}>
        <div className='section_group'>
            <div className='sections '>
                <div className='section_group'>
                    <div className='sections'>
                        <FormField label="text.commentaire">
                            <div className='input_cont'>
                                {isEditing ?
                                    <>
                                        <textarea
                                            value={currentValue}
                                            onChange={onChange} />
                                        <DataTip title={'text.save'}> <div className='button micro' onClick={() => { updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, commentaire: currentValue } }) }}><icon>done</icon></div></DataTip>
                                        <DataTip title={'text.cancel'}><div className='button micro' onClick={e => { setIsEditing(false); setCurrentValue(dataItem.sav_info_commentaire); }}><icon >cancel</icon></div></DataTip>
                                    </>
                                    :
                                    <><textarea readOnly value={dataItem.sav_info_commentaire} />
                                        <DataTip title={'text.edit'}><div className='button micro' type='button' onClick={e => setIsEditing(true)}><icon >edit</icon></div></DataTip>
                                    </>}
                                <i></i>
                            </div>
                        </FormField>
                    </div>
                </div>
            </div>
        </div>
    </div >
}