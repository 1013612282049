import React from 'react';
import { message } from 'antd';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { ApiUrl, AppPages } from 'project/Defines.js';

import { Project } from '../../../project/Project';
import { EVENT_NAME } from '../../../project/utilities';
import { T } from 'components/Translations';

/**
 * ...
 * @exports ReseauConfigCBS
 */
export default function ReseauConfigCBS(props) {
    const columnConfig = [
        {
            text: 'text.site_id',
            field: 'site_id',
            width: 100,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.secret',
            field: 'secret',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.reseau',
            field: 'reseau_nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.pays',
            field: 'pays',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },

        {
            text: 'text.type_de_compte',
            field: 'type_compte',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.compte_cb',
            field: 'compte_cb',
            width: 140,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.activites',
            field: 'activites_nom',
            width: 140,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.mode',
            field: 'mode',
            width: 140,
            type: COLUMN_TYPE.TEXT,
        },
        {
            type: COLUMN_TYPE.ACTION,
            onBeforeAction: (record, command) => {
                switch (command) { // NOSONAR
                    case 'delete':
                        if (record.commandes_count > 0) {
                            message.warning(<T>message.cb_config_delete_warning_message</T>);
                            return false;
                        }
                        break;
                    default:
                        break;
                }
                return true;
            }
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ReseauConfigCBSList}
            deleteUrl={ApiUrl.ReseauConfigCBSDelete}
            detailUrl={Project.getPageUrl(AppPages.ReseauConfigCBSDetails)}
            filters={[
                { name: 'reseau_uuid', type: 'select', text: 'text.reseau', valuelist: 'reseaux', autoComplete: "new-password", disableCach: true },
                { name: 'pays', type: 'select', text: 'text.pays', valuelist: 'pays', autoComplete: "new-password", disableCach: true },
                { name: 'type_compte', type: 'select', text: 'text.type_de_compte', valuelist: 'compte_types', autoComplete: "new-password", textAsValue: true, disableCach: true },
                { name: 'mode', type: 'select', text: 'text.mode', valuelist: 'mode', autoComplete: "new-password", disableCach: true }
            ]}
            nameField='site_id'
            newButtonTip='text.create_new_cb_config'
            listUpdateEvent={EVENT_NAME.CB_CONFIG_LIST_UPDATE}
            detailProps={{ newTitle: 'text.new_cb_config', editTitle: 'text.edit_cb_config'}}
            listName={AppPages.ReseauConfigCBS}
            showRefreshButton={false}
        />
    );
}