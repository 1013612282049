import { message, Progress } from 'antd';
import { Ajax } from 'components/Ajax.js';
import { PopupTypes } from 'components/Popup';
import { DataTip, T } from 'components/Translations';
import { AppPages } from 'project/Defines';
import { ApiUrl, StoreKeys } from 'project/Defines.js';
import { Project } from 'project/Project';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities.js';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import DropDownValueList from 'Shared/DropDown';
import TreeView from 'Shared/TreeView/index.js';
import store from 'store';
import { openPopup } from 'store/actions';
import { v4 as uuidv4 } from 'uuid';
import { getLanguages } from '~/Shared/Translation';
import { setListState } from '../../store/actions';





/**
 * the BillOfMaterial extract
 * @exports BOM-List
 */
//export default
function BillOfMaterialsExtract(props) { // NOSONAR
    const cultures = getLanguages();
    const listName = "BillOfMaterialsExtract";
    const { listStates, setListState } = props;
    const listState = listName && listStates[listName];
    const maxProducts = 10;
    const maxFamily = 10;
    const dispatch = useDispatch();
    const [, setFamily] = useState(null);
    const [univers, setUnivers] = useState(listState && listState.univers);
    const [pays, setPays] = useState((listState && listState.pays) || 'FR');
    const [language, setLanguage] = useState((listState && listState.language) || store.get(StoreKeys.Culture));
    const [treeData, setTreeData] = useState([]);
    const [showAll, setShowAll] = useState((listState ? listState.showAll : true));
    const [downloadingController, setDownloadingController] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pageSize] = useState(maxProducts);// eslint-disable-line react-hooks/exhaustive-deps
    const [sum, setSum] = useState(0);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [percent, setPercent] = useState(0);
    const [cacheKey, setCacheKey] = useState(null);
    const loading = <>
        <toolbar>
            <wrap>
                <icon>box</icon>
                <text><b><T>message.preparation_de_archive</T></b></text>
            </wrap>
        </toolbar>
        <view>
            <div className="conteneur download" style={{ display: "block" }}>
                <h1><i className="fa fa-archive" aria-hidden="true"></i> </h1>
                <div className="progress-wrap progress" >
                    <div className="progress-bar progress" style={{ left: 0 }}></div>
                </div>
            </div>

            <toolbar light="light">
                <Progress
                    strokeColor={{
                        from: '#108ee9',
                        to: '#87d068',
                    }}
                    percent={percent}
                    showInfo={percent <= 100}
                    status="active"
                />
            </toolbar>
        </view>
    </>;

    const { t } = useTranslation();

    const listUpdateEvent = EVENT_NAME.FAMILY_LIST_UPDATE;

    const onSelect = useCallback((node) => {
        if (node && node.key) {
            setFamily(Object.assign({}, node));
        } else {
            setFamily(null);
        }
    }, [])

    const loop = useCallback((_data, key, callback) => {
        _data.forEach((item, index, arr) => {
            if (key && item.key === key) {
                return callback(item, index, arr);
            }
            if (!key) {
                callback(item, index, arr);
            }
            if (item.children) {
                return loop(item.children, key, callback);
            }
        });
    }, []);
    const getChildrenKeys = useCallback((_dataItem) => {
        let arr = [];
        if (_dataItem.children) {
            _dataItem.children.forEach((item) => {
                arr.push(item.key);
                arr = arr.concat(getChildrenKeys(item))
            })
        }

        return arr;
    }, [])
    const getParents = useCallback((_dataItem, _data) => {
        let arr = [];
        if (_dataItem && _dataItem.parent_uuid) {
            let item
            loop(_data, _dataItem.parent_uuid, (_item) => {
                item = _item;
            })
            arr.push(item);
            arr = arr.concat(getParents(item, _data))
        }

        return arr;
    }, [loop])

    const moveTreeNode = useCallback((dataItem, data) => {
        if (treeData.length === maxFamily) {
            return;
        }

        if (dataItem.products === 0) {
            return;
        }

        const parentKeys = getParents(dataItem, data).map(el => el.key);
        for (let i = 0; i < parentKeys.length; i++) { // NOSONAR
            if (treeData.find(el => el.uuid === parentKeys[i])) {
                return;
            }
        }

        const childrenKeys = getChildrenKeys(dataItem);
        let _treeData = treeData.filter((el) => childrenKeys.indexOf(el.uuid) === -1);

        //La famille que vous souhaitez ajouter contient un ou plusieurs éléments contenus dans votre panier. Si vous continuez, les éléments concernés seront supprimés.

        const yesCallback = function () {
            const isExist = !!_treeData.find((el) => el.uuid === dataItem.uuid);
            if (!isExist) {
                _treeData = [..._treeData, dataItem];
                setTreeData(_treeData);
            }
        }

        if (_treeData.length !== treeData.length) {
            dispatch(openPopup({
                windowKey: 'wndConfirm',
                type: PopupTypes.Confirm,
                title: <T>message.dragdrop_title</T>,
                text: <T>text.family_add_in_your_basket</T>,
                buttonYesText: <T>text.yes</T>, //Defines.Messages.Yes,
                buttonNoText: <T>text.no</T>, //Defines.Messages.No,
                yesCallback: yesCallback
            }));
        }
        else {
            yesCallback();
        }
    }, [treeData, dispatch, getChildrenKeys, getParents]);

    const onDelete = useCallback((id) => {
        setTreeData(treeData.filter((el) => el.uuid !== id))
    }, [treeData]);

    useEffect(() => {
        dispatchCustomEvent(listUpdateEvent, { action: 'refresh' });
    }, [univers, pays, showAll]);// eslint-disable-line react-hooks/exhaustive-deps

    const onChangeUniversData = useCallback((e, fieldName) => {
        setUnivers(e);
        setTreeData([]);
    }, []);

    const onChangePaysData = useCallback((e, fieldName) => {
        setPays(e);
        setTreeData([]);
    }, []);

    const onChangeShowAll = useCallback((e, fieldName) => {
        setShowAll(e.target.checked);
        setTreeData([]);
    }, []);

    const downloadPDF = useCallback((page) => {
        if (pageSize) { // NOSONAR

        }

        setListState({ listName, state: { univers, pays, language, showAll } });

        let controller =
            Ajax.download({
                // url: '/api/Print/Nomenclature',
                url: '/api/Print/Index', //`${process.env.REACT_APP_API_URL}/api/Print/Index`,
                autoDownload: (page === pages),
                data: {
                    pays: pays,
                    universe: univers,
                    family: treeData.map((item) => item.ID),
                    culture: language,
                    cacheKey: cacheKey,
                    download: (page === pages),
                    page: page,
                    pageSize: (pageSize > 0 ? pageSize : 0) //maxProducts
                },
                success: function (response) {
                    setDownloadingController(null);
                    if (page < pages) {
                        setPage(page + 1);
                        console.timeLog('extract');
                    } else {
                        setPercent(100);
                        message.success(t('message.download_archive_generated'));
                        setIsLoading(false);
                        console.timeEnd('extract');
                        setPage(0);
                    }
                },
                error: function (response) {
                    const msg = response.title || response.message;
                    if (msg === "No products")
                        message.error(t("message.no_products_for_download"));
                    else
                        message.error(msg);
                    setIsLoading(false)
                    setDownloadingController(null);
                    setPage(0);
                }
            });

        setDownloadingController(controller);

    }, [treeData, pays, univers, language, cacheKey, pages, pageSize, setListState, showAll, t]);


    const checkDownload = useCallback(() => {
        if (isLoading && page > 0) {
            let items = (page) * pageSize;
            if (items >= sum) items = sum;
            setPercent((items / sum * 100).toFixed(0));
            downloadPDF(page);
        }
    }, [page, downloadPDF, pageSize, sum, isLoading])

    useEffect(() => {
        checkDownload();
    }, [page]);// eslint-disable-line react-hooks/exhaustive-deps

    const onDownloadClick = useCallback(() => {
        console.time('extract');
        setIsLoading(true)
        setCacheKey(uuidv4());

        if (downloadingController)
            downloadingController.abort();

        const s = treeData.reduce(function (a, b) {
            return a + b.products;
        }, 0);

        setSum(s);
        if (s > pageSize) {
            setPages(Math.ceil(s / pageSize));
        }
        else {
            setPages(1);
        }
        setPage(1);
    }, [pageSize, treeData, downloadingController]);

    const onCancel = useCallback(() => {
        setPage(0);
        setIsLoading(false)
        if (downloadingController)
            downloadingController.abort();
    }, [downloadingController]);

    return (<>
        <view>
            <toolbar light="light">
                <div effect="material" className="button white mini back"><Link to={Project.getPageUrl(AppPages.BillOfMaterials)}><icon>left</icon></Link></div>
                <separator vertical=""></separator>
                <wrap>
                    <text><T>message.export_the_boms_of_your_choice</T></text>
                </wrap>
            </toolbar>
            <view vertical="vertical">
                <view>
                    <toolbar>
                        <wrap>
                            <div className="form_fields line">
                                <DropDownValueList
                                    name='Univers'
                                    allowClear
                                    disableCach={true}
                                    autoSelectFirst={!univers}
                                    placeholder={<T>text.universe</T>}
                                    {...(univers ? { value: univers } : {})}
                                    valuelist='Univers'
                                    showSearch
                                    {...(treeData.length > 0 ? { disabled: true } : {})}
                                    onChange={e => { onChangeUniversData(e, 'univers_uuid') }} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                />
                            </div>
                            <div className="form_fields line">
                                <DropDownValueList
                                    name='pays'
                                    allowClear
                                    placeholder={<T>text.pays</T>}
                                    {...(pays ? { value: pays } : {})}
                                    valuelist="pays"
                                    showSearch
                                    {...(isLoading || treeData.length > 0 ? { disabled: true } : {})}
                                    onChange={e => { onChangePaysData(e, 'pays_uuid') }} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                />
                            </div>
                            <div className="form_fields line">
                                <DropDownValueList
                                    name='culture'
                                    allowClear
                                    placeholder={<T>text.culture</T>}
                                    {...(language ? { value: language } : {})}
                                    items={cultures}
                                    showSearch
                                    {...(isLoading ? { disabled: true } : {})}
                                    onChange={e => { setLanguage(e) }} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                />
                            </div>
                        </wrap>
                    </toolbar>
                    <toolbar>
                        <wrap>
                            <icon>bullets</icon>
                            <text><b><T>text.resultats</T></b></text>
                            <action right=''>
                                <>
                                    <label checkbox="">
                                        <input
                                            type="checkbox"
                                            checked={showAll}
                                            onChange={(e) => { onChangeShowAll(e, 'showAll') }}
                                            {...(isLoading || treeData.length > 0 ? { disabled: true } : {})}
                                        />
                                        <box><check></check></box>
                                        <text><T>text.show_all</T></text>
                                    </label>
                                </>
                            </action>
                        </wrap>
                    </toolbar>
                    {
                        (univers && pays) && <TreeView
                            apiUrl={ApiUrl.FamiliesList}
                            dataItemUrl={ApiUrl.FamiliesLoad}
                            updateUrl={ApiUrl.FamiliesUpdate}
                            parentChangeUrl={ApiUrl.FamiliesParentChange}
                            deleteUrl={ApiUrl.FamiliesDelete}
                            nameField="title"
                            hide
                            hideTooltip
                            editable={false}
                            moveTreeNode={moveTreeNode}
                            onSelect={onSelect}
                            staticFilter={{ univers_uuid: univers, pay_code: pays, show_all: showAll }}
                            listUpdateEvent={listUpdateEvent}
                            disabled={isLoading}
                        />
                    }
                </view>
                <view>
                    <toolbar>
                        <wrap>
                            <icon>box</icon>
                            <text><b><T args={[10]}>message.panier_maximum_elements</T></b></text>
                        </wrap>
                    </toolbar>
                    <view>
                        <div className="section_group">
                            <div className="sections">
                                {
                                    treeData.map((item) => {
                                        return (
                                            <div className="form_fields line" key={item.key}>
                                                <span
                                                    {...(isLoading ? {} : {
                                                        onClick: () => onDelete(item.uuid)
                                                    })}
                                                ><icon>delete</icon></span>
                                                <input name="family" type="hidden" value={item.uuid} />
                                                <input type="text" readOnly value={item.title} />
                                            </div>
                                        )
                                    })
                                }
                                <toolbar transparent="transparent">
                                    <wrap>
                                        <action>
                                            {
                                                //<DropDownValueList
                                                //    name='pageSize'
                                                //    placeholder={<T>text.page_size</T>}
                                                //    {...(isLoading ? { disabled: true } : {})}
                                                //    {...(pageSize ? { value: pageSize } : {})}
                                                //    items={pageSizeList}
                                                //    onChange={e => { setPageSize(e) }}
                                                ///>
                                            }
                                            <div className='button primary' onClick={onDownloadClick} disabled={(treeData.length === 0 || !univers || !pays || !language || isLoading) ? true : false}>
                                                <icon>download</icon><T>text.download</T>
                                            </div>
                                            {
                                                isLoading && <DataTip title={'text.cancel'}><div className='button' onClick={onCancel}><icon >cancel</icon><T>text.cancel</T></div></DataTip>
                                            }
                                        </action>
                                    </wrap>
                                </toolbar>
                            </div>
                        </div>
                        {
                            isLoading && loading
                        }

                    </view>

                </view>

            </view>
        </view>
    </>);
}

export default connect(state => ({ listStates: state.listStates }),
    dispatch => ({
        setListState: (data) => dispatch(setListState(data))
    }))(BillOfMaterialsExtract);