import React, { useEffect, useRef, useCallback, useState } from 'react';
import { connect } from "react-redux";
import Draggable from 'react-draggable';
import { closePopup } from './../store/actions';
import BrandDetails from '../pages/Brand/detail';
import UniversDetails from '../pages/Universe/detail';
import FamilyProductPickup from '../pages/Universe/Family/Products/familyProductPickup';
import FamilyProductDetails from '~/pages/Universe/Family/Products/detail';
import FamilyVideoPickup from '../pages/Universe/Family/Videos/familyVideoPickup';
import FamilyInfosDetails from '../pages/Universe/Family/Infos/FamilyInfos/detail';
import EvolutionDetails from '../pages/Universe/Family/Evolutions/detail';
import DefectDetails from '../pages/Universe/Family/Defects/detail';
import DiagnosticsDetails from '../pages/Universe/Family/Support/Diagnostics/detail';
import SalesNetworkDetails from '../pages/SalesNetwork/detail';
import CreditCardPaymentsDetails from '../pages/SalesNetwork/CreditCardPayments/detail';
import BillOfMaterialDetails from '../pages/BillOfMaterials/detail';
import SchemaDetails from '../pages/BillOfMaterials/Schemas/detail';
import ZoneDetails from '../pages/BillOfMaterials/Schemas/zoneDetail';
import SparePartPicker from '../pages/BillOfMaterials/SpareParts/sparePartPicker';
import ProductPicker from '../pages/BillOfMaterials/UseCase/productPicker';
import SparePartLookup from '../pages/BillOfMaterials/Schemas/sparePartLookup';
import SparePartDetails from '../pages/SparePart/detail';
import SparePartTypePicker from '../pages/SparePart/SparePartType/sparePartTypePicker';
import SubstituteSparePartPicker from '../pages/SparePart/Substitute/substituteSparePartPicker';
import ProductBOMPicker from '../pages/Product/ProductBOM/productBomPicker';
import AccessoryPicker from '../pages/Product/Accessories/accessoryPicker';
import ProductDetails from '../pages/Product/detail';
import SocieteUserDetails from '../pages/User/SocieteUser/detail';
import SocieteSalesNetworkDetails from '../pages/User/SalesNetwork/detail';
import SetupCountryGroupingDetails from '../pages/Setup/CountryGrouping/detail';
import ProcessDetails from '../pages/Process/detail';
import ProcessLogPicker from '../pages/Process/ProcessLog/processLogPicker';
import SalesOrderDetails from '../pages/Jobs/SalesOrder/detail';
import WarrantyRequestDetails from '../pages/Jobs/WarrantyRequest/detail';
import FamilyBackupPicker from '../pages/Universe/familyBackupPicker';
import { TemplateDetails } from '../pages/Template';
import { T } from './Translations';
import SirenSiretPickup from '~/pages/Administration/ConnectivityBlocking/sirenSiretPickup';
import HelpVideoPicker from './Navigation/helpVideoPicker';
import SalesNetworkPicker from '~/pages/SalesNetwork/SalesNetworkNatureCode/salesNetworkPicker';
import SkillsDetails from '../pages/Administration/Skills/detail';
import SocieteSkillsPickup from '../pages/User/SocieteSkills/societeSkillsPickup';
import SocieteSkillBatchUpload from '../pages/User/SocieteSkills/batchUpload';
import FamillySkillsPickup from '~/pages/Universe/Family/FamillySkill/famillySkillsPickup';
import SocieteSkillDetails from '~/pages/User/SocieteSkills/detail';
import SocietePostalCodeDetails from '~/pages/User/SocietePostalCode/detail';
import SsoDetails from '~/pages/Universe/Sso/detail';
import SSOLogin from '~/pages/Universe/Sso/SSOLogin/detail';
import SocieteTechnisianDetails from '../pages/User/SocieteTechnician/detail';

/**
 * specifies all the popups without navigation
 */
export const PopupTypes = {
    Confirm: "Confirm",
    Prompt: "Prompt",
    SparePartPicker: "SparePartPicker",
    ProductPicker: "ProductPicker",
    SparePartLookup: "SparePartLookup",
    SparePartDetails: "SparePartDetails",
    SparePartTypePicker: "SparePartTypePicker",
    SubstituteSparePartPicker: "SubstituteSparePartPicker",
    SalesOrderDetails: "SalesOrderDetails",
    WarrantyRequestDetails: "WarrantyRequestDetails",
    ProductDetails: "ProductDetails",
    BrandDetails: "BrandDetails",
    UserDetails: "UserDetails",
    UniversDetails: "UniversDetails",
    FamilyProductPickup: "FamilyProductPickup",
    FamilyProductDetails: "FamilyProductDetails",
    FamilyVideoPickup: "FamilyVideoPickup",
    FamilyEvolutionDetails: "FamilyEvolutionDetails",
    FamilyDefectDetails: "FamilyDefectDetails",
    FamilyBackupPicker: "FamilyBackupPicker",
    FamilyInfosDetails: "FamilyInfosDetails",
    DiagnosticsDetails: "DiagnosticsDetails",
    SalesNetworkDetails: "SalesNetworkDetails",
    CreditCardPaymentsDetails: "CreditCardPaymentsDetails",
    BillOfMaterialDetails: "BillOfMaterialDetails",
    ZoneDetails: "ZoneDetails",
    ProductBOMPicker: "ProductBOMPicker",
    SchemaDetails: "SchemaDetails",
    AccessoryPicker: "AccessoryPicker",
    ProcessDetails: "ProcessDetails",
    ProcessLogPicker: "ProcessLogPicker",
    SocieteUserDetails: "SocieteUserDetails",
    SocieteSalesNetworkDetails: "SocieteSalesNetworkDetails",
    SetupCountryGroupingDetails: "SetupCountryGroupingDetails",
    TemplateDetails: "TemplateDetails",
    SirenSiretPickup: "SirenSiretPickup",
    HelpVideoPicker: "HelpVideoPicker",
    SocieteSkillBatchUpload: "SocieteSkillBatchUpload",
    SalesNetworkPicker: "SalesNetworkPicker",
    Skills: "Skills",
    SkillsDetails: "SkillsDetails",
    SocieteSkillsPickup: "SocieteSkillsPickup",
    SocieteSkillDetails: "SocieteSkillDetails",
    FamillySkillsPickup: "FamillySkillsPickup",
    SocietePostalCodeDetails: "SocietePostalCodeDetails",
    UniversSitesDetails: "UniversSitesDetails",
    UniversSitesSSODetails: "UniversSitesSSODetails",
    SocieteTechnisianDetails: "SocieteTechnisianDetails"
}

export const PopupClasses = {
    Small: "small",
    Medium: "medium",
    Large: "large",
}

/**
 * the main function generating all opened popups
 * @param {object} props  contains props for fulscreen, class, all opened popups info
 */
function Popups(props) {
    let { popups, fullScreen } = props;
    popups = (popups && popups.filter(item => (item.fullScreen || false) === (fullScreen || false))) || [];
    let window_container = (popups.length > 0) ? "window_container open" : "window_container";
    return <div className={window_container}>
        {
            popups.map((item, i) => {
                let closeOutside = false;
                let ComponentVar;
                const { type, bodyProps, className } = item;
                let open_class = type + (item.fullScreen ? " open fullscreen" : " open");
                const onClose = (e) => props.closePopup(item.windowKey);
                let skipCloseClassNames = '';
                switch (type) { // NOSONAR
                    case PopupTypes.Confirm:
                        return <InnerPopup key={type + i} className="open" onClose={onClose} closeOnOutsideClick={true} // NOSONAR
                        >
                            <Confirm {...item} onClose={onClose} // NOSONAR
                            />
                        </InnerPopup>;

                    case PopupTypes.Prompt:
                        return <InnerPopup key={type + i} className="open" onClose={onClose} closeOnOutsideClick={false} // NOSONAR
                        >
                            <Prompt {...item} onClose={onClose} // NOSONAR
                            />
                        </InnerPopup>;

                    case PopupTypes.BrandDetails:
                        ComponentVar = BrandDetails;
                        break;
                    case PopupTypes.UniversDetails:
                        ComponentVar = UniversDetails;
                        break;
                    case PopupTypes.FamilyProductPickup:
                        ComponentVar = FamilyProductPickup;
                        break;
                    case PopupTypes.FamilyProductDetails:
                        ComponentVar = FamilyProductDetails;
                        break;
                    case PopupTypes.FamilyVideoPickup:
                        ComponentVar = FamilyVideoPickup;
                        break;
                    case PopupTypes.FamilyEvolutionDetails:
                        ComponentVar = EvolutionDetails;
                        break;
                    case PopupTypes.FamilyDefectDetails:
                        ComponentVar = DefectDetails;
                        break;
                    case PopupTypes.FamilyBackupPicker:
                        ComponentVar = FamilyBackupPicker;
                        break;
                    case PopupTypes.FamilyInfosDetails:
                        ComponentVar = FamilyInfosDetails;
                        break;
                    case PopupTypes.DiagnosticsDetails:
                        ComponentVar = DiagnosticsDetails;
                        break;

                    case PopupTypes.SalesNetworkDetails:
                        ComponentVar = SalesNetworkDetails;
                        break;
                    case PopupTypes.CreditCardPaymentsDetails:
                        ComponentVar = CreditCardPaymentsDetails;
                        break;

                    case PopupTypes.BillOfMaterialDetails:
                        ComponentVar = BillOfMaterialDetails;
                        skipCloseClassNames = PopupTypes.SparePartPicker;
                        break;
                    case PopupTypes.SparePartDetails:
                        ComponentVar = SparePartDetails;
                        break;
                    case PopupTypes.SparePartTypePicker:
                        ComponentVar = SparePartTypePicker;
                        break;
                    case PopupTypes.SubstituteSparePartPicker:
                        ComponentVar = SubstituteSparePartPicker;
                        break;
                    case PopupTypes.ProductDetails:
                        ComponentVar = ProductDetails;
                        break;
                    case PopupTypes.SchemaDetails:
                        ComponentVar = SchemaDetails;
                        break;
                    case PopupTypes.ZoneDetails:
                        ComponentVar = ZoneDetails;
                        break;
                    case PopupTypes.SparePartPicker:
                        ComponentVar = SparePartPicker
                        break;
                    case PopupTypes.SparePartLookup:
                        ComponentVar = SparePartLookup;
                        break;
                    case PopupTypes.ProductBOMPicker:
                        ComponentVar = ProductBOMPicker;
                        break;
                    case PopupTypes.AccessoryPicker:
                        ComponentVar = AccessoryPicker;
                        break;
                    case PopupTypes.SocieteUserDetails:
                        ComponentVar = SocieteUserDetails;
                        break;
                    case PopupTypes.SocieteSalesNetworkDetails:
                        ComponentVar = SocieteSalesNetworkDetails;
                        break;
                    case PopupTypes.SocieteTechnisianDetails:
                        ComponentVar = SocieteTechnisianDetails;
                        break;
                    case PopupTypes.SalesOrderDetails:
                        ComponentVar = SalesOrderDetails;
                        break;
                    case PopupTypes.WarrantyRequestDetails:
                        ComponentVar = WarrantyRequestDetails;
                        break;
                    case PopupTypes.ProcessDetails:
                        ComponentVar = ProcessDetails;
                        break;
                    case PopupTypes.ProductPicker:
                        ComponentVar = ProductPicker;
                        break;
                    case PopupTypes.ProcessLogPicker:
                        ComponentVar = ProcessLogPicker;
                        break;
                    case PopupTypes.SetupCountryGroupingDetails:
                        ComponentVar = SetupCountryGroupingDetails;
                        break;
                    case PopupTypes.SirenSiretPickup:
                        ComponentVar = SirenSiretPickup;
                        break;
                    case PopupTypes.HelpVideoPicker:
                        ComponentVar = HelpVideoPicker;
                        break;
                    case PopupTypes.SalesNetworkPicker:
                        ComponentVar = SalesNetworkPicker;
                        break;
                    case PopupTypes.SkillsDetails:
                        ComponentVar = SkillsDetails;
                        break;
                    case PopupTypes.SocieteSkillsPickup:
                        ComponentVar = SocieteSkillsPickup;
                        break;
                    case PopupTypes.SocieteSkillDetails:
                        ComponentVar = SocieteSkillDetails;
                        break;

                    case PopupTypes.SocieteSkillBatchUpload:
                        ComponentVar = SocieteSkillBatchUpload;
                        break;

                    case PopupTypes.FamillySkillsPickup:
                        ComponentVar = FamillySkillsPickup;
                        break;

                    case PopupTypes.SocietePostalCodeDetails:
                        ComponentVar = SocietePostalCodeDetails;
                        break;

                    case PopupTypes.UniversSitesDetails:
                        ComponentVar = SsoDetails;
                        break;
                    case PopupTypes.UniversSitesSSODetails:
                        ComponentVar = SSOLogin;
                        break;

                    case PopupTypes.TemplateDetails:
                        ComponentVar = TemplateDetails;
                        break;

                    case PopupTypes.Popup:
                        ComponentVar = item.component;
                        break;
                    default:
                        return <div key={i} // NOSONAR
                        ></div>;
                }
                return <InnerPopup
                    key={type + i} // NOSONAR
                    className={`${open_class} ${className || ''}`}
                    onClose={onClose} // NOSONAR
                    skipCloseClassNames={skipCloseClassNames}
                    closeOnOutsideClick={i === popups.length - 1 && closeOutside}>
                    <PupupWrapper {...item} onClose={onClose} // NOSONAR
                    >
                        <ComponentVar {...bodyProps} onClose={onClose} // NOSONAR
                        />
                    </PupupWrapper>
                </InnerPopup>
            })
        }
    </div>
}
export default connect(state => ({
    popups: state.popups
}),
    dispatch => ({
        closePopup: (key) => dispatch(closePopup(key))
    })
)(Popups);

/**
 *custom hook for running logic on outer click of specified element with given ref
 * @param {func} onOuterClick
 * @param {object} innerRef the ref of element
 * @param {skipCloseClassNames} skipCloseClassNames
 * @param {bool} enable
 */
function useOuterClickNotifier(onOuterClick, innerRef, skipCloseClassNames, enable) {
    useEffect(
        () => {
            if (innerRef.current && enable) {
                document.addEventListener("click", handleClick);
            }

            return () => document.removeEventListener("click", handleClick);

            function handleClick(e) {
                innerRef.current &&
                    (innerRef.current === e.target || !innerRef.current.contains(e.target)) && !(document.getElementsByClassName(skipCloseClassNames)[0] && document.getElementsByClassName(skipCloseClassNames)[0].contains(e.target)) &&
                    onOuterClick(e);
            }
        },
        [enable, innerRef, skipCloseClassNames, onOuterClick] // invoke again, if inputs have changed
    );
}

/**
 * the outer warpper of all popups (for each one)
 * @param {any} props className, children, onClose, skipCloseClassNames, closeOnOutsideClick
 */
function InnerPopup(props) {
    const { className, children, onClose, skipCloseClassNames, closeOnOutsideClick } = props;
    const innerRef = useRef(null);

    useOuterClickNotifier(
        e => { onClose() },
        innerRef,
        skipCloseClassNames,
        closeOnOutsideClick
    );

    return (
        <div ref={innerRef} className={className}>
            {children}
        </div>
    );
};

/**
 *custom confirm popup
 * @param {object} props contains title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose
 */
function Confirm(props) {
    const { title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose } = props;

    let onYesButtonClick = useCallback(function (e) {
        yesCallback && yesCallback(e);
        onClose();
    }, [yesCallback, onClose]);

    let onNoButtonClick = useCallback(function (e) {
        noCallback && noCallback();
        onClose();
    }, [noCallback, onClose]);

    return <div>
        <div>
            <div className="message_box">
                <div className="message_container">
                    <icon large="">question</icon>
                    <h3>{title}</h3>
                    <p>{text}</p>
                </div>
                <panel>
                    <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
                        <text>{buttonYesText}</text>
                    </button>
                    <separator vertical=""></separator>
                    <button type="button" effect="material" command="no" className="button accent" onClick={onNoButtonClick}>
                        <text>{buttonNoText}</text>
                    </button>
                </panel>
            </div>
        </div>
    </div>;
};

Confirm.defaultProps = {
    title: "",
    text: "",
    htmlText: false,
    buttonYesText: "OK",
    buttonNoText: "Cancel"
}

/**
 *custom prompt popup
 * @param {object} props contains title, text, yesCallback, noCallback, buttonYesText, buttonNoText, onClose
 */
function Prompt(props) {
    const { title, isPassword, placeholder, yesCallback, noCallback, buttonYesText, buttonNoText, onClose } = props;
    const [value, setValue] = useState('');

    let onYesButtonClick = useCallback(function (e) {
        yesCallback && yesCallback(e, value);
        onClose();
    }, [yesCallback, onClose, value]);

    let onNoButtonClick = useCallback(function (e) {
        noCallback && noCallback();
        onClose();
    }, [noCallback, onClose]);

    return <div>
        <div>
            <div className="message_box">
                <div className="message_container">
                    <h3>{title}</h3>

                    <form>
                        <div className="form_container">
                            <div className="section_group">
                                <div className="sections ">
                                    <div className="form_fields">
                                        <input type={isPassword ? "password" : "text"} value={value} placeholder={placeholder} onChange={(e) => setValue(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <panel>
                    <button type="button" effect="material" command="yes" className="button primary" onClick={onYesButtonClick}>
                        <text>{buttonYesText}</text>
                    </button>
                    <separator vertical=""></separator>
                    <button type="button" effect="material" command="no" className="button accent" onClick={onNoButtonClick}>
                        <text>{buttonNoText}</text>
                    </button>
                </panel>
            </div>
        </div>
    </div>;
};

Prompt.defaultProps = {
    title: "",
    isPassword: false,
    placeholder: '',
    buttonYesText: "OK",
    buttonNoText: "Cancel"
}

/**
 * default carcas for all popups except confirm
 * @param {any} props
 */
function PupupWrapper(props) {
    const { title, fullScreen, showHeader, children, cancelCallback, onClose } = props;
    let onCancel = useCallback(function (e) {
        cancelCallback && cancelCallback(e);
        onClose();
    }, [onClose, cancelCallback]);

    return (
        <>
            <Draggable handle="strong">
                <div className="box no-cursor">
                    {showHeader &&
                        <header>
                            {
                                fullScreen &&
                                <>
                                    <div effect='material' className='button white mini back' command='close' onClick={onCancel}>
                                        <icon>left</icon>
                                    </div>
                                    <separator vertical=""></separator>
                                </>
                            }

                            <div style={{ width: 'calc(100% - 20px)' }}>
                                <strong className="cursor"><div><p><T>{title}</T></p></div></strong>

                            </div>

                            {
                                !fullScreen &&
                                <div effect="material" className="button white mini close" command="close" onClick={onCancel}>
                                    <icon>close</icon>
                                </div>
                            }
                        </header>
                    }
                    <div>

                        <div className="window_cont">
                            {children}
                        </div>
                    </div>
                </div>
            </Draggable>
        </>);
}

PupupWrapper.defaultProps = {
    showHeader: true,
    fullScreen: true,
}