import React, { useCallback } from 'react';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationDropDownValueList } from '../../../../../Shared/Form/validation';
import { Textarea } from '~/Shared/Input';

/**
 * the Diagnostics details of Family's Support tab
 * @exports Universe-Family-DiagnosticsDetails
 */
export default function DiagnosticsDetails(props) {
    const { windowKey, famille_uuid, sav_info_uuid, ID, data } = props;

    const onLoadData = useCallback((response) => {
        if (response) {
            response.famille_uuid = famille_uuid || (data && data.famille_uuid);
            response.sav_info_uuid = sav_info_uuid || (data && data.sav_info_uuid) || "";
            if (response.ID === "") {
                response.ordre = -1;
            }
        }
    }, [famille_uuid, data, sav_info_uuid]);

    const { dataItem, setDataItem, updateData, onChangeData, closePopup, detailRef, validation } = useDetail({
        data: { ID: ID },
        updateUrl: ApiUrl.SavInfoDiagnosticsUpdate,
        dataLoadUrl: ApiUrl.SavInfoDiagnosticsLoad,
        windowKey,
        onLoadData: onLoadData,
        validationFields: ['defaut_uuid']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(EVENT_NAME.FAMILY_SAV_INFO_DIAGNOSTICS_LIST_UPDATE, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.FAMILY_SAV_INFO_UPDATE, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef]);

    return (<>
        {dataItem && <div id='windowContent' className='window_cont' ref={detailRef}>
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections wide'>
                            <FormField label="text.defect">
                                <ValidationDropDownValueList validation={validation} name='defaut_uuid' value={dataItem.defaut_uuid} valuelist="defauts" onChange={e => onChangeData({ target: { name: 'defaut_uuid', value: e } })} // NOSONAR
                                />
                            </FormField>

                            <FormField label="text.cause">
                                <Textarea name='cause' type='text' value={dataItem.cause} onChange={onChangeData} maxLength={500} />
                            </FormField>
                            <FormField label="text.symptom">
                                <Textarea name='symptome' type='text' value={dataItem.symptome} onChange={onChangeData} maxLength={500} />
                            </FormField>
                            <FormField label="text.remedy">
                                <Textarea name='remede' type='text' value={dataItem.remede} onChange={onChangeData} maxLength={500} />
                            </FormField>
                        </div>
                    </div>
                </div>

                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </div>}
    </>);
}