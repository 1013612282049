import React, { useState, useCallback } from 'react';
import { T } from '../../../components/Translations';
import { ApiUrl } from '../../../project/Defines.js';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { Ajax } from '../../../components/Ajax';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * the Accessory Picker for Product accessories
 * @exports AccessoryPicker
 */
export default function AccessoryPicker(props) {
    const { t } = useTranslation();
    const { produit_uuid } = props;
    const [selectedRows, setSelectedRows] = useState([]);
    const [updating, setUpdating] = useState(false);

    const updateData = useCallback((e) => {
        setUpdating(true);
        Ajax.post({
            url: ApiUrl.AccessoryProductAdd,
            data: {
                produit_uuid,
                selected_ids: selectedRows
            },
            success: function (response) {
                setUpdating(false);
                if (!response.HasError) {
                    message[response.AddedCount ? 'success' : 'info'](<T args={[response.AddedCount || t('text.no')]}>message.accessories_were_added</T>);

                    dispatchCustomEvent(EVENT_NAME.PRODUCT_ACCESSORIES_LIST_UPDATE);
                    dispatchCustomEvent(EVENT_NAME.PRODUCT_ACCESSORY_PICKER_LIST_UPDATE);
                }
            },
            error: function (response) {
                setUpdating(false);
                message.error(response.Message || <T args={[t('text.no')]}> message.accessories_were_added</T>)
            }
        })
    }, [produit_uuid, selectedRows, t]);

    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        },
    ];

    return (<>
        <ListView
            {...props}
            isSimple
            skipEdit
            skipNewButton
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ProductsList}
            search={{ field: 'search' }}
            newButtonTip='text.add'
            staticFilter={{ selectedRows, produit_uuid }}
            listUpdateEvent={EVENT_NAME.PRODUCT_ACCESSORY_PICKER_LIST_UPDATE}
            rowClassName={record => { // NOSONAR
                return selectedRows.some(r => record.ID === r) ? 'select' : null
            }}
            rowSelection={{
                selectedRowKeys: selectedRows,
                onChange: (selectedRowKeys) => {
                    setSelectedRows(selectedRowKeys);
                }
            }}
        />
        <toolbar>
            <wrap>
                <action right=''>
                    <div onClick={updateData} className='button' {...updating ? { disabled: 'disabled' } : {}}><icon>save</icon><T>text.add</T></div>
                </action>
            </wrap>
        </toolbar>
    </>);
}
