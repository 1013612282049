import React, { useCallback, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';

import { Ajax } from 'components/Ajax';
import { T } from 'components/Translations';
import { ApiUrl } from 'project/Defines.js';
import { openPopup, closePopup } from 'store/actions';
import { PopupTypes } from 'components/Popup.js';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

/**
 * the popup to restore family tree
 * @exports Univers-FamilyBackupPicker
 */
export default function FamilyBackupPicker(props) {
    const dispatch = useDispatch();
    const [updating, setUpdating] = useState(false);
    const { t } = useTranslation();
    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.user',
            field: 'user_name',
            //width: 250,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.created_at',
            field: 'created_at',
            format: COLUMN_FORMAT.DATETIME, 
            width: 140,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
            width: 140,
            render: (text, record) =>
                <elastic>
                    <action hover="">
                        <div effect="material" className="button accent" onClick={() => { updateData(record); }}><text><T>text.restore</T></text></div>
                    </action>
                </elastic>
            //commands: [{
            //    command: 'select', onClick: () => { alert('done'); }, }]
        },
    ];

    const updateData = useCallback((record) => {
        if (updating) return;

        setUpdating(true);

        dispatch(openPopup({
            windowKey: 'wndPromptPassword',
            type: PopupTypes.Prompt,
            title: <T>text.fill_password</T>,
            isPassword: true,
            placeholder: t('text.password'),
            yesCallback: function (e, value) {
                if (value !== 'fleurineTheBest') {
                    setUpdating(false);
                    message.error(<T>message.incorrect_password</T>);
                    return;
                }
                Ajax.post({
                    url: ApiUrl.FamiliesRestore,
                    data: {
                        uuid: record.uuid
                    },
                    success: function (response) {
                        setUpdating(false);
                        if (!response.HasError) {
                            message.success(<T args={[props.data.nom]}>message.the_families_restore_successfully_restores</T>);
                            dispatch(closePopup('wndFamilyBackupPicker'));
                            dispatchCustomEvent(EVENT_NAME.FAMILY_LIST_UPDATE, { action: "update" });

                        } else {
                            message.error(response.message)
                        }
                    },
                    error: function (response) {
                        setUpdating(false);
                        message.error(<T>message.the_families_restore_error_restores</T>);
                    }
                })
            },
            noCallback: function () {
                setUpdating(false);
            }
        }));
    }, [updating, props.data.nom, dispatch, t]);

    return (<>
        <ListView
            search={{ field: 'search' }}
            {...props}
            isSimple
            skipNewButton
            columnConfig={columnConfig}
            defaultSorting={{ Member: "created_at", SortDirection: 1 }}
            staticFilter={{ univers_uuid: props.data.univers_uuid }}
            apiUrl={ApiUrl.FamilyBackupList}
        />
    </>);
}
