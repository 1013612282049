import { T } from 'components/Translations';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { Project } from 'project/Project';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields.js';
import useDetail from 'Shared/useDetail';
import { ValidationDropDownValueList, ValidationInput } from '~/Shared/Form/validation';
import DropDownValueList from '~/Shared/DropDown';




/**
 * the DefectCodeDetails and main data of DefectCode
 * @exports DefectCodeDetails
 */
export default function DefectCodeDetails(props) {
    const { id } = useParams();
    const reseau_uuid = new URLSearchParams(props.location.search).get("reseau_uuid");
    const { windowKey, data, onDelete } = props;
    const [tabIndex] = useState(0);
    const listUpdateEvent = EVENT_NAME.DEFECT_CODE_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, onDetailDelete, detailRef, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.DefectCodeUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.DefectCodeLoad,
        onLoadData: (response, setDataItem) => {
            if (!response.ID) {
                setDataItem({ ...response, reseau_uuid });
                return true;
            }
        },
        // nameField: 'code',
        deleteUrl: ApiUrl.DefectCodeDelete,
        backPage: AppPages.DefectCode,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.DefectCodeDetails),
        validationFields: ['reseau_uuid', 'description', 'code', 'value', 'level']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }
        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    useEffect(() => {
        if (dataItem && isNew) {
            dataItem.level = 1;
            setDataItem(dataItem);
        }
    }, [isNew]);// eslint-disable-line react-hooks/exhaustive-deps

    const changeLevelValue = useCallback((e) => {
        if (dataItem) {
            const _dataItem = {...dataItem};
            _dataItem.level = e;
            setDataItem(_dataItem);
        }
    }, [dataItem, setDataItem]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.DefectCode)}
            title={isNew ? <T>text.new_defect_code</T> : <T>text.edit_defect_code</T>}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.sales_network">
                                <ValidationDropDownValueList
                                    name="reseau_uuid"
                                    value={dataItem.reseau_uuid}
                                    validation={validation}
                                    valuelist="reseaux"
                                    onChange={(e) => { onChangeData({ target: { name: "reseau_uuid", value: e } }); }} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                    disabled={true}
                                />
                            </FormField>
                            <FormField label="text.level">
                                <DropDownValueList
                                    name="level"
                                    // defaultValue='1'
                                    value={dataItem.level}
                                    validation={validation}
                                    valuelist="level"
                                    onChange={(e) => changeLevelValue(e)} // NOSONAR
                                    // onChange={(e) => { onChangeData({ target: { name: "level", value: e } }); }}
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                />
                            </FormField>
                            <FormField label="text.description">
                                <ValidationInput validation={validation} name='description' inputType='textarea' value={dataItem.description} onChange={onChangeData} />
                            </FormField>

                        </div>
                        <div className='sections '>
                            <FormField label="text.code">
                                <ValidationInput validation={validation}
                                    name='code'
                                    type='text'
                                    value={dataItem.code}
                                    onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.parent">
                                <ValidationInput validation={validation}
                                    name='parent'
                                    type='text'
                                    value={dataItem.parent}
                                    onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.value">
                                <ValidationInput
                                    validation={validation}
                                    name='value'
                                    type='text'
                                    value={dataItem.value}
                                    onChange={onChangeData}/>
                            </FormField>
                        </div>
                    </div>
                </div>
                {tabIndex < 2 &&
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                                {dataItem.ID &&
                                    <div className='button' onClick={(e) => {
                                        (onDelete && onDelete(dataItem, closePopup)) ||
                                            onDetailDelete(dataItem)
                                    }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>}
            </form>
        </DetailView>}
    </>);
}
