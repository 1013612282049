import React from 'react';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';

import { Project } from '../../../project/Project';

/**
 * ...
 * @exports Maintenance
 */
export default function Maintenance(props) {

    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.portal',
            field: 'portal',
            width: 100,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                var portal = ''; // NOSONAR
                switch (text) {
                    case 'espace-sav':
                        portal = 'Espace-Sav';
                        break;
                    case 'web-station':
                        portal = 'Web Station';
                        break;
                    default:
                        portal = text;
                        break;
                }
                return <wrap title={portal}>
                    <text>{portal}</text>
                </wrap>;
            }
        },
        {
            text: 'text.sites',
            field: 'sites',
            width: 200,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                var sites = (record.sites || []).join(', '); // NOSONAR
                return <wrap title={sites}>
                    <text>{sites}</text>
                </wrap>;
            }
        },
        {
            text: 'text.date_begin',
            field: 'date_start',
            type: COLUMN_TYPE.TEXT,
            format: COLUMN_FORMAT.DATETIME,
            width: 130
        },
        {
            text: 'text.date_end',
            field: 'date_end',
            type: COLUMN_TYPE.TEXT,
            format: COLUMN_FORMAT.DATETIME,
            width: 130
        },
        {
            text: 'text.active',
            field: 'active',
            width: 80,
            render: (text, record, index) => {
                if (record.active)
                    return <wrap><text><icon edit="">done</icon></text></wrap>;

                return <></>;
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.MaintenanceList}
            deleteUrl={ApiUrl.MaintenanceDelete}
            detailUrl={Project.getPageUrl(AppPages.MaintenanceDetails)}
            filters={[{ name: 'nom', type: 'text', text: 'text.name' }]}
            newButtonTip='text.create_new_maintenance'
            listUpdateEvent={EVENT_NAME.MAINTENANCE_LIST_UPDATE}
            detailProps={{ newTitle: 'text.new_maintenance', editTitle: 'text.edit_maintenance' }}
            listName={AppPages.Maintenance}
            showRefreshButton={false}
            nameField='nom'
        />
    );
}