import React from "react";
import { Resizable  } from "react-resizable";

function ResizableTitle(props) {
  const { onResize, onResizeStop, width,  ...restProps  } = props;

  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      // width={resizable === true ? width : '' }
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      // axis={'x'}
      // resizeHandles={['se']}
      minConstraints={[100, 100]}
      draggableOpts={{ enableUserSelectHack: false }}
      // onResizeStop={onResizeStop}
      // onResizeStart={e => {
        //   console.log(e);
        // }}
      // onMouseDown={e => {
      // }}
    >
      <th {...restProps} />
    </Resizable>
  );
}

export default ResizableTitle;
