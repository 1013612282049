import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';

import { Project } from 'project/Project';

import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields';
import { ValidationDropDownValueList, ValidationInput } from '../../../Shared/Form/validation';
/**
 * ...
 * @exports TVADetails
 */
export default function TVADetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.TVA_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.SalesNetworkTVAUpdate,
        dataLoadUrl: ApiUrl.SalesNetworkTVALoad,
        deleteUrl: ApiUrl.SalesNetworkTVADelete,
        nameField: 'tva',
        backPage: AppPages.TVA,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.TVADetails),
        validationFields: ['pays', 'reseau_uuid', 'tva']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.TVA)}
            title={isNew ? <T>text.new_tva</T> : dataItem.tva}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.pays">
                                <ValidationDropDownValueList validation={validation} name='pays' valuelist="pays" value={dataItem.pays} onChange={e => onChangeData({ target: { name: 'pays', value: e } })} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.reseau">
                                <ValidationDropDownValueList validation={validation} name='reseau_uuid' valuelist="reseaux" value={dataItem.reseau_uuid} onChange={e => onChangeData({ target: { name: 'reseau_uuid', value: e } })} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.tva">
                                <ValidationInput validation={validation} name='tva' type='text' value={dataItem.tva || ''} onChange={onChangeData} />
                            </FormField>
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon><T>text.delete</T>
                                    </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
