import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { message } from 'antd';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { Ajax } from '~/components/Ajax';
import { T } from 'components/Translations';

import { FormField } from 'Shared/Form/formFields.js';
import { MultiSelectValueList } from 'Shared/DropDown';

import { closePopup } from '~/store/actions';

export default function FamilyProductDetails(props) {
    const { data, onDelete, updateSuccessCallback } = props;
    const [dataItem, setDataItem] = useState();
    const dispatch = useDispatch();

    const listUpdateEvent = EVENT_NAME.FAMILY_PRODUCT_LIST_UPDATE;

    useEffect(() => {
        setDataItem({...data});
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const _closePopup = useCallback(() => {
        dispatch(closePopup("changeFamilyCountry"));
    }, [dispatch]);

    const updateData = useCallback(() => {
        Ajax.post({
            url: ApiUrl.FamilyProductPayUpdate,
            data: {
                selectedProducts: [dataItem.uuid], // NOSONAR
                // selectedPays: dataItem.country_uuids, 
                selectedPaysGroup: dataItem.country_group_uuids,  
                reset: true
            },
            success: function (response) {
                if (!response.HasError) {
                    message.success(<T>message.data_has_been_successfully_updated</T>);
                    if (updateSuccessCallback) {
                        updateSuccessCallback(response);
                    } else {
                        dispatchCustomEvent(listUpdateEvent, { action: "update" });
                    }
                    _closePopup();
                } else {
                    message.error(response.message);
                }
            }
        })
    }, [dataItem, updateSuccessCallback, _closePopup, listUpdateEvent]);

    const onChangeCountryGroupCodes = group_uuids => {
        const dataItemCopy = {...dataItem};
        dataItemCopy.country_group_uuids = group_uuids;
        setDataItem(dataItemCopy);
    };

    return (<>
        {dataItem &&
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections'>
                            <FormField label="text.reference">
                                <input name='reference' type='text' defaultValue={dataItem.reference} disabled />
                            </FormField>
                           
                        </div>
                        <div className='sections'>
                            <FormField label="text.name">
                                <input name='traduction' type='text' defaultValue={dataItem.traduction} disabled />
                            </FormField>
                            
                        </div>
                    </div>
                    <div className='section_group'>
                        <div className='sections wide'>
                            <div className='form_fields'>
                                <div className='data_label'><T>text.country_groups</T></div>
                                <MultiSelectValueList 
                                    name='country_group_uuids'
                                    // {... (dataItem.pays_uuid ? { value: dataItem.pays_uuid } : {})} 
                                    {... (dataItem.country_group_uuids ? { value: dataItem.country_group_uuids } : {})} 
                                    valuelist='group_pays' 
                                    onChange={e => onChangeCountryGroupCodes(e)} // NOSONAR
                                    showSearch
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                />
                            </div>
                            {/* <div className='form_fields'>
                                <div className='data_label'><T>text.pays</T></div>
                                <MultiSelectValueList
                                    name='country_uuids' 
                                    {... (dataItem.country_uuids ? { value: dataItem.country_uuids } : {})} 
                                    valuelist='pays_uuids' 
                                    onChange={e => onChangeCountryCodes(e)}
                                    showSearch
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={updateData} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.famille_uuid &&
                                <div className='button' onClick={(e) => { 
                                    (onDelete && onDelete(dataItem, _closePopup))} }
                                >
                                    <icon>delete</icon><T>text.delete</T>
                                </div>
                            }
                        </action>
                    </wrap>
                </toolbar>
            </form>
        }
    </>);
}