import React from 'react';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { Project } from '../../../project/Project';

/**
 * ...
 * @exports APIAccess
 */
export default function APIAccess(props) {

    const columnConfig = [
        {
            text: 'text.description',
            field: 'api',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.apikey',
            field: 'key',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ApiKeyList}
            deleteUrl={ApiUrl.ApiKeyDelete}
            detailUrl={Project.getPageUrl(AppPages.APIAccessDetails)}
            filters={[
                { name: 'api', type: 'text', text: 'text.description' },
                { name: 'key', type: 'text', text: 'text.apikey' },
            ]}
            nameField='api'
            newButtonTip='text.create_new_api_access'
            listUpdateEvent={EVENT_NAME.API_ACCESS_TYPE_LIST_UPDATE}
            detailProps={{ newTitle: 'text.new_api_access', editTitle: 'text.edit_api_access'}}
            listName={AppPages.APIAccess}
            showRefreshButton={false}
        />
    );
}