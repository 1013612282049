import React from 'react';
import { useTranslation } from 'react-i18next';

import { ApiUrl } from '../../../project/Defines.js';
import { T } from '../../../components/Translations'
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';

/**
 * the Substitute Picker of SparePart in substitute tab
 * @exports SparePart-SubstituteSparePartPicker
 */
export default function SubstituteSparePartPicker(props) {
    const { t } = useTranslation("defines");

    const { updateData, ID } = props;
    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.name',
            field: 'designation',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.supplier_code',
            field: 'fournisseur',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
            width: t('width.action_select'),
            render: (text, record) =>
                <elastic>
                    <action hover="">
                        <div effect="material" className="button accent" onClick={() => { updateData(record); }}><text><T>text.select</T></text></div>
                    </action>
                </elastic>
        },
    ];

    return (<>
        <ListView
            {...props}
            isSimple
            skipNewButton
            staticFilter={{ excludeArticles: [ ID ] }}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ArticleList}
            search={{ field: 'search' }} />

    </>);
}
