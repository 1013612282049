import React, { useEffect, useState } from 'react';

import { ApiUrl } from '../../../project/Defines';
import { EVENT_NAME } from '../../../project/utilities';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import ListView from '../../../Shared/ListView';
import { PopupTypes, PopupClasses } from '../../../components/Popup';

/**
 * the product detail Accessories list tab
 * @exports Accessories
 */
export default function Accessories(props) {
    const { dataItem } = props;
    const [detailProps, setDetailProps] = useState({});
    const columnConfig = [

        {
            text: 'text.reference',
            field: 'reference',
            width: 120,
            type: COLUMN_TYPE.TEXT,
            readOnly: true
        },
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT,
            readOnly: true
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    useEffect(() => {
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.add_new_accessory',
            popupType: PopupTypes.AccessoryPicker,
            popupClassName: PopupClasses.Large,
            windowKey: 'pickAccessories',
            newData: {
                produit_uuid: dataItem.ID
            }
        });
    }, [dataItem && dataItem.ID]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ListView
            {...props}
            skipEdit
            search={{ field: 'search' }}
            isSimple
            noPaging
            staticFilter={{ produit_uuid: dataItem.ID}}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.AccessoryProductList}
            deleteUrl={ApiUrl.AccessoryProductDelete}
            deleteKeys={['produit_uuid', 'accessoire_uuid']}
            nameField='nom'
            newButtonTip='text.add_new_accessory'
            listUpdateEvent={EVENT_NAME.PRODUCT_ACCESSORIES_LIST_UPDATE}
            detailProps={detailProps} />
    );
}
