import React, { useCallback, useRef, useEffect, useState } from 'react';
import { message } from 'antd';

import { Ajax } from 'components/Ajax';
import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { T } from 'components/Translations';

/**
 * the ProductWarranty list of Family's ProductWarranty tab
 * @exports Universe-Family-ProductWarranty
 */
export default function ProductWarranty(props) {
    const { uuid } = props;
    const [checkeds, setCheckeds] = useState([]);
    const viewRef = useRef(null);

    useEffect(() => {
        viewRef.current = true;

        return () => { viewRef.current = false; }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const updateCustomerWarranty = useCallback(() => {
        Ajax.post({
            url: ApiUrl.FamilyProductWarrantyUpdate,
            data: {
                "famille_uuid": uuid, "selected": checkeds
            },
            success: function (response) {
                if (response && viewRef.current) {
                    if (!response.HasError) {
                        message.success(<T>message.data_has_been_successfully_updated</T>);
                        dispatchCustomEvent(EVENT_NAME.FAMILY_PRODUCT_WARRANTY_LIST_UPDATE, { action: "update" });
                        dispatchCustomEvent(EVENT_NAME.FAMILY_LIST_UPDATE, { action: 'refreshNode' });
                    } else {
                        message.error(response.message)
                    }
                }
            }
        })
    }, [uuid, checkeds]);

    const onClickRemplacable = useCallback((key) => {
        let data = [...checkeds];
        if (data.filter((r) => r === key).length > 0)
            data = checkeds.filter((r) => r !== key);
        else
            data.push(key);

        setCheckeds(data);
    }, [checkeds, setCheckeds])

    const columnConfig = [
        {
            text: 'text.defect',
            field: 'traduction',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.product_warranty',
            field: 'remplacable',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) =>
                <wrap><label classic=''><input type="checkbox"
                    disabled={record.disabled > 0}
                    checked={record.disabled > 0 || checkeds.filter((key) => key === record.key).length > 0}
                    onClick={() => { onClickRemplacable(record.key) }} onChange={()=>{ /**/ }} /><box><check></check></box></label></wrap>
            //    <wrap><FormField><DropDownValueList name='garantie_types' value={record.garantie_type_uuid} valuelist='garantie_types' onChange={(e) => updateCustomerWarranty(record, e || "")} allowClear /></FormField></wrap>
        },
    ];

    return (
        <>
            <ListView
                {...props}
                isSimple
                skipEdit
                skipNewButton
                noPaging
                columnConfig={columnConfig}
                apiUrl={ApiUrl.FamilyProductWarrantyList}
                staticFilter={{ famille_uuid: uuid }}
                listUpdateEvent={EVENT_NAME.FAMILY_PRODUCT_WARRANTY_LIST_UPDATE}
                onLoaded={(data, sorts) => { // NOSONAR

                    let filter = data.filter((r) => r.remplacable).map((r) => r.key);
                    setCheckeds(filter);
                    return data;
                }}
                rowClassName={record => { // NOSONAR
                    return record.disabled ? "disabled" : ""; // NOSONAR
                }}
            />
            <toolbar>
                <wrap>
                    <action right=''>
                        <div className="button" onClick={(e) => updateCustomerWarranty()} ><icon>save</icon><T>text.save</T></div>
                    </action>
                </wrap>
            </toolbar>
        </>
    );
};