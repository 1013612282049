import React from 'react';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { Project } from '../../../project/Project';

/**
 * ...
 * @exports TVA
 */
export default function TVA(props) {

    const columnConfig = [
        {
            text: 'text.pays',
            field: 'country_name',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.reseau',
            field: 'reseau_name',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.tva',
            field: 'tva',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.SalesNetworkTVAList}
            deleteUrl={ApiUrl.SalesNetworkTVADelete}
            detailUrl={Project.getPageUrl(AppPages.TVADetails)}
            filters={[
                { name: 'country_name', type: 'select', text: 'text.pays', valuelist: 'pays', autoComplete: "new-password", disableCach: true },
                { name: 'reseau_uuid', type: 'select', text: 'text.reseau', valuelist: 'reseaux', autoComplete: "new-password", disableCach: true },
                { name: 'created_at', type: 'date', text: 'text.created_at' },
                { name: 'updated_at', type: 'date', text: 'text.updated_at' }
            ]}
            newButtonTip='text.create_new_tva'
            listUpdateEvent={EVENT_NAME.TVA_LIST_UPDATE}
            detailProps={{ newTitle: 'text.new_tva', editTitle: 'text.edit_tva'}}
            listName={AppPages.TVA}
            showRefreshButton={false}
            nameField='country_name'
        />
    );
}