import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import store from 'store';
import { message } from 'antd';
import { useTranslation } from "react-i18next";
import { Ajax } from '../../../components/Ajax';
import { T } from '../../../components/Translations';
import { ApiUrl, StoreKeys } from '../../../project/Defines.js';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { EVENT_NAME, dispatchCustomEvent } from '../../../project/utilities';
import { closePopup } from 'store/actions';

/**
 * the popup to attach spareparts to a zone in the schema tab in BOM Detail
 * @exports BOM-Schema-SparePartLookup
 */
export default function SparePartLookup(props) {
    const dispatch = useDispatch();

    const [checkeds, setCheckeds] = useState([]);
    const [updating, setUpdating] = useState(false);
    const { t } = useTranslation();
    const columnConfig = [
        {
            text: 'text.letter',
            noSort: true,
            field: 'lettre',
            width: 60,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.reference',
            field: 'reference',
            width: 120,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.designation',
            field: 'designation',
            //width: 250,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.period',
            field: 'periode',
            width: 180,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.quantite',
            field: 'quantite',
            width: 80,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.commentaire',
            field: 'commentaire',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.complement',
            field: 'complement',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
    ];

    const excludeLetters = (props.excludeLetters) || (props.data.ArticleZones && props.data.ArticleZones.map((zone) => zone.lettre)) || [];

    const updateData = useCallback(() => {
        setUpdating(true);
        Ajax.post({
            url: ApiUrl.ZoneAddArticles,
            data: {
                zone_uuid: props.data.ID,
                selected_articles: checkeds
            },
            success: function (response) {
                setUpdating(false);
                if (!response.HasError) {
                    message.success(<T args={[response.AddedCount] || 'No'}>message.articles_were_added</T>);
                    dispatch(closePopup('lookupSpareParts'));

                    dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2, { action: "update" });
                    dispatchCustomEvent(EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE2, { action: "update" });

                } else {
                    message.error(response.message)
                }
            },
            error: function (response) {
                setUpdating(false);
                message.error(response.Message || <T args={[t('text.no')]}> message.accessories_were_added</T>)
            }
        })

    }, [checkeds, props.data.ID, dispatch, t]);

    return (<>
            <ListView
                search={{ field: 'search' }}
                isSimple
                skipEdit
                {...props}
                skipNewButton
                noPaging
                tableLayout="Fixed"
                rowSelection={{
                    selectedRowKeys: checkeds.map((r) => r.key),
                    onSelect: (record, selected, selectedRows, nativeEvent) => {
                        if (selected) {
                            setCheckeds(selectedRows.filter((r) => r.lettre !== record.lettre || (r.lettre === record.lettre && r.key === record.key)));
                        }
                        else {
                            setCheckeds(selectedRows);
                        }
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        let arr = [];
                        let arr_empty = [];
                        if (selected) {
                            selectedRows.forEach((r) => {
                                if (arr.filter((a) => a.lettre === r.lettre).length === 0) {
                                    arr.push(r);
                                }
                                if (arr_empty.filter((a) => a.lettre === r.lettre).length === 0) {
                                    if (r.lettre === "") {
                                        arr_empty.push(r);
                                    }
                                }
                            });
                            if (arr_empty.length > 0) {
                                setCheckeds(arr_empty);
                            } else {
                                setCheckeds(arr);
                            }
                        }
                        else {
                            setCheckeds(selectedRows);
                        }
                    },
                    getCheckboxProps: record => ({
                        disabled: checkeds.filter((r) => record.key !== r.key && (r.lettre === record.lettre || (record.lettre === '' && r.lettre !== '') || (record.lettre !== '' && r.lettre === ''))).length > 0,
                        // Column configuration not to be checked
                        name: record.key,
                    })
                }}
                onClick={(record) => { // NOSONAR
                }}
                onLoaded={(data, sorts) => { // NOSONAR
                    if (!sorts) {
                        data.sort((a, b) => a.designation > b.designation ? 1 : (a.designation < b.designation ? -1 : 0)); // NOSONAR
                        data.sort((a, b) => a.lettre > b.lettre ? 1 : (a.lettre < b.lettre ? -1 : 0)); // NOSONAR
                    }
                    if (checkeds && checkeds.length > 0) {
                        const keys = checkeds.map((r) => r.key);
                        let filter = data.filter((r) => keys.indexOf(r.key) === -1);
                        data = [...checkeds, ...filter];
                    }
                    return data;
                }}
                staticFilter={{ nomenclature_uuid: props.ID, lang: store.get(StoreKeys.Culture), excludeLetters: excludeLetters }}
                columnConfig={columnConfig}
                apiUrl={ApiUrl.ArticleBillOfMaterialList}
            />
        {
            <toolbar>
                <wrap>
                    <action right=''>
                        <div onClick={updateData} className='button' {...updating ? { disabled: 'disabled' } : {}}><icon>save</icon><T>text.add</T></div>
                    </action>
                </wrap>
            </toolbar>
        }
    </>);
}
