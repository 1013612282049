import React, { useEffect, useState } from 'react';
import { PopupClasses, PopupTypes } from '~/components/Popup';
import { ApiUrl } from '~/project/Defines';
import { EVENT_NAME } from '~/project/utilities';
import ListView from '~/Shared/ListView';
import { COLUMN_TYPE } from '~/Shared/ListView/helper';

export function SalesNetworkNatureCode(props) {
    const [detailProps, setDetailProps] = useState({});
    const {parentID} = props;

    const listUpdateEvent = EVENT_NAME.SALES_NETWORK_PICKER_LIST_UPDATE;

    useEffect(() => {
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.add_discount_cb',
            popupType: PopupTypes.SalesNetworkPicker,
            popupClassName: PopupClasses.Small,
            windowKey: 'pickSalesNetwork',
            newData: {
                data: {
                    nature_code: '',
                    code_client: '',
                    reseau_uuid: parentID
                }
            }
        })
    }, [parentID]);

    const columnConfig = [
        {
            text: 'text.nature_code',
            field: 'nature_code',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.code_client',
            field: 'code_client',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.reseau_code',
            field: 'reseau_code',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];


    return <>
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.SalesNetworkNatureCodeList}
            deleteUrl={ApiUrl.SalesNetworkNatureCodeDelete}
            staticFilter={{ reseau_uuid: parentID }}
            // deleteKeys={['nature_code', 'reseau_uuid']}
            nameField='nature_code'
            listUpdateEvent={listUpdateEvent}
            newButtonTip='text.create_new_discount_cb'
            detailProps={detailProps}
        />
    </>;
}