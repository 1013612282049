import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import { AppPages, ApiUrl } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Ajax } from '../components/Ajax';
import { connect } from 'react-redux';
import { login, clearListStates } from '../store/actions/index.js';
import { validate, ValidationInput } from '../Shared/Form/validation.js';
import { T } from '~/components/Translations.js';
import { showLoader, hideLoader } from '../Shared/Loader';

const validationFields = [
    { name: 'Email', rules: [{ type: 'required', message: 'text.fill_user_name' }] },
    { name: 'Password', rules: [{ type: 'required', message: 'text.fill_password' }] }
];

const loaderName = 'LOGIN_LOADER';

function getParams() {
    return new URLSearchParams(window.location.search);
}

/**
 * the Login Component where user authorization occurs
 * @exports Login
 */
function Login(props) {
    const { isLoggedIn, history } = props;
    const query = getParams();
    const redirectUrl = query.get("redirectUrl") || "";
    const ref = useRef(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);

    const [validation, setValidation] = useState({});
    const [allowValidation, setAllowValidation] = useState(true);
    const [windowFocused, setWindowFocused] = useState(false);
    const [loginButtonClicked, setLoginButtonClicked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);


    useEffect(() => {
        ref.current = true;
        let f = () => {
            setTimeout(() => {
                if (ref.current) {
                    setWindowFocused(true);
                }
            }, 300)
        }
        document.documentElement.addEventListener('click', f)
        return () => {
            ref.current = false;
            document.documentElement.removeEventListener('click', f);
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (windowFocused && loginButtonClicked) {
            doLogin();
        }
    }, [windowFocused, loginButtonClicked]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (allowValidation) {
            setValidation(validate({ data: getValidationData(), validationFields }));
        }
    }, [/*state,*/ email, password, remember, allowValidation])//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoggedIn) {
            if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                history.push(redirectUrl);
            }
            else {
                history.push(Project.getPageUrl(AppPages.Home));
            }

        }
    }, [isLoggedIn]);// eslint-disable-line react-hooks/exhaustive-deps

    const getValidationData = useCallback(() => {
        return {
            Email: email,
            Password: password,
            //PasswordShow: false,
            Remember: remember
        };
    }, [email, password, remember]);

    const doLogin = useCallback((e) => { // NOSONAR
        if (!loginButtonClicked) {
            setLoginButtonClicked(true);
            return;
        }
        const validationData = validate({ data: getValidationData(), validationFields });
        setValidation(validationData);
        if (validationData.isValid) {
            showLoader(loaderName);
            Ajax.post({
                url: ApiUrl.Login,
                data: {
                    UserName: email, //state.Email,
                    Password: password, //state.Password,
                    Remember: remember, //state.Remember,
                    ReturnUrl: redirectUrl
                },
                success: function (response) {
                    hideLoader(loaderName);
                    if (response && ((response.status && response.status === 200) || response.redirectUrl)) {
                        props.dispatch(clearListStates());
                        props.dispatch(login({ userToken: response.userToken, userName: response.userName, culture: response.culture, role: response.role, settings: response.settings || [] }));
                        if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                            history.push(redirectUrl);
                        }
                        else {
                            history.push(Project.getPageUrl(AppPages.Home));
                        }
                    } else {
                        if (response) {
                            message.error(response.title || <T>message.error_api</T>)
                        } else {
                            message.error(<T>message.error_api</T>)
                        }
                    }
                },
                error: function (response) {
                    hideLoader(loaderName);
                    if (response) {
                        message.error(response.message || <T>message.error_api</T>)
                    } else {
                        message.error(<T>message.error_api</T>)
                    }
                }
            })
        } else {
            setAllowValidation(true);
        }

    }, [/*state,*/ email, password, remember, getValidationData, props, loginButtonClicked, history, redirectUrl]);

    const submitForm = useCallback((event) => {
        if (event.key === "Enter") {
            doLogin()
        }
    }, [doLogin]);

    const onShowPassword = useCallback((event) => {
        setShowPassword(!showPassword);
    }, [showPassword]);

    if (!props.isLoggedIn) {
        return <>
            <view intro="">
                <login>
                    <cont>
                        <wrap id="container" multiple="multiple">
                            <info>
                                <text className="info"><b><T>text.sign_in</T></b></text>
                                <text className="info"><b><T>text.use_your_account</T></b></text>
                            </info>
                            <form defaultbutton="[command='login']" autoComplete="off">
                                <wrap space="">
                                    <div className="input_wrap">
                                        <div className="wrap">
                                            <ValidationInput
                                                validation={validation}
                                                name={'Email'}
                                                //value={state.Email || ''}
                                                value={email || ''}
                                                maxLength="50"
                                                placeholder=" "
                                                type="text"
                                                //onChange={setEmail}
                                                onChange={(event) => setEmail(event.target.value)} // NOSONAR
                                                onKeyPress={submitForm}
                                                //onBlur={setEmail}
                                                onBlur={(event) => setEmail(event.target.value)} // NOSONAR
                                            />
                                            <decor></decor>
                                            <name><T>text.username</T></name>
                                        </div>
                                        <info><message></message></info>
                                    </div>
                                    <div className="input_wrap">
                                        <div className="wrap">
                                            <ValidationInput
                                                validation={validation}
                                                name='Password'
                                                //value={state.Password || ''}
                                                value={password || ''}
                                                autoCapitalize="off"
                                                autoComplete="off"
                                                maxLength="50"
                                                placeholder=" "
                                                type={showPassword ? "text" : "password"}
                                                //onChange={setPassword}
                                                onChange={(event) => setPassword(event.target.value)} // NOSONAR
                                                onKeyPress={submitForm}
                                            />
                                            <decor></decor>
                                            <name><T>text.password</T></name>
                                            <div className="button shrink" effect="material" onClick={onShowPassword}><text><T>{showPassword ? "text.hide" : "text.show"} </T></text></div>

                                        </div>
                                        <info><message></message></info>
                                    </div>
                                </wrap>
                                <toolbar none="">
                                    <label className="checkbox" classic="">
                                        <input
                                            type="checkbox"
                                            name="Remember"
                                            //onChange={setRemember}
                                            onChange={(event) => setRemember(event.target.checked)}
                                        /><box><check></check></box><text><T>text.remember_me</T></text></label>
                                    <Link className="button link right" to="/"><T>text.forgot_password</T></Link>
                                </toolbar>
                                <toolbar none="">
                                    <button className="button primary large right" type="button" command="login" onClick={doLogin}><text><T>text.log_in</T></text></button>
                                </toolbar>
                            </form>
                        </wrap>
                    </cont>
                </login>
            </view>
        </>;
    } else {
        return <></>;
    }
};

export default connect(state => ({ isLoggedIn: state.isLoggedIn }), null)(Login);