import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { DatePicker } from 'Shared/DatePicker';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';
import Translation from 'Shared/Translation';

import { Project } from 'project/Project';

import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields';
import { ValidationDropDownValueList, ValidationInput } from '../../../Shared/Form/validation';
import { MultiSelectValueList } from 'Shared/DropDown';

/**
 * ...
 * @exports MaintenanceDetails
 */
export default function MaintenanceDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.MAINTENANCE_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.MaintenanceUpdate,
        dataLoadUrl: ApiUrl.MaintenanceLoad,
        deleteUrl: ApiUrl.MaintenanceDelete,
        nameField: 'nom',
        backPage: AppPages.Maintenance,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.MaintenanceDetails),
        validationFields: ['nom', 'portal']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.MAINTENANCE_TRANSLATIONS_UPDATE, { ID: response.ID });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);


    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.Maintenance)}
            title={isNew ? <T>text.new_maintenance</T> : dataItem.nom}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <div className="form_fields"><div className="section_title"><text><T>text.main</T></text></div><div className="form_separator"></div></div>
                            <FormField label="text.name">
                                <ValidationInput validation={validation} name='nom' maxLength="255" type='text' value={dataItem.nom} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.portal">
                                <ValidationDropDownValueList disableCach validation={validation} name='portal' type='text' valuelist='portals' value={dataItem.portal}
                                    onChange={e => onChangeData({ target: { name: 'portal', value: e } })} // NOSONAR
                                />
                            </FormField>
                            {
                                dataItem.portal === 'espace-sav' &&
                                <FormField label="text.sites">
                                    <MultiSelectValueList
                                        name="sites"
                                        valuelist="univers_sites"
                                        disableCach
                                        parentValue="group"
                                        {... (dataItem.sites ? { value: dataItem.sites } : { value: [] })}
                                            onChange={(e) => { onChangeData({ target: { name: "sites", value: e } }); }} // NOSONAR
                                        searchOptions={{
                                            noAccent: true,
                                            contains: true
                                        }}
                                    />
                                </FormField>
                            }
                            <FormField>
                                <label checkbox=''><input name='active' type='checkbox' checked={dataItem.active} onChange={onChangeData} /><box><check></check></box><text><T>text.active</T></text></label>
                            </FormField>
                        </div>
                        <div className='sections '>
                            <div className="form_fields"><div className="section_title"><text><T>text.date_time</T></text></div><div className="form_separator"></div></div>
                            <FormField label="text.date_begin">
                                <DatePicker dataField="date_start" dataItem={dataItem} setDataItem={setDataItem} showTime={true} />
                            </FormField>
                            <FormField label="text.date_end">
                                <DatePicker dataField="date_end" dataItem={dataItem} setDataItem={setDataItem} showTime={true} />
                            </FormField>
                        </div>
                        <div className='sections '>
                            <div className="form_fields"><div className="section_title"><text><T>text.login</T></text></div><div className="form_separator"></div></div>
                            <FormField>
                                <label checkbox=''><input name='disabled_login' type='checkbox' checked={dataItem.disabled_login} onChange={onChangeData} /><box><check></check></box><text><T>text.disabled</T></text></label>
                            </FormField>
                            <FormField label="text.date_begin">
                                <DatePicker dataField="disabled_login_start" dataItem={dataItem} setDataItem={setDataItem} showTime={true} />
                            </FormField>
                            <FormField label="text.date_end">
                                <DatePicker dataField="disabled_login_end" dataItem={dataItem} setDataItem={setDataItem} showTime={true} />
                            </FormField>
                        </div>
                    </div>
                    { !isNew &&
                    <Translation
                        ID={dataItem.ID}
                        fields={['title', 'message']}
                        settings={{
                            "message": { "maxLength": 255, "section": "wide"}
                        }}
                        showField={true}
                        _class='Maintenance'
                        updateEvent={EVENT_NAME.MAINTENANCE_TRANSLATIONS_UPDATE} />
                        
                    }
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon><T>text.delete</T>
                                    </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
