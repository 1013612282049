import React, { useCallback } from 'react';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from '../../../project/utilities';
import { Project } from '~/project/Project';
import { connect } from 'react-redux';

/**
 * ...
 * @exports Coverage
 */
function Coverage (props) {
    const { listState } = props;

    const getDetailUrl = useCallback((record, rowNumber, event, isNewRecord) => {
        if (!isNewRecord) {
            return Project.getPageUrl(AppPages.CoverageDetails, record.ID);
        } else {
            return Project.getPageUrl(AppPages.CoverageDetails, "create", { reseau_uuid: listState.appliedFilter.reseau_uuid });
        }
    }, [listState]);

    const columnConfig = [
        {
            text: 'text.business_unit',
            field: 'business_unit',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.template',
            field: 'template_code',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.coverage',
            field: 'coverage',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.value',
            field: 'coverage_value',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.CoverageList}
            deleteUrl={ApiUrl.CoverageDelete}
            getDetailUrl={getDetailUrl}
            //// detailUrl={Project.getPageUrl(AppPages.DefectCodeDetails)}
            filters={[
                { name: 'reseau_uuid', type: 'select', text: 'text.reseau', valuelist: 'reseaux' },
                { name: 'business_unit', type: 'text', text: 'text.business_unit' },
                { name: 'template_code', type: 'text', text: 'text.template' },
            ]}
            newButtonTip='text.create_new_coverage'
            listUpdateEvent={EVENT_NAME.COVERAGE_LIST_UPDATE}
            //// detailProps={{ newTitle: 'text.create_new_defect_code', editTitle: 'text.edit' }}
            listName={AppPages.Coverage}
            showRefreshButton={false}
            nameField='code'
            skipNewButton={!(listState && listState.appliedFilter && listState.appliedFilter.reseau_uuid)}
        />

    );
}

export default connect(state => ({ listState: state.listStates[AppPages.Coverage] }))(Coverage);