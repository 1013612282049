import React from "react";

export default function VideoContainer(props) {
    const { videoId, videoType, style } = props;

    let _src;

    switch (videoType) {
        case 'YouTube':
            _src = `https://www.youtube.com/embed/${videoId}`;
            break;
        case 'Vimeo':
            _src = `https://player.vimeo.com/video/${videoId}?dnt=true`;
            break;
        default:
            break;
    }
    

    return (
        <iframe
            src={_src}
            title={videoId}
            style={style}
            id={videoId}
        /> 
    )
}