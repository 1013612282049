import { T } from 'components/Translations';
import { ApiUrl } from 'project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';
import React, { useCallback, useEffect } from 'react';
import { FormField } from 'Shared/Form/formFields.js';
import useDetail from 'Shared/useDetail';
import DropDownValueList from '../../../Shared/DropDown';
import { ValidationInput } from '../../../Shared/Form/validation';

export default function SocietePostalCodeDetails(props) {
    const { windowKey, data, onDelete } = props;
    const listUpdateEvent = EVENT_NAME.SOCIETE_POSTAL_CODE_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, onDetailDelete, detailRef, validation } = useDetail({
        data: data,
        updateUrl: ApiUrl.SocietePostalCodeUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.SocietePostalCodeLoad,
        nameField: 'nom',
        deleteUrl: ApiUrl.SocietePostalCodeDelete,
        listUpdateEvent,
        onLoadData: (response) => {
            response.societe_uuid = data.societe_uuid;
            setDataItem(response);
            return true;
        },
        validationFields: ['postal_code']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }
        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, detailRef, listUpdateEvent, closePopup]);

    useEffect(() => {
        if (isNew) {
            dataItem.pays_uuid = props.data.parentData.dataItem.pays_uuid;
            setDataItem(dataItem);
        }
    }, [isNew]);// eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        {dataItem &&
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.pays">
                                <DropDownValueList
                                    name='pays_uuid'
                                    defaultValue={dataItem.pays_uuid}
                                    valuelist='pays_uuids'
                                    onChange={e => onChangeData({ target: { name: 'pays_uuid', value: e } })} // NOSONAR
                                    showSearch
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                />
                            </FormField>
                            <FormField label="text.postal_code">
                                <ValidationInput validation={validation} name='postal_code' type='text' value={dataItem.postal_code} maxLength="20" onChange={onChangeData} />
                            </FormField>
                        </div>
                    </div>
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                                {dataItem.ID &&
                                    <div className='button' onClick={(e) => {
                                        (onDelete && onDelete(dataItem, closePopup)) ||
                                            onDetailDelete(dataItem)
                                    }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>
                </div>
            </form>}
    </>);
}