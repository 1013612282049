import React from 'react';

export default function helpVideoPicker({menuItems}) {
    const videoTitle = () => {
        let title = '';  
        if (menuItems) {
            for (let i = 0; i < menuItems.length; i++) { // NOSONAR
                if (menuItems[i].help.video) {
                    title = menuItems[i].help.title.props.children.slice(5).toUpperCase();
                }
            }
        }
        return title;
    }

    return (
        <div className='form_container'>
            <div className='section_group'>
                <div className='sections wide'>
                    {/* <iframe src={`https:\/\/player.vimeo.com\/video\/7jjx9cH4pjw?autoplay=1&loop=1&muted=1`} width="660" height="344" /> */}
                    <iframe src={`https://www.youtube.com/embed/${'BlgYNapUeNc'}?dnt=true`} title={videoTitle()} type="video/mp4" width="660" height="344" />
                </div>
            </div>
        </div>
    )
};