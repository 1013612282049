import React from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { Project } from '../../../project/Project';

/**
 * Norms list of setup
 * @exports SetupNorms
 */
export default function SetupNorms(props) {

    const columnConfig = [
        {
            text: 'text.norms',
            field: 'libelle',
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.SetupNormDetails
        },
        {
            text: 'text.logo',
            field: 'logo',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.logo',
            key: 'logo',
            width: 160,
            noSort: true,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (
                <>
                    <wrap>
                        <div style={{
                            backgroundImage: "Url(" + record.image_url + ")",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            height: "35px"
                        }} />
                    </wrap>
                </>
            )
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.NormsList}
            deleteUrl={ApiUrl.NormsDelete}
            detailUrl={Project.getPageUrl(AppPages.SetupNormDetails)}
            filters={[{ name: 'libelle', type: 'text', text: 'text.norms' }]}
            newButtonTip='text.create_new_standard'
            listUpdateEvent={EVENT_NAME.SETUP_NORMS_LIST_UPDATE}
            detailProps={{ newTitle: 'text.create_new_standard', editTitle: 'text.edit_norm' }}
            nameField='libelle'
            showRefreshButton={false}
            listName={AppPages.SetupNormes} />

    );
}
