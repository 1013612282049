import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { TabStrip } from 'Shared/TabStrip';
import { T } from 'components/Translations';
import { Project } from 'project/Project';
import Translation from 'Shared/Translation';
import CustomUpload from 'Shared/CustomUpload';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationInput } from '../../Shared/Form/validation';
import DropDownValueList from '../../Shared/DropDown';

/**
 * the BrandDetails and main data of Brand
 * @exports BrandDetails
 */
export default function BrandDetails(props) {
    const { id } = useParams();
    const { windowKey, data, onDelete } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const listUpdateEvent = EVENT_NAME.BRAND_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, onDetailDelete, detailRef, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.BrandUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.BrandLoad,
        nameField: 'nom',
        deleteUrl: ApiUrl.BrandsDelete,
        backPage: AppPages.Brand,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.BrandDetails),
        validationFields: ['nom']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.BRAND_TRANSLATIONS_UPDATE, { ID: response.ID });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.Brand)}
            title={isNew ? <T>text.new_brand</T> : dataItem.nom}
        >
            <form>
                <div className='form_container'>
                    <TabStrip
                        onChange={(selected) => setTabIndex(selected)} // NOSONAR
                        skipSecondaryTabs={isNew}> 
                        <div title='text.main' primary>
                            <div className='section_group'>
                                <div className='sections '>
                                    <FormField label="text.name">
                                        <ValidationInput validation={validation} name='nom' maxLength="240" type='text' value={dataItem.nom} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.code">
                                        <input name='code' maxLength="240" type='text' value={dataItem.code} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.logo">
                                        <CustomUpload
                                            imageUrl='image_url'
                                            imageField='images'
                                            dataItem={dataItem}
                                            setDataItem={setDataItem}
                                            type={'marques'}
                                        />
                                    </FormField>
                                    <FormField label="text.defaut_universe">
                                        <DropDownValueList
                                            name="defaut_univers_uuid"
                                            valuelist="Univers"
                                            value={dataItem.defaut_univers_uuid}
                                            onChange={(e) => { onChangeData({ target: { name: "defaut_univers_uuid", value: e } }); }} // NOSONAR
                                            searchOptions={{
                                                noAccent: true,
                                                contains: true
                                            }}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </div>
                        <div title='text.translation'>
                            <Translation
                                ID={dataItem.ID}
                                fields={['libelle']}
                                _class='App\Models\Marque'
                                updateEvent={EVENT_NAME.BRAND_TRANSLATIONS_UPDATE} />
                        </div>
                    </TabStrip>
                </div>
                {tabIndex < 2 &&
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                                {dataItem.ID &&
                                    <div className='button' onClick={(e) => {
                                        (onDelete && onDelete(dataItem, closePopup)) ||
                                            onDetailDelete(dataItem)
                                    }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>}
            </form>
        </DetailView>}
    </>);
}
