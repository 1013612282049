import React, { useCallback, useRef, useEffect, useState } from 'react';

import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';

import { openPopup, closePopup } from '../../../store/actions';
import { T, DataTip } from '../../../components/Translations';
import { PopupTypes } from '../../../components/Popup';
import { connect } from 'react-redux';
import { FormField } from '../../../Shared/Form/formFields';
import { AppPages } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { Link } from 'react-router-dom';

/**
 * the Product BOM tab with picking
 * @exports ProductBOM
 */
function ProductBOM(props) {
    const windowKey = 'wndProductBOMPicker';
    const { dataItem, setDataItem, updateData, detailRef, openPopup, closePopup } = props;
    const [isLetterEditing, setIsLetterEditing] = useState(false);
    const [letterCurrentValue, setLetterCurrentValue] = useState('');
    const productBOMRef = useRef(null)

    useEffect(() => {
        productBOMRef.current = true;

        return () => {
            productBOMRef.current = false;
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem({ ...dataItem, lettre: response.lettre, nomenclature_reference: response.nomenclature_reference, nomenclature_uuid: response.nomenclature_uuid });
            if (productBOMRef.current) {
                setIsLetterEditing(false);
            }
            closePopup(windowKey)
        }
        dispatchCustomEvent(EVENT_NAME.PRODUCT_BILL_OF_MATERIAL_LIST_UPDATE, { action: 'update' });
    }, [setDataItem, dataItem, detailRef, closePopup]);

    useEffect(() => {
        setLetterCurrentValue(dataItem.lettre); 
    }, [dataItem.lettre]);

    const onLetterChange = e => {
        setLetterCurrentValue(e.target.value); 
    };

    const openPicker = useCallback(() => {
        openPopup({
            windowKey,
            fullScreen: false,
            title: <T>text.select</T>,
            type: PopupTypes.ProductBOMPicker,
            bodyProps: {
                updateData: (record) => { updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, nomenclature_uuid: record.uuid, nomenclature_reference: record.reference } }) }
            }
        });
    }, [updateData, openPopup, updateSuccessCallback, dataItem.ID]);

    return (<>
        <div className='form_container'>
            <div className='section_group'>
                <div className='sections '>
                    <FormField label="text.letter">
                        <div className='input_cont'>
                            {isLetterEditing ?
                                <>
                                    <input name='letter' type='text' maxLength="1" value={letterCurrentValue} onChange={onLetterChange} />
                                    <DataTip title={'text.save'}> <div className='button micro' onClick={() => { updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, lettre: letterCurrentValue } }) }}><icon>done</icon></div></DataTip>
                                    <DataTip title={'text.cancel'}><div className='button micro' onClick={e => setIsLetterEditing(false)}><icon >cancel</icon></div></DataTip>

                                </> :
                                <><input type='text' readOnly value={dataItem.lettre} />   <DataTip title={'text.edit'}><div className='button micro' type='button' onClick={e => { setIsLetterEditing(true); setLetterCurrentValue(dataItem.lettre); }}><icon >edit</icon></div></DataTip></>}
                            <i></i>
                        </div>
                    </FormField>
                    <FormField label="text.bill_of_material">
                        <div className='input_cont'>
                            {!dataItem.nomenclature_uuid ? <input type='text' readOnly value={dataItem.nomenclature_reference} /> :
                                <Link to={Project.getPageUrl(AppPages.BillOfMaterialDetails, dataItem.nomenclature_uuid, { backUrl: Project.getPageUrl(AppPages.ProductDetails, dataItem.ID, { tab: 2 }) })}
                                    style={{ color: "var(--main-color)", width: '100%', marginLeft: '5px' }}>
                                    <text>{dataItem.nomenclature_reference}</text>
                                </Link>}
                            <DataTip title={'text.edit'}><div className='button micro' onClick={openPicker} ><icon >edit</icon></div></DataTip>
                            {dataItem.nomenclature_reference && <DataTip title={'text.remove'}><div className='button micro' onClick={() => updateData(updateSuccessCallback, null, { overrideData: { ID: dataItem.ID, nomenclature_uuid: null, nomenclature_reference: null } })} ><icon >remove</icon></div></DataTip>}
                            <i></i>
                        </div>
                    </FormField>
                </div>
            </div>
        </div>
    </>);
}

export default connect(null, (dispatch) => ({
    openPopup: (data) => dispatch(openPopup(data)),
    closePopup: (windowKey) => dispatch(closePopup(windowKey))
}))(ProductBOM);