import React from 'react';
import { useParams } from 'react-router-dom';

import PanierSuccess from './PanierSuccess';
import PanierError from './PanierError';

import Logo from '../Logo/Logo.js';

export default function PanierMessage() {
    const { message} = useParams();
    return <>
        <view>
            <Logo />
            {message === 'success' ? <PanierSuccess /> : <PanierError />}
        </view>
    </>
}