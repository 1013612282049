import { T } from 'components/Translations';
import { ApiUrl } from 'project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';
import React, { useCallback } from 'react';
import { FormField } from 'Shared/Form/formFields.js';
import useDetail from 'Shared/useDetail';
import { ValidationDropDownValueList, ValidationTagsValueList } from '../../../Shared/Form/validation';

export default function SocieteSkillDetails(props) {
    const { windowKey, data } = props;

    const listUpdateEvent = EVENT_NAME.SOCIETE_SKILL_LIST_UPDATE;
    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data, // || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.SocieteSkillUpdate,
        dataLoadUrl: ApiUrl.SocieteSkillLoad,
        deleteUrl: ApiUrl.SocieteSkillDelete,
        nameField: 'nom',
        //backPage: AppPages.UserDetails,
        listUpdateEvent,
        onLoadData: (response) => {
            response.societe_uuid = data.societe_uuid;
            if (!response.pays_uuid) {
                response.pays_uuid = data.pays_uuid;
            }

            setDataItem(response);
            return true;
        },
        validationFields: ['skill_uuid', 'pays_uuid', 'postal_codes']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
    }, [setDataItem, detailRef, listUpdateEvent]);

    return (<>
        {dataItem &&
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections'>
                            <FormField label="text.name">
                                {/*<input name='traduction' type='text' defaultValue={dataItem.nom} readOnly={!isNew} />*/}

                                <ValidationDropDownValueList
                                    validation={validation}
                                    readOnly={!isNew}
                                    disabled={!isNew}
                                    allowClear
                                    showSearch
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                    disableCach
                                    additionalData={data.societe_uuid}
                                    name='skill_uuid'
                                    valuelist="societe_skills"
                                    value={dataItem.skill_uuid} onChange={e => onChangeData({ target: { name: 'skill_uuid', value: e } })} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.pays">
                                <ValidationDropDownValueList
                                    validation={validation}
                                    readOnly={!isNew}
                                    disabled={!isNew}
                                    allowClear
                                    showSearch
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                    name='pays_uuid'
                                    valuelist="pays_uuids"
                                    value={dataItem.pays_uuid} onChange={e => onChangeData({ target: { name: 'pays_uuid', value: e } })} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.postal_code">

                                <ValidationTagsValueList
                                    validation={validation}
                                    name='postal_codes'
                                    value={dataItem.postal_codes} onChange={e => onChangeData({ target: { name: 'postal_codes', value: e } })} // NOSONAR
                                />

                                {/*<Select*/}
                                {/*mode="tags"*/}
                                {/*style={{ width: '100%' }}*/}
                                {/*value={dataItem.postal_codes}*/}
                                {/*onChange={e => onChangeData({ target: { name: 'postal_codes', value: e } })}>*/}
                                {/*</Select>*/}
                            </FormField>
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div
                                onClick={() => updateData(updateSuccessCallback)}
                                className='button'
                            ><icon>save</icon><T>text.save</T></div>
                            {dataItem.societe_uuid && !isNew &&
                                <div className="button" onClick={e => { onDetailDelete(dataItem, closePopup) }}><icon>delete</icon><T>text.delete</T></div>
                            }
                        </action>
                    </wrap>
                </toolbar>
            </form>
        }
    </>);
}