import React from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { Project } from '../../../project/Project';
import VideoContainer from '../../../Shared/Video';

/**
 * video list of setup
 * @exports SetupVideos
 */
export default function SetupVideos(props) {
    const columnConfig = [
        {
            text: 'text.title',
            field: 'title',
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.SetupVideoDetails
        },
        {
            text: 'text.tag_de_droit',
            field: 'tag',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.tag_de_type',
            field: 'tag_types',
            render: (text, record) => {
                if (text) {
                    return <group style={{ padding: 10 }}>
                        {
                            text.map((type, index) => {
                                return (
                                    <badge filled="" accent="" button="" key={index} // NOSONAR
                                    >
                                        <text>{type}</text>
                                    </badge>
                                )
                            })
                        }
                    </group>
                }
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.video_id',
            field: 'video_id',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                return <VideoContainer
                            videoType={record.video_type}
                            videoId={record.video_id}
                            />
            }
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.VideosVimeoList}
            deleteUrl={ApiUrl.VideosVimeoDelete}
            detailUrl={Project.getPageUrl(AppPages.SetupVideoDetails)}
            filters={[{ name: 'title', type: 'text', text: 'text.title' }, { name: 'video_id', type: 'text', text: 'text.video_id' }]}
            newButtonTip='text.create_new_video'
            listUpdateEvent={EVENT_NAME.SETUP_VIDEOS_VIMEO_LIST_UPDATE}
            detailProps={{ newTitle: 'text.create_new_video', editTitle: 'text.edit_video' }}
            nameField='title'
            showRefreshButton={false}
            listName={AppPages.SetupVideo}
        />
    );
}
