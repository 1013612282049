import React, { useCallback, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Dropdown, message } from 'antd';

import ListView from 'Shared/ListView';
import DropdownTree from 'Shared/DropdownTree';
import { FormField } from '~/Shared/Form/formFields';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { Project } from 'project/Project.js';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';
import { ApiUrl, AppPages, UserRoles } from 'project/Defines.js';

import { T } from '~/components/Translations';
import { Ajax } from '~/components/Ajax';
import { PopupClasses, PopupTypes } from 'components/Popup.js';

import { openPopup } from '~/store/actions';

/**
 * the Products list of Products's Infos tab
 * @exports Universe-Family-Products
 */
const Products = (props) => {
    const dispatch = useDispatch();
    const [checkeds, setCheckeds] = useState([]);
    const [dataKeys, setDataKeys] = useState([]);
    const [selectedPays, setSelectedPays] = useState([]);
    const [loading, setLoading] = useState();
    const [isDeleteMode, setIsDeleteteMode] = useState(false);
    const initialCountryCount = 5;
    const { uuid, role } = props;

    const listUpdateEvent = EVENT_NAME.FAMILY_PRODUCT_LIST_UPDATE;

    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            width: 100,
            type: COLUMN_TYPE.TEXT,
            onRenderLink: ![UserRoles.DISPATCHER].includes(role) ? (record) => Project.getPageUrl(AppPages.ProductDetails, record.produit_uuid) : ""
        },
        {
            text: 'text.name',
            field: 'traduction',
            width: 240,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.country_groups',
            field: 'country_groups',
            render: (text, record) => {
                if (text) {
                    const _text = text.map(t => JSON.parse(t));

                    return _text.map((countryGroup, index) =>
                        <div className="input_cont badge_wrap" key={index} // NOSONAR
                        >
                            <text><b>{countryGroup.slug}</b></text>
                            <group line="">
                                {
                                    (countryGroup.country_name ?? '').split(',').slice(0, initialCountryCount).map((country, i) => {
                                        return (
                                            <badge filled="" accent="" button="" key={i} // NOSONAR
                                            >
                                                <text>{country}</text>
                                            </badge>
                                        )
                                    })
                                }
                            </group>
                            {
                                ((countryGroup.country_name ?? '').split(',').length > initialCountryCount) && <ellipsis>
                                    <Dropdown
                                        className=""
                                        trigger={['click']}
                                        overlay={<div className="list_menu ant-dropdown-menu">
                                            <ul>
                                                {(countryGroup.country_name ?? '').split(',').slice(initialCountryCount).map((item, i) => {
                                                    return (
                                                        <li key={i} // NOSONAR
                                                        ><icon></icon><text>{item}</text></li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        }
                                        overlayStyle={{ maxHeight: 250, overflow: 'auto' }}
                                        // align={{offset: [5, 2], overflow: {adjustX: true}}}
                                        placement="topRight"
                                    >
                                        <div effect="material" className="">
                                            <text><counter>{(countryGroup.country_name ?? '').split(',').length - initialCountryCount}</counter>More...</text>
                                        </div>
                                    </Dropdown>
                                </ellipsis>
                            }
                        </div>
                    )
                }
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    const onApply = useCallback(() => {
        const data = {
            selectedProducts: dataKeys,
            // selectedPays:  selectedPays.filter((el) => { return el[0] === 'p' }),
            selectedPaysGroup: selectedPays.filter((el) => { return el[0] === 'g' }),
            isDeleteMode
        };

        setLoading(true);

        Ajax.post({
            url: ApiUrl.FamilyProductPayUpdate,
            data: data,
            success: function (response) {
                if (!response.HasError) {
                    message.success(<T>message.data_has_been_successfully_updated</T>);
                    setCheckeds([]);
                    setDataKeys([]);
                    dispatchCustomEvent(EVENT_NAME.FAMILY_PRODUCT_LIST_UPDATE, { action: "refresh" });
                    setLoading(false);
                    setSelectedPays([]);
                } else {
                    message.error(<T>message.error_api</T>);
                }
            }
        })
    }, [selectedPays, dataKeys, isDeleteMode]);

    const onSelectPay = (e) => {
        setSelectedPays(e);
    };

    const isDisabled = () => {
        return loading || checkeds.length === 0 || selectedPays.length === 0;
    };

    const onNewFamilyProduct = useCallback(() => {
        dispatch(
            openPopup({
                // windowKey: "lookupFamilyProduct",
                fullScreen: false,
                title: <T>text.add_new_product</T>,
                type: PopupTypes.FamilyProductPickup,
                bodyProps: {
                    ...{ data: { ID: props.ID, selected: '' } },
                    windowKey: "lookupFamilyProduct"
                }
            })
        );

    }, [props.ID, dispatch]);

    return (
        <>
            <toolbar highlight="highlight" scroll="scroll">
                <wrap>
                    <action>
                        <wrap>
                            <label checkbox=""><input checked={isDeleteMode} type="checkbox" onChange={(e) => { setIsDeleteteMode(e.target.checked) }} /><box><check></check></box><text><T>text.delete_mode</T></text></label>
                            <separator vertical="vertical"></separator>
                            <div className='form_container'>
                                <div className='sections_group'>
                                    <div className='sections'>

                                        <FormField formFieldClass="line large" label='text.country_groups'>
                                            <DropdownTree
                                                name="group_pays"
                                                valuelist="group_pays"
                                                disabled={loading}
                                                onChange={onSelectPay} // NOSONAR
                                                placeholder="Please select"
                                                allowClear
                                                showSearch
                                                disableCach={true}
                                                searchTreeNodeOption={{
                                                    noAccent: true,
                                                    contains: true
                                                }}
                                                treeCheckable={true}
                                                dropdownStyle={{ minWidth: 300 }}
                                                value={selectedPays}
                                            />
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                            <separator vertical="vertical"></separator>
                            <div
                                className="button primary"
                                effect="material"
                                disabled={isDisabled()}
                                onClick={onApply}
                            >
                                <text><T>text.apply</T></text>
                            </div>
                        </wrap>
                    </action>
                </wrap>
            </toolbar>
            <view light="light">
                <ListView
                    {...props}
                    loading={loading}
                    isSimple
                    // skipEdit
                    noPaging
                    // scroll={{x: 1000}}
                    columnConfig={columnConfig}
                    apiUrl={ApiUrl.FamilyProductList}
                    deleteUrl={ApiUrl.FamilyProductDelete}
                    deleteKeys={["famille_uuid", "produit_uuid"]}
                    nameField="reference"
                    staticFilter={{ famille_uuid: uuid }}
                    newButtonTip='text.add_new_product'
                    onNewClick={onNewFamilyProduct}
                    listUpdateEvent={listUpdateEvent}
                    listName={AppPages.Products}
                    detailProps={{
                        fullScreen: false,
                        newTitle: 'text.add_new_product',
                        editTitle: 'text.edit_product_country',
                        popupType: PopupTypes.FamilyProductDetails,
                        popupClassName: PopupClasses.Medium,
                        windowKey: 'changeFamilyCountry',
                        newData: { ID: uuid }
                    }}
                    rowSelection={{
                        columnWidth: "50px",
                        selectedRowKeys: checkeds,
                        onSelect: (record, selected) => {
                            let arr = [...checkeds];
                            let dataArr = [...dataKeys];
                            if (selected) {
                                arr.push(record.key);
                                dataArr.push(record.produit_uuid);
                                setCheckeds(arr);
                                setDataKeys(dataArr);
                            }
                            else {
                                let index = arr.findIndex(item => item === record.key);
                                if (index !== -1) {
                                    arr.splice(index, 1)
                                    setCheckeds(arr);
                                }
                            }
                        },
                        onSelectAll: (allSelected, selectedRows, changeRows) => {
                            if (allSelected) {
                                setCheckeds(selectedRows.map(item => item.key));
                                setDataKeys(selectedRows.map(item => item.produit_uuid));
                            } else {
                                setCheckeds([]);
                                setDataKeys([]);
                            }
                        },
                    }}
                />
            </view>
        </>
    );
};

export default connect(state => ({
    role: state.role
}),
    dispatch => ({ openPopup: (data) => dispatch(openPopup(data)) }))(Products);