import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Tooltip } from "antd";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ReactDOM from 'react-dom';
import { ValidationInput } from 'Shared/Form/validation';
import { validate } from 'Shared/Form/validation.js';
import { T } from '~/components/Translations';
import { deepCopy } from '../../project/utilities';

const loadingIcon = <LoadingOutlined spin />;

const Tree = (props) => {
    const [treeData, setTreeData] = useState([]);
    const [_expandedKeys, setExpandedKeys] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemKey, setSelectedItemKey] = useState(null);

    const [loadedKeys, setloadedKeys] = useState([]);
    const [loadingItemKey, setLoadingItemKey] = useState('');

    const [addingItem, setAddingItem] = useState(false);
    const [allowChanging, setAllowChanging] = useState(true);
    const [draggableNode, setDraggableNode] = useState(null);
    const [timeOut, _setTimeOut] = useState(null);
    const container = useRef(null);

    const {
        data,
        field,
        parentKeyField,
        expandedKeys,
        selectedKeys,
        selectable,
        editable,
        loadData,
        onDrop,
        onSelect,
        onUpdate,
        onAddNew,
        onDelete,
        hide,
        hideTooltip,
        moveTreeNode,
        clear,
        newButtonTip,
        disabled,
        listCustomEvent
    } = props;

    const [showTooltip] = useState(!hideTooltip);

    const getChildrenKeys = useCallback((_dataItem) => {
        let arr = [];
        if(_dataItem.children) {
            _dataItem.children.forEach((item) => {
                arr.push(item.key);
                arr = arr.concat(getChildrenKeys(item))
            })
        }

        return arr;
    },[])

    const loop = useCallback((_data, key, callback) => {
        _data.forEach((item, index, arr) => {
            if (key && item.key === key) {
                return callback(item, index, arr);
            }
            if (!key) {
                callback(item, index, arr);
            }
            if (item.children) {
                return loop(item.children, key, callback);
            }
        });
    }, []);

    const getPerents = useCallback((_dataItem) => {
        let arr = [];
        if(_dataItem && _dataItem[parentKeyField]) {
            let item
            loop(treeData, _dataItem[parentKeyField], (_item) => {
                item = _item;
            })
            arr.push(item);
            arr = arr.concat(getPerents(item))
        }

        return arr;
    },[treeData, loop, parentKeyField])

    const _onSelect = useCallback((_dataItem) => {
        setSelectedItem(_dataItem);

        if(_dataItem) {
            setSelectedItemKey(_dataItem.key);
        } else {
            setSelectedItemKey('');
        }

        onSelect && onSelect(_dataItem);
    }, [onSelect]);

    const _loadData = useCallback((_dataItem, callback) => {
        if(loadedKeys.indexOf(_dataItem.key) === -1) {
            setLoadingItemKey(_dataItem.key);

            loadData && loadData(_dataItem, (isLoaded) => {
                isLoaded && setLoadingItemKey("");
            }, callback);

            setloadedKeys([...loadedKeys, _dataItem.key])


        }
    }, [loadData, loadedKeys])

    const _onExpand = useCallback((_dataItem) => {
        let newKeys = [];

        if(_expandedKeys.indexOf(_dataItem.key) === -1) {
            _loadData(_dataItem);
            newKeys = [..._expandedKeys, _dataItem.key];
        } else {
            let _keys = getChildrenKeys(_dataItem);
            _keys.push(_dataItem.key)
            newKeys = _expandedKeys.filter((k)=> _keys.indexOf(k) === -1);
        }

        if(newKeys?.length) {
            setExpandedKeys(newKeys);
        }

    },[_expandedKeys, getChildrenKeys,_loadData])

    const _onAddNew = () => {
        if(allowChanging) {
            setAllowChanging(false)
            selectedItem && _expandedKeys.indexOf(selectedItem.key) === -1 && _onExpand(selectedItem);
            setAddingItem(true);
        }
    };

    const _acceptNewItem = (_paretItem, value) => {
        setAllowChanging(true);
        onAddNew && onAddNew(_paretItem, value, ((newDataItem, additionalChanges) => {
            let _treeData = [...treeData];
            if (_paretItem) {

                let parentItem;
                loop(_treeData, _paretItem.key, (item, index, arr) => {
                    parentItem = item;
                });

                if (!parentItem.children)
                    parentItem.children = [];
                parentItem.children.push(newDataItem);

                additionalChanges && additionalChanges(parentItem, (changedItem) => {
                    parentItem = changedItem;
                })
            } else {
                _treeData.push(newDataItem);
            }

            return _treeData;
            //setTreeData(_treeData)
        }))

        setAddingItem(false);
    }

    const _cancelAddNew = useCallback(() => {
        setAllowChanging(true);
        setAddingItem(false);
    }, []);

    const _onDelete = (_dataItem) => {
        if (selectedItem && selectedItem.key === _dataItem.key) {
            setSelectedItem(null);
        }

        onDelete && onDelete(_dataItem);
        setloadedKeys(loadedKeys.filter((_key) => _key !== _dataItem.key));
    };

    const _onUpdate = (newDataItem) => {
        onUpdate && onUpdate(newDataItem, (updatedDataItem, additionalChanges) => {
            let _treeData = [...treeData];
            let item;
            loop(_treeData, updatedDataItem.key, (_item, index, arr) => {
                item = _item;
            });
            Object.assign(item, updatedDataItem);

            additionalChanges && additionalChanges(item, (changedItem) => {
                item = changedItem;
            })
            return _treeData;
            //setTreeData(_treeData)
        })
    }

    const _onDragStart = useCallback((_dataItem) => {
        setDraggableNode(_dataItem);
        _expandedKeys.indexOf(_dataItem.key) !== -1 && _onExpand(_dataItem);
    }, [_expandedKeys, _onExpand ])

    const _onDragOver = useCallback((ev, _dataItem) => {
        ev.preventDefault();

        const element = ev.currentTarget;
        const offsetHeight = element.offsetHeight;
        const offsetY = ev.nativeEvent.offsetY;

        if(offsetY > offsetHeight * 2 / 3) {
            if(timeOut) { clearTimeout(timeOut);  _setTimeOut(null) }
            element.classList.add("border-bottom")

            element.classList.remove("background-clr")
            element.classList.remove("border-top")
        } else if(offsetY < offsetHeight / 3) {
            if(timeOut) { clearTimeout(timeOut);  _setTimeOut(null) }
            element.classList.add("border-top")

            element.classList.remove("border-bottom")
            element.classList.remove("background-clr")
        } else {
            element.classList.add("background-clr")

            element.classList.remove("border-top")
            element.classList.remove("border-bottom")
            if(!timeOut) {
                if(_expandedKeys.indexOf(_dataItem.key) === -1) {
                    _setTimeOut(setTimeout(() => {
                        _onExpand(_dataItem);
                    }, 1500))
                }
            }
        }
    }, [_expandedKeys, _onExpand, timeOut])

    const _onDragLeave = useCallback((ev, _dataItem) => {
        ev.currentTarget.className = "node-text";
        if(timeOut) { clearTimeout(timeOut);  _setTimeOut(null) }
    }, [timeOut])

    const _onDrop = useCallback((ev, _dataItem) => { // NOSONAR
        ev.currentTarget.className = "node-text"
        if(timeOut) { clearTimeout(timeOut);  _setTimeOut(null) }

        const offsetHeight = ev.currentTarget.offsetHeight;
        const offsetY = ev.nativeEvent.offsetY;

        const dragNode = deepCopy(draggableNode);
        const dropNode = deepCopy(_dataItem);

        if(dragNode.key === dropNode.key)
            return;

        const onItemTop = offsetY < offsetHeight / 3;
        const onItemBottom = offsetY > offsetHeight * 2 / 3;
        const onItem = !onItemTop && !onItemBottom;

        let dropNodeParentKey = dropNode[parentKeyField];
        let dropNodeParentNode;


        const dragNodeParentsNames = getPerents(dragNode).map((node) => node[field]).reverse();
        const dragNodePath = dragNodeParentsNames.join(">");

        let newTreeData = deepCopy(treeData);
        let dropNodeIndex;
        let dragNodePos;
        let loadingChild = false;
        if(onItem) {
                dropNodeParentKey = dragNode[parentKeyField] = dropNode.key;
                dropNodeParentNode = dropNode;

        } else if(dropNodeParentKey) {
            loop(newTreeData, dropNodeParentKey, (item, index, arr) => {
                dropNodeParentNode = item;
            })

            for(dropNodeIndex = 0; dropNodeIndex < dropNodeParentNode.children.length; dropNodeIndex++) {
                if(dropNodeParentNode.children[dropNodeIndex].key === dropNode.key) break;
            }

            if(onItemBottom) {
                dragNodePos = dropNodeIndex + 1;
            } else if(onItemTop) {
                dragNodePos = dropNodeIndex;
            }

            loop(newTreeData, dragNode.key, (item, _index, arr) => {
                arr.splice(_index, 1);
            });

            dragNode[parentKeyField] = dropNodeParentKey;
            dropNodeParentNode.children.splice(dragNodePos, 0, dragNode);

        } else {
            for(dropNodeIndex =0; dropNodeIndex < newTreeData.length; dropNodeIndex++) {
                if(newTreeData[dropNodeIndex].key === dropNode.key) break;
            }

            if(onItemBottom) {
                dragNodePos = dropNodeIndex + 1;
            } else if(onItemTop) {
                dragNodePos = dropNodeIndex;
            }

            loop(newTreeData, dragNode.key, (item, _index, arr) => {
                arr.splice(_index, 1);
            });

            dragNode[parentKeyField] = dropNodeParentKey
            newTreeData.splice(dragNodePos, 0, dragNode)
        }

        const dropNodeParents = getPerents(dropNode).map((node) => node[field]).reverse();//.push(dropNodeParentNode[field]).join(">");
        onItem &&  dropNodeParents.push(dropNodeParentNode[field]);
        const dropNodePath = dropNodeParents.join(">");

        let message = `Do you watn to move the ${dragNode[field]} from ${dragNodePath ? dragNodePath : "root"} to ${dropNodePath ? dropNodePath : "root"}?`

        if(onItem) {
            loop(newTreeData, dragNode.key, (item, _index, arr) => {
                arr.splice(_index, 1);
            });

            let item;
            loop(newTreeData, dropNode.key, (_item, _index, arr) => {
                item = _item;
            });

            if(dropNode.children) {
                item.children.push(dragNode);
                setExpandedKeys([..._expandedKeys, item.key])
            } else {
                loadingChild = true;
                _loadData(dropNode, (childrens) => {
                    if(childrens) {
                        item.children = deepCopy(childrens);
                        item.children.push(dragNode);

                        setExpandedKeys([..._expandedKeys, item.key])

                        let parentItems = item.children
                        onDrop && onDrop({
                            message: message,
                            dragNodeKey: dragNode.key,
                            dropNodeParentKey: dropNodeParentKey,
                            items: parentItems,
                        }, newTreeData );
                    }
                });
            }
        }

        if(!loadingChild) {
            let parentItems = [...newTreeData]
            if (dropNodeParentKey) {
                loop(newTreeData, dropNodeParentKey, (item, index, arr) => {
                    parentItems = item.children;
                });
            }
            onDrop && onDrop({
                message: message,
                dragNodeKey: dragNode.key,
                dropNodeParentKey: dropNodeParentKey,
                items: parentItems,
            }, newTreeData );
        }
    }, [treeData, _expandedKeys, draggableNode, loop, _loadData, field, getPerents, onDrop, parentKeyField, timeOut]);

    const moveNode = useCallback((dataItem) => {
        moveTreeNode(dataItem, treeData);
    }, [moveTreeNode, treeData]);

    const mapDataToReactNode = (_data) => {
        return (
            <>
                {
                    _data.map(dataItem => {
                        return (
                        <li key={dataItem.key}>
                            <TreeNode
                                dataItem={dataItem}
                                field={field}
                                //movedKeys={movedKeys}
                                selectable={selectable}
                                // selectedItem={selectedItem}
                                selectedItemKey={selectedItemKey}
                                expandedKeys={expandedKeys}
                                _expandedKeys={_expandedKeys}

                                editable={editable}
                                allowChanging={allowChanging}
                                loadingItemKey={loadingItemKey}

                                setAllowChanging={setAllowChanging}
                                onExpand={_onExpand}
                                onDragStart={_onDragStart}
                                onDragOver={_onDragOver}
                                onDragLeave={_onDragLeave}
                                onDrop={_onDrop}
                                onSelect={_onSelect}
                                onDelete={_onDelete} // NOSONAR
                                onUpdate={_onUpdate} // NOSONAR
                                hide={hide}
                                moveNode={moveNode}
                                disabled={disabled}
                                listCustomEvent={listCustomEvent}
                            />
                            {
                                dataItem.children && dataItem.children.length > 0 && _expandedKeys && _expandedKeys.indexOf(dataItem.key) !== -1 ?
                                    <ul  className="nested">
                                        {mapDataToReactNode(dataItem.children)}
                                        { addingItem && (selectedItem && selectedItem.key===dataItem.key) ?
                                                <AddNewNode acceptNewItem={(value) => _acceptNewItem(dataItem, value)} cancelAddNew={_cancelAddNew} // NOSONAR
                                                />
                                             : null}
                                    </ul> :
                                        addingItem && (selectedItem && selectedItem.key === dataItem.key) ? // NOSONAR
                                            <ul className="nested">
                                                <AddNewNode acceptNewItem={(value) => _acceptNewItem(dataItem, value)} cancelAddNew={_cancelAddNew} // NOSONAR
                                                />
                                            </ul> : null
                            }

                        </li>

                        )
                    })
                }
            </>)
    }

     useEffect(() => {
        setTreeData(data);
        setLoadingItemKey('');
    }, [data]);

    useEffect(() => {
        if(selectedKeys && selectedKeys.length > 0) {
            setSelectedItemKey(selectedKeys[0])
        } else {
            setSelectedItemKey("")
        }

    }, [selectedKeys]);

    useEffect(() => {
        if(addingItem) {
            let parentsKeys = getPerents(selectedItem)
            parentsKeys.forEach((item) => {
                if(_expandedKeys.indexOf(item.key) === -1) {
                    _onExpand(item)
                }
            })
        }

    }, [addingItem, _expandedKeys, _onExpand, getPerents, selectedItem])

    useEffect(() => {
        if(clear) {
            setExpandedKeys([]);
            setloadedKeys([]);
        }
    }, [clear])

    return (
        <>
            <view scroll="">
                <div ref={container} className="tree_view">
                    <ul>
                        {mapDataToReactNode(treeData)}
                        {addingItem && !selectedItem ?
                            <AddNewNode acceptNewItem={(value) => _acceptNewItem(null, value)} cancelAddNew={_cancelAddNew} // NOSONAR
                            />
                        : null}
                    </ul>
                </div>
            </view>
            {showTooltip &&
                <Tooltip title={newButtonTip || <T>text.new</T>}><div  onClick={_onAddNew} className="fab_container bottom right"><div effect="material" className="button fab primary"><icon>plus</icon></div></div></Tooltip>
            }
        </>
    )
}

const AddNewNode = (props) => {
    const newNodeRef = useRef(null);

     useEffect(() => {
            const domElement = ReactDOM.findDOMNode(newNodeRef.current);
            domElement && domElement.scrollIntoView();
    }, []);

    return (
        <li>
            <div className="tree-node new" ref={newNodeRef}>
                <EditableNode value='' onDone={props.acceptNewItem} onCancel={props.cancelAddNew} />
            </div>
        </li>
    )
}

const type = "dragablenode";
const TreeNode = (props) => { // NOSONAR
    const [editing, setEditing] = useState(false);
    const {
        dataItem,
        field,
        selectable,
        // selectedItem,
        selectedItemKey,
        //selectedKeys,
        expandedKeys,
        _expandedKeys,

        editable,
        allowChanging,
        setAllowChanging,

        loadingItemKey,
        onSelect,
        onDragStart,
        onDragLeave,
        onDragOver,
        onDrop,
        onExpand,
        onUpdate,
        onDelete,
        hide,
        moveNode,
        //movedKeys,
        disabled
    } = props;
    const refToScroll = useRef();
    const [show] = useState(!hide);

    const _onExpand = useCallback(() => {
        allowChanging && onExpand(dataItem)
    }, [allowChanging, onExpand, dataItem])

    const _onSelect = useCallback(() => {
        if(allowChanging) {
            if(selectedItemKey && selectedItemKey === dataItem.key){
                onSelect(null);
            } else {
                onSelect(dataItem)
            }
        }
    }, [dataItem, onSelect, allowChanging, selectedItemKey])

    const editHandler = (e) => {
        e.stopPropagation();
        if(allowChanging) {
            setEditing(true);
            setAllowChanging(false);
            onSelect(dataItem)
        }
    }

    const deleteHandler = (e) => {
        e.stopPropagation();
        if(allowChanging) {
            onDelete && onDelete(dataItem);
        }
    }

    const cancelEditing = () => {
        setEditing(false);
        setAllowChanging(true);
    }

    const onDone = (value) => {
        setEditing(false);
        setAllowChanging(true);
        const newDataItem = {...dataItem};
        newDataItem[field] = value;

        onUpdate(newDataItem);
    }

    const _onDragStart = useCallback(() => onDragStart(dataItem), [dataItem, onDragStart]);
    const _onDrop = useCallback((ev) => onDrop(ev, dataItem), [dataItem, onDrop]);
    const _onDragOver = useCallback((ev) => onDragOver(ev, dataItem), [onDragOver, dataItem]);
    const _onDragLeave = useCallback((ev) => onDragLeave(ev, dataItem), [dataItem, onDragLeave]);

    // useEffect(() => {
    //     //if(selectedKeys && selectedKeys[0] === dataItem.key) {
    //     if(selectedItem && selectedItem.key === dataItem.key) {
    //         onSelect(dataItem);
    //     }
    // }, [dataItem]);

    useEffect(() => {
        if (selectedItemKey === dataItem.key) {
            const domElement = ReactDOM.findDOMNode(refToScroll.current);
            domElement && domElement.scrollIntoView();
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedItemKey === dataItem.key) {
            onSelect(dataItem);
        }
    }, [selectedItemKey]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (expandedKeys && expandedKeys.indexOf(dataItem.key) >= 0) {
            _onExpand();
        }
    }, [expandedKeys]);// eslint-disable-line react-hooks/exhaustive-deps

    const ref = useRef();
    const [, drop] = useDrop({
        accept: type,
    });
    const [, drag] = useDrag({
        item: { type },
        begin: monitor => {
            _onDragStart()
        },
    });
    drop(drag(ref));

    const onMoveNode = useCallback((e) => {
        e.stopPropagation();

        moveNode(dataItem);
    }, [dataItem, moveNode]);

    return (
        <div ref={refToScroll} className={"tree-node" + (selectedItemKey && selectedItemKey===dataItem.key ? " selected" : "")}>
            {<span
                className="toggler"
                onClick={_onExpand}>
                    {
                    _expandedKeys.indexOf(dataItem.key) === -1 ? (dataItem.childs > 0 ? <icon>right</icon> : <icon></icon>) // NOSONAR
                        : dataItem.key === loadingItemKey ? <Spin indicator={loadingIcon} size="small" /> : dataItem.children && dataItem.children.length > 0 ? <icon>down</icon> : <icon></icon> // NOSONAR
                    }
            </span>}
            {
                selectable ?
                    <div onClick={_onSelect} className="node">
                        {
                            editable ?
                                editing ? // NOSONAR
                                <>
                                        <EditableNode value={dataItem[field]} onDone={onDone} onCancel={cancelEditing} // NOSONAR
                                        />
                                </>
                                :
                                <>
                                    <wrap>
                                        <elastic>
                                            <text
                                                title={dataItem[field]}
                                                ref={ref}
                                                onDoubleClick={editHandler}
                                                className="node-text"
                                                onDrop={_onDrop}
                                                onDragOver={_onDragOver}
                                                onDragLeave={_onDragLeave}
                                            >{dataItem[field]}
                                            </text>
                                                {
                                                    !disabled &&
                                                    <action hover="hover">
                                                        {!show &&
                                                            <div className="button mini" onClick={onMoveNode}><icon>plus</icon></div>
                                                        }
                                                        {show &&
                                                            <>
                                                                <div className="button mini" onClick={deleteHandler}><icon>delete</icon></div>
                                                                <div className="button mini" onClick={editHandler}><icon>edit</icon></div>
                                                            </>
                                                        }
                                                    </action>
                                                }
                                        </elastic>
                                    </wrap>
                                </>
                                : <wrap>
                                    <elastic>
                                        <text className="node-text">{dataItem[field]}</text>
                                        {!show && !disabled &&
                                            <action hover="hover">
                                                <div className="button mini" onClick={onMoveNode}><icon>plus</icon></div>
                                            </action>
                                        }
                                    </elastic>
                                </wrap>
                        }
                    </div>
                    :
                    <div>
                        <span className="node-text">{dataItem[field]}</span>
                    </div>
            }
        </div>)
}




const EditableNode = (props) => {
    const [value, setValue] = useState(props.value);
    const [validation, setValidation] = useState({});
    const validationFields = [ { name: 'NewNode', rules: [{ type: 'required', message: 'Please fill.' }] }];

    const onChangeHandler = (e) => {
        const _value = e.target.value.trim();
        setValue(_value);
        setValidation(validate({ data: { NewNode: _value },  validationFields}));
    }

    return (
        <wrap>
            <elastic>
                <div className="form_fields">
                    <ValidationInput validation={validation} name='NewNode' type='text' value={value} maxLength={250} onChange={onChangeHandler} // NOSONAR
                    />
                </div>
                <action hover="hover">
                    <div disabled={!validation.isValid} className="button mini" onClick={() => props.onDone(value)}><icon>done</icon></div>
                    <div className="button mini" onClick={props.onCancel}><icon>cancel</icon></div>
                </action>
            </elastic>
        </wrap>
    )
}

Tree.defaultProps = {
    //hasAddNew: false,
    selectable: true,
    editable: true,
    disabled: false,

    data: [],
    field: "name",
    parentKeyField: "perentKey",

    selectedKeys: [],
    expandedKeys: []

    //onSelect: () => {},
    //onDragStart: () => {},
    //onDrop: () => {},
    //onUpdate: () => {},
    //onAddNew: () => {},
    //onDelete: () => {}
    //onSelect: () => {}
};

Tree.propTypes = {
    hasAddNew: PropTypes.bool
};

export default Tree;
