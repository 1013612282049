import React, { useEffect, useState, useRef, useCallback } from 'react';
import { message } from 'antd';
import { PopupClasses, PopupTypes } from '~/components/Popup';
import { ApiUrl } from '~/project/Defines';
import { dispatchCustomEvent, EVENT_NAME } from '~/project/utilities';
import ListView from '~/Shared/ListView';
import { COLUMN_TYPE } from '~/Shared/ListView/helper';
import { Ajax } from '~/components/Ajax';
import { hideLoader, showLoader } from '~/Shared/Loader';

const loaderName = 'SSO_LOADER';

export function Sso(props) {
    const { uuid, reseau_uuids } = props;
    const [detailProps, setDetailProps] = useState({});
    const detailRef = useRef(null);
    const listUpdateEvent = EVENT_NAME.UNIVERS_SITES_LIST_UPDATE;

    useEffect(() => {
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.add_site',
            popupType: PopupTypes.UniversSitesDetails,
            popupClassName: PopupClasses.Medium,
            windowKey: 'pickUniversSites',
            newData: {
                data: {
                    univers_uuid: uuid,
                    reseau_uuids: reseau_uuids,
                    current_lang: 'fr'
                }
            }
        })
    }, [uuid, reseau_uuids]);

    const updateData = useCallback((record) => {
        showLoader(loaderName);
        if (record != null) {
            Ajax.post({
                url: ApiUrl.UniversSitesUpdate,
                data: record,
                success: function (response) {
                    hideLoader(loaderName);
                    if (detailRef && response) {
                        dispatchCustomEvent(listUpdateEvent, 'update');
                    }
                    else {
                        message.error(response.message);
                    }
                },
                error: function () {
                    hideLoader(loaderName);
                }
            })
        }
    }, [detailRef, listUpdateEvent]);

    const updateSSOData = useCallback((record) => {
        showLoader(loaderName);
        if (record != null) {
            Ajax.post({
                url: ApiUrl.UniversSitesSSOUpdate,
                data: record,
                success: function (response) {
                    hideLoader(loaderName);
                    if (detailRef && response) {
                        dispatchCustomEvent(listUpdateEvent, 'update');
                    }
                    else {
                        message.error(response.message);
                    }
                },
                error: function () {
                    hideLoader(loaderName);
                }
            })
        }
    }, [detailRef, listUpdateEvent]);

    const updateSSOAllowCreateAccount = useCallback((record) => {
        if (record != null) {
            record.wso_allow_create_account = !record.wso_allow_create_account;
            updateSSOData(record);
        }
    }, [updateSSOData]);

    const updateActive = useCallback((record) => {
        if (record != null) {
            record.active = !record.active;
            updateData(record);
        }
    }, [updateData]);
    const updateMainHost = useCallback((record) => {
        if (record != null) {
            record.main_host = !record.main_host;
            updateData(record);
        }
    }, [updateData]);

    const updateSSOActive = useCallback((record) => {
        if (record != null) {
            record.active = !record.active;
            updateSSOData(record);
        }
    }, [updateSSOData]);

    const columnConfig = [
        {
            text: 'text.url_access',
            field: 'url',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.host_name',
            field: 'host_name',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.sso_cible',
            field: 'sso_names',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                const sso = JSON.parse(record.sso_names).join(', ');
                return <wrap title={sso}>
                    <text>{sso}</text>
                </wrap>;
            }
            //render: (text, record) => {
            //    return <wrap>
            //        <div class="form_fields ">
            //        <DropDownValueList
            //                disableCach
            //                name='sso_cible'
            //                type='text'
            //                value={record.login_types || ''}
            //                valuelist='sso_cible'
            //                //disabled={!enable_new_sso}
            //                onChange={e => updateLoginType(record, e)}
            //            //addEmptyValue
            //            />
            //        </div>
            //    </wrap>;
            //}
        },
        //{
        //    text: 'text.account_creation',
        //    field: 'allow_create_account',
        //    type: COLUMN_TYPE.TEXT,
        //    width: 150,
        //    render: (text, record) => {
        //        return <wrap>
        //            <label checkbox=''><input type='checkbox' checked={text} onClick={(e) => updateAllowCreateAccount(record)} /><box><check></check></box><text></text></label>
        //        </wrap>;
        //    }
        //},
        //{
        //    text: 'text.account_creation_url',
        //    field: 'create_account_url',
        //    type: COLUMN_TYPE.TEXT
        //},
        //{
        //    text: 'text.login_types',
        //    field: 'login_types',
        //    type: COLUMN_TYPE.TEXT
        //},
        {
            text: 'text.current_lang',
            field: 'current_lang',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.active',
            field: 'active',
            type: COLUMN_TYPE.TEXT,
            width: 150,
            render: (text, record) => {
                return <wrap>
                    <label checkbox=''><input type='checkbox' checked={record.active} value={record.active} onClick={(e) => updateActive(record)} onChange={()=>{ /**/ }} /><box><check></check></box><text></text></label>
                </wrap>;
            }
        },
        {
            text: 'text.main_host',
            field: 'main_host',
            type: COLUMN_TYPE.TEXT,
            width: 150,
            render: (text, record) => {
                return <wrap>
                    <label checkbox=''><input type='checkbox' checked={record.main_host} value={record.main_host} onClick={(e) => updateMainHost(record)} onChange={() => { /**/ }}
                        {...(record.active ? { disabled: false } : { disabled: true}) }
                    /><box><check></check></box><text></text></label>
                </wrap>;
            }
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];

    const expandedColumnConfig = [
        {
            text: 'text.sso',
            field: 'sso_name',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.wso_client_id',
            field: 'wso_client_id',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.wso_client_secret',
            field: 'wso_client_secret',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.wso_callback',
            field: 'wso_callback',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.account_creation',
            field: 'wso_allow_create_account',
            type: COLUMN_TYPE.TEXT,
            width: 150,
            render: (text, record) => {
                return <wrap>
                    <label checkbox=''><input type='checkbox' checked={record.wso_allow_create_account} value={record.wso_allow_create_account} disabled={!record.allow_create_account} onClick={(e) => { updateSSOAllowCreateAccount(record) }} onChange={()=>{ /**/ }} /><box><check></check></box><text></text></label>
                </wrap>;
            }
        },
        {
            text: 'text.active',
            field: 'active',
            type: COLUMN_TYPE.TEXT,
            width: 150,
            render: (text, record) => {
                return <wrap>
                    <label checkbox=''><input type='checkbox' checked={record.active} value={record.active} onClick={(e) => { updateSSOActive(record) }} onChange={()=>{ /**/ }} /><box><check></check></box><text></text></label>
                </wrap>;
            }
        },
        //{
        //    type: COLUMN_TYPE.ACTION
        //},
    ];

    const onExpandedRowRender = useCallback((record) => {
        return <ListView
            isSimple
            noPaging
            skipEdit
            skipNewButton={true}
            columnConfig={expandedColumnConfig}
            apiUrl={ApiUrl.UniversSitesSSOList}
            //deleteUrl={ApiUrl.UniversSitesSSODelete}
            nameField="sso_name"
            staticFilter={{ univers_sites_uuid: record.ID }}
            pagination={false}
            listUpdateEvent={EVENT_NAME.FAMILY_INFOS_LIST_UPDATE}
        />;
    }, [expandedColumnConfig]);

    return <>
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.UniversSitesList}
            deleteUrl={ApiUrl.UniversSitesDelete}
            staticFilter={{ univers_uuid: uuid }}
            // deleteKeys={['nature_code', 'reseau_uuid']}
            nameField='domain'
            listUpdateEvent={listUpdateEvent}
            //skipNewButton
            newButtonTip='text.create_new'
            detailProps={detailProps}
            expandable={{
                expandedRowRender: record => onExpandedRowRender(record),
                rowExpandable: () => {
                    return true;
                },
            }}
        />
    </>;
}