import React from 'react';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { EVENT_NAME } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { PopupTypes } from '../../components/Popup.js';
import { Project } from '../../project/Project.js';

/**
 * the SalesNetwork page main List
 * @exports SalesNetwork
 */
export default function SalesNetwork(props) {
    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            width: 400,
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.SalesNetworkDetails
        },
        {
            text: 'text.code',
            field: 'code',
            width: 200,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.sap_sales_org',
            field: 'sap_sales_org',
            width: 200,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.sap_dist_chan',
            field: 'sap_dist_chan',
            // width: 200,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];


    return <>
        <ListView
            {...props}
            columnConfig={columnConfig}
            listUpdateEvent={EVENT_NAME.SALES_NETWORK_LIST_UPDATE}
            apiUrl={ApiUrl.SalesNetworkList}
            deleteUrl={ApiUrl.SalesNetworkDelete}
            filters={[{ name: 'nom', type: 'text', text: 'text.name' },
            { name: 'code', type: 'text', text: 'text.code', includeEmpty: true },
            { name: 'sap_sales_org', type: 'text', text: 'text.sap_sales_org', includeEmpty: true }]}
            newButtonTip='text.create_new_sales_network'
            detailProps={
                {
                    newTitle: 'text.new_sales_network',
                    editTitle: 'text.edit_sales_network',
                    popupType: PopupTypes.SalesNetworkDetails,
                    windowKey: 'wndSalesNetwork',
                    newData: { ID: "", data: { nom: "", code: "", sap_prix: false, sap_dist_chan: "", sap_sales_org: "" } }
                }}
            listName={AppPages.SalesNetwork}
            detailUrl={Project.getPageUrl(AppPages.SalesNetworkDetails)}
            resizable
        />
    </>;
}
