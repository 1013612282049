import React from 'react';
import { useParams } from 'react-router-dom';
import { T } from '../../../components/Translations';

export default function PanierSuccess() {
    const { ref } = useParams();
    return <>
        <div className="alert_contianer" center="center">
            <alert success="success" message="message">
                <text>
                    <b><T>message.payment_confirmed</T></b><br/>
                    <T args={[ref]}>message.order_payment_confirmed</T>
                </text>
            </alert>
        </div>
    </>
}