import React, { useEffect, useCallback, useState } from 'react';
import { Line, /*Pie,*/ Column } from '@ant-design/charts';
import { Spin } from 'antd';

import { DatePicker } from 'Shared/DatePicker';
import { Ajax } from 'components/Ajax';

/**
 * the Process page main List for scheduling
 * @exports Process
 */
export default function Charts(props) {
    const loading = <div style={{ "margin": "25% auto" }}><Spin size="large" /></div>;
    const [inProcess, setInProcess] = useState(false);
    const [day, setDay] = useState(new Date());
    const [data1, setData1] = useState(null);
    const [data2, setData2] = useState(null);

    useEffect(() => {
        doAction();
    }, [day]);// eslint-disable-line react-hooks/exhaustive-deps

    const doAction = useCallback(() => {
        setInProcess(true);
        Ajax.post({
            url: "/api/common/TestCharts",
            data: {
                day: day
            },
            success: function (response) {
                if (response) {
                    setData1(response.Data1);
                    setData2(response.Data2);
                }
                setInProcess(false);
            }
        })
    }, [day])

    const config1 = {
        data: data1,
        height: 400,
        xField: 'time',
        yField: 'value',
        point: {
            size: 5,
            shape: 'diamond',
        },
    };
    const config2 = {
        data: data2,
        isGroup: true,
        xField: 'date',
        yField: 'value',
        seriesField: 'text',
        label: {
            position: 'middle',
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' },
            ],
        },
    };
    return <>
        <view>
            <toolbar light="light">
                <wrap>
                    <div className="form_fields line" style={{ "maxWidth": "290px" }}>
                        <DatePicker dataField='day' dataItem={{'day': day}}
                            setDataItem={(e)=>{
                                setDay(e.day)
                            }}
                        />
                    </div>
                </wrap>
            </toolbar>
            <view vertical="vertical">
                {
                    inProcess && loading
                }
                {
                    //data && <div><Line {...config} /></div>
                }
                {
                    data1 && <view><Line {...config1} /></view>
                }
                {
                    data2 && <view><Column {...config2} /></view>
                }
            </view>
            </view>
        </>;

    
}
