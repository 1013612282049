import React, { useCallback, useState } from 'react';

import { ApiUrl, AppPages } from '../../../project/Defines.js';
import { Project } from '../../../project/Project';

import { T } from '../../../components/Translations';
import { Ajax } from '../../../components/Ajax.js';

import DetailView from '../../../Shared/DetailView';
import { showLoader, hideLoader } from 'Shared/Loader';

import { message } from 'antd';

const loaderChange = 'SIRET_CHANGE_LOADER';
const loaderVerify = 'SIRET_VERIFY_LOADER';

/**
 * @exports UsersSiretChanges
 */
export default function UsersSiretChanges() {
    const [oldSiret, setOldSiret] = useState('');
    const [newSiret, setNewSiret] = useState('');
    const [oldUser, setOldUser] = useState();
    const [newUser, setNewUser] = useState();

    const oldSiretChange = (e) => {
        setOldSiret(e.target.value);
    };

    const newSiretChange = (e) => {
        setNewSiret(e.target.value);
    }

    const changeSiretAndDelete = useCallback((isDelete) => {
        showLoader(loaderChange);
        Ajax.post({
            url: ApiUrl.SocieteChangeSiret,
            data: {
                old_siret: oldSiret,
                new_siret: newSiret,
                delete: isDelete
            },
            success: function (response) {
                hideLoader(loaderChange);
                if (!response.HasError) {
                    message.success(<T>message.data_has_been_successfully_updated</T>);
                } else {
                    message.error(response.message);
                }
            },
            error: function () {
                hideLoader(loaderChange);
            }
        })
    }, [oldSiret, newSiret]);

    const verifySiret = useCallback((siret, updateUser) => {
        if (siret !== '' && Project.isValideSiret(siret)) {
            showLoader(loaderVerify);
            Ajax.post({
                url: ApiUrl.SocieteVerifySiret,
                data: {
                    siret: siret
                },
                success: function (response) {
                    hideLoader(loaderVerify);
                    if (!response.HasError) {
                        updateUser(response);
                    } else {
                        message.error(response.message);
                    }
                },
                error: function () {
                    updateUser(null)
                    hideLoader(loaderVerify);
                }
            })
        }
    }, []);
    let isValidOldSiret = Project.isValideSiret(oldSiret);
    let isValidNewSiret = Project.isValideSiret(newSiret);
    return (<>
        {<DetailView
            backUrl={Project.getPageUrl(AppPages.User)}
            title={<T>text.siret_change</T>}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections wide'>
                            <div className="form_fields">
                                <div className="data_label">Description</div>
                                <div className="data_help"><T>text.move_data_from_old_siret</T></div>
                            </div>
                            <div className="form_fields">
                                <div className="data_help"><b><T>text.the_data_moved_are</T></b></div>
                            </div>
                            <ul list="">
                                <li><b><T>text.request_for_support</T></b></li>
                                <li><b><T>text.order</T></b></li>
                                <li><b><T>text.client</T></b></li>
                                <li><b><T>text.collaborator</T></b></li>
                                <li><b><T>text.club_order</T></b></li>
                                <li><b><T>text.basket</T></b></li>
                                <li><b><T>text.warranty_basket</T></b></li>
                            </ul>
                        </div>
                    </div>
                    <div className='section_group'>
                        <div className='sections wide'>
                            <div className="field_group">
                                <div className="form_fields mini">
                                    <div className="data_label"><T>text.old_siret</T></div>
                                    <input
                                        name='oldSiret'
                                        type='text'
                                        value={oldSiret}
                                        onBlur={() => verifySiret(oldSiret, (response) => { setOldUser(response); })}
                                        onChange={e => oldSiretChange(e)}
                                    />
                                    {isValidOldSiret || (oldSiret !== '' &&
                                        <div className="form_fields input-validation-error">
                                            <div className="input_cont">
                                                <icon yellow="yellow">warning</icon>
                                                <div className="data_help"><T>text.invalid_siret</T></div>
                                            </div>
                                        </div>)
                                    }
                                </div>
                                {isValidOldSiret && oldUser &&
                                    <div className="form_fields mini">
                                        <div className="data_label"><T>text.detail</T></div>
                                        <input name="oldUser" type="text" value={`${oldUser.raison_sociale} ${oldUser.livraison_code_postal} ${oldUser.livraison_ville}`} readOnly />
                                    </div>
                                }

                            </div>
                            <div className="field_group">
                                <div className="form_fields mini">
                                    <div className="data_label"><T>text.new_siret</T></div>
                                    <input
                                        name='newSiret'
                                        type='text'
                                        value={newSiret}
                                        onBlur={() => verifySiret(newSiret, (response) => { setNewUser(response); })}
                                        onChange={e => newSiretChange(e)}
                                    />
                                    {isValidNewSiret || (newSiret !==''  &&
                                        <div className="form_fields input-validation-error">
                                            <div className="input_cont">
                                                <icon yellow="yellow">warning</icon>
                                                <div className="data_help"><T>text.invalid_siret</T></div>
                                            </div>
                                        </div>)
                                    }
                                </div>
                                {isValidNewSiret && newUser &&
                                    <div className="form_fields mini">
                                        <div className="data_label"><T>text.detail</T></div>
                                        <input name="newUser" type="text" value={`${newUser.raison_sociale} ${newUser.livraison_code_postal} ${newUser.livraison_ville}`} readOnly />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <toolbar transparent=''>
                        <wrap>
                            <action>
                                <div
                                    className='button primary'
                                    onClick={() => changeSiretAndDelete(true)}
                                    disabled={newSiret === '' || oldSiret === '' || !isValidOldSiret || !isValidNewSiret}
                                >
                                    {/* <icon>save</icon> */}
                                    <T>text.replace_and_delete_the_old_one</T>
                                </div>
                                <div><b><T>text.or</T></b></div>
                                <div
                                    className='button primary'
                                    onClick={() => changeSiretAndDelete(false)}
                                    disabled={newSiret === '' || oldSiret === '' || !isValidOldSiret || !isValidNewSiret}
                                >
                                    {/* <icon>save</icon> */}
                                    <T>text.replace</T>
                                </div>
                            </action>
                        </wrap>
                    </toolbar>
                </div>
            </form>
        </DetailView>}
    </>);
}
