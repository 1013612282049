import { message, Spin } from 'antd';
import { Ajax } from 'components/Ajax';
import { PopupTypes } from 'components/Popup';
import { T } from 'components/Translations';
import { dispatchCustomEvent, useCustomEvent, useDelete } from 'project/utilities';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'Shared/Loader';
import { openPopup } from 'store/actions';
import { ApiUrl } from '~/project/Defines';
import Tree from './Tree';


const treeLoaderName = 'TREE_LOADER';

function updateTreeData(list, key, treeData) {
    return list.map(node => {
        if (node.key === key) {
            return { ...node, children: treeData, isLeaf: false };
        }
        if (node.children) {
            return { ...node, isLeaf: false, children: updateTreeData(node.children, key, treeData) };
        }

        return node;
    });
}

export default function TreeView(props) {
    const [loading, setLoading] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [clear, setClear] = useState(false);

    const viewRef = useRef(null);

    const {
        onLoad,
        onSelect,
        onUpdate,
        onBeforeUpdate,
        selectedKeys,
        apiUrl,
        updateUrl,
        parentChangeUrl,
        deleteUrl,
        nameField,
        deleteKeys,
        staticFilter,
        listUpdateEvent,
        listCustomEvent,
        hide,
        hideTooltip,
        moveTreeNode,
        editable,
        newButtonTip,
        disabled
    } = props;

    const dispatch = useDispatch();

    const loop = useCallback((data, key, callback) => {
        data.forEach((item, index, arr) => {
            if (key && item.key === key) {
                return callback(item, index, arr);
            }
            if (!key) {
                callback(item, index, arr);
            }
            if (item.children) {
                return loop(item.children, key, callback);
            }
        });
    }, []);

    const loadData = useCallback((refresh) => {
        if (loading)
            return

        setLoading(true);
        Ajax.post({
            url: apiUrl,
            data: {
                filterData: JSON.stringify(staticFilter)
            },
            success: function (response) {
                if (response) {
                    const data = response.Data;
                    for (var i = 0; i < data.length; i++) { // NOSONAR
                        data[i].key = data[i].uuid;
                        data[i].title = data[i][nameField || "nom"]
                    };

                    setTreeData(data);

                    if (onLoad) {
                        onLoad(data, (keys) => {
                            setExpandedKeys(keys);
                        });
                    }
                    setLoading(false);
                }
            }
        })

    }, [loading, apiUrl, staticFilter, onLoad, nameField]);

    const loadNodeData = useCallback(() => {
        Ajax.post({
            url: ApiUrl.FamiliesNodeLoad,
            data: { uuid: props.selectedKeys[0] },
            success: function (response) {
                if (response) {
                    if (!response.HasError) {
                        let newData = [...treeData];
                        let item = null;
                        loop(newData, response.ID, function (data) {
                            item = data;
                        });

                        if (item) {
                            item[nameField || "nom"] = response[nameField || "nom"];
                        }

                        setTreeData(newData);
                    }
                }
            }
        })

    }, [props, loop, treeData, nameField]);

    const refresh = useCallback((e) => {
        if (e.detail.action === 'refreshNode') {
            loadNodeData(e.detail.data);
        } else {
            setClear(true);
            loadData(true);
            setClear(false);
        }
    }, [loadData, setClear, loadNodeData]);

    useCustomEvent(listUpdateEvent, refresh);

    const loadChildData = useCallback((key, setLoaded, callback) => {
        let filter = { ...staticFilter };
        filter.parent_uuid = key;
        Ajax.post({
            url: apiUrl,
            data: {
                filterData: JSON.stringify(filter)
            },
            success: function (response) {
                if (response) {
                    const data = response.Data;
                    for (var i = 0; i < data.length; i++) { // NOSONAR
                        data[i].key = data[i].uuid;
                        data[i].title = data[i][nameField || "nom"]
                    };

                    if (data.length > 0) {
                        let newTreeData = updateTreeData(treeData, key, data);
                        setTreeData(newTreeData);
                    }

                    callback && callback(data)
                    setLoaded && setLoaded(true)
                }
            }
        })
    }, [treeData, apiUrl, staticFilter, nameField]);

    const onLoadData = useCallback((treeNode, setLoaded, callback) => {
        loadChildData(treeNode.key, setLoaded, callback);
    }, [loadChildData]);

    const updateNode = useCallback((data, getUpdatedTreeData) => { // NOSONAR
        if (updateUrl) {
            let formData = { ...data }
            formData.title = '';
            formData.children = null;

            if (onBeforeUpdate) {
                formData = onBeforeUpdate(formData);
            }
            showLoader(treeLoaderName);
            Ajax.post({
                url: updateUrl,
                data: formData,
                success: function (response) {
                    if (response && viewRef.current) {
                        if (!response.HasError) {
                            message.success(`TreeNode has been successfully updated!`);
                        }
                        response.key = response.uuid;
                        hideLoader(treeLoaderName);

                        let exists = false;
                        let _treeData = [...treeData];
                        loop(_treeData, response.key, (item, index, arr) => {
                            exists = true;
                        });

                        if (exists) {
                            setTreeData(getUpdatedTreeData(response));
                        } else {
                            setTreeData(getUpdatedTreeData(response, (parentItem) => {
                                if (parentItem && parentItem.childs !== parentItem.children.length) {
                                    parentItem.childs = parentItem.children.length;
                                    return parentItem
                                }
                            }));
                        }
                        //setLoading(false)

                        onUpdate && onUpdate(response);
                        listCustomEvent && dispatchCustomEvent(listCustomEvent, { action: "custom" });
                    }
                }
            })
        }
    }, [treeData, loop, updateUrl, onUpdate, listCustomEvent, onBeforeUpdate]);

    const onDelete = useDelete({
        nameField, deleteUrl, deleteKeys,
        startDelete: () => {
            //setLoading(true)
        },
        endDelete: () => {
            //setLoading(false)
        }
    });
    const onDeleteNode = useCallback((record, data) => {
        if (record) {
            loop(data, record.key, (item, index, arr) => {
                arr.splice(index, 1);
            });
            if (selectedKeys[0] === record.key) {
                if (record.parent_uuid) {
                    loop(data, record.parent_uuid, (item, index, arr) => {
                        onSelect && onSelect(item);
                    });
                } else {
                    onSelect && onSelect(null)
                }
            }

            setTreeData(data);

            listCustomEvent && dispatchCustomEvent(listCustomEvent, { action: "custom" });
        }
    }, [onSelect, selectedKeys, loop, listCustomEvent]);

    useEffect(() => {
        loadData();

        viewRef.current = true;

        return () => { viewRef.current = false; }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const onExpand = useCallback((expandKeys, node) => {
        setExpandedKeys(expandKeys);
    }, []);

    const onNewItem = useCallback((_item, value, getUpdatedTreeData) => {
        let count = treeData.length + 1;
        if (_item)
            count = _item.childs ? _item.childs + 1 : 1;
        let newData = { ...staticFilter, nom: value, no_index: count };
        newData[nameField || "nom"] = value;
        if (_item) {
            newData.parent_uuid = _item.key;
            newData.path_uuid = (_item.path_uuid ? _item.path_uuid + "|" : "") + _item.key;
        }
        updateNode(newData, getUpdatedTreeData);
    }, [treeData, staticFilter, updateNode, nameField]);


    const updateParentAndLineNumbers = useCallback((uuid, parent_uuid, parentItems, accept) => {
        if (parentChangeUrl) {
            let formData = {
                uuid: uuid,
                parent_uuid: parent_uuid,
                line_numbers: []
            };

            parentItems.forEach((item, index, arr) => {
                formData.line_numbers[item.uuid] = index + 1;
            })
            showLoader(treeLoaderName);
            Ajax.post({
                url: parentChangeUrl,
                data: formData,
                success: function (response) {
                    if (response && viewRef.current) {
                        if (!response.HasError) {
                            message.success(`TreeNode has been successfully updated!`);
                            accept()
                        }
                    }
                    hideLoader(treeLoaderName);
                }
            })
        }
    }, [parentChangeUrl]);

    const onDrop = useCallback((info, newTreeData) => {
        dispatch(openPopup({
            windowKey: 'wndConfirmDrop',
            type: PopupTypes.Confirm,
            title: <T>message.dragdrop_title</T>,
            text: <T>{info.message}</T>,
            buttonYesText: <T>text.yes</T>, //Defines.Messages.Yes,
            buttonNoText: <T>text.no</T>, //Defines.Messages.No,
            yesCallback: function () {
                updateParentAndLineNumbers(info.dragNodeKey, info.dropNodeParentKey, info.items, () => { setTreeData(newTreeData) });
            }
        }))
    }, [dispatch, updateParentAndLineNumbers])

    return (
        // <view small="small" preloader="preloader">
        <>
            {loading && <div className="ant-spin-nested-loading"><div><Spin /></div><div className="ant-spin-container ant-spin-blur"></div></div>}
            {!loading &&
                <DndProvider backend={HTML5Backend}>
                    <Tree
                        expandedKeys={expandedKeys}
                        field={nameField}
                        parentKeyField={"parent_uuid"}
                        data={treeData}
                        selectedKeys={selectedKeys}

                        onUpdate={updateNode}
                        onDelete={(node) => onDelete(node, () => onDeleteNode(node, [...treeData]))} // NOSONAR
                        onDrop={onDrop}
                        onAddNew={onNewItem}
                        onExpand={onExpand}
                        loadData={onLoadData}
                        onSelect={(selectedDataItem) => { // NOSONAR
                            onSelect(selectedDataItem)
                        }}
                        clear={clear}
                        hide={hide}
                        hideTooltip={hideTooltip}
                        moveTreeNode={moveTreeNode}
                        editable={editable}
                        newButtonTip={newButtonTip}
                        disabled={disabled}
                        listCustomEvent={listCustomEvent}
                    />
                </DndProvider>}
        </>
        // </view>
    )
}