import React from 'react';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { Project } from '../../../project/Project';

/**
 * ...
 * @exports AllowedIP
 */
export default function AllowedIP(props) {

    const columnConfig = [
        {
            text: 'text.ip',
            field: 'ip',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.description',
            field: 'description',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.AuthorizationAccessList}
            deleteUrl={ApiUrl.AuthorizationAccessDelete}
            detailUrl={Project.getPageUrl(AppPages.AllowedIPDetails)}
            filters={[
                { name: 'ip', type: 'text', text: 'text.ip' },
                { name: 'created_at', type: 'date', text: 'text.created_at' },
                { name: 'updated_at', type: 'date', text: 'text.updated_at' }
            ]}
            nameField='ip'
            newButtonTip='text.create_new_allowed_ip'
            listUpdateEvent={EVENT_NAME.ALLOWED_IP_LIST_UPDATE}
            detailProps={{
                newTitle: 'text.new_allowed_ip',
                editTitle: 'text.edit_allowed_ip'
            }}
            listName={AppPages.AllowedIP}
            showRefreshButton={false}
        />
    );
}