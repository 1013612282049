import React from 'react';
import Filters from './../Filters';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { T }  from '../../components/Translations';
/**
 * the wrapper of main list pages with filters
 * @param {any} props
 */
export default function MainListWrapper(props) {
    const {
        children,
        filters,
        newButton,
        appliedFilter,
        setAppliedFilter,
        loadData,
        clearFilter,
        showRefreshButton,
        pagination,
        setPagination
    } = props;


    return <view>
        {showRefreshButton && <toolbar light='' shadow=''>
           <>
                <Tooltip title={<T>text.refresh</T>}>
                    <div className='button accent' onClick={loadData}><text><T>text.refresh</T></text></div>
                </Tooltip>
           </>
        </toolbar>}
        <view vertical='' >
            {filters && <Filters
                clear={clearFilter}
                appliedFilter={appliedFilter}
                filters={filters}
                setAppliedFilter={setAppliedFilter}
                setPagination={setPagination}
                pagination={pagination}
            />}
            <view>
                {newButton}
                {children}
            </view>
        </view>
    </view>;
}


MainListWrapper.propTypes = {
    filters: PropTypes.array,
}

MainListWrapper.defaultProps = {
    filters: null,
}