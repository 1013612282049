import React, { useCallback } from 'react';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from '../../../project/utilities';
import { Project } from '~/project/Project';
import { connect } from 'react-redux';

/**
 * ...
 * @exports DefectCode
 */
function DefectCode (props) {
    const { listState } = props;
    const getDetailUrl = useCallback((record, rowNumber, event, isNewRecord) => {
        if (!isNewRecord) {
            return Project.getPageUrl(AppPages.DefectCodeDetails, record.ID)
        } else {
            return Project.getPageUrl(AppPages.DefectCodeDetails, "create", { reseau_uuid: listState.appliedFilter.reseau_uuid })
        }
    }, [listState]);

    const columnConfig = [
        {
            text: 'text.code',
            field: 'code',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.description',
            field: 'description',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.level',
            field: 'level',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.parent',
            field: 'parent',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.value',
            field: 'value',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.DefectCodeList}
            deleteUrl={ApiUrl.DefectCodeDelete}
            getDetailUrl={getDetailUrl}
            // detailUrl={Project.getPageUrl(AppPages.DefectCodeDetails)}
            filters={[{ name: 'reseau_uuid', type: 'select', text: 'text.reseau', valuelist:'reseaux' }]}
            newButtonTip='text.create_new_defect_code'
            listUpdateEvent={EVENT_NAME.DEFECT_CODE_LIST_UPDATE}
            // detailProps={{ newTitle: 'text.create_new_defect_code', editTitle: 'text.edit' }}
            listName={AppPages.DefectCode}
            showRefreshButton={false}
            nameField='code'
            skipNewButton={!(listState && listState.appliedFilter && listState.appliedFilter.reseau_uuid)}
        />

    );
}

export default connect(state => ({ listState: state.listStates[AppPages.DefectCode] }))(DefectCode);