import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { PopupTypes } from '../../../components/Popup';
import { Project } from '../../../project/Project';

/**
 * the User(Societe) detail User list tab
 * @exports User-User
 */
function SocieteUser(props) {
    const { ID } = props;
    const [detailProps, setDetailProps] = useState({});
    const columnConfig = [
        {
            text: 'text.identifiant',
            field: 'login',
            width: 250,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.administrator',
            field: 'administrator',
            width: 112,
            type: COLUMN_TYPE.TEXT,
            render: (administrator) => {
                return <label checkbox="" style={{ justifyContent: 'space-evenly'} }>
                    <input disabled="disabled" defaultChecked={administrator} type="checkbox" /><box><check></check></box>
                </label>
            }
        },
        {
            text: 'text.name',
            field: 'nom',
            width: 250,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.last_name',
            field: 'prenom',
            width: 250,
            type: COLUMN_TYPE.TEXT,
        },

        {
            text: 'text.email',
            field: 'email',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.code_postal',
            width: 90,
            field: 'code_postal',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.city',
            field: 'ville',
            width: 150,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.telephone',
            field: 'telephone',
            width: 150,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.roles',
            field: 'roles',
            type: COLUMN_TYPE.TEXT,
        },
        {
            type: COLUMN_TYPE.ACTION,
            //render:((text, record, commandList) => (
            //    <>
            //        <elastic>
            //            <action hover="">
            //                {commandList.filter(cmd => cmd.command === 'edit' || record.roles !== "gestionnaire").map((cmd) => {
            //                    return record && !record['skip' + cmd.command] ? <div
            //                        key={cmd.command}
            //                        command={cmd.command} className="button extra" onClick={() => { cmd.onClick(record); }}>
            //                        {cmd.noIcon ? cmd.command : <icon edit="">{cmd.command}</icon>}
            //                    </div> : <div key={cmd.command}></div>
            //                })}
            //            </action>
            //        </elastic>
            //    </>
            //))
        },
    ];

    useEffect(() => {
        setDetailProps({ newTitle: 'text.new_user', editTitle: 'text.edit_user', popupType: PopupTypes.SocieteUserDetails, windowKey: 'wndSocieteUserDetail', newData: { ID: '', data: { societe_uuid: ID, nom: '' } } })
    }, [ID]);

    return <ListView
        showHeader={true}
        tableLayout="auto"
        isSimple
        //   isChieldView
        deleteUrl={ApiUrl.SocieteUsersDelete}
        noPaging
        staticFilter={{ societe_uuid: ID }}
        apiUrl={ApiUrl.SocieteUserList}
        columnConfig={columnConfig}
        pagination={false}
        newButtonTip='text.create_new_user'
        listUpdateEvent={EVENT_NAME.SOCIETE_USER_LIST_UPDATE}
        detailProps={detailProps}
        detailUrl={Project.getPageUrl(AppPages.SocieteUserDetails, ID)}

    />;
}

export default connect(state => ({ userName: state.userName }))(SocieteUser)
