import React from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import { T } from 'components/Translations';

import { Project } from 'project/Project';

import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields';
import { Textarea } from 'Shared/Input';
import { DatePicker, TimePicker } from 'Shared/DatePicker';


/**
 * ...
 * @exports HistoryDetails
 */
export default function HistoryDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.TVA_LIST_UPDATE;

    const { isNew, dataItem } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        dataLoadUrl: ApiUrl.HistoryLoad,
        nameField: 'reference_code',
        backPage: AppPages.History,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.HistoryDetails)
    });

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.History)}
            title={isNew ? <T>text.new_history</T> : `${dataItem.reference_code} / ${dataItem.operation_type} / ${dataItem.created_by}`}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.reference">
                                <input name='reference_code' type='text' value={dataItem.reference_code} readOnly />
                            </FormField>
                            <FormField label="text.type_code">
                                <input name='operation_type' type='text' value={dataItem.operation_type} readOnly />
                            </FormField>
                            <FormField label="text.commentaire">
                                <Textarea name='comment' type='text' value={dataItem.comment} large_note='large_note' readOnly />
                            </FormField>
                            <FormField label="text.name">
                                <input name='created_by' type='text' value={dataItem.created_by} readOnly />
                            </FormField>
                            <FormField label="text.created_at">
                                <DatePicker dataField="created_at" dataItem={dataItem} disabled />
                                <TimePicker dataField="created_at" dataItem={dataItem} disabled />
                            </FormField>
                        </div>
                    </div>
                </div>
            </form>
        </DetailView>}
    </>);
}
