import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, message, Tooltip } from 'antd';
import ReactDragListView from 'react-drag-listview';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { connect, useDispatch } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { Switchable } from 'Shared/Switchable';
import { closePopup } from 'store/actions';
import { Ajax } from '../../../components/Ajax';
import { PopupTypes } from '../../../components/Popup.js';
import { DataTip, T } from '../../../components/Translations';
import { ApiUrl } from '../../../project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME, useCustomEvent } from '../../../project/utilities';
import { useCacheData } from '../../../Shared/CacheData';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';
import { openPopup } from '../../../store/actions';
import ReferredSpareparts from './referredSpareparts';

import { Allotment } from "allotment";
import "allotment/dist/style.css";




const validationFields = ['taille'];

/**
 * the schema tab with list of zones in BOM Detail
 * @exports BOM-SchemasNew
 */
function Schemas(props) {
    const dispatch = useDispatch();
    const { data, openPopup } = props;
    let letters = [...(data.all_article_letters || [])];
    letters = letters.filter((l, i, s) => s.indexOf(l) === i).sort();
    const [schemas, setSchemas] = useState([]);
    const [activeSchema, setActiveSchema] = useState(null);
    const [points, setPoints] = useState([]);
    const [activeZone, setActiveZone] = useState(null);
    const [scrollZone, setScrollZone] = useState(null);
    const [scrollPoint, setScrollPoint] = useState(null);
    const [maxZoneNumber, setMaxZoneNumber] = useState(0);
    const [inNewZoneProcess, setInNewZoneProcess] = useState(false);
    const [splitterSize, setSplitterSize] = useState();
    const [isNameEditing, setIsNameEditing] = useState(false);
    const [nameCurrentValue, setNameCurrentValue] = useState('');

    const viewRef = useRef(null);
    const timeOut = useRef(null);

    const { getData, setData } = useCacheData({ key: "spl-nomenclatures"/*`spl-${data.ID}`*/, isClearOnLogout: false });

    const onNameChange = (e) => {
        setNameCurrentValue(e.target.value);
    };

    const updateNameCallback = useCallback((a, id) => {
        if (nameCurrentValue !== "") {
            let data = schemas.find(el => el.ID === id);
            data.nom = nameCurrentValue;
            Ajax.post({
                url: ApiUrl.SchemaUpdate,
                data: data,
                success: function (response) {
                    if (response && viewRef.current) {
                        message.success(<T>message.data_has_been_successfully_updated</T>);
                        setIsNameEditing(false);
                    }
                }
            })
        }
    }, [nameCurrentValue, schemas]);

    useEffect(() => {
        viewRef.current = true;

        setSplitterSize(getData() || 500);

        return () => {
            viewRef.current = false;
            if (timeOut.current) {
                clearTimeout(timeOut.current);
                timeOut.current = null;
            };
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const loadSchemaData = useCallback(() => {
        Ajax.post({
            url: ApiUrl.SchemaList,
            data: {
                filterData: JSON.stringify({ nomenclature_uuid: data.ID }),
                id: data.ID
            },
            success: function (response) {
                if (response && viewRef.current) {
                    if (response.Total > 0) {
                        if (!activeSchema) {
                            setActiveSchema(response.Data[0]);
                        }
                        setSchemas(response.Data);
                    }
                    else {
                        setActiveSchema(null);
                        setSchemas([]);
                    }
                }
                else {
                    setActiveSchema(null);
                    setSchemas([]);
                }
            }
        })
    }, [data.ID, viewRef, activeSchema]);

    useEffect(() => {
        loadSchemaData();
    }, [loadSchemaData]);

    useEffect(() => {
        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2, { action: "refresh" });
    }, [activeSchema]);

    useCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_SCHEMA_UPDATE, loadSchemaData);

    const selectRow = useCallback((zone) => {
        setActiveZone(zone);
        setScrollPoint(true);
    }, [setActiveZone, setScrollPoint]);


    const updateZone = useCallback((zone, callback) => { // NOSONAR
        if (data.ID) {
            if (!zone.nomenclature_uuid)
                zone.nomenclature_uuid = data.ID;

            Ajax.post({
                url: ApiUrl.ZoneUpdate,
                data: zone,
                success: function (response) {
                    if (response && viewRef.current) {
                        if (!response.HasError) {
                            message.success(<T args={[zone.libelle]}>message.zone_updated</T>);
                            zone.ID = response.ID;
                            selectRow(zone);

                            let zones = [...points];
                            zones = zones.map((z) => {
                                if (z.ID === zone.ID) {
                                    z.margin_left = zone.margin_left;
                                    z.margin_top = zone.margin_top;
                                }
                                return z;
                            })
                            setPoints(zones);

                            if (callback) callback(response);
                        }

                        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE, { action: "update" });
                        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2, { action: "update" });
                    }
                }
            })
        }
    }, [data, selectRow, points]);

    useEffect(() => {
        if (scrollPoint) {
            let element = document.querySelector('point.select');
            let selectElement = (el) => {
                scrollIntoView(el, {
                    align: {
                        top: 0,
                    },
                });
                setScrollPoint(false);
            }
            element && selectElement(element);
        }
    }, [scrollPoint, setScrollPoint])

    useEffect(() => {
        if (scrollZone) {
            scrollIntoView(document.querySelector('table .select'), {
                align: {
                    top: 0,
                },
            });
            setScrollZone(false);
        }
    }, [scrollZone, setScrollZone])

    const onStart = useCallback((e, record) => {
        if (record) {
            setActiveZone(record);
            setScrollZone(true);
        }
    }, [setActiveZone]);

    const onStop = useCallback((e, ui, record) => {
        if (e) {
            let zone = { ...record };
            if (zone.margin_left !== ui.x || zone.margin_top !== ui.y) {
                zone.margin_left = ui.x;
                zone.margin_top = ui.y;

                setActiveZone(zone);
                updateZone(zone);
            }
        }
    }, [updateZone, setActiveZone]);

    const onNewZone = useCallback((callback) => {
        let count = maxZoneNumber;
        if (count === 0) {
            let newPoints = points.map((p) => p);
            count = newPoints.length + 1;
        } else {
            count += 1;
        }
        setMaxZoneNumber(count);

        let zone = { margin_left: 0, margin_top: 0, libelle: "0" + count, taille: "normale", ID: "", key: count, schema_uuid: activeSchema.ID };

        setActiveZone(zone);
        updateZone(zone, callback);
    }, [maxZoneNumber, setMaxZoneNumber, points, setActiveZone, updateZone, activeSchema]);

    const onNewZoneDetail = useCallback(() => {
        openPopup({
            windowKey: "wndZoneDetail",
            fullScreen: false,
            title: <T>text.create_new_zone</T>,
            type: PopupTypes.ZoneDetails,
            bodyProps: {
                ...{ data: { margin_left: 0, margin_top: 0, libelle: '', taille: "normale", ID: "", key: '', schema_uuid: activeSchema.ID } },
                windowKey: "wndZoneDetail"
            }
        });

    }, [activeSchema, openPopup]);

    const onNewArticleZone = useCallback((record) => {
        if (inNewZoneProcess) return;

        setInNewZoneProcess(true);
        onNewZone((zone) => {
            Ajax.post({
                url: ApiUrl.ZoneAddArticles,
                data: {
                    zone_uuid: zone.ID,
                    selected_articles: [record]
                },
                success: function (response) {
                    setInNewZoneProcess(false);
                    if (!response.HasError) {
                        message.success(<T args={[response.AddedCount] || 'No'}>message.articles_were_added</T>);
                        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_ARTICLE_LIST_UPDATE2, { action: "update" });
                    } else {
                        message.error(response.message)
                    }
                },
                error: function (response) {
                    setInNewZoneProcess(false);
                }
            })
        })
    }, [inNewZoneProcess, setInNewZoneProcess, onNewZone]);

    const onSchemaDelete = useCallback((record, callback) => {
        openPopup({
            windowKey: 'wndConfirmDelete',
            type: PopupTypes.Confirm,
            title: <T args={[record.nom]}>message.delete_title</T>,//`Delete �${record.nom}�`,
            text: <T>message.delete_confirm</T>, //'This action cannot be undone. Are you sure you want to continue?',
            buttonYesText: <T>text.delete</T>,//Defines.Messages.confirmDelete,
            buttonNoText: <T>text.cancel</T>,//Defines.Messages.cancelDelete,
            yesCallback: function () {
                Ajax.get({
                    url: ApiUrl.SchemaDelete,
                    data: {
                        id: record.ID
                    },
                    success: function (response) {
                        if (response && viewRef.current) {
                            if (activeSchema && activeSchema.ID === record.ID) {
                                setActiveSchema(null);
                                setPoints([]);
                            }
                            dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_SCHEMA_UPDATE, { action: "delete" });
                            callback && callback(response);
                        }
                    }
                })
            }
        });
    }, [activeSchema, openPopup]);

    const onZoneDuplicate = useCallback((record) => {
        Ajax.post({
            url: ApiUrl.ZoneDuplicate,
            data: {
                zone_uuid: record.ID,
            },
            success: function (response) {
                if (response && viewRef.current) {
                    if (!response.HasError) {
                        message.success(<T args={[response.Duplicate] || 'No'}>message.zone_were_duplicated</T>);
                        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE, { action: "update" });
                        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2, { action: "update" });
                    } else {
                        message.error(response.message)
                    }
                }
            }
        })
    }, []);

    const onZoneDelete = useCallback((record) => {
        openPopup({
            windowKey: 'wndConfirmDelete',
            type: PopupTypes.Confirm,
            title: <T args={[record.libelle]}>message.delete_title</T>,//`Delete �${record.nom}�`,
            text: <T>message.delete_confirm</T>, //'This action cannot be undone. Are you sure you want to continue?',
            buttonYesText: <T>text.delete</T>,//Defines.Messages.confirmDelete,
            buttonNoText: <T>text.cancel</T>,//Defines.Messages.cancelDelete,
            yesCallback: function () {

                Ajax.get({
                    url: ApiUrl.ZoneDelete, //"/api/ArticleZone/DeleteRelationData",
                    data: {
                        id: record.ID
                    },
                    success: function (response) {
                        if (response && viewRef.current) {
                            if (!response.HasError) {
                                dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE, { action: "update" });
                                dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2, { action: "update" });
                                dispatchCustomEvent(EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE, { action: "update" });
                                dispatchCustomEvent(EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE2, { action: "update" });
                            } else {
                                message.error(response.message)
                            }
                        }
                    }
                })
            }
        });

    }, [openPopup]);

    const onArticleZoneNew = useCallback((newData) => {
        const winKey = 'lookupSpareParts';
        openPopup({
            windowKey: winKey,
            fullScreen: false,
            title: <T>Select Spare Part</T>,
            type: PopupTypes.SparePartLookup,
            bodyProps: { ...newData, windowKey: winKey, listUpdateEvent: EVENT_NAME.BILL_OF_MATERIAL_ZONE_ARTICLE_LIST_UPDATE2 }
        });
    }, [openPopup])

    const onArticleZoneDelete = useCallback((record, sub, callback) => {
        openPopup({
            windowKey: 'wndConfirmDelete',
            type: PopupTypes.Confirm,
            title: <T args={[sub.traduction]}>message.delete_title</T>,//`Delete �${record.nom}�`,
            text: <T>message.delete_confirm</T>, //'This action cannot be undone. Are you sure you want to continue?',
            buttonYesText: <T>text.delete</T>,//Defines.Messages.confirmDelete,
            buttonNoText: <T>text.cancel</T>,//Defines.Messages.cancelDelete,
            yesCallback: function () {

                Ajax.post({
                    url: ApiUrl.ZoneDeleteArticle,
                    data: {
                        zone_uuid: sub.zone_uuid,
                        article_uuid: sub.article_uuid,
                        lettre: sub.lettre
                    },
                    success: function (response) {
                        if (response && viewRef.current) {
                            if (!response.HasError) {
                                message.success(<T args={[response.DeletedCount] || 'No'}>message.article_were_deleted</T>);

                                dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2, { action: "update" });
                                dispatchCustomEvent(EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE2, { action: "update" });
                            } else {
                                message.error(response.message)
                            }
                        }
                    }
                })
            }
        });

    }, [openPopup]);

    const onSchemaSelect = useCallback((schema) => {
        if (schema.ID === activeSchema.ID) {
            return;
        }
        setPoints([]);
        setActiveSchema(schema);
    }, [setPoints, activeSchema, setActiveSchema])

    const onArticleSelect = useCallback((e) => {
        if (e) {
            if (e.detail && e.detail.action) {
                switch (e.detail.action) { // NOSONAR
                    default:
                        dispatch(closePopup('lookupSpareParts'));
                        break;
                }
            }
            dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2, { action: "update" });
            dispatchCustomEvent(EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE2, { action: "update" });
        }
    }, [dispatch]);

    useCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_ARTICLE_LIST_UPDATE2, onArticleSelect);

    const onZoneSelect = useCallback((e) => {
        if (e) {
            switch (e.detail.action) { // NOSONAR
                default:
                    let zone = { ...e.detail.data };
                    setTimeout(() => {
                        selectRow(zone);
                        setTimeout(() => {
                            setScrollZone(true);
                        }, 500);
                    }, 500);
                    break;
            }
        }
    }, [selectRow, setScrollZone]);
    useCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_SELECT, onZoneSelect);

    const onEditSchema = useCallback((record, rowNumber, event, isNewRecord, nameEdit) => {
        openPopup({
            windowKey: "wndSchemaDetail",
            fullScreen: false,
            title: <T>{isNewRecord ? "text.new_schema" : "text.edit_schema"}</T>,
            type: PopupTypes.SchemaDetails,
            bodyProps: {
                ...(isNewRecord ? { data: { ID: '', nom: '', image: '', images: [], image_guid: '', image_url: '', nomenclature_uuid: data.ID } } : { ID: record.ID, data: record }),
                onDelete: (record, callback) => { onSchemaDelete(record, callback) },
                windowKey: "wndSchemaDetail",
                nameEdit: nameEdit
            }
        });
    }, [data, onSchemaDelete, openPopup]);

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text"><T>text.upload</T></div>
        </div>
    );

    const updateSchemaLineNumbers = useCallback((line_numbers) => {
        if (data.ID) {
            Ajax.post({
                url: ApiUrl.SchemaChangeLineNumbers,
                data: {
                    line_numbers: line_numbers,
                    nomenclature_uuid: data.ID
                }
                // success: function (response) {
                //     if (response && viewRef.current) {
                //     }
                // }
            })
        }
    }, [data]);

     const onDragEnd = useCallback((fromIndex, toIndex) => {
         if (toIndex < 0) return; // Ignores if outside designated area

         const items = [...schemas];
         const item = items.splice(fromIndex, 1)[0];
         items.splice(toIndex, 0, item);
         const line_numbers = [];
         for (let i = 0; i < items.length; i++) {
             items[i].line_number = i + 1;
             line_numbers[items[i].ID] = items[i].line_number;
         }
         setSchemas(items);

         if (timeOut.current) {
             clearTimeout(timeOut.current);
         }
         timeOut.current = setTimeout(() => {
             updateSchemaLineNumbers(line_numbers);
         }, 300);

     }, [schemas, setSchemas, updateSchemaLineNumbers]);

    const onPaneSizeChange = useCallback((size) => {
        // setTimeout(() => {
            setSplitterSize(size);
            setData(splitterSize);
        // }, 200)

    }, [setSplitterSize, setData, splitterSize]);

    const contextMenu = (record) => (
        <div className="list_menu ant-dropdown-menu">
            <ul>
                <li onClick={() => onEditSchema(record, null, null, false)}><icon></icon><text><T>text.edit_schema</T></text></li>
                <li className="separator"></li>
                <li onClick={() => onSchemaDelete(record)}><icon></icon><text><T>text.delete_schema</T></text></li>
            </ul>
        </div>);

    const sortZonesAndArticles = (data) => { // NOSONAR
        let isNumberRegExp = /^\d+$/;
        data.sort((a, b) => {
            if (a.libelle === b.libelle) {
                if (a.ID === b.ID) {
                    if (a.model_type === 'zone') {
                        return -1;
                    }
                    if (b.model_type === 'zone') {
                        return 1;
                    }

                    return (a.letters > b.letters) ? 1 : ((b.letters > a.letters) ? -1 : 0) // NOSONAR
                } else {
                    return (a.ID > b.ID) ? 1 : -1;
                }
            }

            if (a.libelle === '') {
                return -1;
            }
            if (b.libelle === '') {
                return 1;
            }

            if (!isNumberRegExp.test(a.libelle)) {
                return isNumberRegExp.test(b.libelle) ? -1 : ((a.libelle > b.libelle) ? 1 : -1); // NOSONAR
            }
            if (!isNumberRegExp.test(b.libelle)) {
                return 1;
            }

            return Number(a.libelle) - Number(b.libelle);
        })
    }

    const addArticleSearchFieldsToZones = (sortedList, ...searchFieldsArray) => {
        let currentZone;

        sortedList.forEach(element => {
            if (element.model_type === 'zone') {
                currentZone = element;
                return; // NOSONAR
            } else {
                searchFieldsArray.forEach(searchField => {
                    if (!(currentZone[searchField] instanceof Set)) {
                        currentZone[searchField] = new Set();
                    }
                    currentZone[searchField].add(element[searchField]);
                })
            }
        })
    }

    const onFilterLabel = (value, record, dataIndex) => {
        return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '';
    }

    const onFilterTraduction = (value, record, dataIndex) => {
        if (record.model_type === 'zone') {
            if (!record[dataIndex] || record[dataIndex].size === 0) {
                return false
            } else {
                const arr = Array.from(record[dataIndex]);

                for (let element of arr) {
                    if (element.toString().toLowerCase().includes(value.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            }
        }

        return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '';
    }

    const onFilterReference = (value, record, dataIndex) => { // NOSONAR
        if (record.model_type === 'zone') {
            if (!record[dataIndex] || record[dataIndex].size === 0) {
                return false
            } else {
                const arr = Array.from(record[dataIndex]);

                for (let element of arr) {
                    if (element.toString().toLowerCase().includes(value.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            }
        }

        return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '';
    }

    const onLoadZonesList = useCallback((data, sorts) => {
        sortZonesAndArticles(data);
        addArticleSearchFieldsToZones(data, 'traduction', 'reference');
        if (activeZone) {
            let zone = data.find((z) => z.ID === activeZone.ID);
            setActiveZone(zone);
            setTimeout(() => {
                setScrollZone(true);
            }, 500);
        }
        if (data) {
            data.map((z) => {
                if (z.model_type !== "zone")
                    z.ID = z.key;
                return z;
            })
            let zones = data.filter((z) => z.model_type === "zone");
            setPoints(zones);
        }

        return data;
    }, [activeZone, setPoints, setScrollZone, setActiveZone]);

    const columns = [
        {
            text: 'text.label',
            field: 'libelle',
            width: 90,
            noSort: true,
            searchProperties: {
                dataIndex: 'libelle',
                placeholder: 'text.search_label',
                onFilterData: onFilterLabel,
            },
            //editable: true,
            type: COLUMN_TYPE.TEXT,
            render: (text, record, index) => {
                if (record.model_type === "zone")
                    return <wrap><text mark="">{text}</text></wrap>;

                return {
                    children: <></>,
                    props: {
                        colSpan: 2,
                    },
                }
            },
            editor: {
                maxLength: 255,
                //required: true,
                //customRenderer: (props) => {
                //    const { editingRecord, title, setEditingRecord, onChange } = props;
                //    return(
                //        <ValidationInput validation={validate({ data: editingRecord, validationFields: ["libelle"] })} name="libelle" type='text' value={editingRecord.libelle} onChange={onChange} />
                //        );
                //},
                onChangeData: row => {
                },
            },
        },
        {
            text: 'text.size',
            field: 'taille',
            width: 110,
            noSort: true,
            //editable: true,
            type: COLUMN_TYPE.TEXT,
            render: (text, record, index) => {
                if (record.model_type === "zone") {
                    if (record.libelle === '') {
                        record.taille = 'hidden';
                        return <wrap><text>{record.taille}</text></wrap>;
                    } else {
                        return <wrap><text>{text}</text></wrap>;
                    }
                }

                return {
                    children: <></>,
                    props: {
                        colSpan: 0,
                    },
                }
            },
            editor: {
                valueList: [
                    { value: "mini", text: "mini" },
                    { value: "petite", text: "petite" },
                    { value: "normale", text: "normale" },
                    { value: "grande", text: "grande" },
                    { value: "hidden", text: "hidden" }
                ],
                dropdownStyle: { minWidth: 80 },
                onChangeData: row => {
                },
            },
        },
        {
            text: 'text.letter',
            field: 'lettre',
            width: 60,
            noSort: true,
            type: COLUMN_TYPE.CUSTOM,
            render: (text, record, index, commands) => {
                if (record.model_type !== "zone")
                    return <wrap><text>{text}</text></wrap>;

                const excludeLetters = record.all_article_letters || []; 
                let noAdd = true;
                if (excludeLetters.length === 1 && !excludeLetters[0]) {
                    noAdd = true; // NOSONAR
                } else {
                    noAdd = excludeLetters.indexOf(' ') !== -1 || excludeLetters.indexOf('') !== -1 || (excludeLetters.length !== 0 && letters.length <= excludeLetters.length);
                }

                return {
                    children: <>
                        <elastic>
                            <text style={{ width: "100%", paddingLeft: 10 }}>
                                {text}
                            </text>
                            <action hover="">
                                {
                                    commands.editingRecord
                                        ?
                                        (commands.editingRecord.ID === record.ID ? // NOSONAR
                                            <>
                                                <div command="done" className="button extra" onClick={() => commands.onSave({ editingRecord: commands.editingRecord, record })}>
                                                    <icon edit="">done</icon>
                                                </div>
                                                <div command="cancel" className="button extra" onClick={() => commands.setEditingRecord(null)}>
                                                    <icon edit="">cancel</icon>
                                                </div>
                                            </>
                                            : <></>)
                                        :
                                        <>
                                            {
                                                !noAdd &&
                                                <div command="add" className="button extra" onClick={() => {
                                                    onArticleZoneNew({ data: record, ID: data.ID, excludeLetters: excludeLetters })
                                                }}>
                                                    <icon edit="">plus</icon>
                                                </div>
                                            }
                                            <div command="duplicate" className="button extra" onClick={() => onZoneDuplicate(record)}>
                                                <icon edit="">copy</icon>
                                            </div>
                                            <div command="edit" className="button extra" onClick={() => commands.onEdit(record)}>
                                                <icon edit="">edit</icon>
                                            </div>
                                            <div command="delete" className="button extra" onClick={() => commands.onDelete(record)}>
                                                <icon edit="">delete</icon>
                                            </div>
                                        </>
                                }

                            </action>
                        </elastic>
                    </>,
                    props: {
                        colSpan: 3,
                    },
                }
            },
        },
        {
            text: 'text.reference',
            field: 'reference',
            searchProperties: {
                dataIndex: 'reference',
                placeholder: 'text.search_reference',
                onFilterData: onFilterReference,
            },
            width: 120,
            noSort: true,
            editable: false,
            type: COLUMN_TYPE.TEXT,
            render: (text, record, index) => {
                if (record.model_type !== "zone")
                    return <wrap><text>{text}</text></wrap>;

                return {
                    children: <></>,
                    props: {
                        colSpan: 0,
                    },
                }
            },
        },
        {
            text: 'text.article',
            field: 'traduction',
            noSort: true,
            editable: false,
            searchProperties: {
                dataIndex: 'traduction',
                placeholder: 'text.search_article',
                onFilterData: onFilterTraduction,
            },
            type: COLUMN_TYPE.CUSTOM,
            render: (text, record, index, commands) => {
                if (record.model_type === "zone") {
                    return {
                        children: <></>,
                        props: {
                            colSpan: 0,
                        },
                    }
                }
                return (
                    <>
                        <elastic>
                            <text style={{ width: "100%", paddingLeft: 10 }}>
                                {text}
                            </text>
                            <action hover="">
                                {
                                    (record.periode || record.complement) &&
                                    <DataTip title={record.periode || record.complement ? // NOSONAR
                                        () => ( // NOSONAR
                                            <>
                                                {record.periode && (<><T>text.period</T>: {record.periode}<br /></>)}
                                                {record.commentaire && (<><T>text.commentaire</T>: {record.commentaire}<br /></>)}
                                                {record.complement && (<><T>text.complement</T>: {record.complement}</>)}
                                            </>) : ''

                                        //(record.periode ? t('text.period') + ': ' + record.periode + '; ' : '') +
                                        //(record.complement ? t('text.complement') + ': ' + record.complement : '')
                                    }>
                                        <div command="info" className="extra"><icon edit="">info</icon></div>
                                    </DataTip>
                                }
                                {
                                    !commands.editingRecord &&
                                    <div command="delete" className="button extra" onClick={() => {
                                        let zone = points.find((p) => p.uuid === record.zone_uuid);
                                        onArticleZoneDelete(zone, record)
                                    }}>
                                        <icon edit="">delete</icon>
                                    </div>
                                }

                            </action>
                        </elastic>
                    </>);
            }
        }
    ];

    return (<>
        <container schemas="schemas">
        
                <nav>
                    <wrap>
                        <DataTip title={'text.create_new_schema'}>
                            <span className="ant-upload-picture-card-wrapper">
                                <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
                                    <span tabIndex="0" className="ant-upload" role="button" onClick={() => onEditSchema(null, null, null, true)}>
                                        {uploadButton}
                                    </span>
                                </div>
                            </span>
                        </DataTip>

                        <separator horizontal="horizontal" small="small"></separator>
                        <ReactDragListView
                        //ReactDragListView
                        nodeSelector=".ant-list-item.draggble"
                        onDragEnd={onDragEnd}
                        >
                            {
                                schemas.map((schema, i) => {
                                    return (
                                        <Dropdown key={i} // NOSONAR
                                            overlay={contextMenu(schema)} trigger={['contextMenu']}>
                                            <Tooltip placement="right" title={ // NOSONAR
                                                () => ( // NOSONAR
                                                    <>
                                                        {schema.nom && (<><T>text.name</T>: {schema.nom}<br /><br /></>)}
                                                        <T>message.right_click_to_modify_or_delete_schema</T>
                                                    </>)
                                                //schema.nom
                                            }>
                                                <thumbs className={activeSchema && activeSchema.ID === schema.ID ? "select ant-list-item draggble" : "ant-list-item draggble"} onClick={() => onSchemaSelect(schema)}
                                                    style={
                                                        {

                                                            "backgroundImage": "url(" + schema.image_url + ")",
                                                            "backgroundPosition": "center",
                                                            "backgroundSize": "contain",
                                                            "backgroundRepeat": "no-repeat"
                                                        }
                                                    }
                                                />
                                            </Tooltip>
                                        </Dropdown>
                                    );
                                })
                            }
                        </ReactDragListView>
                    </wrap>
                </nav>
                {
                    schemas.length > 0 && activeSchema &&
                    <>
                        <div className="splitter-layout splitter">
                            <Allotment
                                onChange={onPaneSizeChange}
                                // onVisibleChange={e => onPaneSizeChange(e)}
                                // onChange={onSplitterDragEnd}
                                // onChange={onSecondaryPaneSizeChange}
                                defaultSizes={splitterSize}
                            >
                                <Allotment.Pane 
                                    minSize={200}
                                    // maxSize={splitterSize[0]}
                                    // preferredSize={splitterSize[0]}
                                >
                                    {/* <div className="layout-pane" style={{width: 678} }> */}
                                        <ListView
                                            staticFilter={{ schema_uuid: activeSchema.ID }}
                                            isSimple
                                            noPaging
                                            noSort
                                            editable
                                            apiUrl={ApiUrl.ZoneAndArticleList}
                                            inlineUpdateUrl={ApiUrl.ZoneUpdate}
                                            //deleteUrl={ApiUrl.ZoneDelete}
                                            onCustomDelete={onZoneDelete}
                                            dataItemUrl={ApiUrl.ZoneLoad}
                                            columnConfig={columns}
                                            onClick={(record) => { // NOSONAR
                                                if (record.model_type === "zone") {
                                                    selectRow(record)
                                                } else {
                                                    let zone = points.find((p) => p.uuid === record.zone_uuid);
                                                    selectRow(zone)
                                                }
                                            }}
                                            rowClassName={record => { // NOSONAR
                                                return record.model_type === "zone" ? (activeZone && record.key === activeZone.key ? 'select' : (!record.all_article_letters ? 'empty' : '')) + ' parent' : "children"; // NOSONAR //activeZone && record.key === activeZone.key ? 'select' : ''
                                            }}
                                            nameField="libelle"
                                            newButtonTip='text.create_new_zone'
                                            validationFields={validationFields}
                                            //onNewClick={() => onNewZone()}
                                            onNewClick={() => onNewZoneDetail()} // NOSONAR
                                            //detailProps={{
                                            //    newTitle: 'text.create_new_zone',
                                            //    editTitle: 'text.edit_zone',
                                            //    fullScreen: false,
                                            //    popupType: PopupTypes.ZoneDetails,
                                            //    windowKey: 'wndZoneDetail',
                                            //    newData: {
                                            //        data: {
                                            //            margin_left: 0, margin_top: 0, libelle: '', taille: "normale", ID: "", key: '', schema_uuid: activeSchema.ID
                                            //        }
                                            //    }
                                            //}}
                                            listUpdateEvent={EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2}
                                            //keepEditingStateOnload
                                            //expandable={{
                                            //    expandedRowRender: record => onExpandedRowRender(record),
                                            //    rowExpandable: record => {
                                            //        return true;
                                            //    },
                                            //}}
                                            onLoaded={onLoadZonesList}
                
                                        />
                                    {/* </div> */}
                                </Allotment.Pane>
                                <Allotment.Pane
                                    minSize={200}
                                    // preferredSize={splitterSize[1]}
                                >
                                    <div role="separator" className="layout-splitter"></div>
                                    <div className="layout-pane layout-pane-primary">
                                        <>
                                            <toolbar light="light">
                                                <wrap>
                                                    <action>
                                                        <div className="section_group">
                                                            <div className="sections">
                                                                <div className="form_fields line">
                                                                    <div className="data_label"><T>text.name</T></div>
                                                                    {
                                                                        schemas.map((schema, i) => {
                                                                            return (activeSchema && activeSchema.ID === schema.ID ?
                                                                                <div className="input_cont" key={i} // NOSONAR
                                                                                >
                                                                                <input type='text' readOnly value={schema.nom} />
                                                                                <DataTip title={'text.edit'}>
                                                                                    <div
                                                                                        className='button micro'
                                                                                        type='button'
                                                                                        onClick={e => { onEditSchema(schema, null, null, false, true); setIsNameEditing(true); setNameCurrentValue(schema.nom); }}
                                                                                    >
                                                                                        <icon >edit</icon>
                                                                                    </div>
                                                                                </DataTip>
                                                                                    <i></i>
                                                                                </div> : null
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </action>
                                                </wrap>
                                            </toolbar>
                                            <holder >
                                                <cont>
                                                    {
                                                        schemas.map((schema, i) => {
                                                            return (
                                                                activeSchema && activeSchema.ID === schema.ID ?
                                                                    <thumbs key={i} // NOSONAR
                                                                        className={"select"}
                                                                    ><img src={schema.image_url} alt="" id="schema_image" /></thumbs> : null
                                                            );
                                                        })
                                                    }
                                                    <wrap>
                                                        {
                                                            points && points.map((point, i) => { // NOSONAR
                                                                const isActive = activeZone && activeZone.key === point.key;
                                                                const zone = isActive ? activeZone : point;
                                                                let x = zone.margin_left >= 0 ? zone.margin_left : 0;
                                                                let y = zone.margin_top >= 0 ? zone.margin_top : 0;
                                                                let d = zone.taille === "normale" ? 17 : (zone.taille === "petite" ? 12 : (zone.taille === "mini" ? 10 : 22)); // NOSONAR
                                                                let w = zone.taille === "normale" ? 35 : (zone.taille === "petite" ? 25 : (zone.taille === "mini" ? 20 : 45)); // NOSONAR
                                                                let cn = zone.taille === "normale" ? "large" : (zone.taille === "petite" ? "normal" : (zone.taille === "mini" ? "mini" : "extra")); // NOSONAR
                                                                let hide = zone.taille === "hidden" || !zone.libelle;
                                                                return <Draggable bounds="parent" handler="strong" key={i} position={{ x: x, y: y }} onStart={(e) => onStart(e, zone)} onStop={(e, ui) => onStop(e, ui, zone)} /*onDrag={handleDrag}*/ // NOSONAR
                                                                >
                                                                    <point id={zone.ID} className={(isActive ? "box select " : "box ") + cn} style={{ marginLeft: "-" + d + "px", marginTop: "-" + d + "px", zIndex: (isActive ? "10" : ""), width: w, height: w, overflow: "hidden", display: (hide ? "none" : "") }}>{zone.libelle}</point>
                                                                </Draggable>;
                                                            })
                                                        }
                                                    </wrap>
                                                </cont>
                                            </holder>
                                        </>
                                    </div>
                                </Allotment.Pane>
                            </Allotment>
                        </div>
                        <Switchable
                            left
                            rounded={true}
                            hide
                            name='text.referred_Spareparts'>
                            <ReferredSpareparts 
                                ID={data.ID} 
                                schema_uuid={activeSchema.ID} 
                                onSelect={record => onNewArticleZone(record)} // NOSONAR
                                listUpdateEvent={EVENT_NAME.ARTICLE_BILL_OF_MATERIAL_LIST_UPDATE2}
                            ></ReferredSpareparts>
                        </Switchable>
                    </>
                }
        </container>
    </>);
}

export default connect(null,
    dispatch => ({ openPopup: (data) => dispatch(openPopup(data)) }))(Schemas);