import React, { useCallback, useState, useEffect } from 'react';
import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent, } from 'project/utilities';
import DropDownValueList from 'Shared/DropDown';
import { TabStrip } from 'Shared/TabStrip';
import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { getLanguages } from '../../../Shared/Translation';
import SSOLogin from './SSOLogin';
import { ValidationInput } from '../../../Shared/Form/validation';
import SunEditor from '../../../Shared/SunEditor';
import ListView from '~/Shared/ListView';
import { COLUMN_TYPE } from '~/Shared/ListView/helper';
import { Ajax } from 'components/Ajax';
import { message } from 'antd';
import { SSOSettings } from './SSOSettings';
const cultures = getLanguages();


/**
 * the SsoDetails of SSO tab of sales networks
 * @exports SsoDetails
 */
export default function SsoDetails(props) {
    const columnConfig = [
        {
            text: 'text.associated_reseauxs',
            field: 'nom',
            width: 300,
            type: COLUMN_TYPE.TEXT
        }
    ];

    const { windowKey, data, onDelete } = props;

    const hideSettings = false;

    const listUpdateEvent = EVENT_NAME.UNIVERS_SITES_LIST_UPDATE;
    const [sunEditorValue, setSunEditorValue] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);

    const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, detailRef, validation } = useDetail({
        data: data,
        updateUrl: ApiUrl.UniversSitesUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.UniversSitesLoad,
        nameField: 'domain',
        deleteUrl: ApiUrl.UniversSitesUpdateDelete,
        listUpdateEvent,
        onLoadData: (response, _setDataItem) => {
            if (data.univers_uuid) {
                response.univers_uuid = data.univers_uuid;
                response.current_lang = data.current_lang;
            }

            if (response.settings) {
                response.settings = JSON.parse(response.settings);
            }

            _setDataItem(response);
            return true;
        },
        validationFields: ['univers_uuid', 'domain', 'domain_name']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            if (response.settings) {
                response.settings = JSON.parse(response.settings);
            }

            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
    }, [setDataItem, detailRef, listUpdateEvent]);

    const onChangeHostName = useCallback((e) => {
        let newData = { ...dataItem };
        let host = e.target.value;
        host = host.replace("http://", "");
        host = host.replace("https://", "");
        host = host.replace("/", "");
        newData.host_name = host;
        newData.url = 'https://' + host;
        if (!newData.front_url){
            newData.front_url = 'https://' + host;
        }
        setDataItem(newData);
    }, [dataItem, setDataItem]);

    const onChangeMentionsLegales = useCallback((value) => {
        setSunEditorValue(value);
    }, []);

    useEffect(() => {
        if (dataItem) {
            onChangeData({ target: { value: sunEditorValue, name: 'mentions_legales' } });
        }
    }, [sunEditorValue]);// eslint-disable-line react-hooks/exhaustive-deps

    const updateReseaux = useCallback(() => {
        Ajax.post({
            url: ApiUrl.UniverseSitesReseauxUpdate,
            data: {
                "univers_sites_uuid": data.uuid, "univers_uuid": data.univers_uuid, "keys": selectedRows
            },
            success: function (response) {
                if (response) {
                    if (response.HasError) {
                        message.error(response.message)
                    }
                }
            }
        })
    }, [data.uuid, selectedRows, data.univers_uuid]);

    const tabFilter = useCallback((tab) => {
        return !(tab.props.title === "text.settings" && hideSettings);
    }, [hideSettings]);

    return (<>
        {dataItem && <div id='windowContent' className='window_cont' ref={detailRef} style={{ width: 800, height: 500 }}>
            <form>
                <div className='form_container'>
                    <TabStrip skipSecondaryTabs={isNew} tabFilter={tabFilter}>
                        <div title='text.main' primary>
                            <div className='section_group'>
                                <div className='sections '>
                                    <FormField label="text.slug">
                                        <ValidationInput validation={validation} name='domain' type='text' value={dataItem.domain} onChange={(e) => onChangeData({ target: { name: 'domain', value: (e.target.value || "").toLowerCase() } })} // NOSONAR
                                        />
                                    </FormField>
                                    <FormField label="text.name">
                                        <ValidationInput validation={validation} name='domain_name' type='text' value={dataItem.domain_name} onChange={onChangeData} />
                                    </FormField>

                                    {/*<FormField label="text.login_types">*/}
                                    {/*    <input name='login_types' type='text' value={dataItem.login_types} onChange={onChangeData} />*/}
                                    {/*</FormField>*/}

                                </div>

                                <div className='sections '>
                                    <FormField label="text.current_lang">
                                        <DropDownValueList name='current_lang' value={dataItem.current_lang} items={cultures} onChange={data => onChangeData({ target: { name: 'current_lang', value: data } })} // NOSONAR
                                        />
                                    </FormField>
                                    <FormField>
                                        {
                                            // eslint-disable-next-line
                                            <label checkbox=''><input name='active' type='checkbox' onChange={onChangeData} defaultChecked={dataItem.active} /><box><check></check></box><text><T>text.active</T></text></label>
                                        }
                                    </FormField>
                                    <FormField>
                                        {
                                            // eslint-disable-next-line
                                            <label checkbox=''><input name='main_host' type='checkbox' onChange={onChangeData} defaultChecked={dataItem.main_host}
                                                {...(dataItem.active ? { disabled: false } : { disabled: true })}
                                            /><box><check></check></box><text><T>text.main_host</T></text></label>
                                        }
                                    </FormField>

                                </div>
                            </div>
                            <div className='section_group'>
                                <div className='sections wide'>
                                    <FormField label="text.host_name">
                                        <ValidationInput validation={validation} name='host_name' type='text' value={dataItem.host_name} onChange={onChangeHostName} />
                                    </FormField>
                                    <FormField label="text.url">
                                        <ValidationInput validation={validation} name='url' type='text' value={dataItem.url} onChange={onChangeData} />
                                    </FormField>
                                    <FormField label="text.front_url">
                                        <ValidationInput validation={validation} name='front_url' type='text' value={dataItem.front_url} onChange={onChangeData} />
                                    </FormField>
                                </div>
                            </div>
                        </div>

                        <div title='text.sso_cible'>
                            <SSOLogin {...dataItem} />
                        </div>
                        <div title='text.mentions_legales'>
                            <SunEditor value={sunEditorValue || dataItem.mentions_legales} onChange={onChangeMentionsLegales} />
                        </div>
                        <div title='text.associated_reseauxs'>
                            <ListView
                                isSimple
                                skipEdit
                                {...props}
                                skipNewButton
                                noPaging
                                tableLayout="Fixed"
                                staticFilter={{ univers_uuid: data.univers_uuid, univers_sites_uuid: data.uuid }}
                                columnConfig={columnConfig}
                                apiUrl={ApiUrl.SalesNetworkUniversList}
                                listUpdateEvent={EVENT_NAME.UNIVERS_SITES_RESEAUX_LIST_UPDATE}
                                rowSelection={{
                                    selectedRowKeys: selectedRows,
                                    onChange: (selectedRowKeys) => {
                                        setSelectedRows(selectedRowKeys);
                                    }
                                }}
                                onLoaded={(data, sorts) => { // NOSONAR
                                    let keys = data.map((r) => { r.key = r.reseau_uuid; return r }).filter((r) => r.univers_sites_uuid_selected === dataItem.ID).map((r) => r.key);
                                    setSelectedRows(keys);

                                    data = data.filter((r) => r.univers_sites_uuid === dataItem.ID)

                                    return data;
                                }}
                            />
                        </div>

                        <div title='text.settings'><SSOSettings validation={validation} onChangeData={onChangeData} dataItem={dataItem} setDataItem={setDataItem} /></div>
                        
                    </TabStrip>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div className='button' onClick={(e) => {
                                updateReseaux(); updateData(updateSuccessCallback, null
                                    , { additionalData: { settings: JSON.stringify(dataItem.settings) } }
                                );
                            }}><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID && <div className='button' onClick={(e) => { onDelete(dataItem, closePopup) }}><icon>delete</icon><T>text.delete</T></div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </div>}
    </>);
}
