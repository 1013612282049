import React from 'react';


/**
 * ...
 * @exports Applications
 */
export default function Applications(props) {

    return <p style={{textAlign: "center", marginTop: "120px"}}><b>To be defined</b></p>;
}