import React, { useEffect, useRef, useState, useCallback } from 'react';
import { message } from 'antd';

import { PopupTypes } from 'components/Popup.js';
import { ApiUrl } from 'project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from '~/project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { hideLoader, showLoader } from '~/Shared/Loader';
import { Ajax } from '~/components/Ajax';

const loaderName = 'UNIVERS_SSO_LOADER';


/**
 * the FamilyInfos list of Family's Infos tab
 * @exports Universe-Family-Infos-FamilyInfos
 */
export default function SSOLogin(props) {
    const { uuid } = props;
    const [detailProps, setDetailProps] = useState();
    const viewRef = useRef(null);

    const listUpdateEvent = EVENT_NAME.UNIVERS_SITES_SSO_LIST_UPDATE;

    useEffect(() => {
        viewRef.current = true;
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.create_new',
            popupType: PopupTypes.UniversSitesSSODetails,
            windowKey: 'frmUniversSitesSSODetails',
            newData: { univers_sites_uuid: uuid },
        });

        return () => { viewRef.current = false; }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const updateData = useCallback((record) => {
        showLoader(loaderName);
        if (record != null) {
            Ajax.post({
                url: ApiUrl.UniversSitesSSOUpdate,
                data: record,
                success: function (response) {
                    hideLoader(loaderName);
                    if (viewRef && response) {
                        dispatchCustomEvent(listUpdateEvent, 'update');
                    }
                    else {
                        message.error(response.message);
                    }
                },
                error: function () {
                    hideLoader(loaderName);
                }
            })
        }
    }, [viewRef, listUpdateEvent]);

    const updateActive = useCallback((record) => {
        if (record != null) {
            record.active = !record.active;
            updateData(record);
        }
    }, [updateData]);

    const updateAllowCreateAccount = useCallback((record) => {
        if (record != null) {
            record.wso_allow_create_account = !record.wso_allow_create_account;
            updateData(record);
        }
    }, [updateData]);

    const columnConfig = [
        {
            text: 'text.sso',
            field: 'sso_name',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.wso_client_id',
            field: 'wso_client_id',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.wso_client_secret',
            field: 'wso_client_secret',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.wso_callback',
            field: 'wso_callback',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.account_creation',
            field: 'wso_allow_create_account',
            type: COLUMN_TYPE.TEXT,
            width: 150,
            render: (text, record) => {
                return <wrap>
                    <label checkbox=''><input type='checkbox' checked={record.wso_allow_create_account} value={record.wso_allow_create_account} disabled={!record.allow_create_account} onClick={(e) => { updateAllowCreateAccount(record) }} onChange={()=>{ /**/ }} /><box><check></check></box><text></text></label>
                </wrap>;
            }
        },
        {
            text: 'text.active',
            field: 'active',
            type: COLUMN_TYPE.TEXT,
            width: 150,
            render: (text, record) => {
                return <wrap>
                    <label checkbox=''><input type='checkbox' checked={record.active} value={record.active} onClick={(e) => { updateActive(record) }} onChange={()=>{ /**/ }} /><box><check></check></box><text></text></label>
                </wrap>;
            }
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    return (
        <>

            <ListView
                {...props}
                isSimple
                noPaging
                columnConfig={columnConfig}
                apiUrl={ApiUrl.UniversSitesSSOList}
                deleteUrl={ApiUrl.UniversSitesSSODelete}
                nameField="sso_name"
                staticFilter={{ univers_sites_uuid: uuid }}
                newButtonTip='text.create_new'
                listUpdateEvent={listUpdateEvent}
                detailProps={detailProps}
            />
        </>
    );
};