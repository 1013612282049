import React from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { Project } from '../../../project/Project';

/**
 * Countries list of setup
 * @exports SetupCountries
 */
export default function SetupCountries(props) {
    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.SetupCountryDetails
        },
        {
            text: 'text.code',
            field: 'code',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.CountryList}
            deleteUrl={ApiUrl.CountryDelete}
            detailUrl={Project.getPageUrl(AppPages.SetupCountryDetails)}
            filters={[{ name: 'traduction', type: 'text', text: 'text.name' }, { name: 'code', type: 'text', text: 'text.code' }]}
            newButtonTip='text.create_new_country'
            listUpdateEvent={EVENT_NAME.SETUP_COUNTRY_LIST_UPDATE}
            detailProps={{ newTitle: 'text.create_new_country', editTitle: 'text.edit_country' }}
            listName={AppPages.SetupCountries}
            showRefreshButton={false} />
    );
}
