import React, { useState, useCallback, useRef, useEffect } from 'react';

import { Ajax } from 'components/Ajax';
import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { PopupTypes } from 'components/Popup.js';

/**
 * the Diagnostics list of Family's Support tab
 * @exports Universe-Family-Support-Diagnostics
 */
export default function Diagnostics(props) {
    const { uuid /*, sav_info_uuid*/ } = props;
    const [detailProps, setDetailProps] = useState();
    const [diagnostics, setDiagnostics] = useState(null);
    const viewRef = useRef(null);

    useEffect(() => {
        viewRef.current = true;
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.defect',
            popupType: PopupTypes.DiagnosticsDetails,
            windowKey: 'frmDiagnostics',
            newData: { famille_uuid: uuid, ordre: -1 },
        });

        return () => { viewRef.current = false; }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const columnConfig = [
        {
            text: 'text.defect',
            field: 'defaut',
            noSort: true,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.cause',
            field: 'cause',
            noSort: true,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.symptom',
            field: 'symptome',
            noSort: true,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.remedy',
            field: 'remede',
            noSort: true,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION
        },
    ];

    const updateDiagnosticsLineNumbers = useCallback((line_numbers) => {
        Ajax.post({
            url: ApiUrl.SavInfoDiagnosticsChangeLineNumbers,
            data: {
                line_numbers: line_numbers,
                sav_info_uuid: "", //savInfoUUID
                famille_uuid: uuid
            },
            success: function (response) {
                if (response && viewRef.current) {
                    dispatchCustomEvent(EVENT_NAME.FAMILY_SAV_INFO_DIAGNOSTICS_LIST_UPDATE, { action: "update" });
                }
            }
        })
    }, [uuid]);

    return (
        <>

            <ListView
                {...props}
                isSimple
                noPaging
                dragable
                columnConfig={columnConfig}
                apiUrl={ApiUrl.SavInfoDiagnosticsList}
                deleteUrl={ApiUrl.SavInfoDiagnosticsDelete}
                nameField="defaut"
                staticFilter={{ famille_uuid: uuid }}
                newButtonTip='text.create_new_defect'
                listUpdateEvent={EVENT_NAME.FAMILY_SAV_INFO_DIAGNOSTICS_LIST_UPDATE}
                onMoveRow={(dragIndex, hoverIndex) => { // NOSONAR
                    if (diagnostics) {

                        const items = [...diagnostics];
                        const item = items.splice(dragIndex, 1)[0];
                        items.splice(hoverIndex, 0, item);
                        const line_numbers = [];
                        for (var i = 0; i < items.length; i++) { // NOSONAR
                            items[i].ordre = i;
                            line_numbers[items[i].ID] = items[i].ordre;
                        }
                        setDiagnostics(items);
                        updateDiagnosticsLineNumbers(line_numbers);
                    }
                }}
                detailProps={detailProps}
                onLoaded={(data, sorts) => { // NOSONAR
                    setDiagnostics(data);
                }}
                rowClassName={record => { // NOSONAR
                    return record.editable ? "" : "disabled"; // NOSONAR
                }}
            />
        </>
    );
};