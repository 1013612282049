import React from 'react';
import { connect } from 'react-redux';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

function getParams() {
    return new URLSearchParams(window.location.search);
}

/**
 * the View Component to show file
 * @exports View
 */
function DocumentView(props) {
    const query = getParams();
    const url = query.get("url") || "";

    const docs = [
        { uri: url },
        //{ uri: require("./example-files/pdf.pdf") }, // Local File
    ];

    const config = {
        header: {
         disableHeader: true,
         disableFileName: true,
         retainURLParams: true
        }
       };



        return <>
            <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} config={config} />
        </>;
    
};

export default connect(state => ({ isLoggedIn: state.isLoggedIn }), null)(DocumentView);