import React, { useCallback, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { message } from 'antd';

import { closePopup } from 'store/actions';
import { Ajax } from 'components/Ajax';
import { T } from 'components/Translations';
import { ApiUrl, AppPages, UserRoles } from 'project/Defines.js';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { Project } from 'project/Project.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

/**
 * the Products pickup list to add products to family of Family's Products tab
 * @exports Universe-Family-Products
 */
export function FamilyProductPickup(props) {
    const { data, role } = props;
    const dispatch = useDispatch();
    const [selectedRows, setSelectedRows] = useState([]);
    const [updating, setUpdating] = useState(false);
    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            width: 120,
            type: COLUMN_TYPE.TEXT,
            onRenderLink: ![UserRoles.DISPATCHER, UserRoles.SATC].includes(role) ? (record) => Project.getPageUrl(AppPages.ProductDetails, record.ID) : null
        },
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.orphan_product',
            field: 'orphan_product',
            width: 150,
            noSort: 'true',
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                return record.orphan_product ? <wrap><item><icon>done</icon></item></wrap>
                    : <wrap></wrap>
            },
        }
    ];

    const updateData = useCallback(() => {
        setUpdating(true);
        Ajax.post({
            url: ApiUrl.FamilyProductAdd,
            data: {
                famille_uuid: data.ID,
                selected: selectedRows
            },
            success: function (response) {
                setUpdating(false);
                if (!response.HasError) {
                    message.success(<T args={[response.AddedCount || 'No']}>message.products_were_added</T>);

                    dispatchCustomEvent(EVENT_NAME.FAMILY_PRODUCT_LIST_UPDATE, { action: "update" });
                    dispatch(closePopup("lookupFamilyProduct"));
                } else {
                    message.error(response.message)
                }
            },
            error: function (response) {
                setUpdating(false);
                message.error(<T>{response.message}</T>)
            }
        })
    }, [selectedRows, data.ID, dispatch]);

    return (<>
        <ListView
            search={{ field: 'search' }}
            isSimple
            skipEdit
            {...props}
            skipNewButton
            //noPaging
            tableLayout="Fixed"
            staticFilter={{ famille_uuid: props.ID }}
            rowSelection={{
                selectedRowKeys: selectedRows,
                onChange: (selectedRowKeys) => {
                    setSelectedRows(selectedRowKeys);
                }
            }}
            onLoaded={(data, sorts) => { // NOSONAR
                return data;
            }}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ProductsList}

        />
        {
            <toolbar>
                <wrap>
                    <action right=''>
                        <div
                            onClick={updateData}
                            className='button'
                            {...(updating || selectedRows.length === 0) ? { disabled: 'disabled' } : {}}
                        ><icon>save</icon><T>text.add</T>
                        </div>
                        {/* <div
                            className='button'
                            // onClick={(e) => { onDelete(dataItem, closePopup) }}
                        >
                            <icon>delete</icon><T>text.delete</T>
                        </div> */}
                    </action>
                </wrap>
            </toolbar>
        }
    </>);
}


export default connect(state => ({ role: state.role }))(FamilyProductPickup)