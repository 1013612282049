import React from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { Project } from '../../../project/Project';

/**
 * spare part type list of setup
 * @exports SetupArticleTypes
 */
export default function SetupArticleTypes(props) {
    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.SetupArticleTypeDetails
        },
        {
            text: 'text.translation',
            field: 'traduction',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            showRefreshButton={false}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.ArticleTypeList}
            deleteUrl={ApiUrl.ArticleTypeDelete}
            detailUrl={Project.getPageUrl(AppPages.SetupArticleTypeDetails)}
            filters={[{ name: 'nom', type: 'text', text: 'text.name' }]}
            newButtonTip='text.create_new_article_type'
            listUpdateEvent={EVENT_NAME.SETUP_ARTICLE_TYPE_LIST_UPDATE}
            detailProps={{ newTitle: 'text.create_new_article_type', editTitle: 'text.edit_article_type'}}
            listName={AppPages.SetupArticleTypes} />
    );
}
