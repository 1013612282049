import './theme.less';
import './ant_reset.css'; // variables to override above

import React from 'react';
import ReactDOM from 'react-dom';
import { isMobile, isChrome } from "react-device-detect";
import { Provider } from "react-redux";
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker, { unregister } from './serviceWorker';

// import i18n (needs to be bundled ;))
import './i18n';
import { Project } from './project/Project';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


const _toString = Object.prototype.toString;
// toString override added to prototype of Foo class
// eslint-disable-next-line no-extend-native
Object.prototype.toString = function () {
    try {
        switch (this.tagName) {
            case "LOGIN":
            case "CONT":
            case "MESSAGE":
            case "VIEW":
            case "ICON":
            case "SEPARATOR":
            case "LOGO":
            case "INFO":
            case "CONTAINER":
            case "HORIZONAL_WRAP":
            case "CONTENT_WRAP":
            case "TEXT":
            case "CHECK":
            case "BOX":
            case "WRAP":
            case "LIST":
            case "ITEM":
            case "OVERLAY":
            case "GRID":
            case "SCROLLER":
            case "BLOCK":
            case "SPACE":
            case "PICTURE":
            case "DEMOSPACE":
            case "NAME":
            case "BADGE":
            case "GROUP":
            case "DASHBOARD":
            case "SWITCH":
            case "SWITCHABLE":
            case "PLAYER":
            case "PLAYLIST":
            case "MEDIA":
            case "CIRCLE":
            case "LINE":
            case "ROW":
            case "INFOBAR":
            case "THUMB":
            case "DETAIL":
            case "COVER":
            case "CONTROLS":
            case "DECOR":
            case "DOT":
            case "GAP":
            case "PIE":
            case "TIP":
            case "COL":
            case "COUNT":
            case "TEST":
            case "ZONE":
            case "LAYOUT":
            case "TAG":
            case "ACTION":
            case "ELASTIC":
            case "TOOLBAR":
            case "PANEL":
            case "WRAPPER":
            case "DEMO_WRAP":
            case "APPLICATION":
            case "THUMBS":
            case "POINT":
            case "HOLDER":
            case "ALERT":
            case "ELLIPSIS":
            case "COUNTER":
                return "[object div]";
            default:
                break;
        }
    } catch{ }
    return _toString.call(this);
}
const restrictBrowser = Project.restrictBrowser(window.location.pathname.toLowerCase());
const isHeadlessChrome = /\bHeadlessChrome\//.test(navigator.userAgent);

unregister();

console.log(process.env.REACT_APP_ACTIVE_SSO);

// check loaded scripts and refresh screen if version changed
if (isChrome) {
    let elements = document.getElementsByTagName("script");
    let base = document.querySelector('base').href;
    for (const element of elements) {
        if (element.src && element.src.indexOf("/static") !== -1) {
            fetch(element.src + "?t=" + (new Date()), { method: 'GET' })
                .then((response) => { // eslint-disable-line no-loop-func
                    if (response.status === 404) {
                        window.location.href = base + "?t=" + (new Date());
                    }
                    console.log('Success:', response);
                })
                .catch((error) => { // eslint-disable-line no-loop-func
                    window.location.href = base + "?t=" + (new Date());
                    console.error('Error:', error);
                });

            console.log(element.src);
            break;
        }
    }
}

ReactDOM.render(
    <wrapper className={isMobile ? "mobile" : "desktop"}>
        {isChrome || isHeadlessChrome || !restrictBrowser ? <Provider store={store}>
            <BrowserRouter basename={baseUrl}>
                <App />
            </BrowserRouter>
        </Provider> : <h1>Please use Google Chrome for this site</h1>}
    </wrapper>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker();
