import React from 'react';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';

import { Project } from '../../../project/Project';

/**
 * ...
 * @exports SSO
 */
export default function SSO(props) {

    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.code',
            field: 'code',
            width: 100,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.brands',
            field: 'brands',
            width: 200,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => {
                var brands = record.brands.join(', '); // NOSONAR
                return <wrap title={brands}>
                    <text>{brands}</text>
                </wrap>;
            }
        },
        {
            text: 'text.wso_base_api_url',
            field: 'wso_base_api_url',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.wso_end_point',
            field: 'wso_end_point',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.active',
            field: 'active',
            width: 80,
            render: (text, record, index) => {
                if (record.active)
                    return <wrap><text><icon edit="">done</icon></text></wrap>;

                return <></>;
            },
            type: COLUMN_TYPE.TEXT,
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.SSOList}
            deleteUrl={ApiUrl.SSODelete}
            detailUrl={Project.getPageUrl(AppPages.SSODetails)}
            filters={[{ name: 'nom', type: 'text', text: 'text.name' }]}
            newButtonTip='text.create_new_sso'
            listUpdateEvent={EVENT_NAME.SSO_LIST_UPDATE}
            detailProps={{ newTitle: 'text.new_sso', editTitle: 'text.edit_sso' }}
            listName={AppPages.SSO}
            showRefreshButton={false}
            nameField='nom'
        />
    );
}