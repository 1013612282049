import React from 'react';
import { connect } from 'react-redux';
import { ApiUrl, AppPages, UserRoles } from 'project/Defines.js';
import { Project } from 'project/Project';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { PopupTypes } from 'components/Popup.js';

/**
 * the Universe page main List
 * @exports Universe
 */
function Universe(props) {
    const isSuperAdmin = props.role === UserRoles.SUPERADMIN;
    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.UniverseDetails
        },
        {
            type: isSuperAdmin ? COLUMN_TYPE.ACTION : COLUMN_TYPE.VIEW,
        },
    ];

    return <ListView
        {...props}
        columnConfig={columnConfig}
        apiUrl={ApiUrl.UniverseList}
        deleteUrl={ApiUrl.UniverseDelete}
        detailUrl={Project.getPageUrl(AppPages.UniverseDetails)}
        filters={[{ name: 'nom', type: 'text', text: 'text.name' /*, includeEmpty: true*/ }]}
        newButtonTip='text.create_new_universe'
        skipNewButton={!isSuperAdmin}
        listUpdateEvent={EVENT_NAME.UNIVERSE_LIST_UPDATE}
        detailProps={
            {
                newTitle: 'text.new_universe',
                editTitle: 'text.edit_universe',
                popupType: PopupTypes.UniversDetails,
                windowKey: 'wndUnivers',
                newData: { data: { nom: '', email_contact: '', url_redirection: '', restricted: false, url_site_pro: '' } }
            }}
        listName={AppPages.Universe}
    />;
}

export default connect(state => ({ role: state.role }))(Universe);