import React, { useEffect, useState } from 'react';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';
import { PopupTypes } from 'components/Popup.js';

/**
 * the Evolutions list of Family's Evolutions tab
 * @exports Universe-Family-Evolutions
 */
export default function Evolutions(props) {
    const { uuid } = props;
    const [detailProps, setDetailProps] = useState({});
    const columnConfig = [
        {
            text: 'text.model',
            field: 'modele',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.evolution',
            field: 'evolution',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.date_begin',
            field: 'debut',
            format: COLUMN_FORMAT.DATE, 
            width: 120,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.date_end',
            field: 'fin',
            format: COLUMN_FORMAT.DATE, 
            width: 120,
            type: COLUMN_TYPE.TEXT
        },
        //{
        //    text: 'text.technical_detail',
        //    field: 'code',
        //    type: COLUMN_TYPE.TEXT
        //},
        {
            type: COLUMN_TYPE.ACTION
        }
    ];

    useEffect(() => {
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.evolution',
            popupType: PopupTypes.FamilyEvolutionDetails,
            windowKey: 'frmFamilyEvolution',
            newData: { famille_uuid: uuid },
        });
    }, [uuid]);

    return (
        <ListView
            {...props}
            isSimple
            noPaging
            columnConfig={columnConfig}
            apiUrl={ApiUrl.FamilyEvolutionList}
            deleteUrl={ApiUrl.FamilyEvolutionDelete}
            nameField="evolution"
            staticFilter={{ famille_uuid: uuid }}
            newButtonTip='text.create_new_evolution'
            listUpdateEvent={EVENT_NAME.FAMILY_EVOLUTION_LIST_UPDATE}
            rowClassName={record => { // NOSONAR
                return record.editable ? "" : "disabled"; // NOSONAR
            }}
            detailProps={detailProps}
        />
    );
};