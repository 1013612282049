import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import { Project } from 'project/Project';

import { T } from 'components/Translations';

import useDetail from 'Shared/useDetail';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE, COLUMN_FORMAT } from 'Shared/ListView/helper';
import { FormField } from 'Shared/Form/formFields';
import DropDownValueList from 'Shared/DropDown';
import DetailView from '../../../Shared/DetailView';
import { getTVACoefficent  }  from '../../../pages/PanierExterne/calculations';
/**
 * ...
 * @exports SalesOrderDetails
 */
export default function SalesOrderDetails(props) {
    const { id } = useParams();
    const backUrl = new URLSearchParams(props.location.search).get("backUrl");
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.SALES_ORDER_LIST_UPDATE;

    const { dataItem, setDataItem, updateData, closePopup, detailRef, onChangeData } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.SalesOrderUpdate,
        dataLoadUrl: ApiUrl.SalesOrderLoad,
        nameField: 'reference',
        backPage: AppPages.JobsSalesOrder,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.JobsSalesOrderDetails)
    });
    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    let additionalToral = true;
    const columnConfig = [
        {
            text: 'text.reference',
            field: 'name',
            noSort: true,
            type: COLUMN_TYPE.TEXT,
            footerTitle: 'total',
        },
        {
            text: 'text.quantite',
            field: 'quantite',
            noSort: true,
            width: 80,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.price',
            field: 'prix_unitaire',
            noSort: true,
            width: 80,
            format: COLUMN_FORMAT.CURRENCY,
            type: COLUMN_TYPE.TEXT,
            footerTitle: 'prix_unitaire',
            calculate: (record) => {
                let sum =  record.totalTVA;

                if(additionalToral) {
                    sum +=(dataItem.frais_port_ht * (getTVACoefficent(record) + 1));

                    additionalToral = false;
                }

                return sum;
            },
        }
    ];

    return (<>

        {dataItem && <DetailView
            backUrl={backUrl || Project.getPageUrl(AppPages.JobsSalesOrder) }
            title={ dataItem.reference}
        >
            <div className="alert_contianer">
                <alert warning="warning">
                    <icon>info</icon>
                    <text marquee="marquee"><span><T>{
                    dataItem.is_en_compte ? 'message.pour_pouvoir_exporter_une_commande_en_compte' : 'message.pour_pouvoir_exporter_une_commande_paiement_cb'
                    }</T></span></text>
                </alert>
            </div>

            <div className='form_container'>
                <div className='section_group'>
                    <div className='sections'>
                        <FormField label="text.reference">
                            <input name='reference' type='text' value={dataItem.reference} readOnly />
                        </FormField>
                        <FormField label="text.reseau">
                            <input name='reseau_nom' type='text' value={dataItem.reseau_nom} readOnly />
                        </FormField>

                        <FormField label="text.type">
                            <input name='is_en_compte' type='text' value={dataItem.is_en_compte ? "En compte" : "Paiement CB"} readOnly />
                        </FormField>
                    </div>
                    <div className='sections'>
                        <FormField label="text.contact">
                            <textarea name='contact' type='text' value={`${dataItem.livraison_nom}\n${dataItem.telephone}`} readOnly />
                        </FormField>
                        <FormField label="text.address">
                            <textarea name='livraison_adresse' type='text' value={`${dataItem.livraison_adresse}${dataItem.livraison_adresse_complement ? "\n" + dataItem.livraison_adresse_complement : ""}${dataItem.livraison_code_postal ? "\n" + dataItem.livraison_code_postal : ""} ${dataItem.livraison_ville}`} readOnly />
                        </FormField>
                        {
                            //<div className='form_fields'>
                            //    <label checkbox=''><input name='is_en_compte' type='checkbox' defaultChecked={dataItem.is_en_compte} disabled /><box><check></check></box><text><T>text.is_in_account</T></text></label>
                            //</div>
                        }
                    </div>
                </div>
                <div className='section_group'>
                    <div className='sections wide'>
                        <div className="form_fields">
                            <div className="section_title"><text><T>text.pieces</T></text></div><div className="form_separator"></div>
                        </div>
                        <div className="form_fields">
                            <ListView
                                {...props}
                                isSimple
                                noPaging
                                skipNewButton
                                skipEdit
                                hasTotalFooter
                                columnConfig={columnConfig}
                                apiUrl={ApiUrl.ArticleSalesNetworkSalesOrderList}
                                staticFilter={{ commande_uuid: dataItem.ID, facturation_pays: dataItem.facturation_pays, nom: dataItem.reseau_nom, frais_port_ht: dataItem.frais_port_ht }}
                            />
                        </div>

                    </div>
                </div>
                <div className='section_group'>
                    <div className='sections'>
                        <FormField label="text.etat">
                            <DropDownValueList name='etat' type='text' valuelist='etat_status_order_update' value={dataItem.etat} onChange={e => onChangeData({ target: { name: 'etat', value: e } })} // NOSONAR
                            />
                        </FormField>
                    </div>
                </div>

            </div>
            <toolbar>
                <wrap>
                    <action right=''>
                        <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                    </action>
                </wrap>
            </toolbar>

        </DetailView>
        }
    </>);
}
