import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ApiUrl /*, UserRoles*/ } from 'project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { PopupTypes, PopupClasses } from '../../../components/Popup';
import { Ajax } from '~/components/Ajax';
import { hideLoader, showLoader } from '~/Shared/Loader';
import { message } from 'antd';

const loaderName = 'STATION_SAV_LOADER';

/**
 * the User(Societe) detail SalesNetwork list tab
 * @exports User-SalesNetwork
 */
function SocieteSalesNetwork(props) {
    const { ID /*, role*/ } = props;
    const [detailProps, setDetailProps] = useState({});
    const [savUpdateingInfo, setSavUpdateingInfo] = useState(null);
    const detailRef = useRef(null);
    const listUpdateEvent = EVENT_NAME.SOCIETE_SALES_NETWORK_LIST_UPDATE;

    useEffect(() => {
        updateStationSav();
    }, [savUpdateingInfo]);// eslint-disable-line react-hooks/exhaustive-deps

    const updateStationSav = useCallback(() => {
        showLoader(loaderName);
        if (savUpdateingInfo != null) {
            Ajax.post({
                url: ApiUrl.SocieteSalesNetworkUpdate,
                data: savUpdateingInfo,
                success: function (response) {
                    hideLoader(loaderName);
                    if (detailRef && response) {
                        dispatchCustomEvent(listUpdateEvent, 'update');
                    }
                    else {
                        message.error(response.message);
                    }
                },
                error: function () {
                    hideLoader(loaderName);
                }
            })
            setSavUpdateingInfo(null);
        } else {
            hideLoader(loaderName);
        }
    }, [detailRef, savUpdateingInfo, listUpdateEvent]);

    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            width: 250,
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.code_client',
            field: 'code_client',
            width: 250,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.code_contact',
            field: 'code_contact',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.code_fournisseur',
            field: 'supplier_code',
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.station_sav',
            field: 'station_sav',
            width: 100,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (
                <elastic>
                    <action>
                        <div className="button extra" onClick={(e) => {setSavUpdateingInfo({station_sav: !record.station_sav, id: record.ID}) }}>
                            {record.station_sav ? <wrap><item><icon>done</icon></item></wrap>: <wrap><item effect="material"><icon>close</icon></item></wrap>}
                        </div>
                    </action>
                </elastic>
            )
        },
        {
            text: 'text.station_web',
            field: 'station_web',
            width: 100,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (
                <elastic>
                    <action>
                        <div className="button extra" onClick={(e) => { setSavUpdateingInfo({ station_web: !record.station_web, id: record.ID }) }}>
                            {record.station_web ? <wrap><item><icon>done</icon></item></wrap> : <wrap><item effect="material"><icon>close</icon></item></wrap>}
                        </div>
                    </action>
                </elastic>
            )
        },
        {
            text: 'text.can_create_call',
            field: 'can_create_call',
            width: 145,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (
                <elastic>
                    <action>
                        <div className="button extra" onClick={(e) => { setSavUpdateingInfo({ can_create_call: !record.can_create_call, id: record.ID }) }} disabled={!record.station_web}>
                            {record.can_create_call ? <wrap><item><icon>done</icon></item></wrap> : <wrap><item effect="material"><icon>close</icon></item></wrap>}
                        </div>
                    </action>
                </elastic>
            )
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];
    useEffect(() => {
        setDetailProps({
            fullScreen: false,
            newTitle: 'text.new_sales_network',
            editTitle: 'text.edit_sales_network',
            popupType: PopupTypes.SocieteSalesNetworkDetails,
            windowKey: 'wndUserDetail',
            popupClassName: PopupClasses.Small,
            newData: { data: { societe_uuid: ID } }
        });
    }, [ID]);

    return <ListView
        tableLayout="auto"
        isSimple
        //   isChieldView
        noPaging
        //   skipEdit
        //skipNewButton={true}
        deleteUrl={ApiUrl.SocieteSalesNetworksDelete}
        staticFilter={{ societe_uuid: ID }}
        apiUrl={ApiUrl.SocieteSalesNetworkList}
        columnConfig={columnConfig}
        pagination={false}
        listUpdateEvent={listUpdateEvent}
        newButtonTip='text.create_new_sales_network'
        detailProps={detailProps}

    />;
}

export default connect(state => ({ role: state.role }))(SocieteSalesNetwork)