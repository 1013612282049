import React, { useCallback } from 'react';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';

import useDetail from '~/Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationDropDownValueList, ValidationInput } from '~/Shared/Form/validation';

export default function SetupCountryGroupingDetails(props) {
    const { windowKey, data, onDelete } = props;
    const listUpdateEvent = EVENT_NAME.SETUP_COUNTRY_GROUP_LIST_UPDATE;
    const {
        dataItem,
        setDataItem,
        updateData,
        onChangeData,
        closePopup,
        detailRef,
        validation } = useDetail({
            data,
            updateUrl: ApiUrl.CountryGroupUpdate,
            windowKey,
            validationFields: ['slug', 'country_uuids']
        });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
    }, [setDataItem, listUpdateEvent, detailRef]);

    return (<>
        {dataItem && 
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections wide'>
                            <FormField label="text.slug">
                                <ValidationInput validation={validation} name='slug' type='text' value={dataItem.slug} onChange={onChangeData} />
                            </FormField>
                            <div className='form_fields'>
                                <div className='data_label'><T>text.pays</T></div>
                                <ValidationDropDownValueList
                                    validation={validation}
                                    mode='multiple'
                                    name='country_uuids' 
                                    value={dataItem.country_uuids}
                                    valuelist='pays_uuids'
                                    showSearch
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                    onChange={data => onChangeData({ target: { name: 'country_uuids', value: data } })} // NOSONAR
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => { onDelete(dataItem, closePopup) }}>
                                    <icon>delete</icon><T>text.delete</T>
                                </div>
                            }
                        </action>
                    </wrap>
                </toolbar>
            </form>
        }
    </>);
}
