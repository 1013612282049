import FileSaver from "file-saver";
import { Ajax } from "~/components/Ajax";
import { ApiUrl } from "~/project/Defines";
import { hideLoader, showLoader } from "~/Shared/Loader";
import { dispatchCustomEvent } from 'project/utilities';
import { message } from "antd";

const loaderName = 'FEE_FILE_UPLOAD_LOADER';
const exportLoaderName = 'FEES_FILE_EXPORT_LOADER';

export function FeeImport(data, listUpdateEvent, additionalData, successCallback) {
    showLoader(loaderName);

    Ajax.post({
        // url: ApiUrl.FeeImport,
        url: ApiUrl.FeeImport + "?reseau_uuid=" + additionalData,
        data: {
            file: data && data.file
        },
        success: (response) => {
            if (!response.HasError) {
                successCallback && successCallback(response);
                dispatchCustomEvent(listUpdateEvent);
                hideLoader(loaderName);
            } else {
                message.error(response.message);
                hideLoader(loaderName);
            }
        }
    });
}

export function FeeExport(reseaux_uuid) {
    showLoader(exportLoaderName);
    Ajax.download({
        url: ApiUrl.FeeExport,
        data: {reseaux_uuid},
        //autoDownload: false,
        success: (blob) => {
            FileSaver.saveAs(blob, "Fees.xlsx");
            hideLoader(exportLoaderName);
        },
        error: () => {
            hideLoader(exportLoaderName);
        }
    })
}

