import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { FormField } from '~/Shared/Form/formFields';
import { showLoader, hideLoader } from 'Shared/Loader';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from '../../../project/utilities';

import { T } from '~/components/Translations.js';
import { Ajax } from '~/components/Ajax';
import { PopupClasses, PopupTypes } from '~/components/Popup';

import { message } from 'antd';

import { openPopup } from '~/store/actions';
import { Project } from '~/project/Project';

const loaderName = 'SIREN_SIRET_UPLOAD_LOADER';

/**
 * ...
 * @exports ConnectivityBlocking
 */
export default function ConnectivityBlocking(props) {
    const [sirenSiret, setSirenSiret] = useState('');
    const [sirenSiretList, setSirenSiretList] = useState([]);
    const [sirenCheckeds, setSirenCheckeds] = useState([]);
    const [siretCheckeds, setSiretCheckeds] = useState([]);
    const [siren, setSiren] = useState([]);
    const [siret, setSiret] = useState([]);
    const [sirenCount, setSirenCount] = useState();
    const [siretCount, setSiretCount] = useState();
    const [fileName, setFileName] = useState();
    const [newlyAdded, setNewlyAdded] = useState([]);
    const [isExistSirenSiret, setIsExistSirenSiret] = useState();

    const dispatch = useDispatch();
    const uploadRef = useRef(null);

    const sirenListUpdateEvent = EVENT_NAME.SIREN_LIST_UPDATE;
    const siretListUpdateEvent = EVENT_NAME.SIRET_LIST_UPDATE;

    const columnConfigSiren = [
        {
            text: 'text.siren',
            noSort: 'true',
            field: 'siren_siret',
            render: (text, record) => {
                if (record.is_siren) {
                    return (
                        <wrap title={text}>
                            <text>{text}</text>
                        </wrap>
                    );
                }
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.exist',
            width: 74,
            noSort: 'true',
            field: 'existe',
            render: (text, record) => {
                return record.existe ? <wrap><item><icon>done</icon></item></wrap>
                              : <wrap><item effect="material"><icon>close</icon></item></wrap>
            },
            type: COLUMN_TYPE.TEXT
        }
    ];

    const columnConfigSiret = [
        {
            text: 'text.siret',
            field: 'siren_siret',
            noSort: 'true',
            render: (text, record) => {
                if (!record.is_siren) {
                    return (
                        <wrap title={text}>
                            <text>{text}</text>
                        </wrap>
                    );
                }
            },
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.exist',
            width: 74,
            field: 'existe',
            noSort: 'true',
            render: (text, record) => {
                return record.existe ? <wrap><item><icon>done</icon></item></wrap>
                              : <wrap><item effect="material"><icon>close</icon></item></wrap>
            },
            type: COLUMN_TYPE.TEXT
        }
    ];

    const openSirenSiretListPopup = useCallback(() => {
        dispatch(
            openPopup({
                windowKey: "lookupConnectivityBlocking",
                title: <T>text.summary_treatment</T>,
                type: PopupTypes.SirenSiretPickup,
                fullScreen: false,
                popupClassName: PopupClasses.Medium,
                bodyProps: {
                    selected : newlyAdded
                }
            })
        );
    }, [newlyAdded, dispatch]);

    const isValid = () => {
        let is_valid = sirenSiret === '' ? true : Project.isValideSiret(sirenSiret) || Project.isValideSiren(sirenSiret);
        return is_valid;
    }

    useEffect(() => {
        if (Project.isValideSiren(sirenSiret)) {
            setIsExistSirenSiret(siren.some(item => item.siren_siret === sirenSiret));
        }
        if (Project.isValideSiret(sirenSiret)) {
            setIsExistSirenSiret(siret.some(item => item.siren_siret === sirenSiret));
        }
    }, [siren, siret, sirenSiret]);

    useEffect(()=> {
        newlyAdded.length !== 0 && openSirenSiretListPopup();
    }, [newlyAdded, openSirenSiretListPopup]);
    
    useEffect(() => {
        setSirenCount(siren.length);
        setSiretCount(siret.length);
    }, [siren, siret]);

    const onChangeSirenSiret = (e) => {
        setSirenSiret(e.target.value);
        setIsExistSirenSiret();
    };

    const onClear = () => {
        setSirenSiret('');
    };

    const updateSirenSiret = useCallback(() => {
        showLoader(loaderName);

        Ajax.post({
            url: ApiUrl.BlocageConnectivitesAdd,
            data: {
                selected: sirenSiret !== "" ? sirenSiret : sirenSiretList
            },
            success: function (response) {
                if (!response.HasError) {
                    sirenSiretList.length !== 0 && setNewlyAdded(response.Items.map(item => ({...item, key: item.ID}) ));
                    
                    message.success(<T>message.data_has_been_successfully_updated</T>);
                    hideLoader(loaderName);
                    if (Project.isValideSiren(sirenSiret) || sirenSiretList.some(item => Project.isValideSiren(item))) {
                        dispatchCustomEvent(sirenListUpdateEvent, { action: "update" });
                    }
                    if (Project.isValideSiret(sirenSiret) || sirenSiretList.some(item => Project.isValideSiret(item))) {
                        dispatchCustomEvent(siretListUpdateEvent, { action: "update" });
                    }

                    setSirenSiret('');
                    setSirenSiretList([]);
                } else {
                    message.error(response.message);
                    hideLoader(loaderName);
                }
            }
        });
    }, [sirenSiret, sirenSiretList, sirenListUpdateEvent, siretListUpdateEvent]);

     const onDelete = useCallback((isSiren) => {
        dispatch(openPopup({
            windowKey: 'wndConfirmDelete',
            type: PopupTypes.Confirm,
            text: <T>message.delete_confirm</T>,
            buttonYesText: <T>text.delete</T>,
            buttonNoText: <T>text.cancel</T>,
            yesCallback: function () {
                Ajax.post({
                    url: ApiUrl.BlocageConnectivitesRemove,
                    data: {
                        selected: isSiren ? sirenCheckeds : siretCheckeds 
                    },
                    success: function (response) {
                        if (!response.HasError) {
                            message.success(<T>message.data_has_been_successfully_updated</T>);
                            if (isSiren) {
                                setSirenCheckeds([]);
                                dispatchCustomEvent(sirenListUpdateEvent, { action: "update" });
                            } else {
                                setSiretCheckeds([]);
                                dispatchCustomEvent(siretListUpdateEvent, { action: "update" });
                            }
                        } else {
                            message.error(response.message);
                        }
                    }
                })
            }
        }))
    }, [sirenCheckeds, siretCheckeds, sirenListUpdateEvent, siretListUpdateEvent, dispatch]);

    const onFileUpload = e => {
        if (e.target && e.target.files.length !== 0) {
            const fileReader = new FileReader();
            fileReader.readAsText(e.target.files[0], "UTF-8");
            fileReader.onload = e => {
                let lines = e.target.result.split("\r\n").filter(Boolean);
                setSirenSiretList(lines);
            };
    
            setFileName(e.target.files[0].name);
        }
    };

    return (
        <>
            <view vertical="vertical">
                <view space="space" intro="intro">
                    <h2><T>text.blocked_sirens</T> ({sirenCount})</h2>
                    <ListView
                        {...props}
                        columnConfig={columnConfigSiren}
                        skipNewButton
                        apiUrl={ApiUrl.BlocageConnectivitesList}
                        deleteUrl={ApiUrl.BlocageConnectivitesRemove}
                        onLoaded={loaded => setSiren(loaded)} // NOSONAR
                        staticFilter={{is_siren: true}}
                        rowSelection={{
                            columnWidth: "50px",
                            onSelect: (record, selected) => {
                                let arr = [...sirenCheckeds];
                                if (selected) {
                                    arr.push(record.siren_siret);
                                    setSirenCheckeds(arr);
                                }
                                else {
                                    let index = arr.findIndex(item => item === record.siren_siret);
                                    if(index !== -1) {
                                        arr.splice(index, 1)
                                        setSirenCheckeds(arr);
                                    }
                                }
                            },
                            onSelectAll: (allSelected, selectedRows, changeRows) => {
                                if (allSelected) {
                                    setSirenCheckeds(selectedRows.map(item => item.siren_siret));
                                } else {
                                    setSirenCheckeds([]);
                                }
                            },
                        }}
                        listUpdateEvent={sirenListUpdateEvent}
                        listName={AppPages.ConnectivityBlocking}
                    />
                    <toolbar transparent='transparent'>
                        <action right="">
                            <div
                                effect="material"
                                className="button primary"
                                onClick={() => onDelete(true)}
                                disabled={sirenCheckeds.length === 0}
                            >
                                <text><T>text.delete</T></text>
                            </div>
                        </action>
                    </toolbar>
                </view>
        
                <view space="space" intro="intro">
                    <h2><T>text.blocked_sirets</T> ({siretCount})</h2>
                    <ListView
                        {...props}
                        columnConfig={columnConfigSiret}
                        skipNewButton
                        apiUrl={ApiUrl.BlocageConnectivitesList}
                        staticFilter={{is_siren: false}}
                        onLoaded={loaded => setSiret(loaded)} // NOSONAR
                        rowSelection={{
                            columnWidth: "50px",
                            onSelect: (record, selected) => {
                                let arr = [...siretCheckeds];
                                if (selected) {
                                    arr.push(record.siren_siret);
                                    setSiretCheckeds(arr);
                                }
                                else {
                                    let index = arr.findIndex(item => item === record.siren_siret);
                                    if(index !== -1) {
                                        arr.splice(index, 1)
                                        setSiretCheckeds(arr);
                                    }
                                }
                            },
                            onSelectAll: (allSelected, selectedRows, changeRows) => {
                                if (allSelected) {
                                    setSiretCheckeds(selectedRows.map(item => item.siren_siret));
                                } else {
                                    setSiretCheckeds([]);
                                }
                            },
                        }}
                        listUpdateEvent={siretListUpdateEvent}
                    />
                    <toolbar transparent='transparent'>
                        <action right=''>
                            <div
                                effect="material"
                                className="button primary"
                                onClick={() => onDelete(false)}
                                disabled={siretCheckeds.length === 0}
                            >
                                <text><T>text.delete</T></text>
                            </div>
                        </action>
                    </toolbar>
                </view>
            </view>
            
            <form>
                <div className="form_container">
                    <div className="section_group">
                        <div className="sections wide">
                            <h2><T>text.import_creation_sirens_sirets</T></h2>
                            <FormField label="text.siren_siret">
                               <div className="input_cont">
                                    <input 
                                        type="text"
                                        value={sirenSiret} 
                                        onChange={e => onChangeSirenSiret(e)}
                                    />
                                    <separator vertical=""></separator>
                                    <div
                                        effect="material"
                                        className="button micro"
                                        onClick={onClear}
                                    >
                                        <icon>close</icon>
                                    </div>
                                    <i></i>
                               </div>
                            </FormField>
                           {!(isValid()) &&
                               <div className="form_fields input-validation-error">
                                    <div className="input_cont">
                                        <icon yellow="yellow">warning</icon>
                                        <div className="data_help"><T>text.invalid_siren_siret</T></div>
                                    </div>
                                </div>
                           }
                           {isExistSirenSiret &&
                                <div className="form_fields input-validation-error">
                                    <div className="input_cont">
                                        <icon yellow="yellow">warning</icon>
                                        <div className="data_help"><T>text.code_is_already_in_the_list</T></div>
                                    </div>
                                </div>
                           }
                            <toolbar transparent='transparent'>
                                <action>
                                    <div className="button primary">
                                        <icon>upload</icon>
                                        <label htmlFor="siren_siret"><text><T>text.fichier_importer</T></text></label>
                                        <input 
                                            type="file"
                                            ref={uploadRef}
                                            id="siren_siret"
                                            onChange={onFileUpload}
                                            style={{display: "none"}}
                                            accept='.txt'
                                        />
                                    </div>
                                </action>
                                { sirenSiretList.length !== 0 &&
                                    <wrap className="label">
                                        <text>{fileName} ({sirenSiretList.length})</text>
                                        <div 
                                            effect="material" 
                                            className="button micro"
                                            onClick={() => {
                                                setSirenSiretList([]);
                                                uploadRef.current.value = null;
                                            }}
                                        >
                                            <icon>close</icon>
                                        </div>
                                    </wrap>
                                }
                                <action right="">
                                    <div
                                        effect="material"
                                        className="button primary"
                                        onClick={() => updateSirenSiret()}
                                        disabled={(!(isValid()) || isExistSirenSiret || sirenSiret === "") && sirenSiretList.length === 0 }
                                    >
                                        <text><T>text.valider</T></text>
                                    </div>
                                </action>
                            </toolbar>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}