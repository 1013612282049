import { /*DatePicker, TimePicker,*/ message } from 'antd';
import { Project } from 'project/Project';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { hideLoader, showLoader } from 'Shared/Loader';
import { Ajax } from '../../components/Ajax.js';
import { T } from '../../components/Translations';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from '../../project/utilities';
import DetailView from '../../Shared/DetailView';
import { FormField } from '../../Shared/Form/formFields.js';
import { ValidationInput } from '../../Shared/Form/validation.js';
import { Input, Textarea } from '../../Shared/Input';
import { TabStrip } from '../../Shared/TabStrip';
import Translation from '../../Shared/Translation';
import useDetail from '../../Shared/useDetail';
import Print from './Print/index';
import Schemas from './Schemas/index';
import SpareParts from './SpareParts/index';
import UseCase from './UseCase/index';
import { DatePicker, TimePicker } from 'Shared/DatePicker';



const loaderName = 'BOM_DUBLICATE_LOADER';

/**
 * the BillOfMaterialDetails and main data of BOM
 * @exports BOM-BillOfMaterialDetails
 */

export default function BillOfMaterialDetails(props) {
    const backUrl = new URLSearchParams(props.location.search).get("backUrl");
    const tab = new URLSearchParams(props.location.search).get("tab");
    const { id } = useParams();
    const { windowKey, data } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const listUpdateEvent = EVENT_NAME.BILL_OF_MATERIAL_LIST_UPDATE;
    // const { isNew, dataItem, setDataItem, updateData, onChangeData, onDetailDelete, detailRef, validation } = useDetail({
    const useDetailResult = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.BillOfMaterialUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.BillOfMaterialLoad,
        nameField: 'reference',
        deleteUrl: ApiUrl.BillOfMaterialDelete,
        backPage: AppPages.BillOfMaterials,
        listUpdateEvent,
        validationFields: ["reference"],
        detailUrl: Project.getPageUrl(AppPages.BillOfMaterialDetails),
        reloadEvent: EVENT_NAME.BILL_OF_MATERIAL_DETAIL_RELOAD
    });

    const { dataItem, setDataItem, updateData, onChangeData, isNew, detailRef, onDetailDelete, validation } = useDetailResult;

    const history = useHistory();

    useEffect(() => {
        if (tab)
            setTabIndex(parseInt(tab));
    }, [tab]);

    useEffect(() => {
        return () => {
            console.log("UNMOUNT");
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_TRANSLATIONS_UPDATE, { ID: response.ID });
    }, [setDataItem, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            key={dataItem.ID}
            backUrl={backUrl || Project.getPageUrl(AppPages.BillOfMaterials)}
            title={isNew ? <T>text.new_bill_of_material</T> : dataItem.reference || <T>text.edit_bill_of_material</T>}
        >
            {/* <form> */}
            <div className='form_container'>
                <TabStrip onChange={(selected) => setTabIndex(selected)} skipSecondaryTabs={isNew} selectedIndex={tabIndex} // NOSONAR
                >
                    <div title='text.main' primary>
                        <div className='section_group'>
                            <div className='sections '>
                                <FormField label="text.reference">
                                    <ValidationInput name='reference' type='text' value={dataItem.reference} onChange={onChangeData} validation={validation} />
                                </FormField>
                                <FormField label="text.complement">
                                    <Input name='complement' type='text' value={dataItem.complement} onChange={onChangeData} />
                                </FormField>
                                <FormField label="text.updated_at">
                                    <DatePicker dataField="updated_at" dataItem={dataItem} disabled />
                                    <TimePicker dataField="updated_at" dataItem={dataItem} disabled />
                                </FormField>
                            </div>
                            <div className='sections medium'>
                                <FormField label="text.commentaire">
                                    <Textarea name='commentaire' type='text' value={dataItem.commentaire} large_note='large_note' onChange={onChangeData} />
                                </FormField>
                            </div>
                        </div>
                    </div>
                    <div title='text.translation'>
                        <Translation
                            ID={dataItem.ID}
                            fields={['libelle']}
                            _class='App\Models\Catalogue\Nomenclature'
                            updateEvent={EVENT_NAME.BILL_OF_MATERIAL_TRANSLATIONS_UPDATE} />
                    </div>
                    <div title='text.use_case'>
                        <UseCase {...dataItem} />
                    </div>
                    <div title='text.spare_parts'>
                        <SpareParts {...dataItem} />
                    </div>
                    <div title='text.schemas'>
                        <Schemas
                            data={dataItem}
                            {...useDetailResult}
                        />
                    </div>
                    <div title='text.printingtab'>
                        <Print {...dataItem} />
                    </div>
                </TabStrip>
            </div>
            {tabIndex < 2 &&
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <>
                                    <div className='button' onClick={(e) => {
                                        showLoader(loaderName);

                                        Ajax.post({
                                            url: ApiUrl.BillOfMaterialDuplicate,
                                            data: { id: dataItem.ID },
                                            success: function (response) {
                                                if (response.bomId) {
                                                    message.success(<T>message.successfully_copied</T>);
                                                    hideLoader(loaderName);
                                                    history.push(Project.getPageUrl(AppPages.BillOfMaterialDetails) + '/' + (response.bomId));
                                                } else {
                                                    message.error(<T>message.an_error_occured_while_trying_to_copy</T>);
                                                    hideLoader(loaderName);
                                                }
                                            },
                                            error: function (response) {
                                                message.error(response.Message);
                                                hideLoader(loaderName);
                                            }
                                        });

                                    }}>
                                        <icon>copy</icon><T>text.duplicate</T>
                                    </div>
                                    <div className='button' onClick={(e) => { onDetailDelete(dataItem) }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>
                                </>}
                        </action>
                    </wrap>
                </toolbar>}
            {/* </form> */}
        </DetailView>}
    </>);
}
