import React, { useCallback } from 'react';

import { ApiUrl } from '../../../project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from '../../../project/utilities';

import DropDownValueList from 'Shared/DropDown';
import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationInput } from 'Shared/Form/validation.js';

/**
 * the new zone editing part in BOM Detail
 * @exports BOM-SchemaZoneDetails
 */
export default function ZoneDetails(props) {
    const { windowKey, data, onDelete } = props;

    const onLoadData = useCallback((response) => {
        if (response) { // NOSONAR
        }
    }, []);

    const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, detailRef, validation, updating } = useDetail({
        data, updateUrl: ApiUrl.ZoneUpdate, windowKey, onLoadData: onLoadData,
        //validationFields: ["libelle"],
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }


        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE, { action: 'update' });
        dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_LIST_UPDATE2, { action: 'update' });

        if (isNew) {
            dispatchCustomEvent(EVENT_NAME.BILL_OF_MATERIAL_ZONE_SELECT, { action: 'select', data: response });
        }


        closePopup();
    }, [setDataItem, detailRef, closePopup, isNew]);

    const onChangeLabel = useCallback((e) => {
        const newDataItem = { ...dataItem }
        newDataItem.libelle = e.target.value;
        newDataItem.taille = newDataItem.libelle === '' ? 'hidden' : 'normale';
        setDataItem(newDataItem);
    }, [setDataItem, dataItem]);

    return (<>
        {dataItem && <div id='windowContent' className='window_cont' ref={detailRef}>
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.label">
                                <ValidationInput 
                                    name='libelle' 
                                    type='text' 
                                    value={dataItem.libelle} 
                                    onChange={e => onChangeLabel(e)} // NOSONAR
                                    validation={validation} 
                                    maxLength="255" 
                                />
                            </FormField>
                            <FormField label="text.size">
                                <DropDownValueList name='taille' type='text'
                                //    valuelist={
                                    //    [{ value: "mini", text: "mini" }, { value: "petite", text: "petite" }, { value: "normale", text: "normale" }, { value: "grande", text: "grande" }]
                                //}
                                    valuelist='zone_size'
                                    value={dataItem.libelle === '' ? 'hidden' : dataItem.taille}
                                    disabled={dataItem.libelle === '' ? true : false}
                                    onChange={e => onChangeData({ target: { name: 'taille', value: e } })} // NOSONAR
                                />
                            </FormField>
                        </div>

                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div 
                                onClick={(e) => updateData(updateSuccessCallback)} 
                                className='button' 
                                {...updating ? { disabled: 'disabled' } : {}}
                                disabled={(dataItem.libelle !== '') && (dataItem.taille === 'hidden') ? true : false }
                            >
                                <icon>save</icon><T>text.save</T>
                            </div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => { onDelete(dataItem, closePopup) }}>
                                    <icon>delete</icon><T>text.delete</T>
                                    </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </div>}
    </>);
}
