import { Dropdown } from 'antd';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Project } from '~/project/Project';
import { ApiUrl, AppPages } from '../../../project/Defines';
import ListView from '../../../Shared/ListView';
import { COLUMN_TYPE } from '../../../Shared/ListView/helper';

export default function Family(props) {
    const [groupWidth, setGroupWidth] = useState(0);

    const { parentId } = props;

    useLayoutEffect(() => {
        updateGroupWidth();
    });

    const updateGroupWidth = useCallback(() => {
        let group = document.querySelector('.group');
        let newWidth = group && group.clientWidth;

        if (newWidth >= 0 && newWidth !== groupWidth) {
            setGroupWidth(newWidth);
        }
    }, [groupWidth]);

    useEffect(() => {
        const timeOut = null;

        const resizeCallback = () => {
            if (timeOut) {
                clearTimeout(timeOut);
            }

            setTimeout(updateGroupWidth, 300);
        }

        window.addEventListener('resize', resizeCallback);

        return () => {
            window.removeEventListener('resize', resizeCallback);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const columnConfig = [
        {
            text: 'text.universe',
            field: 'univers_nom',
            width: 240,
            type: COLUMN_TYPE.TEXT,
        },
        {
            text: 'text.family',
            field: 'famille_nom',
            width: 240,
            type: COLUMN_TYPE.TEXT,
            onRenderLink: (record) => Project.getPageUrl(AppPages.UniverseDetails, record.famille_univers_uuid,
                {
                     tab: 1,
                     sub: 1,
                     familles: record.famille_uuid,
                    backUrl: Project.getPageUrl(AppPages.ProductDetails, parentId, { tab: 7 })
                }

            )
        },
        {
            text: 'text.country_groups',
            field: 'country_groups',
            render: (text, record) => { // NOSONAR
                if (text) {
                    const _text = text.map(t => JSON.parse(t));

                    return _text.map((countryGroup, index) => {
                        let countryNames = countryGroup.country_name ? countryGroup.country_name.split(',') : [];

                        let isLengthFine = true;
                        let totalCountriesLength = 0;
                        let countryCount = 0;
                        let characterLength = 10;

                        if (groupWidth !== null && groupWidth !== 0) {

                            while (isLengthFine) {
                                if (totalCountriesLength > groupWidth) {
                                    isLengthFine = false;
                                    countryCount--;
                                    totalCountriesLength -= (countryNames[countryCount].length * characterLength)
                                } else {
                                    isLengthFine = true;
                                    if (countryCount === countryNames.length) {
                                        isLengthFine = false;
                                    } else {
                                        totalCountriesLength = totalCountriesLength + (countryNames[countryCount].length * characterLength);
                                        countryCount++;
                                    }
                                }
                            }
                        }

                        return <div className="input_cont badge_wrap" key={index} // NOSONAR
                        >
                            <text><b>{countryGroup.slug}</b></text>
                            <group className='group' line="">
                            {
                                (countryGroup.country_name ?? '').split(',').slice(0, countryCount).map((country, i) => {
                                    return (
                                        <badge filled="" accent="" button="" key={i} // NOSONAR
                                        >
                                            <text>{country}</text>
                                        </badge>
                                    )
                                })
                            }
                            </group>
                            {
                                ((countryGroup.country_name ?? '').split(',').length > countryCount) &&
                                <ellipsis>
                                    <Dropdown
                                        className=""
                                        trigger={['click']}
                                        overlay={<div className="list_menu ant-dropdown-menu">
                                            <ul>
                                                {(countryGroup.country_name ?? '').split(',').slice(countryCount).map((item, i) => {
                                                    return (
                                                        <li key={i} // NOSONAR
                                                        ><icon></icon><text>{item}</text></li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        }
                                        overlayStyle={{maxHeight: 250, overflow: 'auto' }}
                                        // align={{offset: [5, 2], overflow: {adjustX: true}}}
                                        placement="topRight"
                                    >
                                        <div effect="material" className="">
                                            <text><counter>{(countryGroup.country_name ?? '').split(',').length - countryCount}</counter>More...</text>
                                        </div>
                                    </Dropdown>
                                </ellipsis>
                            }
                        </div>
                    })
                }
            },
            type: COLUMN_TYPE.TEXT
        },
    ];

    return <ListView
        {...props}
        isSimple
        skipEdit
        noPaging
        skipNewButton
        columnConfig={columnConfig}
        apiUrl={ApiUrl.FamilyProductList}
        staticFilter={{ produit_uuid: parentId }}
        deleteKeys={['famille_uuid', 'produit_uuid']}
    />;
}