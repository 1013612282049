import React, { useCallback } from 'react';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';


import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationInput, ValidationDropDownValueList } from 'Shared/Form/validation';

/**
 * the SSOLoing details
 * @exports Universe-SSO-SSOLoginDetails
 */
export default function SSOLoginDetails(props) {
    const { windowKey, univers_sites_uuid, ID, onDelete } = props;
    const listUpdateEvent = EVENT_NAME.UNIVERS_SITES_SSO_LIST_UPDATE;

    const onLoadData = useCallback((response) => {
        if (response) {
            if (univers_sites_uuid) {
                response.univers_sites_uuid = univers_sites_uuid;
            }
        }
    }, [univers_sites_uuid]);

    const { dataItem, setDataItem, updateData, onChangeData, closePopup, detailRef, validation } = useDetail({
        data: { ID: ID },
        updateUrl: ApiUrl.UniversSitesSSOUpdate,
        dataLoadUrl: ApiUrl.UniversSitesSSOLoad,
        windowKey,
        onLoadData: onLoadData,
        listUpdateEvent,
        validationFields: ['sso_uuid']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <div id='windowContent' className='window_cont' ref={detailRef}>
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections wide'>
                            <FormField label="text.sso_cible">
                                <ValidationDropDownValueList
                                    validation={validation}
                                    disableCach
                                    name='sso_uuid'
                                    type='text'
                                    value={dataItem.sso_uuid || ''}
                                    valuelist='sso_cible_select'
                                    onChange={e => onChangeData({ target: { name: 'sso_uuid', value: e } })} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.wso_client_id">
                                <ValidationInput validation={validation} name='wso_client_id' maxLength="255" type='text' value={dataItem.wso_client_id} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_client_secret">
                                <ValidationInput validation={validation} name='wso_client_secret' maxLength="255" type='text' value={dataItem.wso_client_secret} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_callback">
                                <ValidationInput validation={validation} name='wso_callback' type='text' value={dataItem.wso_callback} onChange={onChangeData} />
                            </FormField>
                            <FormField>
                                <label checkbox=''><input name='wso_allow_create_account' type='checkbox' checked={dataItem.wso_allow_create_account} onChange={onChangeData} disabled={!dataItem.allow_create_account} /><box><check></check></box><text><T>text.wso_allow_create_account</T></text></label>
                            </FormField>
                            <FormField>
                                <label checkbox=""><input name="active" type="checkbox" defaultChecked={dataItem.active} onChange={onChangeData} /><box><check></check></box><text><T>text.active</T></text></label>
                            </FormField>
                        </div>
                    </div>
                </div>

                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => { onDelete(dataItem, closePopup) }}>
                                    <icon>delete</icon><T>text.delete</T>
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </div>}
    </>);
}