import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { T } from '../../../components/Translations';
import { AppPages } from '../../../project/Defines';
import { Project } from '../../../project/Project';
import { FormField } from '../../../Shared/Form/formFields';


/**
 * the Replacement list of SparePart in Replacement tab
 * @exports SparePart-Replacement
 */
function Replacement(props) {
    const { dataItem /*, setDataItem, openPopup, closePopup*/ } = props;

    return (<>
        <FormField>
            <div className="input_cont" style={{ minHeight: "30px", paddingLeft: "5px" }}>
                {
                    dataItem.article_uuid ?
                        <Link to={Project.getPageUrl(AppPages.SparePartDetails, dataItem.article_uuid)}
                            style={{ color: "var(--main-color)", width: '100%' }}>
                            <text>{dataItem.is_replaced_by}</text>
                        </Link>
                        :
                        <T>text.none</T>
                }
                <i></i>
            </div>
        </FormField>
    </>);
}

export default connect(null, (dispatch) => ({
    //openPopup: (data) => dispatch(openPopup(data)),
    //closePopup: (windowKey) => dispatch(closePopup(windowKey))
}))(Replacement);

