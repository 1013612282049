import { ACTION } from './../actions';
import store from 'store';
import { useCacheData } from '../../Shared/CacheData'
import { useSWCache } from '../../Shared/SWCache'
import { StoreKeys } from '../../project/Defines'

const defaultState = {
    isLoggedIn: store.get(StoreKeys.LoggedIn),
    popups: [],
    listStates: store.get(StoreKeys.ListStates) || {},
    userToken: store.get(StoreKeys.UserToken),
    culture: store.get(StoreKeys.Culture),
    role: store.get(StoreKeys.Role),
    userName: store.get(StoreKeys.UserName),
    settings: store.get(StoreKeys.Settings),
    loaders: [],
};
export default (state = defaultState, action) => {
    switch (action.type) {
        case ACTION.LOGIN:
            store.set(StoreKeys.ExpireTime, (new Date()).setMinutes((new Date()).getMinutes() + 20));
            store.set(StoreKeys.UserToken, action.payload.userToken);
            store.set(StoreKeys.LoggedIn, true);
            store.set(StoreKeys.UserName, action.payload.userName);
            store.set(StoreKeys.Culture, action.payload.culture);
            store.set(StoreKeys.Role, action.payload.role);
            store.set(StoreKeys.Settings, action.payload.settings);

            return {
                ...state,
                isLoggedIn: true,
                userToken: store.get(StoreKeys.UserToken),
                role: store.get(StoreKeys.Role),
                userName: store.get(StoreKeys.UserName),
                settings: store.get(StoreKeys.Settings)
            };

        case ACTION.LOGOUT:
            store.remove(StoreKeys.Settings);
            store.remove(StoreKeys.UserToken);
            store.remove(StoreKeys.Role);
            store.remove(StoreKeys.UserName);
            store.remove(StoreKeys.LoggedIn);
            store.remove(StoreKeys.ExpireTime);

            useCacheData.clearOnLogout();
            useSWCache.clearOnLogout();
            return {
                ...state,
                isLoggedIn: false,
                userToken: '',
                userName: null,
                role: null
            };

        case ACTION.CHANGE_CULTURE:
            store.set(StoreKeys.Culture, action.data);
            return {
                ...state,
                culture: action.data
            };

        case ACTION.OPEN_POPUP: {
            const popups = [...state.popups];
            popups.push(action.data);
            return {
                ...state,
                popups
            };
        }

        case ACTION.CLOSE_POPUP:
            {
                const popups = [...state.popups];
                const index = popups.findIndex(p => p.windowKey === action.windowKey);
                if (index >= 0) {
                    popups.splice(index, 1);
                }
                return {
                    ...state,
                    popups
                };
            }
        case ACTION.UPDATE_POPUP_TITLE:
            {
                const popups = [...state.popups];
                const index = popups.findIndex(p => p.windowKey === action.windowKey);
                if (index >= 0) {
                    popups[index].title = action.title;
                }
                return {
                    ...state,
                    popups
                };
            }

        case ACTION.CLOSE_ALL_POPUPS: {
            return {
                ...state,
                popups: []
            };
        }
        case ACTION.SET_LIST_STATE: {
            const ls = {
                ...state.listStates,
                [action.payload.listName]: action.payload.state
            };
            store.set(StoreKeys.ListStates, ls);
            return {
                ...state,
                listStates: ls
            };
        }
        case ACTION.CLEAR_LIST_STATES: {
            store.remove(StoreKeys.ListStates);
            return {
                ...state,
                listStates: {}
            };
        }

        case ACTION.STORAGE_CHANGE: {
            return {
                ...state,
                isLoggedIn: store.get(StoreKeys.LoggedIn),
                listStates: store.get(StoreKeys.ListStates) || {},
                userToken: store.get(StoreKeys.UserToken),
                culture: store.get(StoreKeys.Culture),
                role: store.get(StoreKeys.Role),
                userName: store.get(StoreKeys.UserName),
                settings: store.get(StoreKeys.Settings)
            };
        }

        case ACTION.SET_LOADERS: {
            return {
                ...state,
                loaders: action.payload
            };
        }

        default:
            return state;
    }
}