import React, { useCallback, useEffect, useRef } from 'react';
import { InputNumber } from 'antd';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import CustomUpload from 'Shared/CustomUpload';
import { FormField } from 'Shared/Form/formFields';
import Diagnostics from './Diagnostics';
import { ValidationInput } from 'Shared/Form/validation';

/**
 * the Support list of Family's Support tab
 * @exports Universe-Family-Support
 */
export default function Support(props) {
    const { ID } = props;
    const listUpdateEvent = EVENT_NAME.FAMILY_LIST_UPDATE;
    const viewRef = useRef(null);

    const onLoadData = useCallback((response) => {
        response.famille_uuid = ID;
    }, [ID]);

    const { dataItem, setDataItem, updateData, onChangeData, detailRef, validation } = useDetail({
        data: { ID: "" },
        dataLoadParams: { famille_uuid: ID },
        updateUrl: ApiUrl.SavInfoUpdate,
        dataLoadUrl: ApiUrl.SavInfoLoad,
        onLoadData: onLoadData,
        validationFields: [{ name: "satc_email", rules: [{ type: "email" }] }],
        listUpdateEvent,
        reloadEvent: EVENT_NAME.FAMILY_SAV_INFO_UPDATE
    });

    useEffect(() => {
        viewRef.current = true;

        return () => { viewRef.current = false; }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

    }, [setDataItem, detailRef]);
    return (<>
        {dataItem &&
            <>
                <view vertical="" scroll="">
                    <div className="section_group">
                        <div className="sections s_medium">
                            <div className="form_fields"><div className="section_title"><text><T>text.user_information</T></text></div><div className="form_separator"></div></div>
                            <FormField label="text.referent">
                            <input name='referent' type='text' value={dataItem.referent} onChange={onChangeData} placeholder={dataItem.sav_info_parent.referent} />
                            </FormField>

                            <FormField label="text.commentaire">
                            <textarea name='commentaire' type='text' value={dataItem.commentaire} onChange={onChangeData} placeholder={dataItem.sav_info_parent.commentaire} />
                            </FormField>

                            <div className="field_group" start="start">
                                <FormField label="text.serial_number_of_a_product">
                                <textarea name='aide_saisie_no_serie_texte' type='text' value={dataItem.aide_saisie_no_serie_texte} onChange={onChangeData} placeholder={dataItem.sav_info_parent.aide_saisie_no_serie_texte} />
                                </FormField>
                                <FormField formFieldClass="compact" label="text.image">
                                    <CustomUpload
                                        imageUrl='image_url'
                                        imageField='images'
                                        dataItem={dataItem}
                                        setDataItem={setDataItem}
                                        type={'sav_info'}
                                        compact="compact"
                                    />
                                </FormField>
                            </div>

                            <FormField label="text.guaranteed_application">
                            <textarea name='application_garantie' type='text' value={dataItem.application_garantie} onChange={onChangeData} placeholder={dataItem.sav_info_parent.application_garantie} />
                            </FormField>

                            <FormField label="text.generic_warranty_procedure">
                            <textarea name='procedure_garantie_generique' type='text' value={dataItem.procedure_garantie_generique} onChange={onChangeData} placeholder={dataItem.sav_info_parent.procedure_garantie_generique} />
                            </FormField>

                            <FormField label="text.exceptional_warranty_procedure">
                            <textarea name='procedure_garantie_exceptionnelle' type='text' value={dataItem.procedure_garantie_exceptionnelle} onChange={onChangeData} placeholder={dataItem.sav_info_parent.procedure_garantie_exceptionnelle} />
                            </FormField>
                        </div>
                        <div className="sections s_medium">
                            <div className="form_fields"><div className="section_title"><text><T>text.contact_information_satc</T></text></div><div className="form_separator"></div></div>

                        <FormField label="text.email">
                            <ValidationInput validation={validation} name='satc_email' type='text' value={dataItem.satc_email} onChange={onChangeData} placeholder={dataItem.sav_info_parent.satc_email} />

                            </FormField>
                            <FormField label="text.address">
                            <textarea name='satc_adresse' type='text' value={dataItem.satc_adresse} onChange={onChangeData} placeholder={dataItem.sav_info_parent.satc_adresse} />
                            </FormField>

                            <FormField label="text.amount_of_labor">
                            <InputNumber name='montant_main_oeuvre' min={0} max={100000} value={dataItem.montant_main_oeuvre} onChange={(e) => { onChangeData({ target: { name: 'montant_main_oeuvre', value: e } }) }} placeholder={dataItem.sav_info_parent.montant_main_oeuvre} // NOSONAR
                            />
                            </FormField>
                        </div>

                        <div className="sections extra">
                            <div className="form_fields"><div className="section_title"><text><T>text.diagnostics</T></text></div><div className="form_separator"></div></div>

                            {
                                <Diagnostics {...props} sav_info_uuid={dataItem.ID} />
                            }
                        </div>
                    </div>
                </view>

                <toolbar>
                    <wrap>
                        <action right=''>
                            <div className="button" onClick={(e) => updateData(updateSuccessCallback)} ><icon>save</icon><T>text.save</T></div>
                        </action>
                    </wrap>
                </toolbar>
            </>
        }
    </>);
};