import React from 'react';
import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME } from 'project/utilities';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { Project } from '../../../project/Project';

/**
 * warranty type list of setup
 * @exports SetupWarrantyTypes
 */
export default function SetupWarrantyTypes(props) {

    const columnConfig = [
        {
            text: 'text.name',
            field: 'nom',
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.SetupWarrantyTypeDetails
        },
        {
            text: 'text.slug',
            field: 'slug',
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        },
    ];

    return (
        <ListView
            {...props}
            columnConfig={columnConfig}
            apiUrl={ApiUrl.WarrantyTypeList}
            deleteUrl={ApiUrl.WarrantyTypeDelete}
            detailUrl={Project.getPageUrl(AppPages.SetupWarrantyTypeDetails)}
            filters={[{ name: 'traduction', type: 'text', text: 'text.name' }]}
            newButtonTip='text.create_new_warranty_type'
            listUpdateEvent={EVENT_NAME.SETUP_WARRANTY_TYPE_LIST_UPDATE}
            detailProps={{ newTitle: 'text.create_new_warranty_type', editTitle: 'text.edit_warranty_type' }}
            listName={AppPages.SetupWarrantyTypes}
            showRefreshButton={false}/>

    );
}
