import React, { useState, useCallback, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { T } from '../../components/Translations';
import Scroll from '../Scroll/Scroll';

/**
 * custom tabstrip allowing additional functionality
 * @param {object} props  onChange, skipSecondaryTabs, selectedIndex
 */

export function TabStrip(props) {
    const { onChange, skipSecondaryTabs, selectedIndex, tabFilter } = props;
    const [selected, setSelected] = useState(0);
    const [uuid] = useState(uuidv4());
    const ref = useRef(false);
    const handleSelect = useCallback((e) => {
        if (onChange) {
            onChange(e.currentTarget.value || 0);
        }
        setSelected(e.currentTarget.value || 0)
    }, [onChange])

    useEffect(() => {
        ref.current = true;
        return () => { ref.current = false; };
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (ref.current) {
            setSelected(selectedIndex || 0);
        }
    }, [selectedIndex])

    const filterTabs = useCallback((tab) => {
        return tabFilter ? tabFilter(tab) : true;
    }, [tabFilter]);

    const children = (!skipSecondaryTabs && !tabFilter && props.children) || (props.children.filter && props.children.filter(child => child && child.props && (!skipSecondaryTabs || child.props.primary) && filterTabs(child))) || props.children;
    return <>
        <div className="tab_strip">
            <wrap id={uuid}>
                <Scroll>
                    <ul>
                        {
                            React.Children.map(children, (child, index) => {
                                return <li
                                    onClick={handleSelect}
                                    value={index}
                                    effect="material"
                                    className={selected === index ? "selected" : ""}
                                >
                                    {child.props.icon && <icon>{child.props.icon}</icon>}

                                    {child.props.title && <text><T>{child.props.title}</T></text>}

                                </li>
                            })
                        }
                    </ul>
                </Scroll>
            </wrap>
            <div>
                {
                    React.Children.map(children, (child, index) => {
                        return <div className={selected === index ? "selected" : ""}>
                            {child.props.children}
                        </div>;
                    })
                }

                {/*props.children*/}
            </div>
        </div>
    </>
}