import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';
import { Project } from 'project/Project';
import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from '../../../Shared/Form/formFields';
import { ValidationInput } from '../../../Shared/Form/validation';
import CustomUpload from '~/Shared/CustomUpload';

/**
 * Language detail of setup
 * @exports Setup-LanguageDetails
 */
export default function LanguageDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.SETUP_LANGUAGE_LIST_UPDATE;
    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.LanguageUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.LanguageLoad,
        nameField: 'lang',
        deleteUrl: ApiUrl.LanguageDelete,
        backPage: AppPages.SetupLanguages,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.SetupLanguageDetails),
        validationFields: ["lang"]
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }
        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    const onChangeLang = useCallback((e) => {
        let newData = { ...dataItem };
        newData.lang = e.target.value;
        newData.traduction = "languages." + e.target.value;
        setDataItem(newData);
    }, [dataItem, setDataItem]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.SetupLanguages)}
            title={isNew ? <T>text.create_new_language</T> : dataItem.lang}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.lang">
                                <ValidationInput validation={validation} autoComplete='off' name='lang' type='text' value={dataItem.lang} onChange={onChangeLang} maxLength="2" />
                            </FormField>
                            <FormField label="text.poid">
                                <ValidationInput validation={validation} autoComplete='off' name='poid' type='text' inputType='number' value={dataItem.poid || 8} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.traduction">
                                <ValidationInput validation={validation} autoComplete='off' name='traduction' type='text' value={dataItem.traduction} readOnly={true} />
                            </FormField>
                            <FormField label="text.flag">
                                <CustomUpload
                                    imageField='image'
                                    dataItem={dataItem}
                                    setDataItem={setDataItem}
                                    isLocalUpload={true}
                                    maxHeight={30}
                                    maxWidth={30}
                                />
                            </FormField>
                            <FormField label="text.index">
                                <ValidationInput validation={validation} autoComplete='off' name='index' type='text' inputType='number' value={dataItem.index} onChange={onChangeData} />
                            </FormField>
                            <FormField >
                                <label checkbox=''><input name='active' type='checkbox' defaultChecked={dataItem.active} onChange={onChangeData} /><box><check></check></box><text><T>text.active</T></text></label>
                            </FormField>
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon><T>text.delete</T>
                                </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
