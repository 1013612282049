import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import { Project } from 'project/Project';

import { T } from 'components/Translations';

import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields';
import { ValidationInput } from '../../../Shared/Form/validation';
import { Textarea } from '~/Shared/Input';

/**
 * ...
 * @exports AllowedIPDetails
 */
export default function AllowedIPDetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.ALLOWED_IP_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.AuthorizationAccessUpdate,
        dataLoadUrl: ApiUrl.AuthorizationAccessLoad,
        deleteUrl: ApiUrl.AuthorizationAccessDelete,
        nameField: 'ip',
        backPage: AppPages.AllowedIP,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.AllowedIPDetails),
        validationFields: ['ip']
    });
    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.AllowedIP)}
            title={isNew ? <T>text.new_allowed_ip</T> : dataItem.ip}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.ip">
                                <ValidationInput validation={validation} name='ip' type='text' value={dataItem.ip} onChange={onChangeData} />
                            </FormField>
                        </div>
                    </div>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.description">
                                <Textarea name='description' type='text' value={dataItem.description} onChange={onChangeData} />
                            </FormField>
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon><T>text.delete</T>
                                    </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
