import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';
/**
 * replaces the content with it's translation if givent flat value from translations object in locals
 * @param {object} props args, children
 */
export const T = props => {
    const { t } = useTranslation();

    const { args, children } = props;
    let str = children || "";

    if (Array.isArray(str))
        str = str.find((s) => s.trim() !== "") || "";

    if (str && str.indexOf && str.indexOf(".") !== -1)
        str = t(str.trim());

    if (args) {
        for (let i = 0; i < props.args.length; i++) {
            str = str.replace(`\${args[${i}]}`, args[i])
        }
    }
    return str;
}

export const ComplexT = props => {
    const { t } = useTranslation();

    const { args, children } = props;
    let str = children || "";
    if (Array.isArray(str))
        str = str.find((s) => s.trim() !== "") || "";

    if (str && str.indexOf && str.indexOf(".") !== -1)
        str = t(str.trim());

    let result = [];
    if (args) {
        let temp = str;
        let reg = new RegExp('\\$\\{args\\[(\\d)\\]\\}'); // NOSONAR
        let match;
        do {
            match = reg.exec(temp);
            if (match) {
                result.push(temp.substr(0, match.index), { key: match[0], value: args[match[1]] });
                temp = temp.substr(match.index + match[0].length);
            } else {
                result.push(temp);
            }
        }
        while (match)
        return <>
            {result.map((r, i) => <Fragment key={i + (r.key || r)} // NOSONAR
            >{r.value || r}</Fragment>)} 
        </>
    }

    return str;
}

/**
 * extended version of antd tooltip supporting trasnlation
 * @param {any} props
 */
export const DataTip = props => {
    const { t } = useTranslation();

    return <Tooltip placement="top" title={typeof props.title === 'string' && props.title.indexOf(".") !== -1 ? t(props.title) : props.title}>
        {props.children}
    </Tooltip>
}

/**
 * input supporting translation for placeholder
 * @param {any} props
 */
export const Input = props => {
    const { t } = useTranslation();

    let p = Object.assign({}, props)
    if (p.placeholder)
        p.placeholder = t(p.placeholder);

    return <input {...p}>
        {props.children}
    </input>
}