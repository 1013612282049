import React from 'react';
import { ApiUrl, AppPages } from '../../project/Defines.js';
import { Project } from '../../project/Project';
import { EVENT_NAME } from '../../project/utilities';
import ListView from '../../Shared/ListView';
import { COLUMN_TYPE } from '../../Shared/ListView/helper';
import { PopupTypes } from '../../components/Popup.js';
import { Link } from 'react-router-dom';
import { T } from '../../components/Translations.js';

/**
 * the SparePart page main List
 * @exports SparePart
 */
export default function SparePart(props) {
    const columnConfig = [
        {
            text: 'text.reference',
            field: 'reference',
            width: 150,
            type: COLUMN_TYPE.TEXT,
            linkAppPage: AppPages.SparePartDetails
        },
        {
            text: 'text.name',
            field: 'designation',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.supplier_code',
            field: 'fournisseur',
            width: 150,
            type: COLUMN_TYPE.TEXT
        },
        {
            type: COLUMN_TYPE.ACTION,
        }
    ];

    return (
        <>
            <toolbar light='' shadow=''>
                <Link to={Project.getPageUrl(AppPages.SparePartImagesUpload)}><div className='button accent' ><text><T>text.article_images</T></text></div></Link>
            </toolbar>
            <ListView
                {...props}
                columnConfig={columnConfig}
                apiUrl={ApiUrl.ArticleList}
                deleteUrl={ApiUrl.ArticleDelete}
                detailUrl={Project.getPageUrl(AppPages.SparePartDetails)}
                filters={[
                    { name: 'reference', type: 'text', text: 'text.reference', includeEmpty: true },
                    { name: 'designation', type: 'text', text: 'text.name', includeEmpty: true },
                    { name: 'fournisseur', type: 'text', text: 'text.supplier_code', includeEmpty: true }
                ]}
                nameField='designation'
                newButtonTip='text.create_new_spare_part'
                listUpdateEvent={EVENT_NAME.SPARE_PART_LIST_UPDATE}
                detailProps={{ newTitle: 'text.spare_part', editTitle: 'text.edit_spare_part', popupType: PopupTypes.SparePartDetails, windowKey: 'wndSparePartDetail' }}
                listName={AppPages.SpareParts}
                onLoaded={(data) => { // NOSONAR
                    if (data && data.length) {
                        data.forEach((item, index) => item.skipdelete = item.code && item.code.length>0);
                    }
                    return data;
                }}
            />
        </>
    );
}
