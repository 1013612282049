import React, { useCallback } from 'react';
import { ApiUrl } from 'project/Defines.js';

import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import { DatePicker } from 'Shared/DatePicker';
import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import CustomUpload from 'Shared/CustomUpload';
import FamilyInfos from './FamilyInfos';

/**
 * the Infos list of Family's Infos tab
 * @exports Universe-Family-Infos
 */
export default function Infos(props) {
    const listUpdateEvent = EVENT_NAME.FAMILY_LIST_UPDATE;
    
    const { dataItem, setDataItem, updateData, onChangeData, detailRef } = useDetail({
        data: props, //{ id: ID },
        updateUrl: ApiUrl.FamiliesUpdate,
        dataLoadUrl: ApiUrl.FamiliesLoad,
        nameField: 'nom',
        deleteUrl: ApiUrl.FamiliesDelete,
        listUpdateEvent
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }
        
        dispatchCustomEvent(listUpdateEvent, { action: 'refreshNode' });
    }, [setDataItem, listUpdateEvent, detailRef]);

    return (<>
        {dataItem &&
            <>
            <div className="section_group">
                <div className="sections ">
                    <div className="form_fields"><div className="section_title"><text>Options</text></div><div className="form_separator"></div></div>
                    <div className="form_fields">
                        <label checkbox="">
                            <input name="visible" type="checkbox" disabled={!!dataItem.masked} defaultChecked={dataItem.masked ? !!dataItem.masked : !dataItem.visible} onChange={(e) => onChangeData({ target: { name: "visible", value: !e.target.checked } })} /><box style={dataItem.masked ? { color: 'grey' } : {}}><check></check></box><text><T>text.family_masked</T></text>
                        </label>
                    </div>
                    <div className="form_fields">
                        <div className="data_label"><T>text.date_begin</T></div>
                        <DatePicker
                            dataField="date_debut"
                            dataItem={dataItem}
                            setDataItem={setDataItem}
                            max={dataItem.date_fin}
                        />
                    </div>
                    <div className="form_fields">
                        <div className="data_label"><T>text.date_end</T></div>
                        <DatePicker
                            dataField="date_fin"
                            dataItem={dataItem}
                            setDataItem={setDataItem}
                            min={dataItem.date_debut}
                        />
                    </div>
                </div>
                <div className="sections ">
                    <div className="form_fields"><div className="section_title"><text><T>text.image</T></text></div><div className="form_separator"></div></div>
                    <div className="form_fields">
                        <CustomUpload
                            imageUrl='image_url'
                            imageField='images'
                            dataItem={dataItem}
                            setDataItem={setDataItem}
                            type={'familles'}
                            className="big"
                        />
                    </div>
                </div>

                <div className="sections wide" style={{"marginBottom": "50px"}}>
                    <div className="form_fields"><div className="section_title"><text><T>text.general_informations</T></text></div><div className="form_separator"></div></div>

                    {
                        <FamilyInfos {...props} />
                    }
                </div>
            </div>
            <toolbar>
                <wrap>
                    <action right=''>
                        <div className="button" onClick={(e) => updateData(updateSuccessCallback) } ><icon>save</icon><T>text.save</T></div>
                    </action>
                </wrap>
            </toolbar>
            </>
        }
    </>);
};