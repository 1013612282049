import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';

import { Project } from 'project/Project';

import useDetail from 'Shared/useDetail';
import DetailView from 'Shared/DetailView';
import { FormField } from 'Shared/Form/formFields';
import { /*ValidationDropDownValueList,*/ ValidationInput } from '../../../Shared/Form/validation';
import { MultiSelectValueList } from 'Shared/DropDown';

/**
 * ...
 * @exports SSODetails
 */
export default function SSODetails(props) {
    const { id } = useParams();
    const { windowKey, data } = props;
    const listUpdateEvent = EVENT_NAME.SSO_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, closePopup, onDetailDelete, detailRef, onChangeData, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        windowKey,
        updateUrl: ApiUrl.SSOUpdate,
        dataLoadUrl: ApiUrl.SSOLoad,
        deleteUrl: ApiUrl.SSODelete,
        nameField: 'nom',
        backPage: AppPages.SSO,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.SSODetails),
        validationFields: ['nom', 'code']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.SSO)}
            title={isNew ? <T>text.new_sso</T> : dataItem.nom}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <div className="form_fields"><div className="section_title"><text><T>text.sso</T></text></div><div className="form_separator"></div></div>
                            <FormField label="text.name">
                                <ValidationInput validation={validation} name='nom' maxLength="255" type='text' value={dataItem.nom} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.code">
                                <ValidationInput validation={validation} name='code' maxLength="255" type='text' value={dataItem.code} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.brands">
                                <MultiSelectValueList
                                    name="brands"
                                    valuelist="email_brands"
                                    disableCach
                                    parentValue="sso"
                                    {... (dataItem.brands ? { value: dataItem.brands } : { value: [] })}
                                    onChange={(e) => { onChangeData({ target: { name: "brands", value: e } }); }} // NOSONAR
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }}
                                />
                            </FormField>
                            <FormField label="text.wso_token_name">
                                <ValidationInput validation={validation} name='wso_token_name' maxLength="255" type='text' value={dataItem.wso_token_name} onChange={onChangeData} />
                            </FormField>
                            <FormField>
                                <label checkbox=''><input name='active' type='checkbox' checked={dataItem.active} onChange={onChangeData} /><box><check></check></box><text><T>text.active</T></text></label>
                            </FormField>
                        </div>
                        <div className='sections '>
                            <div className="form_fields"><div className="section_title"><text><T>text.wso</T></text></div><div className="form_separator"></div></div>
                            <FormField label="text.wso_base_api_url">
                                <ValidationInput validation={validation} name='wso_base_api_url' maxLength="255" type='text' value={dataItem.wso_base_api_url} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_end_point">
                                <ValidationInput validation={validation} name='wso_end_point' maxLength="255" type='text' value={dataItem.wso_end_point} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_client_id">
                                <ValidationInput validation={validation} name='wso_client_id' maxLength="255" type='text' value={dataItem.wso_client_id} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_client_secret">
                                <ValidationInput validation={validation} name='wso_client_secret' maxLength="255" type='text' value={dataItem.wso_client_secret} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_basic_token">
                                <ValidationInput validation={validation} name='wso_basic_token' maxLength="255" type='text' value={dataItem.wso_basic_token} onChange={onChangeData} />
                            </FormField>
                        </div>
                        <div className='sections '>
                            <div className="form_fields"><div className="section_title"><text><T>text.url</T></text></div><div className="form_separator"></div></div>
                            <FormField label="text.wso_url_access_token">
                                <ValidationInput validation={validation} name='wso_url_access_token' maxLength="255" type='text' value={dataItem.wso_url_access_token} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_url_authorize">
                                <ValidationInput validation={validation} name='wso_url_authorize' maxLength="255" type='text' value={dataItem.wso_url_authorize} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_url_logout">
                                <ValidationInput validation={validation} name='wso_url_logout' maxLength="255" type='text' value={dataItem.wso_url_logout} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_url_ressource_detail">
                                <ValidationInput validation={validation} name='wso_url_ressource_detail' maxLength="255" type='text' value={dataItem.wso_url_ressource_detail} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_password_lost">
                                <ValidationInput validation={validation} name='wso_password_lost' maxLength="2048" type='text' value={dataItem.wso_password_lost} onChange={onChangeData} />
                            </FormField>

                        </div>
                        <div className='sections '>
                            <div className="form_fields"><div className="section_title"><text><T>text.pro_account</T></text></div><div className="form_separator"></div></div>
                
                            <FormField label="text.wso_url_user_pro">
                                <ValidationInput validation={validation} name='wso_url_user_pro' maxLength="255" type='text' value={dataItem.wso_url_user_pro} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_url_societe_pro">
                                <ValidationInput validation={validation} name='wso_url_societe_pro' maxLength="255" type='text' value={dataItem.wso_url_societe_pro} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.wso_allow_create_account">
                                <label checkbox=''><input name='wso_allow_create_account' type='checkbox' checked={dataItem.wso_allow_create_account} onChange={onChangeData} /><box><check></check></box><text><T>text.wso_allow_create_account</T></text></label>
                            </FormField>
                            {
                                dataItem.wso_allow_create_account &&
                                <FormField label="text.wso_account_create_url">
                                    <ValidationInput validation={validation} name='wso_account_create_url' maxLength="255" type='text' value={dataItem.wso_account_create_url} onChange={onChangeData} />
                                </FormField>
                            }
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            {dataItem.ID &&
                                <div className='button' onClick={(e) => {
                                    onDetailDelete(dataItem)
                                }}>
                                    <icon>delete</icon><T>text.delete</T>
                                    </div>}
                        </action>
                    </wrap>
                </toolbar>
            </form>
        </DetailView>}
    </>);
}
